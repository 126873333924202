import { throttle } from 'lodash';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import type { AxiosInstance } from 'axios';

import { performUserLogoutWhenTokenHasExpired, refreshToken } from 'src/actions/authActions';
import { store } from 'src/store';

const throttledLogout = throttle(() => {
  store.dispatch(performUserLogoutWhenTokenHasExpired() as any);
}, 3000);

export const attachAuthAxiosInterceptor = (axiosInstances: AxiosInstance[]) => {
  const NOT_ACCEPTABLE = 406;
  const refreshAuthLogic = async () => store.dispatch(refreshToken(true) as any);

  axiosInstances.forEach((instance: AxiosInstance) => {
    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status && error.response.status === NOT_ACCEPTABLE) {
          throttledLogout();
        }

        return Promise.reject(error);
      }
    );

    createAuthRefreshInterceptor(instance, refreshAuthLogic);
  });
};
