import { FETCH_PHONE_CONFIGURATIONS_SUCCESS } from '../actions/index';

const initialState: any = [];

const configurationsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_PHONE_CONFIGURATIONS_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default configurationsReducer;
