import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Form, Icon } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';
import type { ChangeEvent, SyntheticEvent } from 'react';

import KBApi from 'src/api/KBApi';
import ErrorBoundary from 'src/ErrorBoundary';
import AccordionHeader from 'src/Components/Case/AccordionHeader';
import ArticleListItem from './ArticleListItem/ArticleListItem';
import { getTags } from 'src/Utilities/tags';
import { parseContent } from 'src/Utilities/parseUtils';
import type { State } from 'src/types/initialState';
import type { ListArticle } from 'src/types/Articles';

import './SuggestedArticles.css';

interface SuggestedArticlesProps extends ConnectedProps<typeof connector> {
  id: string;
}

const SuggestedArticles = ({ id, articleId, allTags }: SuggestedArticlesProps) => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState<ListArticle[]>([]);
  const [toggle, setToggle] = useState(false);
  const [term, setTerm] = useState('');
  const [search, setSearch] = useState<ListArticle[]>([]);

  const defaultTag = allTags.find((t) => t.name === 'default');

  useEffect(() => {
    const controller = new AbortController();

    if (articleId) {
      KBApi.getRelatedArticles({ articleId, signal: controller.signal })
        .then((articles) =>
          articles.map((article) => ({ ...article, tags: article.tags.filter((tagId) => tagId !== defaultTag?.id) }))
        )
        .then(setArticles);
    }

    return () => {
      controller.abort();
    };
  }, [articleId]);

  const onToggle = useCallback(() => {
    setToggle(!toggle);
  }, [toggle]);
  const onInputChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  }, []);
  const onSearchClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (term && articleId) {
        toggle
          ? KBApi.searchArticles(term).then(setSearch)
          : KBApi.getRelatedArticles({ articleId, term }).then(setArticles);
      }
    },
    [term, toggle]
  );
  const onCloseClick = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setTerm('');
      if (articleId) {
        toggle ? setSearch([]) : KBApi.getRelatedArticles({ articleId }).then(setArticles);
      }
    },
    [term]
  );

  const items = toggle ? search : articles;

  return (
    <ErrorBoundary>
      <AccordionHeader as="h4" id={id} title={t('widgets.suggested_articles.heading')} active={true} icon="info circle">
        <Form>
          <Form.Group inline style={{ marginBottom: '0px' }}>
            <Form.Input fluid width={'14'} action>
              <input onChange={onInputChange} value={term} />
              <Button icon primary labelPosition="left" onClick={onSearchClick} disabled={!term}>
                {t('widgets.suggested_articles.search')}
                <Icon name={term ? 'close' : 'search'} link={!!term} onClick={term ? onCloseClick : undefined} />
              </Button>
            </Form.Input>
            <Form.Checkbox
              toggle
              width={'4'}
              label={toggle ? t('widgets.suggested_articles.all') : t('widgets.suggested_articles.suggested')}
              checked={toggle}
              onChange={onToggle}
            />
          </Form.Group>
        </Form>
        {!!items?.length && (
          <div className="scrollable-container">
            {items.map((item, index) => (
              <ArticleListItem
                key={`article-item-${index}`}
                title={item.title}
                content={parseContent(item.content, item.attachments, true)}
                term={term}
                tags={getTags(item.tags, allTags)}
              />
            ))}
          </div>
        )}
      </AccordionHeader>
    </ErrorBoundary>
  );
};

const connector = connect((state: State) => ({
  articleId: state.activeTicketTab,
  allTags: state.tags
}));

export default connector(SuggestedArticles);
