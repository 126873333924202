import React from 'react';
import { connect, useDispatch } from 'react-redux';
import type { MemoExoticComponent } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { ReactImageGalleryItem } from 'react-image-gallery';

import ApiConfig from 'src/api/ApiConfig';
import { closeAttachmentsPreview } from 'src/actions/attachmentsActions';
import Lightbox from '../../generic/Lightbox/Lightbox';
import ItemDescription from './ItemDescription/ItemDescription';
import CSVViewer from './CSVViewer/CSVViewer';
import ImageViewer from './ImageViewer';
import PdfViewer from './PdfViewer';
import TxtViewer from './TxtViewer';
import VideoViewer from './VideoViewer';
import { isLightboxType } from 'src/Utilities/lightbox';
import type { Attachment } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

interface AttachmentsLightboxProps extends ConnectedProps<typeof connector> {
  ticketId: string;
  attachments: Attachment[];
}

const getUrl = (uri: string) => `${ApiConfig.getConfig().API_URL}/file/${uri}`;

type ViewerComponent = MemoExoticComponent<(props: { src: string }) => JSX.Element>;
const getItemRender =
  (Component: ViewerComponent) =>
  ({ original, description }: ReactImageGalleryItem) =>
    (
      <ItemDescription description={description}>
        <Component src={original} />
      </ItemDescription>
    );

const getItem = (attachment: Attachment): ReactImageGalleryItem => {
  const { uri, fileName: description } = attachment;
  const original = getUrl(uri);
  switch (true) {
    case /^.*\.pdf$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(PdfViewer)
      };
    case /^.*\.csv$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(CSVViewer)
      };
    case /^.*\.txt$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(TxtViewer)
      };
    case /^.*\.(mov|mp4)$/i.test(uri):
      return {
        original,
        description,
        renderItem: getItemRender(VideoViewer)
      };
    default:
      return {
        original,
        description,
        renderItem: getItemRender(ImageViewer)
      };
  }
};

const AttachmentsLightbox = ({ attachments, open, firstAttachmentId }: AttachmentsLightboxProps) => {
  const dispatch = useDispatch();
  const items: ReactImageGalleryItem[] = [...attachments]
    .sort((_, a) => (a.id === firstAttachmentId ? 1 : -1))
    .filter(isLightboxType)
    .map((a) => getItem(a));

  return (
    <Lightbox
      open={open}
      items={items}
      onClose={() => {
        dispatch(closeAttachmentsPreview());
      }}
    />
  );
};

const connector = connect((state: State) => ({
  open: state.attachments.previewAttachments,
  firstAttachmentId: state.attachments.attachmentId
}));

export default connector(AttachmentsLightbox);
