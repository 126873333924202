import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { startAjaxCall } from './ajaxStatusActions';
import { initialRequestFailure, initialRequestSuccess } from './initActions';
import EnvSettings, { EnvSettingsParameters } from '../api/EnvSettings';
import FeatureFlags, { TFeatureFlag } from '../api/FeatureFlags';
import ConfigurationApi from '../api/ConfigurationApi';
import { FETCH_PHONE_CONFIGURATIONS_SUCCESS, FETCH_PHONE_CONFIGURATIONS_FAILURE } from './index';

export const fetchPhoneConfigurationsSuccess = (configurations: any) => {
  return { type: FETCH_PHONE_CONFIGURATIONS_SUCCESS, payload: configurations };
};
export const fetchPhoneConfigurationsFailure = (configurations: any) => {
  return { type: FETCH_PHONE_CONFIGURATIONS_FAILURE, payload: configurations };
};

export const fetchEnvSettings: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_ENV_SETTINGS' }));
    return ConfigurationApi.getEnvSettings()
      .then((envSettings: EnvSettingsParameters) => {
        EnvSettings.setSettings(envSettings);
        dispatch(initialRequestSuccess('envSettings'));
      })
      .catch((error: Error) => {
        console.error('Failed to load env settings', error);
        dispatch(initialRequestFailure('envSettings', error));
      });
  };
};

export const fetchFeatureFlags = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_FEATURE_FLAGS' }));
    return ConfigurationApi.getFeatureFlags()
      .then((featureFlags: TFeatureFlag[]) => {
        FeatureFlags.setFlags(featureFlags);
        dispatch(initialRequestSuccess('featureFlags'));
      })
      .catch((error: Error) => {
        console.error('Failed to load featureFlags', error);
        dispatch(initialRequestFailure('featureFlags', error));
      });
  };
};

export const fetchPhoneConfigurations: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_ELISA_CONFIGURATIONS' }));
    return ConfigurationApi.getPhoneConfigurations()
      .then((configurations: any) => {
        dispatch(fetchPhoneConfigurationsSuccess(configurations));
        dispatch(initialRequestSuccess('PhoneConfigurations'));
      })
      .catch((error: Error) => {
        console.error('Failed to load PhoneConfigurations', error);
        dispatch(initialRequestFailure('PhoneConfigurations', error));
        dispatch(fetchPhoneConfigurationsFailure(error));
      });
  };
};
