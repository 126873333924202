import React, { useEffect, useMemo } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Comment, Icon } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';

import { parseContent } from 'src/Utilities/parseUtils';
import { sanitizeHTML } from 'src/Utilities/sanitize';
import { addWrapForPreElement, translateContent } from 'src/Utilities/userComment';
import type { Attachment, Comment as TicketComment } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

const EXPAND_COMMENT_HEIGHT = 150;

interface CommentTextProps extends CommentTextReduxProps {
  content?: string | null;
  isChatComment: boolean;
  isCommentExpanded: boolean;
  isLastComment: boolean;
  attachments: Attachment[];
  botButtonClickedState?: TicketComment;
  metaData?: TicketComment['metaData'];
  type: TicketComment['type'];
  expandComment(expand: boolean): void;
  toggleExpand(): void;
  onParsedContentChange(parsedContent: string): void;
}

const CommentText = ({
  content,
  isChatComment,
  isCommentExpanded,
  isLastComment,
  botButtonClickedState,
  metaData,
  type,
  attachments,
  expandComment,
  toggleExpand,
  onParsedContentChange,
  activeTicketId,
  showImages
}: CommentTextProps) => {
  const htmlMetaData = metaData?.html;
  const source = (htmlMetaData ?? content)!;
  const nToBr = !htmlMetaData;

  const commentTextReference = React.useRef<HTMLDivElement>(null);
  const [commentTextHeight, setCommentTextHeight] = React.useState(0);
  const parsedContent = useMemo(
    () => sanitizeHTML(translateContent(parseContent(source, attachments, nToBr), type)),
    [activeTicketId, showImages]
  );

  useEffect(() => {
    onParsedContentChange(parsedContent);
  }, [parsedContent]);

  useEffect(() => {
    if (commentTextReference.current) {
      setCommentTextHeight(commentTextReference.current.clientHeight);
    }
  }, []);

  return (
    <Translation>
      {(t) => (
        <Comment.Text style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
          {isChatComment && (
            <div className={`Comment-Content images-max-w-95`}>
              <em>{parsedContent}</em>
            </div>
          )}
          {!isChatComment && !botButtonClickedState && (
            <div ref={commentTextReference}>
              <div
                onClick={() => expandComment(commentTextHeight > EXPAND_COMMENT_HEIGHT)}
                className={`${addWrapForPreElement(htmlMetaData)} Comment-Content images-max-w-95 ${
                  !isCommentExpanded && !isLastComment && commentTextHeight > EXPAND_COMMENT_HEIGHT
                    ? 'collapsedComment cursorPointer'
                    : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: parsedContent
                }}
              />

              {!isLastComment && commentTextHeight > EXPAND_COMMENT_HEIGHT && (
                <div>
                  <div className="expandCommentIcon">
                    <Icon name={!isCommentExpanded ? 'chevron down' : 'chevron up'} onClick={toggleExpand} />
                  </div>
                </div>
              )}
            </div>
          )}

          {!!metaData?.buttons && (
            <div className="Comment-Content images-max-w-95" style={{ marginTop: '6px' }}>
              {metaData.buttons.map((buttonData) => (
                <Button size="tiny" disabled={true}>
                  {buttonData.label}
                </Button>
              ))}
            </div>
          )}

          {!!botButtonClickedState && (
            <div className="Comment-Content images-max-w-95" style={{ marginTop: '6px' }}>
              <p style={{ marginBottom: '5px' }}>
                {`${t('AUTOMATIC_COMMENT_BOT_CLICKED_BUTTON')} `}
                {
                  botButtonClickedState.metaData?.buttons?.find(
                    (button) => button.buttonState === metaData?.userClickedButton
                  )?.label
                }
              </p>

              {botButtonClickedState.metaData?.buttons?.map((buttonData) => (
                <Button
                  size="tiny"
                  primary={buttonData.buttonState === metaData?.userClickedButton}
                  disabled={buttonData.buttonState !== metaData?.userClickedButton}
                  style={{
                    pointerEvents: 'none'
                  }}
                >
                  {buttonData.label}
                </Button>
              ))}
            </div>
          )}
        </Comment.Text>
      )}
    </Translation>
  );
};

const connector = connect((state: State) => ({
  activeTicketId: state.activeTicketTab,
  showImages: !!state.commentsMenu.showImages[state.activeTicketTab!]
}));

type CommentTextReduxProps = ConnectedProps<typeof connector>;

export default connector(CommentText);
