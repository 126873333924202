import type { AxiosError, AxiosResponse } from 'axios';
import axios from 'axios';
import ApiConfig from './ApiConfig';
import type { EntityResponse } from 'src/types/Ticket';

interface GetEntityIdParams {
  ticketId: string;
  entityId: string;
  entityType: string;
  taskType: string;
}

const GET_ENTITY_BY_ID = '/entities/';
const VERIFY = `${GET_ENTITY_BY_ID}verify`;
const VERIFY_OPEN = `${GET_ENTITY_BY_ID}verifyOpen`;

export default class EntityApi {
  static getEntityById = (params: GetEntityIdParams): Promise<EntityResponse> => {
    const query = `?entityType=${params.entityType}&ticketType=${params.taskType}`;
    const url = `${ApiConfig.getConfig().API_URL}${GET_ENTITY_BY_ID}${params.entityId}`;
    return axios
      .get(url + query)
      .then((response: AxiosResponse<any>) => {
        const result: EntityResponse = {
          _id: params.entityId,
          _type: params.entityType,
          hasAccess: true,
          ticketId: params.ticketId,
          statusCode: response.status,
          data: response.data
        };
        return result;
      })
      .catch((error: AxiosError) => {
        const errorCode = error.response?.status;
        if (errorCode === 400 || errorCode === 500 || errorCode === 502) {
          return {
            _id: params.entityId,
            _type: params.entityType,
            hasAccess: true,
            ticketId: params.ticketId,
            errorMessage: error.message,
            statusCode: errorCode
          };
        } else if (errorCode === 401 || errorCode === 403) {
          return {
            _id: params.entityId,
            _type: params.entityType,
            hasAccess: false,
            ticketId: params.ticketId,
            errorMessage: error.message,
            statusCode: errorCode
          };
        } else {
          return {
            _id: params.entityId,
            _type: params.entityType,
            hasAccess: true,
            ticketId: params.ticketId,
            errorMessage: error.message,
            statusCode: -1
          };
        }
      });
  };

  static verify = async (contentId: string, fields: string[]) => {
    const url = `${ApiConfig.getConfig().API_URL}${VERIFY}`;
    return (await axios.post(url, { contentId, fields })).data;
  };

  static verifyOpen = async (contentId: string) => {
    const url = `${ApiConfig.getConfig().API_URL}${VERIFY_OPEN}`;
    return await axios.post(url, { contentId });
  };
}
