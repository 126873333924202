import { UPDATE_CHAT_VISITOR_TYPE_STATUS } from '../actions/index';
import type Status from '../types/ChatTypeStatus';

const initialState: Status[] = [];

const statusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CHAT_VISITOR_TYPE_STATUS: {
      const {
        composing_status: chatStatus,
        ticketId: changedTypeStatusTicketId,
        chatType
      } = action.payload.data as {
        chatType: string;
        chat_id: string;
        composing_status: string;
        ticketId: string;
      };
      const currentChatTicketStatus = state.find((status) => status.ticketId === changedTypeStatusTicketId);

      if (currentChatTicketStatus) {
        const newStatus = { ...currentChatTicketStatus, status: chatStatus };
        return [...state.filter((obj) => obj.ticketId !== changedTypeStatusTicketId), newStatus];
      } else {
        const newStatus = { status: chatStatus, ticketId: changedTypeStatusTicketId, type: chatType };
        return [...state, newStatus];
      }
    }
    default:
      return state;
  }
};

export default statusReducer;
