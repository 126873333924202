import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TicketsApi from 'src/api/TicketsApi';
import ElisaRing from 'src/Components/PhoneServices/ElisaRing';
import SocketInstance from 'src/realTimeNotifications';
import { activateTab, addTab } from 'src/actions/tabActionsRTK';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import { removeCallRequest } from 'src/actions/phoneActions';
import {
  activateTicket,
  addEntityToCase,
  addTicket,
  createContent,
  searchCustomerByDetail,
  updateSingleTicketCaseDetail
} from 'src/actions/ticketsActions';
import { addComment } from 'src/actions/commentsActions';
import { startWorkingOn } from 'src/Utilities/workStatusParser';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ...ownProps,
    callRequests: state.callRequests,
    userData: state.userData,
    configurations: state.configurations,
    ticketTypes: state.ticketTypes
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeCallRequest: (ticketId: number, phoneNumber: string) => {
      dispatch(
        removeCallRequest({
          ticketId,
          phoneNumber
        })
      );
    },
    handleIncomingPhoneCall: async (
      originalNumber: string,
      UID: string,
      ticketData: any,
      searchObject: any,
      serviceName: string | null
    ) => {
      // createTicket
      const normalizedNumber = normalizePhoneNumber(originalNumber);

      ticketData.originalContact = normalizedNumber;
      const ticket = await dispatch(createContent(ticketData));

      // now with ticket data create some tabs
      SocketInstance.joinRoom(ticket.id);
      await dispatch(addTicket(ticket));
      await dispatch(addTab({ id: ticket.id, type: 'tickets' }));
      await dispatch(activateTicket(ticket.id));
      await dispatch(activateTab(ticket.id));
      await dispatch(
        addComment(ticket.id, {
          channel: 2,
          sendAsMail: false,
          content: `[original_phonenumber:${normalizedNumber}]`
        })
      );
      await TicketsApi.startWorkingOn(...startWorkingOn(UID, ticket.id));

      // search for entity
      const entityResults = await dispatch(
        searchCustomerByDetail({
          ...searchObject,
          taskType: ticketData.taskType
        })
      );

      if (serviceName) {
        dispatch(updateSingleTicketCaseDetail(ticket.id, 'ocServiceName', serviceName, 'CaseDetails'));
      }

      if (entityResults.length === 1) {
        // add entity to case
        dispatch(
          addEntityToCase(ticket.id, {
            _id: entityResults[0]._id,
            taskType: ticket.taskType,
            _type: entityResults[0]._type
          })
        );
      } else if (entityResults.length === 0) {
        // add entity based on originalNumber, ticketType etc.
        dispatch(
          addEntityToCase(ticket.id, {
            taskType: ticketData.taskType,
            ...searchObject
          })
        );
      } else {
        // add entity to case
        dispatch(
          addEntityToCase(ticket.id, {
            _id: entityResults[0]._id,
            taskType: ticket.taskType,
            _type: entityResults[0]._type
          })
        );
        // add comment multiple customers
        dispatch(
          addComment(ticket.id, {
            content: '[had_multiple_entities]'
          })
        );
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ElisaRing));
