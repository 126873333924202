import * as React from 'react';
import { Translation } from 'react-i18next';
import { Accordion, Button, Divider, Dropdown, Form, Icon, TextArea } from 'semantic-ui-react';
import ReplyEditor from './ReplyEditor';
import _ from 'lodash';

interface AddCommentProps {
  onSubmit?: Function;
  subject?: string;
  isSaving: boolean;
  to: string;
  attachments: any[];
  noEmail: boolean;
  simple?: boolean;
  senderEmails: any[];
}

interface AddCommentState {
  content: any;
  cc: string[];
  subject: string;
  bcc: string[];
  to: string[];
  from: string;
  attachments: any[];
  isEmailOptionsOpen: boolean;
  isCaseDoneAfterSubmit: boolean;
  sendAsMail: boolean;
}

class AddComment extends React.Component<AddCommentProps, AddCommentState> {
  contentTemplates: Array<{ key: number; text: string; value?: string }>;
  editor: any;
  options: any[];

  defaultEmail: any;

  componentWillReceiveProps(nextProps: any) {
    if (this.state.content !== '' && this.props.isSaving === true && nextProps.isSaving === false) {
      this.clearFields();
      this.resetAccordions();
    } else if (this.state.subject !== nextProps.subject) {
      this.setState({ subject: 'Re: ' + nextProps.subject });
    }
    this.setState({ to: nextProps.to.split(',') });

    nextProps.senderEmails.forEach((email: any) => {
      if (email.default) {
        this.defaultEmail = email.value;
        this.setState({ from: email.email });
      }
    });
  }

  constructor(props: any) {
    super(props);

    this.options = props.attachments.map((attachment: any) => {
      const option = {
        text: attachment.fileName,
        value: attachment.id
      };
      return option;
    });

    let initialmail = '';
    props.senderEmails.forEach((email: any) => {
      if (email.default) {
        this.defaultEmail = email.value;
        initialmail = email.email;
      }
    });

    this.state = {
      content: '',
      isCaseDoneAfterSubmit: true,
      subject: 'Re: ' + props.subject || 'Re: ',
      to: props.to.split(',') || [],
      cc: ['juhuu'],
      from: initialmail,
      bcc: [],
      attachments: [],
      isEmailOptionsOpen: false,
      sendAsMail: false
    };

    this.contentTemplates = [];
  }

  resetAccordions = () => {
    this.setState({
      isEmailOptionsOpen: false,
      sendAsMail: false
    });
  };

  clearFields = () => {
    this.setState({
      content: '',
      subject: 'Re: ' + this.props.subject,
      from: 'palaute@eee.do',
      to: [],
      cc: [],
      bcc: [],
      attachments: []
    });
  };

  render() {
    const bccOrCCHasContent = !_.isEmpty(this.state.bcc) || !_.isEmpty(this.state.cc);
    return (
      <Translation ns="translations">
        {(t: any) => (
          <Form reply={true}>
            {!this.props.noEmail && (
              <Form.Group>
                <Form.Field width={10}>
                  <label>{t('ADD_COMMENT_TITLE')}</label>
                  <Form.Input
                    onChange={(e: any, d: any) => {
                      this.setState({ subject: d.value });
                    }}
                    type="text"
                    fluid={true}
                    value={this.state.subject}
                  >
                    <input />
                  </Form.Input>
                </Form.Field>
                <Form.Field width={6}>
                  <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
                  <Form.Dropdown
                    fluid={true}
                    selection={true}
                    onChange={(e, v) => {
                      this.setState({ content: v.value });
                    }}
                    search={true}
                    placeholder={t('PLACEHOLDER_SEARCH_CANNED_RESPONSE')}
                    options={this.contentTemplates}
                  />
                </Form.Field>
              </Form.Group>
            )}
            <Form.Field>
              {!this.props.simple && <label>{t('ADD_COMMENT_CONTENT')}</label>}
              {this.props.simple && (
                <TextArea
                  rows={2}
                  value={this.state.content}
                  onChange={(e: any, value: any) => this.setState({ content: value.value })}
                />
              )}
              {!this.props.simple && (
                <ReplyEditor
                  style={{
                    borderRadius: '5px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    background: 'blue'
                  }}
                  value={this.state.content}
                  onChange={(value: string) => this.setState({ content: value })}
                  ticket={undefined}
                />
              )}
            </Form.Field>
            {!this.props.noEmail && (
              <Form.Checkbox
                toggle={true}
                onClick={(event: any, data: any) => {
                  this.setState({ sendAsMail: data.checked });
                }}
                label={t('ADD_COMMENT_SEND_AS_EMAIL')}
              />
            )}
            {this.state.sendAsMail && !this.props.noEmail && (
              <React.Fragment>
                <Form.Group widths="equal">
                  <Form.Input
                    label={t('ADD_COMMENT_RECEPIENTS')}
                    iconPosition="left"
                    onChange={(e: any, d: any) => {
                      this.setState({ to: d.value.split(',') });
                    }}
                    type="text"
                    fluid={true}
                    placeholder={t('ADD_COMMENT_RECEPIENTS_PLACEHOLDER')}
                    value={this.state.to}
                  >
                    <Icon name="at" />
                    <input />
                  </Form.Input>
                  <Accordion>
                    <Accordion.Title
                      onClick={() => {
                        this.setState((previousState) => {
                          return {
                            isEmailOptionsOpen: !previousState.isEmailOptionsOpen
                          };
                        });
                      }}
                      active={this.state.isEmailOptionsOpen}
                    >
                      <Icon name="dropdown" />
                      {this.state.isEmailOptionsOpen
                        ? t('GENERAL_LESS')
                        : `${t('GENERAL_MORE')} ${t('ADD_COMMENT_EMAIL_OPTIONS')}`}
                    </Accordion.Title>
                    <Accordion.Content active={this.state.isEmailOptionsOpen || bccOrCCHasContent}>
                      <Form.Input
                        label="CC"
                        onChange={(e: any, d: any) => {
                          this.setState({ cc: d.value.split(',') });
                        }}
                        type="text"
                        fluid={true}
                        value={this.state.cc}
                        placeholder={t('ADD_COMMENT_CC_PLACEHOLDER')}
                      />
                      <Form.Input
                        label="BCC"
                        onChange={(e: any, d: any) => {
                          this.setState({ bcc: d.value.split(',') });
                        }}
                        type="text"
                        fluid={true}
                        value={this.state.bcc}
                        placeholder={t('ADD_COMMENT_BCC_PLACEHOLDER')}
                      />
                    </Accordion.Content>
                  </Accordion>
                  <Form.Input label={t('ADD_COMMENT_ATTACHMENT_LABEL')}>
                    <Dropdown
                      selection={true}
                      selectOnBlur={false}
                      fluid={true}
                      search={true}
                      multiple={true}
                      noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                      placeholder={t('ADD_COMMENT_ATTACHMENT_PLACEHOLDER')}
                      options={this.options}
                    />
                  </Form.Input>
                </Form.Group>
                <Form.Input label={t('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}>
                  <Dropdown
                    selection={true}
                    selectOnBlur={false}
                    fluid={true}
                    onChange={(e: any, d: any) => {
                      const email = this.props.senderEmails.find((x) => x.value === d.value);
                      this.setState({ from: email.email });
                    }}
                    value={this.defaultEmail}
                    noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                    options={this.props.senderEmails}
                  />
                </Form.Input>

                <Form.Checkbox
                  style={{ marginTop: '25px' }}
                  toggle={true}
                  onClick={(event: any, data: any) => {
                    this.setState({ isCaseDoneAfterSubmit: data.checked });
                  }}
                  checked={this.state.isCaseDoneAfterSubmit}
                  label={t('ADD_COMMENT_MARK_CASE_AS_DONE')}
                />
              </React.Fragment>
            )}
            <Divider />
            <Button
              content={t('GENERAL_CLEAR')}
              onClick={this.clearFields}
              labelPosition="left"
              icon="eraser"
              negative={true}
            />
            <Button
              content={this.state.sendAsMail ? t('GENERAL_SEND') : t('GENERAL_SAVE')}
              labelPosition="left"
              icon={this.state.sendAsMail ? 'send' : 'save'}
              primary={true}
              disabled={this.state.content.length === 0}
              onClick={(event: any) => {
                if (this.props.onSubmit) {
                  const bodyOfRequest = {
                    content: this.state.content,
                    sendAsMail: this.state.sendAsMail,
                    subject: this.state.subject,
                    from: this.state.from,
                    to: this.state.to,
                    html: this.state.content,
                    cc: this.state.cc.length > 0 ? this.state.cc : undefined,
                    bcc: this.state.bcc.length > 0 ? this.state.bcc : undefined
                  };
                  const shouldStateBeChangedToDone = this.state.sendAsMail && this.state.isCaseDoneAfterSubmit;
                  this.props.onSubmit(bodyOfRequest, shouldStateBeChangedToDone);
                }
              }}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default AddComment;
