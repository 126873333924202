import ApiConfig from './ApiConfig';
import TicketsApi from './TicketsApi';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import { InfoPageListItem } from 'src/Utilities/infoPageList';
import SocketInstance from 'src/realTimeNotifications';
import type { Ticket, TicketListTicket } from 'src/types/Ticket';

const API_URL_SINGLE_UPDATE_INFOPAGE = '/cases/';

export default class InfoPagesApi {
  static getInfoPages(searchParams: any): Promise<InfoPageListItem[]> {
    if (searchParams.searchterms.basic) {
      return InfoPagesApi.search(searchParams);
    }

    return axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}/infopages`)
      .then((response) => {
        SocketInstance.resendMessagesAfterTimestamp(parseInt(response.headers['cache-creation'], 10));

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  static updateInfopage = async (id: string, content: Partial<Ticket>): Promise<TicketListTicket> => {
    return axiosWithRetry
      .patch(ApiConfig.getConfig().API_URL + API_URL_SINGLE_UPDATE_INFOPAGE + id, {
        title: content.title,
        content: content.content,
        dueDate: content.dueDate,
        status: content.status,
        taskType: content.taskType,
        channel: content.channel,
        priority: content.priority
      })
      .then((response) => response.data);
  };

  static search(searchParams: any): Promise<InfoPageListItem[]> {
    // TODO info pages separate this properly.
    searchParams.searchterms.basic.type = ['infopage'];
    return TicketsApi.searchTickets(searchParams) as any;
  }
}
