import { AxiosResponse } from 'axios';

import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';

export default class ViestiTyttiApi {
  static sendReply = async (parameters: {
    isNewMessageThread: boolean;
    content: string;
    sourceId: string;
    ticketId: string;
    title: string;
    commentFieldIds: [number, number];
    issueId?: string;
    threadStateId?: number;
  }): Promise<any> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/viestitytti/sendMessage`, parameters)
      .then((response: AxiosResponse<any>) => response.data);
}
