import React from 'react';
import Collapsible from 'react-collapsible';
import type { VFC } from 'react';

import ArticleItemHeading from './ArticleItemHeading';
import ArticleItemTags from './ArticleItemTags';
import { sanitizeHTML } from 'src/Utilities/sanitize';
import { highlightContentByInput } from 'src/Utilities/highlighter';
import type { Tag as TagData } from 'src/types/Tag';

import './ArticleListItem.css';

interface ArticleListItemProps {
  title: string;
  content: string;
  term: string;
  tags?: TagData[];
}

const ArticleListItem: VFC<ArticleListItemProps> = ({ title, content, term, tags }) => (
  <Collapsible
    trigger={
      <div className="article-list-item">
        <ArticleItemHeading title={title} term={term} />
        {tags?.length && <ArticleItemTags keyPrefix={`article-item-tag-${title}`} tags={tags} />}
      </div>
    }
  >
    <div
      className="article-list-item-content"
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(term ? highlightContentByInput({ content, input: term }) : content || '')
      }}
    />
  </Collapsible>
);

export default React.memo(ArticleListItem);
