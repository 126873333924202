import * as React from 'react';
import { Header, Icon, Grid, SemanticICONS } from 'semantic-ui-react';

import './AccordionHeader.css';

interface AccordionHeaderProps {
  id?: string;
  icon: SemanticICONS;
  as: string;
  title: string;
  active: boolean;
  accordionIcons?: AccordionIcons;
  render?: Function;
  compact?: boolean;
  customIconClassname?: string;
}

interface AccordionIcons {
  active: SemanticICONS;
  passive: SemanticICONS;
}

interface AccordionHeaderState {
  active: boolean;
}

class AccordionHeader extends React.Component<AccordionHeaderProps, AccordionHeaderState> {
  static defaultProps: object;
  constructor(props: AccordionHeaderProps) {
    super(props);

    this.state = {
      active: props.active
    };
  }

  handleToggle = () => {
    this.setState((previousState) => {
      return {
        active: !previousState.active
      };
    });
  };

  componentWillReceiveProps(nextProps: AccordionHeaderProps) {
    if (this.props.id && this.props.id !== nextProps.id) {
      this.setState({
        active: nextProps.active
      });
    }
  }

  getWhatToRender() {
    if (!this.state.active) {
      return null;
    } else if (!this.props.children && this.props.render) {
      return this.props.render(this.handleToggle);
    } else {
      return this.props.children;
    }
  }

  render() {
    const activeIcon = this.props.accordionIcons ? this.props.accordionIcons.active : 'chevron down';
    const passiveIcon = this.props.accordionIcons ? this.props.accordionIcons.passive : 'chevron up';
    const id = this.props.id;

    const RenderableContent = this.getWhatToRender();
    return (
      <React.Fragment>
        <Grid id={id} className="AccordionHeader-Title" onClick={this.handleToggle}>
          <Grid.Row columns={2}>
            <Grid.Column width={12} textAlign="left" verticalAlign="middle">
              <Header style={{ display: 'inline-block' }} as={this.props.as}>
                {!this.props.customIconClassname && <Icon name={this.props.icon} style={{ marginRight: '1.25rem' }} />}
                {this.props.customIconClassname && (
                  <div
                    style={{ display: 'inline-block', marginRight: '10px', verticalAlign: 'middle' }}
                    className={this.props.customIconClassname}
                  />
                )}
                {this.props.title}
              </Header>
            </Grid.Column>
            <Grid.Column width={4} textAlign="right" verticalAlign="middle">
              <Icon name={this.state.active ? activeIcon : passiveIcon} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div
          id={id ? `${this.props.id}-content` : undefined}
          className={this.props.compact ? 'gridCompact' : 'gridNonCompact'}
          style={{ display: this.state.active ? 'block' : 'none' }}
        >
          {RenderableContent}
        </div>
      </React.Fragment>
    );
  }
}

AccordionHeader.defaultProps = {
  active: false
};

export default AccordionHeader;
