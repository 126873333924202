import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

import TopBar from '../Components/Case/__legacy__/TopBar';
import SocketInstance from '../realTimeNotifications';
import { activateTab } from 'src/actions/tabActionsRTK';
import { updateTicket } from 'src/actions/ticketsActions';
import { stopWorkingOn } from 'src/Utilities/workStatusParser';
import { StaticTabs } from 'src/types/TicketList';
import type { State } from '../types/initialState';
import type { WorkStatusItem } from 'src/types/RealTimeService';
import type { Ticket } from 'src/types/Ticket';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';

const mapStateToProps = (state: State) => {
  // assert as always set, should check for detailedTickets before render component
  const task = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!;

  return {
    task,
    ajaxCalls: state.ajaxCallsInProgress,
    mobileMode: state.mobileReducer.mobileMode,
    workStatus: state.workStatus.status.find((status: WorkStatusItem) => {
      if (!task) {
        return false;
      }
      return 'TSK' + status.ticketId === task.id;
    }),
    users: state.usersList.usersList,
    // assert as always set, should check for user before render component
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    ticketTypesMetadata: state.ticketTypesMetadata,
    titleTemplates: state.templates.titleTemplates,
    dimmer: task ? task.id === 'NEW' : false,
    tabs: state.ticketTabs,
    tags: state.tags,
    ticketPriorities: state.ticketPriorities,
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    channels: state.channels
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTicket: (id: string, data: Partial<Ticket>) => {
      dispatch(updateTicket(id, data));
    },
    closeAsDone: async (id: any, UID: string | undefined) => {
      await dispatch(updateTicket(id, { status: 'done' }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    closeAndUpdateTicketType: async ({
      UID,
      ticketId: id,
      taskType,
      activeId
    }: {
      UID: string;
      ticketId: string;
      taskType: string;
      activeId: string;
    }) => {
      await dispatch(updateTicket(id, { taskType }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);

      if (activeId !== StaticTabs.MAIN_VIEW) {
        dispatch(activateTab(activeId));
      }
    },
    returnAsNew: async (id: string, UID: string | undefined) => {
      await dispatch(updateTicket(id, { status: 'todo' }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    },
    closeAndReturnAsDoing: async (id: string, UID: string | undefined) => {
      await dispatch(updateTicket(id, { status: 'doing' }, true));

      if (UID !== undefined) {
        await dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, id)));
      }
      SocketInstance.leaveRoom(id);
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface TopBarHOCProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

export default withRouter(connector(TopBar));
