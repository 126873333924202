import { connect } from 'react-redux';

import ForgotPassword from '../ForgotPassword';
import { forgotPassword } from '../actions/authActions';

const mapStateToProps = (state: any) => {
  return {
    isUserAuthenticated: state.auth.loggedIn
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleForgottenPassword: (email: string) => {
      return dispatch(forgotPassword(email));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
