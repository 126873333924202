import { Action, ActionCreator, Dispatch } from 'redux';
import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';

import SuggestionsApi from 'src/api/SuggestionsApi';
import {
  FETCH_AUTO_SUGGESTIONS_ERROR,
  FETCH_AUTO_SUGGESTIONS_SUCCESS,
  CREATE_AUTO_SUGGESTIONS_SUCCESS,
  UPDATE_AUTO_SUGGESTIONS_SUCCESS,
  DELETE_AUTO_SUGGESTIONS_SUCCESS
} from './index';
import { startAjaxCall } from './ajaxStatusActions';
import type { Suggestion } from 'src/types/Ticket';

export const loadAutoSuggestionsSuccess = (suggestions: Suggestion[]) => ({
  type: FETCH_AUTO_SUGGESTIONS_SUCCESS,
  payload: { suggestions }
});

export const updateAutoSuggestionsSuccess = (suggestion: Suggestion) => ({
  type: UPDATE_AUTO_SUGGESTIONS_SUCCESS,
  payload: { suggestion }
});

export const createAutoSuggestionsSuccess = (suggestion: Suggestion) => ({
  type: CREATE_AUTO_SUGGESTIONS_SUCCESS,
  payload: { suggestion }
});

export const deleteAutoSuggestionsSuccess = (id: number) => ({
  type: DELETE_AUTO_SUGGESTIONS_SUCCESS,
  payload: { id }
});

export const loadAutoSuggestionsError = () => ({ type: FETCH_AUTO_SUGGESTIONS_ERROR });

export const patchAutoSuggestion: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_AUTO_SUGGESTION' }));
    return SuggestionsApi.patchAutoSuggestion<Suggestion>(data)
      .then((suggestion) => {
        dispatch(updateAutoSuggestionsSuccess(suggestion));
        return suggestion;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const createAutoSuggestion: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_AUTO_SUGGESTION' }));
    return SuggestionsApi.createAutoSuggestion<Suggestion>(data)
      .then((suggestion) => {
        dispatch(createAutoSuggestionsSuccess(suggestion));
        return suggestion;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const deleteAutoSuggestion: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'DELETE_AUTO_SUGGESTION' }));
    return SuggestionsApi.deleteAutoSuggestion(id)
      .then((response) => {
        dispatch(deleteAutoSuggestionsSuccess(id));
        return response;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const fetchAutoSuggestions: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_GET_AUTOSUGGESTIONS' }));
    return SuggestionsApi.fetchAutoSuggestions<Suggestion[]>()
      .then((suggestions) => {
        dispatch(loadAutoSuggestionsSuccess(suggestions));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load autosuggestions', error);
        dispatch(loadAutoSuggestionsError());
      });
  };
};
