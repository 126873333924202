import axios from 'axios';
import { t } from 'i18next';

import ApiConfig from 'src/api/ApiConfig';
import { Survey, SurveyQuestionAnswerQuestion, Template } from 'src/types/Survey';

const surveyUrl = 'cases';

const getSurveyAnswers = async (ticketId: string) => {
  const backendUrl = ApiConfig.getConfig().API_URL;
  const response = await axios.get(`${backendUrl}/${surveyUrl}/${ticketId}/surveys`);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined || response.status === 404;
      successful ? resolve(data) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

// Get Survey Templates List
// NOTE: This doesn't include Survey details! Use getSurveyTemplate for that
const getSurveyList = async () => {
  const backendUrl = ApiConfig.getConfig().API_URL;
  const response = await axios.get(`${backendUrl}/cases/surveys`);

  const { data } = response;
  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      successful ? resolve(data) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getSurvey = async (id: number): Promise<Survey> => {
  const backendUrl = ApiConfig.getConfig().API_URL;
  const response = await axios.get(`${backendUrl}/cases/surveys/${id}`);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      successful ? resolve(data) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getSurveyQuestions = async (id: number): Promise<SurveyQuestionAnswerQuestion[]> => {
  id = 0;
  const response = await axios.get(`${surveyUrl}Questions`);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      const questions: SurveyQuestionAnswerQuestion[] = data;
      successful ? resolve(questions) : reject({ message: t('CASE_SURVEY_QUESTIONS_NOT_FOUND') });
    }, 0);
  });
};

const updateSurvey = async (survey: Survey) => {
  const backendUrl = ApiConfig.getConfig().API_URL;
  let returnData: any;
  let message: string;
  let statusCode: number;

  try {
    let response = await axios.put(`${backendUrl}/surveys/${survey.id}`, survey);
    const { data } = response;

    returnData = data;
    statusCode = response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      statusCode = parseInt(error.code!);
      message = error.message;
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = statusCode === 200;
      successful ? resolve(returnData.msg) : reject({ message });
    }, 0);
  });
};

const createSurvey = async (survey: Survey) => {
  const backendUrl = ApiConfig.getConfig().API_URL;
  const response = await axios.post(`${backendUrl}/surveys/`, survey);
  const { data } = response;

  return new Promise((resolve, reject) => {
    setTimeout(function () {
      const successful = data !== undefined;
      successful ? resolve(data.msg) : reject({ message: t('CASE_SURVEY_NOT_FOUND') });
    }, 0);
  });
};

const getTemplateList = async () => {
  const backendUrl = ApiConfig.getConfig().API_URL;
  const response = await axios.get(`${backendUrl}/surveys/templates/`);
  return response.data;
};

const updateTemplate = async (templateData: any) => {
  const backendUrl = ApiConfig.getConfig().API_URL;

  let returnData: any;
  let message: string;
  let statusCode: number;

  try {
    let response = await axios.patch(`${backendUrl}/surveys/templates/${templateData.id}`, templateData);
    const { data } = response;

    returnData = data;
    statusCode = response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      statusCode = parseInt(error.code!);
      message = error.message;
    }
  }

  return new Promise((resolve, reject) => {
    const successful = statusCode === 200;
    successful ? resolve(returnData) : reject({ message });
  });
};

const createTemplate = (template: Template) => {
  return axios.post(`${ApiConfig.getConfig().API_URL}/surveys/templates/`, template);
};

const deleteTemplate = async (templateId: number) => {
  const backendUrl = ApiConfig.getConfig().API_URL;

  let returnData: any;
  let message: string;
  let statusCode: number;

  try {
    let response = await axios.delete(`${backendUrl}/surveys/templates/${templateId}`);
    const { data } = response;

    returnData = data;
    statusCode = response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      statusCode = parseInt(error.code!);
      message = error.message;
    }
  }

  return new Promise((resolve, reject) => {
    const successful = statusCode === 200;
    successful ? resolve(returnData) : reject({ message });
  });
};

export {
  getSurveyAnswers,
  getSurveyList,
  getSurvey,
  getSurveyQuestions,
  getTemplateList,
  deleteTemplate,
  updateSurvey,
  updateTemplate,
  createSurvey,
  createTemplate
};
