import Info from '../Case/Info/Info';

import * as React from 'react';

import { Loader, Container, Icon, Message, Button } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import AccordionHeader from '../Case/AccordionHeader';
import * as _ from 'lodash';
import { Ticket } from '../../types/Ticket';
import ErrorBoundary from '../../ErrorBoundary';
import { Field } from 'src/types/Info';

interface AssetProps {
  fields: Field[];
  values: any;
  task: Ticket;
  fetchAsset: Function;
  onSave: Function;
  detailGroup: string;
  fieldSetName: string;
  enableMetaData: boolean;
  assetTypeTags: string[];
  organizationTags: string[];
  assetID: string;
  showAssetMeta: boolean;
}

interface AssetState {
  loading: boolean;
}

class Asset extends React.Component<AssetProps, AssetState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    if (this.props.assetID !== undefined) {
      this.setState({ loading: true }, () => {
        this.props.fetchAsset(this.props.assetID, this.props.task.id, this.props.fieldSetName).then(() => {
          this.setState({ loading: false });
        });
      });
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillReceiveProps(nextProps: AssetProps) {
    if (this.props.assetID !== nextProps.assetID && nextProps.assetID !== undefined) {
      this.setState(
        {
          loading: true
        },
        () => {
          this.props.fetchAsset(nextProps.assetID, nextProps.task.id, nextProps.fieldSetName).then(() => {
            this.setState({ loading: false });
          });
        }
      );
    }
  }

  retryFetch = () => {
    this.setState(
      {
        loading: true
      },
      () => {
        this.props.fetchAsset(this.props.assetID, this.props.task.id, this.props.fieldSetName).then(() => {
          this.setState({ loading: false });
        });
      }
    );
  };

  getTagNamesByIds(categoryTags: any, assetTags: string[]) {
    return assetTags.map((assetTag: string) => {
      const tagData = categoryTags.find((categoryTag: any) => {
        return assetTag === categoryTag.value;
      });
      if (tagData !== undefined) {
        return tagData.text;
      } else {
        return 'Virhe!';
      }
    });
  }

  getDisplayedComponent = () => {
    let components = [];
    if (this.state.loading) {
      components.push(
        <Container fluid={true} textAlign="center">
          <Translation ns="translations">
            {(t: Function) => (
              <Loader active={true} inline={true}>
                {t('LOADING')}...
              </Loader>
            )}
          </Translation>
        </Container>
      );
    } else if (
      this.props.task === undefined ||
      this.props.task.case === undefined ||
      this.props.task.case[this.props.fieldSetName] === undefined
    ) {
      components.push(
        <Translation ns="translations">
          {(t: Function) => (
            <AccordionHeader as="h4" active={true} title={'Kaluston tiedot'} icon="info circle">
              <div style={{ padding: '10px' }}>
                <Message info={true} icon={true}>
                  <Icon name="info circle" />
                  <Message.Header>
                    {t('NO_ASSET_ATTACHED_HEADING')}
                    <p>{t('NO_ASSET_ATTACHED_BODY')}</p>
                  </Message.Header>
                </Message>
              </div>
            </AccordionHeader>
          )}
        </Translation>
      );
    } else if (
      this.props.task !== undefined &&
      this.props.task.case !== undefined &&
      this.props.task.case[this.props.fieldSetName] === false
    ) {
      components.push(
        <Translation ns="translations">
          {(t: Function) => (
            <AccordionHeader as="h4" active={true} title={'Kaluston tiedot'} icon="info circle">
              <div style={{ padding: '10px' }}>
                <Message error={true} icon={true}>
                  <Icon name="warning sign" />
                  <Message.Header>
                    {t('ASSET_FETCH_FAILURE_HEADING')}
                    <p>{t('ASSET_FETCH_FAILURE_BODY')}</p>
                  </Message.Header>
                </Message>
                <Button primary={true} onClick={_.throttle(this.retryFetch)} icon={true} labelPosition="left">
                  <Icon name="refresh" />
                  {t('ERROR_TRY_AGAIN')}
                </Button>
              </div>
            </AccordionHeader>
          )}
        </Translation>
      );
    } else {
      let values = this.props.task.case[this.props.fieldSetName];
      values.assetTypeTag = this.getTagNamesByIds(this.props.assetTypeTags, [values.assetType])[0];
      values.organizationTag = this.getTagNamesByIds(this.props.organizationTags, values.organisations);
      components.push(
        <AccordionHeader as="h4" active={true} title={'Kaluston tiedot'} icon="info circle">
          <Info
            onSave={(editedField: any, editedValue: any) => {
              this.props.onSave(this.props.task.id, editedField, editedValue, this.props.fieldSetName);
            }}
            fields={this.props.fields}
            values={values}
            generalDisable={true}
          />
        </AccordionHeader>
      );
      let isNotUndefined = this.props.task.case[this.props.fieldSetName] !== undefined;
      if (
        isNotUndefined &&
        this.props.task.case[this.props.fieldSetName] !== null &&
        this.props.task.case[this.props.fieldSetName].usageMeta !== undefined &&
        this.props.task.case[this.props.fieldSetName].usageMeta !== null &&
        Object.keys(this.props.task.case[this.props.fieldSetName].usageMeta).length > 0
      ) {
        const usageMeta = this.props.task.case[this.props.fieldSetName].usageMeta;
        const fields = Object.keys(usageMeta).map((key) => {
          return { name: key, value: key };
        });
        components.push(
          <AccordionHeader as="h4" active={true} title={'Kaluston käyttötiedot'} icon="info circle">
            <Info
              onSave={(editedField: any, editedValue: any) => {
                this.props.onSave(this.props.task.id, editedField, editedValue, this.props.fieldSetName);
              }}
              fields={fields}
              values={usageMeta}
              generalDisable={true}
            />
          </AccordionHeader>
        );
      }
      if (
        this.props.task.case[this.props.fieldSetName].assetMeta !== undefined &&
        this.props.task.case[this.props.fieldSetName].assetMeta !== null &&
        this.props.task.case[this.props.fieldSetName] !== undefined &&
        this.props.showAssetMeta === true
      ) {
        const assetMeta = this.props.task.case[this.props.fieldSetName].assetMeta;
        const fields = Object.keys(assetMeta).map((key) => {
          return { name: key, value: key };
        });
        components.push(
          <AccordionHeader as="h4" active={true} title={'Muut kaluston tiedot'} icon="info circle">
            <Info
              onSave={(editedField: any, editedValue: any) => {
                this.props.onSave(this.props.task.id, editedField, editedValue, this.props.fieldSetName);
              }}
              fields={fields}
              values={assetMeta}
              generalDisable={true}
            />
          </AccordionHeader>
        );
      } else {
        components.push(
          <Translation ns="translations">
            {(t) => (
              <AccordionHeader as="h4" active={true} title={'Muut kaluston tiedot'} icon="info circle">
                <Message info={true} icon={true}>
                  <Icon name="info circle" />
                  <Message.Header>
                    {t('ASSET_NO_ASSET_META_HEADING')}
                    <p>{t('ASSET_NO_ASSET_META_BODY')}</p>
                  </Message.Header>
                </Message>
              </AccordionHeader>
            )}
          </Translation>
        );
      }
    }
    return components;
  };

  render() {
    return <ErrorBoundary>{this.getDisplayedComponent()}</ErrorBoundary>;
  }
}

export default Asset;
