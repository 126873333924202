import { connect } from 'react-redux';
import Asset from '../Components/Asset/Asset';
import { fetchAsset } from '../actions/assetActions';
import { Field } from 'src/types/Info';

const initialState: any = {
  assets: []
};

interface OwnProps {
  // TODO: typing props
  fields: Field[];
  values: any;
  task: any;
  detailGroup: any;
  fieldSetName: string;
  organizationTag: any;
  assetTypeTag: any;
  showAssetMeta: any;

  onSave: Function;
}

interface DispatchProps {
  fetchAsset: Function;
}

interface AssetContainerStateProps {
  assetTypeTags: any;
  organizationTags: any;
  assetID: any;
}

const getTagOptionsByCategory = (tags: any, category: any) => {
  return tags
    .filter((tag: any) => {
      return tag.categories && tag.categories.includes(category);
    })
    .map((tag: any) => {
      return {
        text: tag.name,
        key: `${tag.name}-${tag.id}`,
        value: tag.id
      };
    });
};

interface OwnAndStateProps extends OwnProps, AssetContainerStateProps {}

// TODO: types refactoring
const mapStateToProps = (state: any = initialState, ownProps: OwnProps): OwnAndStateProps => {
  let tagId;
  const organizationTags = getTagOptionsByCategory(state.tags, ownProps.organizationTag);
  const assetTypeTags = getTagOptionsByCategory(state.tags, ownProps.assetTypeTag);

  const asset = state.assets.find((a: any) => {
    return ownProps.task.tags.find((tag: any) => {
      return 'TAG' + a.tagId === tag;
    });
  });
  if (asset !== undefined) {
    tagId = asset.tagId;
  }
  return {
    assetID: tagId,
    assetTypeTags: assetTypeTags,
    organizationTags: organizationTags,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    fetchAsset: (assetID: string, ticketID: string, fieldSetName: string) => {
      return dispatch(fetchAsset(assetID, ticketID, fieldSetName));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Asset as any);
