import React from 'react';
import intersection from 'lodash/intersection';
import { Accordion, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { transform, isEqual, isObject, camelCase, uniq, groupBy } from 'lodash';
import type { ConnectedProps } from 'react-redux';

import {
  addRelatedTicketToTicket,
  fetchLinkedTickets,
  removeTicketLinkingFromTicket
} from 'src/actions/ticketsActions';
import RelationsErrorMessage from './RelationsErrorMessage';
import SocketInstance from 'src/realTimeNotifications';
import TicketList from './TicketRelationsList';
import TicketRelationsForm from 'src/Components/Case/Widget/TicketRelationsWidget/TicketRelationsForm';
import TicketRelationsTabs from 'src/Components/Case/Widget/TicketRelationsWidget/TicketRelationsTabs';
import { Direction } from 'src/types/Sorting';
import { convertCaseNaming } from 'src/Utilities/helper';
import { ticketOrdering } from 'src/Utilities/ticketList';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { InfoPageListTab } from 'src/types/InfoPageList';
import type { LinkedTicket, LinkedTickets, RelationOptions } from 'src/types/LinkedTickets';
import type { MenuTab } from 'src/types/MenuTab';
import type { State } from 'src/types/initialState';
import type { Ticket, TicketListTicket } from 'src/types/Ticket';
import type { TicketListTab } from 'src/types/TicketList';

interface Props extends TicketRelatinsReduxProps {
  type: ContentTypes;
  task: Ticket;
  createText: string;
  attachPlaceholderText: string;
  parentsText: string;
  childrenText: string;
  sameOriginText: string;
  relationOptions: RelationOptions;
}

interface TicketListsState {
  showError: boolean;
  activeAccordion: number | undefined;
  activeTab: string;
}

class TicketRelations extends React.Component<Props, TicketListsState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showError: true,
      activeAccordion: undefined,
      activeTab: 'sameOrigin'
    };
  }

  private difference(x: any, y: any) {
    function changes(object: any, base: any) {
      return transform(object, function (result: any, value: any, key: any) {
        if (!isEqual(value, base[key])) {
          result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(x, y);
  }

  shouldComponentUpdate(nextProps: Props, nextState: TicketListsState) {
    if (nextProps.task === undefined) {
      return false;
    }

    const diff = this.difference(nextProps.linkedTickets, this.props.linkedTickets);

    const stateDiff = this.difference(nextState, this.state);

    if (
      Object.keys(diff).length === 0 &&
      this.props.task &&
      this.props.task.id === nextProps.task.id &&
      this.props.task.entities.length === nextProps.task.entities.length &&
      Object.keys(stateDiff).length === 0 &&
      this.props.parentsText === nextProps.parentsText
    ) {
      return false;
    }
    this.props.subscribeToRelatedTicketsRooms(nextProps.linkedTickets.tickets);
    return true;
  }

  private toggleActive = (index: number) => {
    let accordion = this.state.activeAccordion;
    if (accordion === index) {
      accordion = undefined;
    } else {
      accordion = index;
    }

    this.setState({
      activeAccordion: accordion
    });
  };

  private setActiveTab = (tab: string) => {
    this.setState({ activeTab: tab });
  };

  private getTicketsWithSameOrigin = (tickets: TicketListTicket[], task: Ticket) => {
    const taskEntityIds = task.entities.map(({ _id }) => _id);
    return tickets.filter((ticket) => ticket.id !== task.id && intersection(ticket.entityIds, taskEntityIds).length);
  };

  private getTabsTickets = () => {
    const { task, tickets, linkedTickets } = this.props;
    const { parentTickets = [], childTickets = [] } = linkedTickets.tickets;
    const sameOriginTickets = this.getTicketsWithSameOrigin(tickets, task);

    return {
      parent: parentTickets,
      child: childTickets,
      sameOrigin: sameOriginTickets
    };
  };

  private groupTickets = (tickets: TicketListTicket[] | LinkedTicket[]) => {
    return groupBy<TicketListTicket | LinkedTicket>(tickets, 'status');
  };

  private getTicketList = (
    index: number,
    title: string,
    tickets: (TicketListTicket | LinkedTicket)[] | undefined,
    ticketGroupName: string,
    mode: Direction
  ) => {
    const sortedTickets = ticketOrdering<TicketListTicket | LinkedTicket>(
      tickets || [],
      null,
      mode,
      this.props.personalData.userCustomTicketOrdering
    );

    return (
      <TicketList
        ticketListId={camelCase(`relationsTicketlist ${ticketGroupName}`)}
        ticketTypes={this.props.ticketTypes}
        active={this.state.activeAccordion === index}
        index={index}
        onClick={this.toggleActive}
        tickets={sortedTickets}
        title={title}
        tags={this.props.tags}
        channels={this.props.channels}
        priorities={this.props.priorities}
        removeTicketLinkingFromTicket={this.props.removeTicketLinkingFromTicket}
        usersList={this.props.usersList}
        userData={this.props.userData}
      />
    );
  };

  render() {
    const { task, type, relationOptions } = this.props;
    const { activeTab } = this.state;
    const tabsTickets = this.getTabsTickets();
    const groupedActiveTickets = this.groupTickets(tabsTickets[activeTab]);
    const sorting = Direction.ASC;

    if (task && this.props.linkedTickets.activeTicketId !== task.id) {
      this.props.fetchLinkedTickets(task.id);
    }

    if (this.props.ticketsListTab.hasError && this.state.showError) {
      return <RelationsErrorMessage setShowError={(show) => this.setState({ showError: show })} />;
    }

    return (
      <React.Fragment>
        <Menu vertical={true} className="sideMenu ticketRelations">
          <TicketRelationsForm
            attachPlaceholderText={this.props.attachPlaceholderText}
            createText={this.props.createText}
            relationOptions={relationOptions}
            task={this.props.task}
            ticketTypes={this.props.ticketTypes}
            type={type}
            userData={this.props.userData}
          />

          {this.props.userData.permissions.includes('showTicketlist') && (
            <>
              <hr style={{ marginTop: '11px', marginBottom: '7px' }} />

              <TicketRelationsTabs
                activeItem={activeTab}
                setActiveItem={this.setActiveTab}
                items={[
                  { key: 'sameOrigin', title: `${this.props.sameOriginText} (${tabsTickets.sameOrigin.length})` },
                  { key: 'parent', title: `${this.props.parentsText} (${tabsTickets.parent.length})` },
                  { key: 'child', title: `${this.props.childrenText} (${tabsTickets.child.length})` }
                ]}
              />

              <Accordion
                className="ticketlist"
                style={{
                  padding: '0px !important',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                {type === 'infopage' ? (
                  <>
                    {this.getTicketList(0, t('ticket_list.status.draft'), groupedActiveTickets.draft, 'draft', sorting)}
                    {this.getTicketList(
                      1,
                      t('ticket_list.status.in_review'),
                      groupedActiveTickets.inReview,
                      'inReview',
                      sorting
                    )}
                    {this.getTicketList(
                      2,
                      t('ticket_list.status.waiting_to_be_published'),
                      groupedActiveTickets.waitingToBePublished,
                      'waitingToBePublished',
                      sorting
                    )}
                    {this.getTicketList(
                      3,
                      t('ticket_list.status.published'),
                      groupedActiveTickets.published,
                      'published',
                      sorting
                    )}
                    {this.getTicketList(4, t('ticket_list.status.waste'), groupedActiveTickets.waste, 'waste', sorting)}
                  </>
                ) : (
                  <>
                    {this.getTicketList(0, t('CASE_STATUS_TODO_PLURAL'), groupedActiveTickets.todo, 'todo', sorting)}
                    {this.getTicketList(1, t('CASE_STATUS_DOING_PLURAL'), groupedActiveTickets.doing, 'doing', sorting)}
                    {this.getTicketList(2, t('CASE_STATUS_DONE_PLURAL'), groupedActiveTickets.done, 'done', sorting)}
                  </>
                )}
              </Accordion>
            </>
          )}
        </Menu>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: State, ownProps: { type: ContentTypes }) {
  const tabs: MenuTab[] =
    ownProps.type === 'task' ? Object.values(state.ticketListTabs) : [...state.infoPageListTabs.values()];
  const ticketsListTab = tabs.find((ticketListTab) => ticketListTab.activeTab)!;

  const tickets: TicketListTicket[] =
    ownProps.type === 'task'
      ? (ticketsListTab as TicketListTab).tickets
      : (ticketsListTab as InfoPageListTab).infoPages;

  return {
    ticketsListTab,
    tags: state.tags,
    channels: state.channels,
    priorities: state.ticketPriorities,
    tickets,
    ticketTypes: state.ticketTypes,
    personalData: state.userData,
    usersList: state.usersList.usersList,
    linkedTickets: state.linkedTickets,
    userData: state.userData
  };
}

function mapDispatchToProps(dispatch: any, ownProps: { type: ContentTypes }) {
  return {
    fetchLinkedTickets: (id: string) => {
      dispatch(fetchLinkedTickets(id));
    },
    addRelatedTicketToTicket: (...args: Parameters<typeof addRelatedTicketToTicket>) => {
      dispatch(addRelatedTicketToTicket(...args));
    },
    // TODO: move utils
    subscribeToRelatedTicketsRooms: (ticketsIds: LinkedTickets['tickets']) => {
      if (ticketsIds) {
        const relatedTicketArray = uniq([
          ...(ticketsIds.childTickets || []).map((t) => t.id),
          ...(ticketsIds.parentTickets || []).map((t) => t.id)
        ]);
        relatedTicketArray.forEach((ticketId) => {
          SocketInstance.joinRoom(convertCaseNaming(ticketId, 'string', ownProps.type) as string);
        });
      }
    },
    removeTicketLinkingFromTicket: async (...args: Parameters<typeof removeTicketLinkingFromTicket>) => {
      dispatch(removeTicketLinkingFromTicket(...args));
    }
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type TicketRelatinsReduxProps = ConnectedProps<typeof connector>;

export default connector(TicketRelations);
