import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import iziToast from 'izitoast';
import { Container, Dropdown, Form, Header, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { t } from 'i18next';
import type { Canceler } from 'axios';
import type { DropdownProps } from 'semantic-ui-react';

import ApiConfig from 'src/api/ApiConfig';
import CustomerResult from './CustomerResult';
import Info from 'src/Components/Case/Info/Info';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { Field } from 'src/types/Info';
import type { FieldSet } from 'src/types/TicketType';

import './AddCustomer.css';

interface AddCustomerProps {
  fields: Field[];
  onSelect: (...args: any[]) => any;
  task: {
    id: Ticket['id'];
    taskType: Ticket['taskType'];
    entities: Ticket['entities'];
  };
  fieldSets: FieldSet[];
  dropDownValue?: string;
  generalDisable?: boolean;

  changeEntity?: (entityName: string, id: string) => void;
}

interface AddCustomerState {
  isLoading: boolean;
  results: Array<Entity>;
  values: object;
  filledFields: string[];
  entityTypesFromField: string[] | undefined;
  enableButtons: boolean;
}

class AddCustomer extends React.Component<AddCustomerProps, AddCustomerState> {
  private cancelSearch: Canceler | null;
  constructor(props: AddCustomerProps) {
    super(props);

    this.cancelSearch = null;

    this.state = {
      isLoading: false,
      results: [],
      values: {},
      filledFields: [],
      entityTypesFromField: undefined,
      enableButtons: false
    };
  }

  componentWillReceiveProps(nextProps: AddCustomerProps) {
    // If either task or task's type changes - cancel current search and set initialState
    if (
      this.props.task.id !== nextProps.task.id ||
      this.props.task.taskType !== nextProps.task.taskType ||
      this.props.dropDownValue !== nextProps.dropDownValue
    ) {
      if (this.cancelSearch !== null) {
        this.cancelSearch();
      }
      this.setState({
        results: [],
        filledFields: [],
        values: {},
        isLoading: false,
        enableButtons: false
      });
    }
  }

  shouldComponentUpdate(nextProps: Readonly<AddCustomerProps>, nextState: Readonly<AddCustomerState>) {
    /**
     * Getting rid of passed function
     * Best way is to memoize them but not that effective because of their dependencies on another props
     * Future implementation: refactor those function with useCallback
     */
    const filteredProps = _.clone(this.props);
    const filteredNextProps = _.clone(nextProps);
    for (const [key, value] of Object.entries(this.props)) {
      if (typeof value === 'function') {
        delete filteredProps[key];
        delete filteredNextProps[key];
      }
    }

    if (_.isEqual(filteredProps, filteredNextProps) && _.isEqual(this.state, nextState)) {
      return false;
    }
    return true;
  }

  private entityChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const entities = _.cloneDeep(this.props.fieldSets);
    const entity = entities.find((entity) => entity.displayName === data.value);
    if (this.props.changeEntity !== undefined && entity !== undefined) {
      this.props.changeEntity(String(data.value), entity.id);
    }
  };

  // TODO: refactor to use object of params under Info.tsx callback (too much unnecessary parameters)
  private fieldSave = (
    fieldName: string,
    valueToSave: any,
    object: any,
    partial: boolean,
    name: any,
    optionText: any,
    datagroup?: string,
    entityType?: string,
    id?: string,
    entityTypeFromField?: string[]
  ) => {
    const values = Object.assign({}, this.state.values);
    if (object === false) {
      values[fieldName] = valueToSave;
      this.handleFilledFieldsState(fieldName, valueToSave);
    } else {
      if (values[object] === undefined) {
        values[object] = {};
      }
      values[object][fieldName] = valueToSave;
      this.handleFilledFieldsState(`${object}.${fieldName}`, valueToSave);
    }

    this.setState({ values, entityTypesFromField: entityTypeFromField });
  };

  private createNewCustomer = async (createEntityType: string, postAttachmentOperation: boolean) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        if (createEntityType) {
          await this.props.onSelect({
            ...this.state.values,
            entityType: createEntityType,
            postAttachmentOperation
          });
        } else {
          await this.props.onSelect({
            ...this.state.values,
            postAttachmentOperation
          });
        }

        this.setState({
          isLoading: false,
          filledFields: [],
          results: [],
          values: {},
          enableButtons: false
        });
      }
    );
  };

  private handleResultSelect = (result: Entity) => {
    this.setState(
      {
        isLoading: true
      },
      async () => {
        await this.props.onSelect({
          _id: result._id,
          _type: result._type,
          postAttachmentOperation: result.postAttachmentOperation
        });
        this.setState({
          isLoading: false,
          results: [],
          filledFields: [],
          values: {}
        });
      }
    );
  };

  private performUserSearch = () => {
    const params = {};
    this.state.filledFields.forEach((field) => {
      if (this.state[field] === '') {
        // do nothing
      } else if (field === 'phoneNumber.number') {
        params[field] = normalizePhoneNumber(this.state[field]);
      } else {
        params[field] = this.state[field];
      }
    });
    let queryString = Object.keys(params)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
    queryString += '&taskType=' + this.props.task.taskType;
    if (this.state.entityTypesFromField) {
      queryString += this.state.entityTypesFromField
        .map((entityType) => '&' + encodeURIComponent('entityTypes[]') + '=' + encodeURIComponent(entityType))
        .join('');
    }

    this.setState({ isLoading: true }, () => {
      axios
        .get(`${ApiConfig.getConfig().API_URL}/entities/search?mode=approximate&${queryString}`, {
          cancelToken: new axios.CancelToken((cancelFunction) => {
            this.cancelSearch = cancelFunction;
          })
        })
        .then((response) => {
          iziToast.success({
            message: t('SEARCH_RESULTS', { amount: response.data.length }),
            icon: 'icon check'
          });
          const results = response.data || [];
          this.setState({
            results,
            isLoading: false
          });
        })
        .catch((error) => {
          iziToast.error({
            message: t('SEARCH_RESULTS_ERROR'),
            timeout: 3000,
            position: 'bottomRight'
          });
          console.error('Error while searcing entities', error);
          this.setState({
            isLoading: false
          });
        });
    });
  };

  private handleFilledFieldsState = (property: string, value: any) => {
    this.setState(
      (previousState: AddCustomerState) => {
        const copyOfState = _.cloneDeep(previousState);
        copyOfState[property] = value;
        if (copyOfState.filledFields.find((field) => field === property) === undefined) {
          copyOfState.filledFields.push(property);
        } else {
          if (value === '') {
            copyOfState.filledFields = copyOfState.filledFields.filter((f) => f !== property);
          }
        }

        return copyOfState;
      },
      () => {
        this.performUserSearch();
      }
    );
  };

  private onFieldChange = (field: Field, value: any) => {
    this.setState({ enableButtons: value.length >= 3 });
  };

  render() {
    const entityFieldSets = this.props.fieldSets
      .filter((fieldset) => fieldset.group === 'entity')
      .map((fieldset) => ({
        text: fieldset.displayName,
        value: fieldset.displayName
      }));

    const activeFieldSet = this.props.fieldSets.find((fieldset) => fieldset.displayName === this.props?.dropDownValue);

    const postAttachmentOperation = activeFieldSet?.postAttachmentOperation;

    let activeFieldSetFields = undefined as any;
    if (activeFieldSet) {
      activeFieldSetFields = activeFieldSet[activeFieldSet.id];
    }

    // This is the only place in the code searchHelp is mentioned, still might be the case
    const searchInfo = activeFieldSet?.searchHelp;

    return (
      <Translation ns="translations">
        {(tr) => (
          <Container>
            {this.state.results.length === 0 && (
              <Message info={true} icon={true}>
                <Icon name="info circle" />
                <Message.Header>
                  <p>{tr('ADD_CUSTOMER_DESCRIPTION')}</p>
                  {searchInfo ? <p>{searchInfo}</p> : ''}
                </Message.Header>
              </Message>
            )}

            <Segment padded={true}>
              <Form>
                {entityFieldSets.length > 1 && (
                  <>
                    <span>
                      {t('ENTITY_TYPE')}{' '}
                      <Dropdown
                        value={this.props?.dropDownValue as string}
                        style={{ marginLeft: '5px' }}
                        onChange={this.entityChange}
                        selection
                        placeholder="Valitse"
                        options={entityFieldSets}
                      />
                    </span>
                    <div className="entityTypeSelect"></div>
                  </>
                )}

                <Form.Group widths="equal">
                  <Info
                    generalDisable={this.props.generalDisable}
                    entity={activeFieldSet}
                    onSave={this.fieldSave}
                    onChange={this.onFieldChange}
                    fields={this.props.fields}
                    values={this.state.values}
                    searchableFields={true}
                    taskId={this.props.task?.id}
                  />
                </Form.Group>

                <Form.Group widths="equal" className="addCustomer__buttonsContainer">
                  <Form.Button
                    fluid={true}
                    icon={true}
                    labelPosition="left"
                    disabled={this.props.generalDisable || !this.state.enableButtons || this.state.isLoading}
                    positive={true}
                    onClick={() => {
                      this.createNewCustomer(activeFieldSet?.createEntity as string, postAttachmentOperation!);
                    }}
                  >
                    <Icon name="add user" />
                    {tr('ADD_CUSTOMER_CREATE_NEW_CUSTOMER')}
                  </Form.Button>

                  <Form.Button
                    fluid={true}
                    icon={true}
                    disabled={this.props.generalDisable || !this.state.enableButtons || this.state.isLoading}
                    labelPosition="left"
                    primary={true}
                    onClick={() => {
                      this.performUserSearch();
                    }}
                  >
                    <Icon name="search" />
                    {tr('ADD_CUSTOMER_SEARCH_EXISTING_CUSTOMER')}
                  </Form.Button>
                </Form.Group>
              </Form>

              <Header as="h4" dividing={true} textAlign="center">
                <Icon name="search" />
                {tr('SEARCH_RESULTS_LIST')}{' '}
                {!this.state.isLoading
                  ? this.state.results.length > 20
                    ? `(20+)`
                    : `(${this.state.results.length})`
                  : ''}
              </Header>

              {!this.state.isLoading && this.state.results.length === 0 && this.state.filledFields.length !== 0 && (
                <Message warning={true} icon={true}>
                  <Icon name="info circle" />
                  <Message.Header>
                    {tr('NORESULTS_CUSTOMER_TITLE_NO_CUSTOMER')}
                    <p>{tr('NORESULTS_CUSTOMER_DESCRIPTION')}</p>
                  </Message.Header>
                </Message>
              )}

              {!this.state.isLoading && this.state.results.length === 20 && (
                <Message warning={true} icon={true}>
                  <Icon name="info circle" />
                  <Message.Header>
                    {tr('MAXRESULTS_CUSTOMER_TITLE_NO_CUSTOMER')}
                    <p>{tr('MAXRESULTS_CUSTOMER_DESCRIPTION')}</p>
                  </Message.Header>
                </Message>
              )}

              {!this.state.isLoading &&
                this.state.results.map((result, index) => {
                  let isAttached = false;
                  if (this.props.task.entities.length > 0 && result) {
                    const intersectedEntities = _.intersectionWith(this.props.task.entities, [result], (a, b) => {
                      return a._type.toString() === b._type.toString() && a._id.toString() === b._id.toString();
                    });
                    if (intersectedEntities.length > 0) {
                      isAttached = true;
                    }
                  }
                  return (
                    <CustomerResult
                      isAttached={isAttached}
                      mongodb={result.mongodb || false}
                      key={index}
                      onClick={() =>
                        this.handleResultSelect({
                          ...result,
                          postAttachmentOperation
                        })
                      }
                      fields={activeFieldSetFields || this.props.fields}
                      values={result}
                    />
                  );
                })}

              <div>
                <Loader active={this.state.isLoading} indeterminate={true} inline="centered">
                  {tr('LOADING')}
                </Loader>
              </div>
            </Segment>
          </Container>
        )}
      </Translation>
    );
  }
}

export default AddCustomer;
