import FeatureFlags from '../FeatureFlags';
import UserTimeLogsApi from './UserTimeLogsApi';
import { unifyTaskId, getCurrentSeconds, consoleLogUserTimeLogs } from './utils';
import type { LifeCycleMethodCalledFrom, UserTimeLogsRow } from './types';

const AUTOSAVE_TIMEOUT = 1000 * 60 * 15;

class UserTimeLogs {
  private startedAt: number | null = null;
  private activeTicketId: string | null = null;
  private unSentLogs: UserTimeLogsRow[] = [];
  private autoSaveTimeout: NodeJS.Timeout | null = null;

  sendLogs = () => {
    consoleLogUserTimeLogs('[userTimeLogs]: unSentLogs', this.unSentLogs);
    if (this.unSentLogs.length === 0) return;

    UserTimeLogsApi.sendUserTimeLogs(this.unSentLogs);
    this.unSentLogs = [];
  };

  clearState = (opts?: { autoSave: boolean }) => {
    if (!this.activeTicketId || !this.startedAt) return;

    const now = getCurrentSeconds();
    const elapsedDuration = now - this.startedAt;

    this.unSentLogs.push({
      id: this.activeTicketId,
      duration: elapsedDuration
    });

    if (!opts?.autoSave) {
      this.startedAt = null;
      this.activeTicketId = null;
    } else {
      this.startedAt = now;
    }

    this.sendLogs();

    this.setupAutoSave();
  };

  setupAutoSave = () => {
    if (this.autoSaveTimeout) {
      clearTimeout(this.autoSaveTimeout);
    }
    this.autoSaveTimeout = setTimeout(() => {
      this.clearState({ autoSave: true });
    }, AUTOSAVE_TIMEOUT);
  };

  onChangeActivelyHandledTicket = (method: LifeCycleMethodCalledFrom, ticketId: string | number | null) => {
    // Not enabled, move on
    if (!FeatureFlags.isFlagOn('ENABLE_USER_TIME_LOGGING')) return;

    const id = unifyTaskId(ticketId);

    if (method === 'componentWillUnmount') {
      consoleLogUserTimeLogs('[userTimeLogs]: Due to unmount set activeTicketId to null');
      return this.clearState();
    }

    // If id is null then stop working on current ticket.
    if (!id) {
      consoleLogUserTimeLogs('[userTimeLogs]: Setting activeTicketId to', id);
      // do something, like stop working on
      return this.clearState();
    }

    // Generate previous and send report IF the id changes
    if (id !== this.activeTicketId) {
      consoleLogUserTimeLogs('[userTimeLogs]: Setting activeTicketId to', id);
      this.clearState();
      this.startedAt = getCurrentSeconds();
      this.activeTicketId = id;
      this.setupAutoSave();
    } else {
      consoleLogUserTimeLogs('[userTimeLogs]: Skipping setting activeTicketId to', id);
    }
  };
}

export default new UserTimeLogs();
