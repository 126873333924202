import * as React from 'react';
import iziToast from 'izitoast';
import { AxiosError } from 'axios';
import { StringMap, t as tr } from 'i18next';
import { Translation } from 'react-i18next';
import { Button, Form, Icon, List, Popup } from 'semantic-ui-react';

import UsersApi from 'src/api/UsersApi';
import { Asterisk } from '../Utilities/FormUtilities';
import type { ChangePasswordData } from 'src/types/ApiRequests';
import type { PasswordPolicy } from 'src/types/User';

interface AddUserFormProps {
  changePassword: (data: ChangePasswordData) => Promise<void>;
  username: string;
}

interface AddUserFormState {
  error: boolean | string;
  isSaving: boolean;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  passwordPolicy?: PasswordPolicy;
}

type ChangePasswordError = AxiosError & {
  response?: {
    data?: {
      msg: string;
      required: number;
    };
  };
};

const userFormPrefixedT = (
  key: string,
  options?: { amount: number } | { minimum: number; maximum: number }
): string => {
  return tr(`settings.add_user_form.${key}`, options as StringMap);
};

type ValidationErrors = 'password_too_short' | 'password_too_long' | 'password_needs_more_requirements';

export const validatePassword = (
  password: string,
  policy: PasswordPolicy
): undefined | { error: ValidationErrors; required: number } => {
  const { passwordLength, requirements } = policy;
  const [minimumLength, maximumLength] = passwordLength;

  if (password.length < minimumLength) {
    return { error: 'password_too_short', required: minimumLength };
  }

  if (password.length > maximumLength) {
    return { error: 'password_too_long', required: maximumLength };
  }

  let fulfilled = 0;
  if (requirements.smallLetters && /[a-zäöå]/.test(password)) {
    fulfilled++;
  }

  if (requirements.bigLetters && /[A-ZÄÖÅ]/.test(password)) {
    fulfilled++;
  }

  // I think the rule here is misguided, unescaping them changed the meaning of the regex.
  // eslint-disable-next-line no-useless-escape
  if (requirements.specialChars && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
    fulfilled++;
  }

  if (requirements.numbers && /\d/.test(password)) {
    fulfilled++;
  }

  if (fulfilled < requirements.howManyRequirementsMustBeFulfilled) {
    return {
      error: 'password_needs_more_requirements',
      required: requirements.howManyRequirementsMustBeFulfilled
    };
  }

  return undefined;
};

export const passwordTooltips = (policy: PasswordPolicy) => {
  let passwordTooltips: JSX.Element[] | undefined;
  if (policy) {
    passwordTooltips = [
      <List.Item key={'passwordLength'}>
        <List.Icon name="info" />
        <List.Content>
          {userFormPrefixedT('password.requirements.length_between', {
            minimum: policy.passwordLength[0],
            maximum: policy.passwordLength[1]
          })}
        </List.Content>
      </List.Item>
    ];

    const requirements = Object.entries(policy.requirements) as [
      keyof PasswordPolicy['requirements'],
      number | boolean
    ][];

    passwordTooltips.push(
      ...requirements
        .sort((a, b) => {
          if (a[0] === 'howManyRequirementsMustBeFulfilled') {
            return -1;
          }

          if (b[0] === 'howManyRequirementsMustBeFulfilled') {
            return 1;
          }

          return 0;
        })
        .map((entry) => {
          const key = entry[0] as keyof PasswordPolicy['requirements'];
          const value = entry[1];

          if (typeof value === 'boolean' && !value) {
            return [key, ''];
          }

          let str: string;
          switch (key) {
            case 'smallLetters':
              str = userFormPrefixedT('password.requirements.small_letters');
              break;
            case 'bigLetters':
              str = userFormPrefixedT('password.requirements.big_letters');
              break;
            case 'specialChars':
              str = userFormPrefixedT('password.requirements.special_characters');
              break;
            case 'numbers':
              str = userFormPrefixedT('password.requirements.numbers');
              break;
            case 'howManyRequirementsMustBeFulfilled':
              str = userFormPrefixedT('password.requirements.amount', { amount: value as number });
              break;
            default:
              console.error(`Unknown password policy requirements key: ${key}`);
              str = '';
              break;
          }

          return [key, str];
        })
        .map((tuple) => {
          const [key, str] = tuple;
          if (str.length === 0) {
            return <></>;
          }

          return (
            <List.Item key={key}>
              <List.Icon name="info" />
              <List.Content>{str}</List.Content>
            </List.Item>
          );
        })
    );
  }

  return passwordTooltips;
};

class ChangePasswordForm extends React.Component<AddUserFormProps, AddUserFormState> {
  constructor(props: AddUserFormProps) {
    super(props);

    this.state = {
      error: false,
      isSaving: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    };
  }

  componentDidMount() {
    UsersApi.getPasswordPolicy().then((policy) => {
      this.setState({ passwordPolicy: policy });
    });
  }

  initialState = () => {
    return {
      error: false,
      isSaving: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    };
  };

  clearFields = () => {
    this.setState({
      error: false,
      isSaving: false,
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: ''
    });
  };

  submit = () => {
    const data: ChangePasswordData = {
      userName: this.props.username,
      oldPassword: this.state.oldPassword,
      newPassword: this.state.newPassword
    };

    this.props
      .changePassword(data)
      .then(() => {
        this.clearFields();
        iziToast.success({
          message: tr('SETTINGS_CHANGE_PASSWORD_RESPONSE_SUCCESS'),
          timeout: 5000
        });
      })
      .catch((error: ChangePasswordError) => {
        const data = error.response?.data;
        const msg = data?.msg;
        if (msg) {
          const key = `settings.add_user_form.password.${msg}`;
          const translation = tr(key, `${data.required}`);
          if (translation) {
            iziToast.error({
              message: tr(`settings.add_user_form.password.${msg}`, { amount: data?.required }),
              timeout: 7500
            });
            return;
          }
        }

        iziToast.error({
          message: tr('SETTINGS_CHANGE_PASSWORD_RESPONSE_FAILURE'),
          timeout: 7500
        });
      });
  };

  checkRequiredFields = () => {
    const requiredFields = ['password', 'passwordConfirm'];

    let isValid = true;

    requiredFields.forEach((fieldName: string) => {
      if (this.state[fieldName] === '') {
        isValid = false;
      }
    });

    return !isValid;
  };

  render() {
    const passwordMisMatch =
      this.state.newPassword !== this.state.newPasswordConfirm && this.state.newPasswordConfirm !== '';
    const newPasswordEmpty = this.state.newPassword.length === 0;
    const passwordInvalid =
      this.state.passwordPolicy && validatePassword(this.state.newPassword, this.state.passwordPolicy)?.error != null;

    return (
      <Translation ns="translations">
        {(t) => (
          <div>
            <Form>
              <Form.Field>
                <label>
                  {t('SETTINGS_CHANGE_PASSWORD_OLD_PASSWORD')}
                  <Asterisk />
                </label>
                <Form.Input
                  type="password"
                  name="oldPassword"
                  value={this.state.oldPassword}
                  onChange={(e, d) => this.setState({ oldPassword: d.value })}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>
                    {t('SETTINGS_CHANGE_PASSWORD_NEW_PASSWORD')}

                    {this.state.passwordPolicy && (
                      <Popup
                        basic={true}
                        content={<List>{passwordTooltips(this.state.passwordPolicy)}</List>}
                        trigger={<Icon name="question circle" />}
                      />
                    )}
                  </label>
                  <Form.Input
                    type="password"
                    fluid={true}
                    error={!newPasswordEmpty && passwordInvalid}
                    name="newPassword"
                    value={this.state.newPassword}
                    onChange={(e, d) => this.setState({ newPassword: d.value })}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    {t('SETTINGS_CHANGE_PASSWORD_NEW_PASSWORD_CONFIRM')}
                    <Asterisk />
                  </label>
                  <Form.Input
                    error={passwordMisMatch}
                    type="password"
                    fluid={true}
                    name="confirmNewPassword"
                    value={this.state.newPasswordConfirm}
                    onChange={(e, d) => this.setState({ newPasswordConfirm: d.value })}
                  />
                </Form.Field>
              </Form.Group>
              <Button
                content={t('GENERAL_CLEAR')}
                labelPosition="left"
                icon="eraser"
                negative={true}
                onClick={this.clearFields}
              />
              <Button
                content={t('SETTINGS_CHANGE_PASSWORD_SUBMIT')}
                labelPosition="left"
                icon="save"
                positive={true}
                disabled={this.checkRequiredFields() || passwordMisMatch || newPasswordEmpty || passwordInvalid}
                onClick={this.submit}
              />
            </Form>
          </div>
        )}
      </Translation>
    );
  }
}

export default ChangePasswordForm;
