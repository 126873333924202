import axiosRetry from 'axios-retry';
import axios from 'axios';

const axiosWithRetry = axios.create({
  withCredentials: true
});

axiosRetry(axiosWithRetry, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
});

export default axiosWithRetry;
