import React from 'react';
import type { VFC } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Checkbox, Divider, Input, List, ListItem, Loader, Message, Table } from 'semantic-ui-react';

import ComplexList from './ComplexList';
import DateSelector from 'src/Components/Case/DateSelector';
import DefaultInfoField from 'src/Components/Case/Info/InfoField/DefaultInfoField';
import FieldHeader from 'src/Components/Case/Info/FieldHeader/FieldHeader';
import GoogleMapsField from './GoogleMapsField';
import InfoDateRange from './InfoDateRange';
import InfoDatepicker from './InfoDatepicker';
import InfoFieldDropdown from './InfoFieldDropdown';
import PhoneNumber from './PhoneNumber';
import SubEntityField from './SubEntityField';
import VerifyFields from './VerifyFields';
import { DATE_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getInfoInputType } from 'src/Utilities/info';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';
import { setCaseIFrame } from 'src/actions/caseIFrameActions';
import type { Field } from 'src/types/Info';
import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';
import type { PersonalData } from 'src/types/User';
import type { State } from 'src/types/initialState';

const searchValueRecursively = (searchedObject: any, parameterArray: string[], uriEncode: boolean) => {
  parameterArray.forEach((parameterKey: string) => {
    if (searchedObject !== undefined && searchedObject !== null) {
      searchedObject = searchedObject[parameterKey];
    }
  });

  if (uriEncode) {
    searchedObject = encodeURIComponent(searchedObject);
  }

  return searchedObject;
};

export const parseFieldValue = ({
  field,
  values,
  shouldEncodeUriComponent,
  personalData,
  integrationSaveDisabled,
  mongoSaveDisabled
}: {
  field: Field;
  values: any;
  shouldEncodeUriComponent: boolean;
  personalData?: PersonalData;
  integrationSaveDisabled?: boolean;
  mongoSaveDisabled?: boolean;
}) => {
  const regex = /\[(.*?)\]/;
  let fieldValue: string | null = '';

  if (field.value.toString().includes('|')) {
    field.value
      .toString()
      .split('|')
      .forEach((fieldParam) => {
        const fieldParamRegexTest = fieldParam.match(regex);
        const dotSplit = fieldParam.toString().split('.');
        if (fieldParamRegexTest !== null && fieldParamRegexTest[1].length > 0) {
          fieldValue += fieldParamRegexTest[1];
        } else if (dotSplit.length > 1) {
          if (dotSplit[0] === 'user') {
            fieldValue += searchValueRecursively(personalData?.profile, dotSplit.slice(1), shouldEncodeUriComponent);
          } else {
            fieldValue += searchValueRecursively(values, dotSplit, shouldEncodeUriComponent);
          }
        } else {
          fieldValue += values[fieldParam.toString()];
        }
      });
  } else {
    const fieldValueToString = field.value.toString() as any;
    const fieldParamRegexTest = fieldValueToString.match(regex);
    const dotSplit = fieldValueToString.split('.');
    if (fieldParamRegexTest !== null && fieldParamRegexTest[1].length > 0) {
      fieldValue += fieldParamRegexTest[1];
    } else if (dotSplit.length > 1) {
      if (dotSplit[0] === 'user') {
        fieldValue = searchValueRecursively(personalData?.profile, dotSplit.slice(1), shouldEncodeUriComponent);
      } else {
        fieldValue = searchValueRecursively(values, dotSplit, shouldEncodeUriComponent);
      }
    } else {
      fieldValue = values[fieldValueToString];
    }
  }

  if (typeof field.object !== 'undefined') {
    if (!Array.isArray(field.value)) {
      // if there is field.object available, true values is
      fieldValue = values[field.object];
      if (fieldValue !== null && fieldValue !== undefined && typeof field.value !== 'boolean') {
        fieldValue = fieldValue[field.value];
      } else {
        fieldValue = '';
      }
    }

    /*
    Because of the default behavior in dropdowns,
    undefined value does not clear the selection while null does
  */
    if (fieldValue === undefined) {
      fieldValue = null;
    }
  }

  if (field?.customType === 'daterange') {
    if (integrationSaveDisabled || mongoSaveDisabled) {
      const dateRange = fieldValue?.split(':') || [];
      const startDate =
        dateRange[0] !== undefined ? getPrettyDate(parseInt(dateRange[0], 10), { format: DATE_FORMAT }) : '';
      const endDate =
        dateRange[1] !== undefined ? getPrettyDate(parseInt(dateRange[1], 10), { format: DATE_FORMAT }) : '';
      fieldValue = startDate + ' - ' + endDate;
    }
  }

  if (field?.customType === 'link') {
    if (typeof fieldValue === 'string') {
      fieldValue = fieldValue.replace(/\+/g, '%2B');
    }

    if (field.params?.replaceString && field.params.replaceString) {
      fieldValue = (fieldValue || '').replace(field.params?.replaceString[0], field.params?.replaceString[1]);
    }
  }

  return fieldValue || '';
};

const InfoField: VFC<InfoFieldProps> = ({
  fields,
  field,
  values,
  isMobile,
  params,
  taskId,
  taskType,
  language,
  entity,
  /**
   * TODO: this is actually data from the state, but here
   * the fact if it is passed or not is part of business logic
   * should move those parts of component that depends
   * */
  personalData,
  generalDisable,
  disableInputIfNotMongoCustomer,
  ticketTypes,
  fireSearch,
  onSaveData,
  setValue,
  getFieldSearchableStatus,
  setCaseIFrameUrl
}) => {
  const mongoSaveDisabled = params?.ignoreFieldDisabling
    ? false
    : !!((values.mongoCustomer && field.params && field.params.noMongo) || (field.params && field.params.readOnly));
  const integrationSaveDisabled = params?.ignoreFieldDisabling
    ? false
    : (!values.mongoCustomer && !values.twoWayIntegrationEntity && disableInputIfNotMongoCustomer) ||
      !!(field.params && field.params.readOnly);
  const mongoNoRender = values.mongoCustomer && field.params !== undefined && field.params.mongoNoRender;
  const integrationNoRender = !values.mongoCustomer && field.params !== undefined && field.params.integrationNoRender;
  const ticketType = ticketTypes.find((type) => type.name === taskType)!;

  if (mongoNoRender || integrationNoRender) {
    return null;
  }

  if (field.value === undefined) {
    field.value = '';
  }

  let fieldValue = '' as any;

  if (
    !['subAccordion', 'subEntityField', 'separator', 'loadingChevron', 'errorMessage', 'empty'].includes(
      field.customType!
    )
  ) {
    const shouldEncodeUriComponent = field.customType === 'link';

    fieldValue += parseFieldValue({
      field,
      values,
      shouldEncodeUriComponent,
      personalData,
      integrationSaveDisabled,
      mongoSaveDisabled
    });
  }

  const FieldHeaderComponent: VFC<{ isNegative?: boolean; value: any }> = ({ value, isNegative = false }) => (
    <FieldHeader
      taskId={taskId}
      taskType={taskType}
      field={field}
      fieldValue={value}
      personalData={personalData}
      fireSearch={fireSearch}
      isNegative={isNegative}
    />
  );

  const inputType = getInfoInputType(field);
  const renderNonDefault =
    [
      'subAccordion',
      'subEntityField',
      'separator',
      'loadingChevron',
      'errorMessage',
      'empty',
      'daterange',
      'link',
      'list',
      'complexList',
      'phoneNumber',
      'verifyFields',
      'datepicker',
      'dateselector',
      'googleMaps'
    ].includes(field.customType!) ||
    field.options !== undefined ||
    field.switch !== undefined;

  const subEntityFieldValue =
    field.customType === 'subEntityField' &&
    values.subEntities?.[values[field.params.getByValue] + field.params.entityType]?.data?.[field.value as any];

  return (
    <Translation>
      {(t) => (
        <>
          {renderNonDefault && field?.options !== undefined && (
            <InfoFieldDropdown
              field={field}
              fieldValue={fieldValue}
              disabled={generalDisable}
              selectionEnabled={integrationSaveDisabled || mongoSaveDisabled}
              setValue={setValue}
              onSaveData={onSaveData}
              renderHeader={FieldHeaderComponent}
              placeholder={t('CHOOSE')}
              noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
            />
          )}
          {renderNonDefault && field?.switch !== undefined && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10}>
                <Checkbox
                  id={field.name?.split(' ').join('_')}
                  disabled={integrationSaveDisabled || mongoSaveDisabled}
                  toggle={true}
                  defaultChecked={fieldValue ? true : false}
                  onChange={(e, data) => {
                    onSaveData?.(field, data.checked);
                  }}
                  label={field.switch.filter((option) => option.value === field.value)}
                />
              </Table.Cell>
            </>
          )}
          {renderNonDefault && field?.customType === 'subEntityField' && subEntityFieldValue && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <SubEntityField
                field={field}
                inputType={inputType}
                setValue={setValue}
                onSaveData={onSaveData}
                subEntityFieldValue={subEntityFieldValue}
                mongoSaveDisabled={mongoSaveDisabled}
                integrationSaveDisabled={integrationSaveDisabled}
                disabled={generalDisable || !getFieldSearchableStatus?.(field)}
              />
            </>
          )}
          {renderNonDefault && field?.customType === 'separator' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10}>
                <Divider />
              </Table.Cell>
            </>
          )}
          {renderNonDefault && field?.customType === 'loadingChevron' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10}>
                <Loader />
              </Table.Cell>
            </>
          )}
          {renderNonDefault && !!entity && field?.customType === 'verifyFields' && (
            <VerifyFields
              taskId={taskId}
              field={field}
              values={values}
              fieldSet={fields}
              entity={entity}
              InfoFieldComponent={InfoField}
              ticketTypes={ticketTypes}
            />
          )}
          {renderNonDefault && field?.customType === 'errorMessage' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10}>
                <Message warning={true} icon={true}>
                  <Message.Header>
                    <Translation>{(t) => <p>{t('ENTITY_ENCOUNTERED_ERROR_DESCRIPTION')}</p>}</Translation>
                  </Message.Header>
                </Message>
              </Table.Cell>
            </>
          )}
          {renderNonDefault && field?.customType === 'empty' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10}>
                <div />
              </Table.Cell>
            </>
          )}
          {renderNonDefault && field?.customType === 'daterange' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <InfoDateRange
                field={field}
                fieldValue={fieldValue}
                language={language}
                mongoSaveDisabled={mongoSaveDisabled}
                integrationSaveDisabled={integrationSaveDisabled}
                inputDisabled={generalDisable || !getFieldSearchableStatus?.(field)}
                onSaveData={onSaveData}
              />
            </>
          )}
          {renderNonDefault && field?.customType === 'link' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10} style={{ maxWidth: '100px' }}>
                {typeof fieldValue === 'string' && (
                  <a
                    style={{ overflowWrap: 'break-word' }}
                    href={fieldValue}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={(e) => {
                      if (field.openInIFrame) {
                        e.preventDefault();

                        if (!taskId) return;

                        const task = taskIdToNumericalId(taskId);
                        if (!task) return;

                        setCaseIFrameUrl?.(task, fieldValue, field.iFrameTabTitle ?? t('link_view'));
                      }
                    }}
                  >
                    {field.linkText ?? fieldValue}
                  </a>
                )}
              </Table.Cell>{' '}
            </>
          )}
          {renderNonDefault && field?.customType === 'list' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <Table.Cell width={10}>
                <List divided={true} className="infoList">
                  {fieldValue === undefined ? (
                    <Input key="3" type="text" disabled={true} size="mini" fluid={true} />
                  ) : (
                    fieldValue.map((fv: any, index: number) => (
                      <ListItem key={`info-list-item-${index}`}>{fv}</ListItem>
                    ))
                  )}
                </List>
              </Table.Cell>
            </>
          )}
          {renderNonDefault && field?.customType === 'complexList' && typeof fieldValue !== 'undefined' && (
            <>
              <ComplexList
                field={field}
                fieldValue={fieldValue}
                disabled={generalDisable || !getFieldSearchableStatus?.(field)}
                mongoSaveDisabled={mongoSaveDisabled}
                integrationSaveDisabled={integrationSaveDisabled}
              />
            </>
          )}
          {renderNonDefault && field?.customType === 'phoneNumber' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <PhoneNumber
                fieldValue={fieldValue}
                inputType={inputType}
                disabled={generalDisable || !getFieldSearchableStatus?.(field)}
                mongoSaveDisabled={mongoSaveDisabled}
                integrationSaveDisabled={integrationSaveDisabled}
                onSaveData={onSaveData}
                setValue={setValue}
                field={field}
              />
            </>
          )}
          {renderNonDefault && field?.customType === 'datepicker' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <InfoDatepicker
                field={field}
                selectDateMessage={t('SELECT_DATE')}
                isMobile={isMobile}
                disabled={integrationSaveDisabled || mongoSaveDisabled}
                fieldValue={fieldValue}
                onSaveData={onSaveData}
              />
            </>
          )}
          {renderNonDefault && field?.customType === 'dateselector' && (
            <DateSelector
              value={fieldValue}
              getHeader={(value) => <FieldHeaderComponent value={value} />}
              onChange={(timestamp) => onSaveData?.(field, timestamp)}
            />
          )}
          {renderNonDefault && field?.customType === 'googleMaps' && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <GoogleMapsField
                disabled={generalDisable || !getFieldSearchableStatus?.(field)}
                mongoSaveDisabled={mongoSaveDisabled}
                integrationSaveDisabled={integrationSaveDisabled}
                field={field}
                fieldValue={fieldValue}
                onSaveData={onSaveData}
              />
            </>
          )}
          {!renderNonDefault && (
            <>
              <FieldHeaderComponent value={fieldValue} />
              <DefaultInfoField
                fields={fields}
                field={field}
                fieldValue={fieldValue}
                disabled={generalDisable || !getFieldSearchableStatus?.(field)}
                mongoSaveDisabled={mongoSaveDisabled}
                integrationSaveDisabled={integrationSaveDisabled}
                inputType={inputType}
                setValue={setValue}
                onSaveData={onSaveData}
                ticketType={ticketType}
                taskId={taskId!}
              />
            </>
          )}
        </>
      )}
    </Translation>
  );
};

const connector = connect(
  (state: State) => ({
    personalData: state.userData,
    ticketTypes: state.ticketTypes
  }),
  (dispatch) => ({
    setCaseIFrameUrl: (id: number, url: string, tabTitle: string) => {
      dispatch(setCaseIFrame({ id, url, tabTitle }));
    }
  })
);

export default connector(InfoField);
