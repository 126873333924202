import * as React from 'react';
import { Tab, Icon, Menu } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import type { TabProps } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';
import type { TFunction } from 'i18next';

import FeatureFlags from 'src/api/FeatureFlags';
import TicketListFilter from 'src/containers/TicketListFilterContainer';
import Search from './Search';
import OpenById from './OpenById';
import TicketLists from './TicketLists';
import ErrorBoundary from 'src/ErrorBoundary';
import { DefaultTicketsOrdering } from 'src/types/Sorting';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { TicketListTab as TicketTab } from 'src/types/TicketList';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;

interface TicketListTabState {
  activeIndex: number;
}

interface OwnProps {
  contentType: ContentTypesFields;
  noDefaultDates?: boolean;
}

interface Props extends TicketListTabReduxProps, OwnProps {}

class TicketListTab extends React.Component<Props, TicketListTabState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeIndex: 2
    };
  }

  getPanes = () => {
    const { filters, sorting, direction, t } = this.props;
    // Check undefined values in filters objects
    const isFilterOn =
      !Object.keys(filters).every((k) => !filters[k]) ||
      (sorting && sorting !== defaultSorting) ||
      (direction && direction !== defaultDirection);

    return [
      {
        menuItem: FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING') ? (
          <Menu.Item
            key="filterComponentTab"
            id="filterComponentTab"
            className="filterComponentTab"
            style={{ marginLeft: '10px !important' }}
          >
            <Icon name="filter" style={{ marginRight: '5px' }} color={isFilterOn ? 'red' : undefined} />
            <span
              style={{
                textDecoration: isFilterOn ? 'underline' : 'none',
                color: isFilterOn ? 'red' : 'inherit'
              }}
            >
              {t('SEARCH_FIlTER_OPTIONS')}
            </span>
          </Menu.Item>
        ) : undefined,
        render: () => {
          if (FeatureFlags.isFlagOn('ENABLE_TICKET_LIST_FILTERING')) {
            return (
              <ErrorBoundary>
                <TicketListFilter contentType={this.props.contentType} />
              </ErrorBoundary>
            );
          } else {
            return undefined;
          }
        }
      },
      {
        menuItem: (
          <Menu.Item key="searchComponentTab" id="searchComponentTab" className="filterComponentTab">
            <Icon name="search" />
            {t('SEARCH_MORE_SEARCH_OPTIONS')}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ErrorBoundary>
              <Search
                noDefaultDates={this.props.noDefaultDates}
                contentType={this.props.contentType}
                closePane={() => this.setState({ activeIndex: 2 })}
              />
            </ErrorBoundary>
          );
        }
      },
      {
        menuItem: (
          <Menu.Item key="searchByIdTab" className="filterComponentTabFloatRight NoActiveTabEffects">
            <OpenById contentType={this.props.contentType} />
          </Menu.Item>
        )
      }
    ];
  };

  changeActiveIndex = (_event: any, tabData: TabProps) => {
    const index = tabData.activeIndex;

    if (this.state.activeIndex !== index) {
      this.setState({ activeIndex: Number(index) });
    } else {
      this.setState({ activeIndex: 2 });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Tab
          className="searchTabMenu"
          panes={this.getPanes()}
          activeIndex={this.state.activeIndex}
          onTabChange={this.changeActiveIndex}
        />
        <ErrorBoundary>
          <TicketLists contentType={this.props.contentType} />
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  const tabs: MenuTab[] =
    ownProps.contentType === 'tickets' ? Object.values(state.ticketListTabs) : [...state.infoPageListTabs.values()];
  const activeTab = tabs.find((tab) => tab.activeTab);

  return {
    filters: activeTab?.filters ?? {},
    sorting: (activeTab as TicketTab)?.sorting,
    direction: (activeTab as TicketTab)?.direction
  };
};

const connector = connect(mapStateToProps);

interface TicketListTabReduxProps extends ConnectedProps<typeof connector> {
  t: TFunction;
}

export default connector(withTranslation('translations')(TicketListTab));
