import { Entity } from 'src/types/Ticket';
import { TicketType } from 'src/types/TicketType';

const isEntityReadOnly = (entity: Entity, ticketType?: TicketType) => {
  if (!ticketType) {
    return false;
  }

  const { entityRouting } = ticketType;
  return entityRouting?.some(({ entityType, readonly }) => entityType === entity._type && readonly);
}

export default isEntityReadOnly;
