import * as React from 'react';

import { Label, Dropdown } from 'semantic-ui-react';
import { Translation } from 'react-i18next';

interface CirclesProps {
  circles: any[];
  usersList: any[];
  onAdd: Function;
  onRemove: Function;
}

interface CirclesState {
  circles: any[];
}

class Circles extends React.Component<CirclesProps, CirclesState> {
  possibleDelegates: { text: string; value: string }[];
  static defaultProps: object;
  constructor(props: CirclesProps) {
    super(props);

    this.state = {
      circles: props.circles
    };

    this.possibleDelegates = props.usersList.map((user) => {
      return {
        text: `${((user || {}).profile || {}).firstName} ${((user || {}).profile || {}).lastName} `,
        value: user.UID
      };
    });
  }

  handleAddDelegate = (usr: any) => {
    // let delagateShouldBeAdded = false;
    // this.setState(
    //   previousState => {
    //     let newDelegates = previousState.delegates;
    //     const delegatesOld = Array.from(previousState.delegates);
    //     delagateShouldBeAdded = delegatesOld.indexOf(usr) === -1;
    //     if (delagateShouldBeAdded) {
    //       newDelegates = [...delegatesOld, usr];
    //     }
    //     return {
    //       delegates: newDelegates
    //     };
    //   },
    //   () => {
    //     if (delagateShouldBeAdded) {
    //       this.props.onAdd(usr);
    //     }
    //   }
    // );
  };

  handleDeleteDelegate = (usr: string) => {
    // let delegateShouldBeRemoved = false;
    // this.setState(
    //   previousState => {
    //     let newDelegates = previousState.delegates;
    //     delegateShouldBeRemoved =
    //       newDelegates.indexOf(usr) !== -1 && newDelegates.length > 1;
    //     if (delegateShouldBeRemoved) {
    //       newDelegates.splice(newDelegates.indexOf(usr), 1);
    //     }
    //     return {
    //       delegates: newDelegates
    //     };
    //   },
    //   () => {
    //     if (delegateShouldBeRemoved) {
    //       this.props.onRemove(usr);
    //     }
    //   }
    // );
  };

  render() {
    return (
      <Translation ns="translations">
        {(t: any) => (
          <React.Fragment>
            <Label.Group>
              {this.state.circles.map((circle) => {
                return null;
                //            const userData = this.getUserData(this.props.usersList, usr);
                // const label = (
                //   <Label
                //     key={circle}
                //     image={true}
                //     size="small"
                //     style={{ backgroundColor: "lightgrey", margin: "5px" }}
                //   >
                //     <img src={getUserImage(userData.profile.profileImage)} />
                //     {`${userData.profile.firstName} ${userData.profile.lastName}`}
                //     <Icon
                //       name="delete"
                //       onClick={() => {
                //         this.handleDeleteDelegate(usr);
                //       }}
                //     />
                //   </Label>
                // );
                // const content = <UserProfile data={userData} />;
                // return (
                //   <Popup
                //     style={{ padding: 0 }}
                //     key={usr}
                //     wide={true}
                //     hoverable={true}
                //     flowing={true}
                //     trigger={label}
                //     content={content}
                //   />
                // );
              })}
            </Label.Group>
            <Dropdown
              selection={true}
              selectOnBlur={false}
              multiple={false}
              search={true}
              size="mini"
              onChange={(event, data) => {
                this.handleAddDelegate(data.value);
              }}
              placeholder={t('PLACEHOLDER_WRITE_TO_SEARCH')}
              options={this.possibleDelegates}
            />
          </React.Fragment>
        )}
      </Translation>
    );
  }

  getUserData = (list: any[], UID: string) => {
    let user: any;
    for (let i = 0; i < list.length; i++) {
      if (list[i].UID === UID) {
        user = list[i];
        break;
      }
    }
    return user;
  };
}

Circles.defaultProps = {
  circles: []
};

export default Circles;
