import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import type { VFC } from 'react';
import type { ConnectedProps } from 'react-redux';

import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import { startWorkingOn } from 'src/Utilities/workStatusParser';
import { updateTicket } from 'src/actions/ticketsActions';
import UserAvatar from '../Components/User/UserAvatar';
import UserPopup from '../Components/User/UserPopup';
import type { PersonalData, User } from 'src/types/User';
import type { State } from 'src/types/initialState';

export interface OwnProps {
  id: string;
  showStartWorkingOnButton: boolean;
  user?: User;
  userData?: PersonalData;
}

type Props = ConnectedProps<typeof connector>;

const WorkStatusImage: VFC<Props> = ({
  id,
  userData,
  usersList,
  workStatus,
  currentUserData,
  showStartWorkingOnButton,
  startWorkingOn
}) => {
  const currentStatus = workStatus.status.find((status) => 'TSK' + status.ticketId === id);
  const currentUser =
    typeof currentStatus !== 'undefined' ? usersList.find((user) => user.UID === 'USR' + currentStatus.UID) : null;
  const showButton = showStartWorkingOnButton && FeatureFlags.isFlagOn('ENABLE_START_WORKING_ON_FROM_TICKETLIST');

  if (!currentUser && showButton) {
    return (
      <Button
        disabled={!userData?.permissions.includes('updateContent')}
        onClick={() => {
          startWorkingOn(currentUserData.UID, id);
        }}
        positive={true}
        icon={true}
        style={{ marginTop: 'auto', marginRight: 0, maxHeight: '36px' }}
      >
        <Icon name="plus" />
      </Button>
    );
  }

  if (!currentUser) {
    return null;
  }

  return (
    <div className="ticket-current-agent topBarTicketAgent">
      <UserPopup UID={currentUser.UID}>
        <span>
          <UserAvatar user={currentUser} size="32" round />
        </span>
      </UserPopup>
    </div>
  );
};

const connector = connect(
  (state: State, ownProps: OwnProps) => ({
    ...ownProps,
    workStatus: state.workStatus,
    usersList: state.usersList.usersList,
    currentUserData: state.userData,
    id: ownProps.id
  }),
  (dispatch: any) => ({
    startWorkingOn: (UID: string, taskId: string) => {
      dispatch(updateTicket(taskId, { status: 'doing' }));
      TicketsApi.startWorkingOn(...startWorkingOn(UID, taskId));
    }
  })
);

export default connector(WorkStatusImage);
