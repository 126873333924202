import type { Ticket } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

export interface ActiveTimeTrackingOptions {
  workStatus: State['workStatus']['status']; // who is working on and what
  activeTicketId: State['activeTicketTab']; // what is currently active tab
  ticketDetails?: Ticket; // status of ticket
  UID: string; // USR1
}

export const getTimeTrackingActionFromState = (opts: ActiveTimeTrackingOptions): string | number | null => {
  const { workStatus, activeTicketId, ticketDetails, UID } = opts;

  // If no ticket is as activeTicketTab
  if (!activeTicketId) return null;

  const isActiveTicketWorkedByMe = workStatus.find((x) => {
    return x.ticketId === activeTicketId?.substring(3) && x.UID === UID.substring(3);
  });

  if (isActiveTicketWorkedByMe && ticketDetails?.status === 'doing') {
    return activeTicketId;
  } else {
    return null;
  }
};
