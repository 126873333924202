import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { TFeatureFlag } from './FeatureFlags';
import type { EnvSettingsParameters } from './EnvSettings';
import type { SMSConfiguration } from 'src/types/Configuration';

const API_URL_GET_ENV_SETTINGS = '/settings';
const API_URL_GET_FEATURE_FLAGS = '/featureFlags';
const API_URL_GET_PHONE_CONFIGURATIONS = '/PhoneConfigurations';

export default class UsersApi {
  static getEnvSettings = (): Promise<EnvSettingsParameters> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_GET_ENV_SETTINGS).then((response) => {
      return {
        ...response.data
      };
    });
  };

  static getFeatureFlags = (): Promise<TFeatureFlag[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_GET_FEATURE_FLAGS).then((response) => {
      return response.data;
    });
  };

  static getPhoneConfigurations = (): Promise<TFeatureFlag[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_GET_PHONE_CONFIGURATIONS).then((response) => {
      return response.data;
    });
  };

  static getSMSConfigurations = async (): Promise<SMSConfiguration[]> => {
    return axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}/SMS/serviceNumbers/all`)
      .then((response) => response.data);
  };
}
