import React from 'react';
import { Label } from 'semantic-ui-react';
import type { VFC } from 'react';

import ErrorBoundary from 'src/ErrorBoundary';
import type { Tag } from 'src/types/Tag';

import * as styles from './TicketListItem.style';

const AllTags: VFC<{ sortedTags: Tag[] }> = ({ sortedTags }) => (
  <ErrorBoundary>
    <div>
      {sortedTags.map((tag: Tag, index: number) => {
        const style = styles.allTagsLabel({
          bgColor: tag?.styles?.bgColor ?? '#e8e8e8',
          fontColor: tag?.styles?.fontColor ?? 'rgba(0,0,0,.6)'
        });

        return (
          <Label style={style} className={tag.name} key={index}>
            {tag.name}
          </Label>
        );
      })}
    </div>
  </ErrorBoundary>
);

export default AllTags;
