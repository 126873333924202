import * as React from 'react';
import { t as tr } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import iziToast from 'izitoast';
import type { DropdownProps } from 'semantic-ui-react';
import { Dropdown, Form, Message, Icon } from 'semantic-ui-react';

import SalesforceTaskForm from './Salesforce/SalesforceTaskForm';
import SalesforceLeadForm from './Salesforce/SalesforceLeadForm';
import SalesforceOpportunityForm from './Salesforce/SalesforceOpportunityForm';
import SalesforceEmailForm from './Salesforce/SalesforceEmailForm';
import SalesforcePhoneForm from './Salesforce/SalesforcePhoneForm';
import type { Option } from '../MultiSelectInput/MultiSelectInput';
import ReplyControlButtons from './components/ReplyControlButtons';
import type { ReplyMethodProps } from './ReplyMethod';
import { ReplyMethod } from './ReplyMethod';
import type { ISalesforceSettingsData } from 'src/api/EnvSettings';
import EnvSettings from 'src/api/EnvSettings';
import SalesforceApi from 'src/api/SalesforceApi';
import type { Ticket } from 'src/types/Ticket';
import type { PersonalData } from 'src/types/User';
import type { IGeneralSalesforceData } from '../../types/Salesforce';
import { Channels } from 'src/types/Channel';

export interface D365FormPayload {
  type?: string;
  Subject?: string;
  Emails?: Option[];
  Phones?: Option[];
  Description?: string;
  Status?: string;
  Name?: string;
  AccountId?: string;
  WhoId?: string;
  OwnerId?: string;
  Company?: string;
  StageName?: string;
  CloseDate?: number;
  ActivityDate?: number;
  Amount?: number;
}

interface ReplyD365Props extends ReplyMethodProps<ReplyD365State> {
  userData: PersonalData;
  drafts: ReplyD365State;
  task: Ticket;
}

export interface ReplyD365State {
  isLoading: boolean;
  isSubmitting: boolean;
  contacts: IGeneralSalesforceData[];
  accounts: IGeneralSalesforceData[];
  users: IGeneralSalesforceData[];
  payload: D365FormPayload;
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
}

class ReplySalesforce extends ReplyMethod<ReplyD365Props, ReplyD365State> {
  constructor(props: ReplyD365Props) {
    super(props);

    this.state = this.getInitialState(this.props.drafts);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  getDraftChannel(): Channels {
    return Channels.d365;
  }

  getDraftState(): Partial<ReplyD365State> {
    return {
      payload: this.state.payload
    };
  }

  private getInitialState = (drafts: ReplyD365State): ReplyD365State => ({
    isLoading: false,
    isSubmitting: false,
    contacts: [],
    accounts: [],
    users: [],
    payload: {
      ...drafts.payload
    },
    validationErrors: {},
    salesforceSettings: EnvSettings.getSettings().SALESFORCE.data
  });

  private handleSetState = (fields: Partial<ReplyD365State['payload']>) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          ...fields
        }
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  handleSelectChange(property: string, value: string) {
    this.handleSetState({
      [property]: value
    });
  }

  public clearFields = () =>
    this.setState(
      {
        payload: {}
      },
      () => {
        this.saveDraft(this.state);
      }
    );

  private onSubmit = async () => {
    this.setState({ isSubmitting: true, validationErrors: {} }, () => {
      let validationSchema = yup.object().shape({});

      switch (this.state.payload.type) {
        case 'task':
          validationSchema = taskValidationSchema;
          break;
        case 'lead':
          validationSchema = leadValidationSchema;
          break;
        case 'opportunity':
          validationSchema = opportunityValidationSchema;
          break;
        case 'email':
          validationSchema = emailValidationSchema;
          break;
        case 'call':
          validationSchema = callValidationSchema;
          break;
        default:
          break;
      }

      validationSchema
        .validate(this.state.payload, { abortEarly: false })
        .then(async () => {
          const {
            Subject,
            Description,
            Status,
            Name,
            AccountId,
            WhoId,
            OwnerId,
            Company,
            StageName,
            CloseDate,
            ActivityDate,
            Amount
          } = this.state.payload;

          const {
            task: taskMapping,
            lead: leadMapping,
            opportunity: opportunityMapping
          } = this.state.salesforceSettings.fieldsMapping;

          switch (this.state.payload.type) {
            case 'task': {
              await SalesforceApi.createTask({
                ticketId: this.props.task.id,
                payload: {
                  ...(!!Subject && { [taskMapping.Subject]: Subject }),
                  ...(!!Description && { [taskMapping.Description]: Description }),
                  ...(!!Status && { [taskMapping.Status]: Status }),
                  ...(!!ActivityDate && {
                    [taskMapping.ActivityDate]: this.state.salesforceSettings.dueDateInSeconds
                      ? ActivityDate
                      : ActivityDate * 1000
                  }),
                  ...(!!WhoId && { [taskMapping.WhoId]: WhoId }),
                  ...(!!OwnerId && { [taskMapping.OwnerId]: OwnerId })
                }
              });
              break;
            }

            case 'lead': {
              await SalesforceApi.createLead({
                ticketId: this.props.task.id,
                payload: {
                  ...(!!Name && { [leadMapping.Name]: Name }),
                  ...(!!Description && { [leadMapping.Description]: Description }),
                  ...(!!Status && { [leadMapping.Status]: Status }),
                  ...(!!Company && { [leadMapping.Company]: Company }),
                  ...(!!OwnerId && { [leadMapping.OwnerId]: OwnerId })
                }
              });
              break;
            }

            case 'opportunity': {
              await SalesforceApi.createOpportunity({
                ticketId: this.props.task.id,
                payload: {
                  Name,
                  ...(!!Name && { [opportunityMapping.Name]: Name }),
                  ...(!!Description && { [opportunityMapping.Description]: Description }),
                  ...(!!StageName && { [opportunityMapping.StageName]: StageName }),
                  ...(!!CloseDate && {
                    [opportunityMapping.CloseDate]: this.state.salesforceSettings.closeDateInSeconds
                      ? CloseDate
                      : CloseDate * 1000
                  }),
                  ...(!!Amount && { [opportunityMapping.Amount]: Amount }),
                  ...(!!AccountId && { [opportunityMapping.AccountId]: AccountId }),
                  ...(!!OwnerId && { [opportunityMapping.OwnerId]: OwnerId })
                }
              });
              break;
            }

            case 'email': {
              const logAnEmailRequests =
                this.state.payload.Emails?.map(
                  async (providedCustomer) =>
                    await SalesforceApi.logAnEmail({
                      ticketId: this.props.task.id,
                      payload: {
                        WhoId: this.state.contacts.find(
                          (contact: IGeneralSalesforceData) => contact.Email === providedCustomer.value
                        )?.Id,
                        Subject: this.state.payload.Emails?.map((email: Option) => email.value).join(', '),
                        Description
                      }
                    })
                ) || [];

              this.state.payload.Emails?.map(async (singeCustomer) => {
                const foundContactToLog = this.state.contacts.find(
                  (contact: IGeneralSalesforceData) => contact.Email === singeCustomer?.value
                );

                if (!foundContactToLog)
                  iziToast.error({
                    title: `${tr('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Record not created for ${singeCustomer.value}. Not found such contact`,
                    timeout: 7500
                  });
              });
              await Promise.all(logAnEmailRequests);
              break;
            }

            case 'call': {
              const logACallRequests =
                this.state.payload.Phones?.map(
                  async (providedCustomer) =>
                    await SalesforceApi.logACall({
                      ticketId: this.props.task.id,
                      payload: {
                        WhoId: this.state.contacts.find(
                          (contact: IGeneralSalesforceData) => contact.Phone === providedCustomer.value
                        )?.Id,
                        Subject: this.state.payload.Phones?.map((phone: Option) => phone.value).join(', '),
                        Description
                      }
                    })
                ) || [];

              this.state.payload.Phones?.map(async (singeCustomer) => {
                const foundContactToLog = this.state.contacts.find(
                  (contact: IGeneralSalesforceData) => contact.Phone === singeCustomer.value
                );

                if (!foundContactToLog)
                  iziToast.error({
                    title: `${tr('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Record not created for ${singeCustomer.value}. Not found such contact`,
                    timeout: 7500
                  });
              });
              await Promise.all(logACallRequests);
              break;
            }

            default: {
              break;
            }
          }

          this.clearFields();
          this.setState({ isSubmitting: false });

          iziToast.success({
            title: tr('OK'),
            icon: 'icon check',
            message: 'Record was created',
            timeout: 5000
          });
        })
        .catch((err) => {
          if (err.inner?.length) {
            err.inner.forEach((error: any) => {
              if (error.path) {
                this.setState({
                  validationErrors: {
                    ...this.state.validationErrors,
                    [error.path]: error.message
                  }
                });
              }
            });
          } else {
            iziToast.error({
              title: `${tr('ERROR')}!`,
              icon: 'icon delete',
              message: `Failed to create Salesforce record`,
              timeout: 7500
            });
          }

          this.setState({ isSubmitting: false });
        });
    });
  };

  private renderFormField = () => {
    switch (this.state.payload.type) {
      case 'task':
        return (
          <SalesforceTaskForm
            validationErrors={this.state.validationErrors}
            salesforceSettings={this.state.salesforceSettings}
            payload={this.state.payload}
            contacts={this.state.contacts}
            users={this.state.users}
            handleSetState={this.handleSetState}
            handleSelectChange={this.handleSelectChange}
            setSearchableSelectFields={this.setSearchableSelectFields}
          />
        );

      case 'lead':
        return (
          <SalesforceLeadForm
            validationErrors={this.state.validationErrors}
            salesforceSettings={this.state.salesforceSettings}
            payload={this.state.payload}
            users={this.state.users}
            handleSetState={this.handleSetState}
            handleSelectChange={this.handleSelectChange}
            setSearchableSelectFields={this.setSearchableSelectFields}
          />
        );

      case 'opportunity':
        return (
          <SalesforceOpportunityForm
            validationErrors={this.state.validationErrors}
            salesforceSettings={this.state.salesforceSettings}
            payload={this.state.payload}
            accounts={this.state.accounts}
            users={this.state.users}
            handleSetState={this.handleSetState}
            handleSelectChange={this.handleSelectChange}
            setSearchableSelectFields={this.setSearchableSelectFields}
          />
        );

      case 'email':
        return (
          <SalesforceEmailForm
            validationErrors={this.state.validationErrors}
            payload={this.state.payload}
            handleSetState={this.handleSetState}
          />
        );

      case 'call':
        return (
          <SalesforcePhoneForm
            validationErrors={this.state.validationErrors}
            payload={this.state.payload}
            handleSetState={this.handleSetState}
          />
        );

      default:
        return;
    }
  };

  private getInitialFormPayload = async (formType: string) => {
    switch (formType) {
      case 'task': {
        if (this.props.task.entities[0]?._id) {
          await this.setSearchableSelectFields('contact', this.props.task.entities[0]._id);
        }

        if (this.state.salesforceSettings.taskDefaultOwnerId) {
          await this.setSearchableSelectFields('user', this.state.salesforceSettings.taskDefaultOwnerId);
        }

        return {
          WhoId: this.props.task.entities[0]?._id ?? '',
          OwnerId: this.state.salesforceSettings.taskDefaultOwnerId,
          Status: this.state.salesforceSettings.taskDefaultStatus
        };
      }

      case 'lead': {
        if (this.state.salesforceSettings.leadDefaultOwnerId) {
          await this.setSearchableSelectFields('user', this.state.salesforceSettings.leadDefaultOwnerId);
        }

        return {
          OwnerId: this.state.salesforceSettings.leadDefaultOwnerId,
          Status: this.state.salesforceSettings.leadDefaultStatus
        };
      }

      case 'opportunity': {
        if (this.props.task.entities[0]?.data?.Account?.data[0].Id) {
          await this.setSearchableSelectFields('account', this.props.task.entities[0].data.Account.data[0].Id);
        }

        if (this.state.salesforceSettings.opportunityDefaultOwnerId) {
          await this.setSearchableSelectFields('user', this.state.salesforceSettings.opportunityDefaultOwnerId);
        }

        return {
          CloseDate: moment().add(30, 'days').unix(),
          AccountId: this.props.task.entities[0]?.data?.Account?.data[0].Id ?? '',
          OwnerId: this.state.salesforceSettings.opportunityDefaultOwnerId,
          StageName: this.state.salesforceSettings.opportunityDefaultStage
        };
      }

      case 'email': {
        await this.setSearchableSelectFields('contact', '', true);

        const defaultEmails = this.props.task.entities
          .map((singleEntity) => {
            if (singleEntity.data?.Email) {
              return {
                label: singleEntity.data.Email,
                value: singleEntity.data.Email
              };
            }
            return undefined;
          })
          .filter((singleEntity) => !!singleEntity);

        return { Emails: defaultEmails };
      }

      case 'call': {
        await this.setSearchableSelectFields('contact', '', true);

        const defaultPhones = this.props.task.entities
          .map((singleEntity) => {
            if (singleEntity.data?.Phone) {
              return {
                label: singleEntity.data.Phone,
                value: singleEntity.data.Phone
              };
            }

            if (singleEntity.data?.MobilePhone) {
              return {
                label: singleEntity.data.Phone,
                value: singleEntity.data.Phone
              };
            }
            return undefined;
          })
          .filter((singleEntity) => !!singleEntity);

        return { Phones: defaultPhones };
      }

      default: {
        return {};
      }
    }
  };

  private setSearchableSelectFields = async (
    type: 'contact' | 'user' | 'account',
    stringToSearch: string,
    allowFullList?: boolean
  ) => {
    if (stringToSearch.length < 3 && !allowFullList) return;

    if (allowFullList) {
      this.setState({ isLoading: true });
    }

    switch (type) {
      case 'contact': {
        await SalesforceApi.getContacts(stringToSearch)
          .then((contacts) => {
            this.setState({
              contacts,
              isLoading: false
            });
          })
          .catch(() =>
            iziToast.error({
              title: `${tr('ERROR')}!`,
              icon: 'icon delete',
              message: `Cannot fetch contacts`,
              timeout: 7500
            })
          );
        break;
      }

      case 'account': {
        await SalesforceApi.getAccounts(stringToSearch)
          .then((accounts) => {
            this.setState({
              accounts,
              isLoading: false
            });
          })
          .catch(() =>
            iziToast.error({
              title: `${tr('ERROR')}!`,
              icon: 'icon delete',
              message: `Cannot fetch accounts`,
              timeout: 7500
            })
          );
        break;
      }

      case 'user': {
        await SalesforceApi.getUsers(stringToSearch)
          .then((users) => {
            this.setState({
              users,
              isLoading: false
            });
          })
          .catch(() =>
            iziToast.error({
              title: `${tr('ERROR')}!`,
              icon: 'icon delete',
              message: `Cannot fetch users`,
              timeout: 7500
            })
          );
        break;
      }
      default:
        break;
    }
  };

  render() {
    return (
      <Form reply={true} style={{ marginTop: '20px' }}>
        {!this.state.isLoading ? (
          <>
            <Form.Field>
              <label>{tr('salesforce_reply.choose_type')}</label>

              <Dropdown
                fluid
                search
                selection
                options={salesforceTypes}
                value={this.state.payload.type || ''}
                onChange={async (event: React.SyntheticEvent, data: DropdownProps) => {
                  const initialPayload: { [key: string]: any } = await this.getInitialFormPayload(data.value as string);

                  this.setState(
                    {
                      payload: { ...initialPayload }
                    },
                    () =>
                      this.handleSetState({
                        type: data.value as string
                      })
                  );
                }}
              />
            </Form.Field>

            {this.state.payload.type && this.renderFormField()}

            <ReplyControlButtons
              disabled={this.state.isSubmitting || !Object.keys(this.state.payload).length}
              internal={true}
              loading={this.state.isSubmitting}
              onClear={this.clearFields}
              onSubmit={this.onSubmit}
            />
          </>
        ) : (
          <Message icon info>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>{tr('salesforce_reply.loading')}</Message.Header>
              {tr('salesforce_reply.fetching_data')}
            </Message.Content>
          </Message>
        )}
      </Form>
    );
  }
}

const taskValidationSchema = yup.object().shape({
  Subject: yup.string().required(),
  Description: yup.string(),
  Status: yup.string().required(),
  WhoId: yup.string().required()
});

const leadValidationSchema = yup.object().shape({
  Name: yup.string().required(),
  Description: yup.string(),
  Status: yup.string().required(),
  Company: yup.string().required()
});

const opportunityValidationSchema = yup.object().shape({
  Name: yup.string().required(),
  Description: yup.string(),
  StageName: yup.string().required(),
  Amount: yup.string(),
  AccountId: yup.string().required()
});

const emailValidationSchema = yup.object().shape({
  Emails: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().email('All values should be email addresses')
      })
    )
    .min(1, 'Should contain at least one email address')
    .required(),
  Description: yup.string().required()
});

const callValidationSchema = yup.object().shape({
  Phones: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .string()
          .matches(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/, 'All values should be phone numbers')
      })
    )
    .min(1, 'Should contain at least one phone number')
    .required(),
  Description: yup.string().required()
});

const salesforceTypes = [
  {
    text: 'Task',
    value: 'task'
  },
  {
    text: 'Lead',
    value: 'lead'
  },
  {
    text: 'Opportunity',
    value: 'opportunity'
  },
  {
    text: 'Email',
    value: 'email'
  },
  {
    text: 'Call',
    value: 'call'
  }
];

export default ReplySalesforce;
