import {
  FETCH_RESPONSE_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TITLE_TEMPLATES_SUCCESS
} from '../actions/index';

const initialState: any = {
  titleTemplates: [],
  responseTemplates: [],
  templates: []
};

const templatesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_RESPONSE_TEMPLATES_SUCCESS: {
      return { ...state, responseTemplates: action.payload.templates };
    }
    case FETCH_TITLE_TEMPLATES_SUCCESS: {
      return { ...state, titleTemplates: action.payload.templates };
    }
    case FETCH_TEMPLATES_SUCCESS: {
      return { ...state, templates: action.payload.templates };
    }

    default:
      return state;
  }
};

export default templatesReducer;
