import * as React from 'react';
import { Card, Icon } from 'semantic-ui-react';
import iziToast from 'izitoast';
import { t as tr } from 'i18next';
import { Translation } from 'react-i18next';
import type { Entity } from '../../types/Ticket';

interface CustomerResultProps {
  onClick: any;
  fields: any[];
  values: Entity;
  mongodb: boolean;
  isAttached?: boolean;
}

class CustomerResult extends React.Component<CustomerResultProps> {
  render() {
    return (
      <Translation ns="translations">
        {(t: Function) => (
          <Card
            onMouseDown={this.props.isAttached ? this.onClickAlreadyAttachedEntity : this.props.onClick}
            fluid={true}
            link={this.props.isAttached ? false : true}
            color={this.props.isAttached ? 'green' : undefined}
            style={
              this.props.isAttached
                ? {
                    boxShadow: '0 0 0 1px #d4d4d5, 0 5px 0 0 #21ba45, 0 1px 3px 0 #d4d4d5',
                    cursor: 'not-allowed'
                  }
                : undefined
            }
          >
            <Card.Content>
              <Card.Header>
                <div className="customerCard">
                  <div>{this.getName()}</div>
                  <div className={this.props.mongodb ? 'eeedoCustomer' : 'integrationCustomer'} />
                </div>
              </Card.Header>
              <Card.Meta>{this.getDetails()}</Card.Meta>
            </Card.Content>
            {this.props.isAttached && (
              <Card.Content extra={true}>
                <Icon name="check" color="green" /> {t('TICKET_CUSTOMER_ALREADY_ATTACHED_CARD')}
              </Card.Content>
            )}
          </Card>
        )}
      </Translation>
    );
  }

  onClickAlreadyAttachedEntity = () => {
    iziToast.info({
      icon: 'info',
      message: tr('TICKET_CUSTOMER_ALREADY_ATTACHED_TOAST'),
      timeout: 5000
    });
  };

  getName = () => {
    const firstNameField = this.props.fields.find((x) => x.displayField === 'name1');
    const lastNameField = this.props.fields.find((x) => x.displayField === 'name2');
    const additionalNameField = this.props.fields.find((x) => x.displayField === 'name3');

    let firstName = '';
    let lastName = '';
    let additionalName = '';
    if (firstNameField !== undefined) {
      firstName = this.props.values[firstNameField.value] ? this.props.values[firstNameField.value] : '';
    }
    if (lastNameField !== undefined) {
      lastName = this.props.values[lastNameField.value] ? this.props.values[lastNameField.value] : '';
    }
    if (additionalNameField !== undefined) {
      additionalName = this.props.values[additionalNameField.value] ? this.props.values[additionalNameField.value] : '';
    }
    return firstName + ' ' + lastName + ' ' + additionalName;
  };

  getDetails = () => {
    const emailField = this.props.fields.find((x) => x.displayField === 'emailField');
    const phoneField = this.props.fields.find((x) => x.displayField === 'phoneField');
    const addressField = this.props.fields.find((x) => x.displayField === 'addressField');
    const companyField = this.props.fields.find((x) => x.displayField === 'companyField');
    const registryField = this.props.fields.find((x) => x.displayField === 'registryField');
    const idField = this.props.fields.find((x) => x.displayField === 'idField');
    let email = 'not found';
    let phoneNumber = 'not found';
    let address = 'not found';
    let company = undefined;
    let registry = undefined;
    let id = undefined;

    if (emailField) {
      if (emailField.object !== undefined) {
        email = this.props.values[emailField.object] ? this.props.values[emailField.object][emailField.value] : '';
      } else {
        email = this.props.values[emailField.value];
      }
    }
    if (phoneField) {
      if (phoneField.object !== undefined) {
        phoneNumber = this.props.values[phoneField.object]
          ? this.props.values[phoneField.object][phoneField.value]
          : '';
      } else {
        phoneNumber = this.props.values[phoneField.value];
      }
    }
    if (addressField) {
      if (addressField.object !== undefined) {
        address = this.props.values[addressField.object]
          ? this.props.values[addressField.object][addressField.value]
          : '';
      } else {
        address = this.props.values[addressField.value];
      }
    }
    if (registryField) {
      if (registryField.object !== undefined) {
        registry = this.props.values[registryField.object]
          ? this.props.values[registryField.object][registryField.value]
          : '';
      } else {
        registry = this.props.values[registryField.value];
      }
    }
    if (companyField) {
      if (companyField.object !== undefined) {
        company = this.props.values[companyField.object]
          ? this.props.values[companyField.object][companyField.value]
          : '';
      } else {
        company = this.props.values[companyField.value];
      }
    }
    if (idField) {
      if (idField.object !== undefined) {
        id = this.props.values[idField.object] ? this.props.values[idField.object][idField.value] : '';
      } else {
        id = this.props.values[idField.value];
      }
    }
    return (
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        {email && typeof email !== 'object' && (
          <span style={{ paddingRight: 10 }}>
            <Icon name="mail" />
            {email}
          </span>
        )}
        {phoneNumber && typeof phoneNumber !== 'object' && (
          <span>
            <Icon name="call" />
            {phoneNumber}
          </span>
        )}
        {address && typeof address !== 'object' && (
          <span>
            <Icon name="address book" />
            {address}
          </span>
        )}
        {company && typeof company !== 'object' && (
          <span>
            <Icon name="mail" />
            {company}
          </span>
        )}
        {registry && typeof registry !== 'object' && (
          <span>
            <Icon name="hashtag" />
            {registry}
          </span>
        )}
        {id && typeof id !== 'object' && (
          <span>
            <Icon name="address book" />
            {id}
          </span>
        )}
      </span>
    );
  };
}

export default CustomerResult;
