import React from 'react';
import { Dropdown, Icon, Label } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { orderBy } from 'lodash';

import UserLabel from '../User/UserLabel';
import { Roles } from 'src/types/User';
import type { PersonalData, User } from 'src/types/User';
import type { Suggestion } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

interface DelegatesProps {
  delegates: string[];
  usersList: User[];
  ticketType: TicketType;
  autosuggestions?: Suggestion[];
  userData: PersonalData;
  user?: User;
  showOnlyRoles?: number[];

  onAdd: (user: string) => void;
  onRemove: (user: string) => void;
  onGroupAdd: (group: number[]) => void;
}

interface DelegatesState {
  delegates: string[];
}

class Delegates extends React.Component<DelegatesProps, DelegatesState> {
  static defaultProps: object;

  handleAddDelegate = (usr: string) => {
    this.props.onAdd(usr);
  };

  handleDeleteDelegate = (usr: string) => {
    this.props.onRemove(usr);
  };

  handleGroupAddDelegate = (usrGroup: any) => {
    this.props.onGroupAdd(usrGroup);
  };

  isDelegationGroupUsed = (valueAsString: string, delegates: string[]) => {
    if (delegates.length > 0) {
      const groupOfUsers = valueAsString.split(',');
      return groupOfUsers.every((usr: string) => delegates.includes(usr));
    }

    return false;
  };

  render() {
    const onlyRoles = this.props.showOnlyRoles;

    const possibleDelegates = [...this.props.usersList]
      .sort((a) => (a.UID === this.props.user?.UID ? 1 : 0))
      .filter(
        (user) =>
          !this.props.delegates.includes(user.UID) &&
          user.profile.hideFromDelegation === 0 &&
          user.role.id !== 'ROL3' &&
          user.ticketTypes.includes(this.props.ticketType.id) &&
          (onlyRoles === undefined || onlyRoles.includes(Roles.idToNumber(user.role.id)))
      )
      .map((user) => ({
        text: `${((user || {}).profile || {}).firstName} ${((user || {}).profile || {}).lastName} `,
        value: user.UID,
        icon: ''
      }));

    // Let's not show "mandatory delegate" to users, only user assigned  delegates
    const delegates = this.props.delegates.filter((delegate) => delegate !== 'USR1');

    this.props?.autosuggestions?.forEach((suggestion: Suggestion) => {
      if (
        suggestion.type === 'DelegationGroup' &&
        !this.isDelegationGroupUsed(suggestion.value, delegates) &&
        (suggestion.ticketTypes.includes(this.props.ticketType.id) || suggestion.ticketTypes.length === 0)
      ) {
        possibleDelegates.push({
          icon: 'users',
          text: suggestion.name,
          value: `${suggestion.type}_${suggestion.name}`
        });
      }
    });

    return (
      <Translation ns="translations">
        {(t) => (
          <React.Fragment>
            <Label.Group>
              {delegates.map((usr, index) => (
                <UserLabel UID={usr} key={`delegate-user-label-${index}`}>
                  <Icon name="delete" onClick={() => this.handleDeleteDelegate(usr)} size="small" link />
                </UserLabel>
              ))}
            </Label.Group>
            <Dropdown
              disabled={!this.props.userData.permissions.includes('updateContent')}
              selection={true}
              selectOnBlur={false}
              multiple={false}
              search={true}
              size="mini"
              id="delegateDropdown"
              value=""
              selectOnNavigation={false}
              onChange={(event, data) => {
                if (data.value !== undefined) {
                  if (typeof data.value === 'string' && data.value.startsWith('DelegationGroup')) {
                    const splittedValue = data.value.split('_');
                    const userGroupName = splittedValue[1];
                    const userGroup = this.props?.autosuggestions?.find((suggestion: Suggestion) => {
                      return suggestion.name === userGroupName;
                    });
                    if (userGroup) {
                      const delArray = userGroup.value.split(',');
                      this.handleGroupAddDelegate(delArray);
                    }
                  } else {
                    this.handleAddDelegate(data.value.toString());
                  }
                }
              }}
              placeholder={t('PLACEHOLDER_WRITE_TO_SEARCH')}
              options={orderBy(possibleDelegates, ['text', 'value'])}
            />
          </React.Fragment>
        )}
      </Translation>
    );
  }

  getUserData = (list: Array<User>, UID: string) => {
    let user: any;
    for (let i = 0; i < list.length; i++) {
      if (list[i].UID === UID) {
        user = list[i];
        break;
      }
    }
    return user;
  };
}

Delegates.defaultProps = {
  delegates: []
};

export default Delegates;
