import React from 'react';

import { channelNameToType } from 'src/Components/CommentIconContent/ChannelType';
import { commentsVC } from 'src/api/VersionControl';
import { parseJsonContent } from 'src/Components/ReplyMethods/components/ReplyVCDrafts';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';
import type { Channels } from 'src/types/Channel';
import type { ViewMode } from 'src/Components/Versions/VersionViewer';

export interface ReplyMethodProps<S> {
  taskId: string;
  updateState: (taskId: string, channel: Channels, state: Partial<S>) => void;
}

const AUTOSAVE_TIMEOUT = 1000 * 60 * 15;

export abstract class ReplyMethod<P extends ReplyMethodProps<S>, S> extends React.PureComponent<P, S> {
  abstract getDraftChannel(): Channels;

  abstract getDraftState(state: S): Partial<S>;

  private autoSaveIntervalId?: NodeJS.Timeout;

  componentDidMount() {
    this.autoSaveIntervalId = setInterval(() => this.saveVCDraft(true), AUTOSAVE_TIMEOUT);
  }

  componentWillUnmount() {
    this.saveVCDraft(true);

    clearInterval(this.autoSaveIntervalId);
  }

  saveDraft = (state: S) => {
    this.props.updateState(this.props.taskId, this.getDraftChannel(), this.getDraftState(state));
  };

  updateState(update: Partial<S>) {
    this.setState(
      (previousState: S) => {
        return {
          ...previousState,
          ...update
        };
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  }

  updateWithPreviousState(func: (previous: S) => void) {
    this.setState(
      (previous) => func(previous),
      () => this.saveDraft(this.state)
    );
  }

  saveVCDraft = (isAutoSave = false) => {
    const channel = channelNameToType(this.getDraftChannel());
    const id = taskIdToNumericalId(this.props.taskId);
    const { content } = this.state as any;

    if (!content && isAutoSave) {
      return;
    }

    commentsVC.sendDraft({
      id,
      channelId: channel,
      content: JSON.stringify({
        ...this.state,
        content
      }),
      attachments: [],
      forceSave: true
    });
  };

  loadVCDraft = async (_mode: ViewMode, draftId: number) => {
    const taskId = taskIdToNumericalId(this.props.taskId);
    const draft = await commentsVC.loadDraftById(taskId, draftId);

    if (draft && draft.content) {
      try {
        const state = parseJsonContent(draft.content);
        this.setState(state);
      } catch (e) {
        console.error(e);
      }
    }
  };
}
