import axios from 'axios';

import ApiConfig from './ApiConfig';
import { Chat } from './../types/Chat';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';

const API_URL_GET_ALL_CHATS = '/chatStatuses/';
const API_URL_PATCH_CHAT = '/chatStatus/';
const API_URL_CREATE_CHAT = '/chatStatuses/';

export default class ChatsApi {
  static getChats = (): Promise<Chat[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_GET_ALL_CHATS).then((response) => {
      return response.data;
    });
  };
  static patchChat = (data: any): Promise<any> => {
    return axios.patch(ApiConfig.getConfig().API_URL + API_URL_PATCH_CHAT + data.id, data.data).then((response) => {
      return response.data;
    });
  };
  static patchAllChats = (data: any): Promise<any> => {
    return axios.patch(ApiConfig.getConfig().API_URL + API_URL_PATCH_CHAT + 'all', data).then((response) => {
      return response.data;
    });
  };
  static createChat = (data: any): Promise<any> => {
    return axios
      .post(ApiConfig.getConfig().API_URL + API_URL_CREATE_CHAT, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  };
}
