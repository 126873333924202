import * as _ from 'lodash';
import { SWIPE_LEFT, SWIPE_RIGHT, ACTIVATE_TAB, TOGGLE_MOBILE_MODE, CLOSE_TAB } from '../actions/index';

const initialState = {
  leftTabOpen: true,
  rightTabOpen: false,
  mobileMode: false
};

export type MobileReducerType = typeof initialState;

const mobileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SWIPE_LEFT: {
      const newState = _.cloneDeep(state);
      newState.leftTabOpen = false;
      return newState;
    }
    case SWIPE_RIGHT:
    case CLOSE_TAB: {
      const newState = _.cloneDeep(state);
      newState.leftTabOpen = true;
      return newState;
    }
    case ACTIVATE_TAB: {
      const newState = _.cloneDeep(state);
      newState.leftTabOpen = false;
      return newState;
    }
    case TOGGLE_MOBILE_MODE: {
      const newState = _.cloneDeep(state);
      newState.mobileMode = action.payload;
      return newState;
    }
    default:
      return state;
  }
};

export default mobileReducer;
