import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { handleIncomingPhoneCall } from '../handlers/handlePhoneCall';
import { removeCallRequest } from 'src/actions/phoneActions';
import ElisaOC from '../Components/PhoneServices/ElisaOC';
import type { IntegrationData } from '../handlers/handlePhoneCall';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    configurations: state.configurations,
    callRequests: state.callRequests,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    removeCallRequest: (ticketId: number, phoneNumber: string) => {
      dispatch(
        removeCallRequest({
          ticketId,
          phoneNumber
        })
      );
    },
    handleIncomingPhoneCall: (callObject: IntegrationData) => {
      return handleIncomingPhoneCall({ dispatch }, callObject);
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ElisaOC));
