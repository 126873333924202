import * as _ from 'lodash';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Dimmer, Divider, Form, Grid, Header, Icon, Loader, Message } from 'semantic-ui-react';
import TopBarContainer from 'src/containers/TopBarContainer';
import AssetContainer from '../../containers/AssetContainer';
import CustomerContainer from '../Customer/CustomerContainer';
import AccordionHeader from './AccordionHeader';
import AddComment from './AddComment';
import Attachments from './Attachments';
import './Case.css';
import Circles from './Circles';
import Delegates from './Delegates';
import ExternalLinksList from './ExternalLinksList';
import Comments from '../Comments/Comments';
import Info from './Info/Info';
import TagsWidget from 'src/Components/Case/Widget/TagsWidget';
import { getTicketHash } from 'src/Utilities/ticket';
import type { AttachmentEdit } from './AttachmentItem';
import type { ReplyTabIndex } from 'src/types/Drafts';
import type { StatusTypes, Ticket } from 'src/types/Ticket';

export type FetchFunction = (id: string) => Ticket;
export type ActivateFunction = (id: any) => void;
export type OpenTabFunction = (id: number) => void;
export type UpdateFunction = (task: Ticket) => void;

interface CaseProps {
  task: Ticket;
  id: string;
  [x: string]: any;
  fetchTicket: FetchFunction;
  activateTicket: ActivateFunction;
  usersList: any;
  userData: any;
  ticketTypes: any;
  activateTab: (...args: any[]) => any;
  updateTicketCaseDetails: (...args: any[]) => any;
  updateEntityDetails: (...args: any[]) => any;
  createTicket: (...args: any[]) => any;
  updateTicket: (...args: any[]) => any;
  addComment: (...args: any[]) => any;
  addTagToContent: (...args: any[]) => any;
  removeTagFromContent: (...args: any[]) => any;
  addDelegateToContent: (...args: any[]) => any;
  removeDelegateFromContent: (...args: any[]) => any;
  addGroupDelegateToContent: (...args: any[]) => any;
  removeGroupDelegateFromContent: (...args: any[]) => any;
  uploadFile: any;
  editAttachment: any;
  deprecateAttachment: any;
  unDeprecateAttachment: any;
  openTab: any;
  closeTab: any;
  history: any;
  showEssentials?: boolean;
  closeAsDone: (...args: any[]) => any;
  externalLinks: any[];
  onSearchPreviousTickets: (...args: any[]) => any;
  ticketChannels: any;
  replyTabIndexList: ReplyTabIndex[];
}

interface Error {
  info: boolean | string;
  msg: string;
}

interface CaseState {
  err: Error;
  task: Ticket | undefined;
  editingContent: boolean;
  commentIsSaving: boolean;
}

class SimpleCase extends React.Component<CaseProps, CaseState> {
  static defaultProps: object;
  caseInfo: any[];
  handleInfo: any[];
  entityInfo: any[];
  senderEmails: any[];
  fieldSetNames: any;
  ticketTypeNames: any;
  channelTypes: any;
  fieldMap: any;
  ticketTypes: any;
  detailGroupMap: any;
  assetInfoFields: any;
  assetInfoDisplayName: any;
  showAssetMeta: any;

  constructor(props: CaseProps) {
    super(props);

    this.state = {
      task: undefined,
      err: {
        info: false,
        msg: ''
      },
      editingContent: false,
      commentIsSaving: false
    };

    this.entityInfo = [];
    this.handleInfo = [];
    this.caseInfo = [];
    this.senderEmails = [];
  }

  componentWillReceiveProps(nextProps: CaseProps) {
    if (nextProps.task) {
      if (nextProps.history.location.pathname === '/case/new_edit_oc' && nextProps.task.id !== 'new_oc') {
        nextProps.history.push(nextProps.task.id);
      }
      this.setState({ task: nextProps.task });
      if (this.state.commentIsSaving && !nextProps.ajaxCalls) {
        this.setState({ commentIsSaving: false });
      }
    }
    if (nextProps.uiFields) {
      nextProps.uiFields.forEach((f: any) => {
        const groupName = f.id;
        switch (groupName) {
          case 'handleStatus':
            this.handleInfo = f[f.id];
            break;
          case 'caseInfo':
            this.caseInfo = f[f.id];
            break;
          default:
            break;
        }
      });
    }

    if (nextProps.ticketTypes !== undefined && nextProps.ticketTypes.length > 0) {
      this.fieldSetNames = [];
      this.ticketTypeNames = [];
      this.channelTypes = [];
      this.fieldMap = new Map();
      this.ticketTypes = new Map();
      this.detailGroupMap = new Map();
      this.senderEmails = [];

      nextProps.ticketTypes.forEach((ticketType: any) => {
        this.ticketTypeNames.push(ticketType.name);
        this.ticketTypes.set(ticketType.name, ticketType.fieldSets);

        const senderEmail = _.cloneDeep(ticketType);
        senderEmail.senderemail.type = senderEmail.name;

        if (senderEmail.name === nextProps.task.taskType) {
          senderEmail.senderemail.default = true;
        }
        this.senderEmails.push(senderEmail.senderemail);
      });

      nextProps.ticketChannels.forEach((channelType: any) => {
        this.channelTypes.push({ text: channelType.channel, value: channelType.id });
      });

      const fieldSets = this.ticketTypes.get(nextProps.task.taskType);
      if (fieldSets && fieldSets.length > 0) {
        fieldSets.forEach((fieldSet: any) => {
          if (fieldSet.id === 'customerInfo') {
            this.entityInfo = fieldSet[fieldSet.id];
          } else if (fieldSet.id === 'status') {
            this.handleInfo = fieldSet[fieldSet.id];
          } else if (fieldSet.id === 'assetInfo') {
            this.assetInfoFields = fieldSet[fieldSet.id];
            this.assetInfoDisplayName = fieldSet.displayName;
            if (fieldSet.showAssetMeta === true) {
              this.showAssetMeta = true;
            } else {
              this.showAssetMeta = false;
            }
            this.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
          } else {
            this.fieldSetNames.push(fieldSet.displayName);
            this.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
            this.fieldMap.set(fieldSet.displayName, fieldSet[fieldSet.id]);
          }
        });
      }
    }
  }

  componentDidMount() {
    this.getTask(this.props.match.params.id);
    if (this.props.task) {
      this.setState({ task: this.props.task });
    }
  }

  componentDidUpdate(prevProps: CaseProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getTask(this.props.match.params.id);
    }
  }

  getTask = (id: string) => {
    if (id === 'new_edit') {
      return;
    }

    this.props.openTab(this.props.match.params.id);
    if (id !== 'new' && id !== 'NEW' && id !== 'new_edit_oc' && 'NEW_EDIT_OC') {
      this.props.fetchTicket(id);
    } else if (this.state.task) {
      this.props.activateTab(this.props.match.params.id);
      this.props.addTicket(this.state.task);
      this.props.activateTicket(this.state.task.id);
    }
  };

  titleEdit = (title: string) => {
    if (this.state.task) {
      const task: Ticket = this.state.task;
      task.title = title;

      if (this.props.location.pathname === '/case/new') {
        task.id = 'new_edit';
        this.props.history.push('new_edit');
      }
      this.setState({ task });
    }
  };

  handleEdit = (content: string) => {
    if (this.state.task) {
      const task = this.state.task;
      task.content = content;
      this.setState({ task: task });
    }
  };

  toggleEdit = () => {
    this.setState({ editingContent: !this.state.editingContent });
  };

  handleTagChange = (tagId: string, addNew: boolean, method: string) => {
    if (!this.props.task) {
      return;
    }

    if (typeof this.props.task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          this.props.removeTagFromContent(this.props.task, tagId);
          break;
        case 'POST':
        default:
          this.props.addTagToContent(this.props.task, tagId, addNew);
          break;
      }
    }
  };

  handleDelegateChange = (usr: string, method: string) => {
    if (!this.props.task) {
      return;
    }

    if (typeof this.props.task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          this.props.removeDelegateFromContent(this.props.task, usr);
          break;
        case 'POST':
        default:
          this.props.addDelegateToContent(this.props.task, usr);
          break;
      }
    }
  };

  handleGroupDelegateChange = (usrGroup: number[], method: string) => {
    if (!this.props.task) {
      return;
    }
    if (typeof this.props.task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          this.props.removeDelegateGroupFromContent(this.props.task, usrGroup);
          break;
        case 'POST':
        default:
          this.props.addDelegateGroupToContent(this.props.task, usrGroup);
          break;
      }
    }
  };

  dueDateChange = (newDueDate: number) => {
    if (!this.state.task) {
      return;
    }
    if (typeof this.state.task.id !== 'undefined' && this.state.task.id !== 'NEW') {
      const task = this.state.task;
      task.dueDate = newDueDate;
      this.setState({ task: task }, () => {
        this.props.updateTicket(this.state.task);
      });
    }
  };

  statusChange = (newStatus: StatusTypes) => {
    if (!this.state.task) {
      return;
    }
    if (typeof this.state.task.id !== 'undefined' && this.state.task.id !== 'NEW') {
      const task = this.state.task;
      task.status = newStatus;
      this.setState({ task: task }, () => {
        this.props.updateTicket(this.state.task);
      });
    }
  };

  ticketSave = () => {
    if (!this.state.task) {
      return;
    }
    if (this.state.task.id === 'new_edit' || this.state.task.id === 'new_oc') {
      this.props.createTicket(this.state.task);
    } else {
      this.props.updateTicket(this.state.task);
    }
  };

  commentSave = (value: any) => {
    this.setState({ commentIsSaving: true });
    const body = value;
    if (value.subject !== '') {
      const { id, created } = this.props.task;
      const hash = getTicketHash(id, created);
      body.subject = `${value.subject} [${id}${hash}]`;
    }
    this.props.addComment(this.props.task.id, value);
  };

  render() {
    const { t } = this.props;

    if (this.state.err.info !== false) {
      const err = this.state.err;
      return (
        <Message error={true}>
          <Message.Header>{err.info}</Message.Header>
          {err.msg}
        </Message>
      );
    } else if (this.state.task) {
      const task = this.state.task;
      const taskTicketType = this.props.ticketTypes.find((x: any) => x.name === task.taskType);

      return (
        <div id="topelement" style={{ padding: '10px 15px' }}>
          <Dimmer.Dimmable>
            <Dimmer active={false} inverted={true}>
              {/* <Dimmer active={this.props.ajaxCalls.find((callType) => callType === "FETCH_TICKET") ? true : false} inverted={true} > */}
              <Loader size="huge" active={this.props.loading}>
                {t('CASE_LOADING_CONTENT')}
              </Loader>
            </Dimmer>
            <TopBarContainer
              simple={true}
              // TODO: figure out if the component itself is in use
              // handleTitleEdit={this.titleEdit}
              // handleStatusChange={this.statusChange}
              // titleTemplates={[]}
              // handleTypeChange={() => console.log('TODO:TYPECHANGE!')}
              // handleDueDateEdit={this.dueDateChange}
              // handleBlur={this.ticketSave}
            />
            <Divider />
            <Grid columns={2} divided={true} stackable={true} stretched={true}>
              <Grid.Column stretched={true} className="case-left-side" width={10}>
                <Form>
                  <Form.TextArea
                    rows={2}
                    value={task.content}
                    onFocus={this.toggleEdit}
                    onChange={(e: any, value: any) => this.handleEdit(value.value)}
                  />
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      content={t('GENERAL_SAVE')}
                      labelPosition="left"
                      icon="save"
                      primary={true}
                      onClick={() => {
                        this.toggleEdit();
                        this.ticketSave();
                      }}
                    />
                  </div>
                </Form>
                <Dimmer.Dimmable>
                  <Dimmer active={this.state.task.id === 'NEW'} inverted={true}>
                    <div className="dimmerText-visible">DISABLED</div>
                  </Dimmer>

                  <Comments senderEmails={this.senderEmails} mobileMode={false} />

                  <Divider />
                  <Header as="h3" style={{ marginTop: '6px', marginBottom: '6px' }}>
                    <Icon name="talk" />
                    {t('CASE_SIMPLE_ANSWER')}
                  </Header>
                  {/* https://stackoverflow.com/questions/2631001/test-for-existence-of-nested-javascript-object-key */}
                  <AddComment
                    senderEmails={this.senderEmails}
                    simple={true}
                    noEmail={this.props.showEssentials || false}
                    subject={this.state.task.title}
                    attachments={task.attachments}
                    to={(((task || {}).customer || {}).email || {}).address || ''}
                    onSubmit={(value: any, isCaseDoneAfterSubmit: boolean) => {
                      this.commentSave(value);
                      if (isCaseDoneAfterSubmit) {
                        this.statusChange('done');
                      }
                    }}
                    isSaving={this.state.commentIsSaving}
                  />
                </Dimmer.Dimmable>
              </Grid.Column>
              <Grid.Column stretched={true} width={6} style={{ padding: 0 }} className="case-right-side">
                <Dimmer.Dimmable style={{ paddingTop: 10 }}>
                  <Dimmer active={this.state.task.id === 'NEW'} inverted={true}>
                    DISABLED
                  </Dimmer>
                  {!this.props.showEssentials && (
                    <AccordionHeader as="h4" active={true} title={t('CASE_TITLE_HANDLESTATUS')} icon="edit">
                      <Info
                        onSave={(editedField: any, editedValue: any) => {
                          this.props.updateTicketCaseDetails(
                            this.props.task.id,
                            editedField,
                            editedValue,
                            'CaseDetails'
                          );
                        }}
                        fields={this.handleInfo}
                        values={task.case}
                      />
                    </AccordionHeader>
                  )}
                  {!this.props.showEssentials && (
                    <AccordionHeader
                      as="h4"
                      active={true}
                      title={`${t('CASE_TITLE_VISIBILITY_GROUPS')} (${task.delegatedTo.length})`}
                      icon="group"
                    >
                      <Circles
                        usersList={this.props.usersList}
                        circles={task.circles}
                        onAdd={(usr: string) => {
                          this.handleDelegateChange(usr, 'POST');
                        }}
                        onRemove={(usr: string) => {
                          this.handleDelegateChange(usr, 'DELETE');
                        }}
                      />
                    </AccordionHeader>
                  )}
                  <AccordionHeader
                    as="h4"
                    active={true}
                    title={`${t('CASE_TITLE_DELEGATIONS')} (${task.delegatedTo.length})`}
                    icon="add user"
                  >
                    <Delegates
                      userData={this.props.userData}
                      usersList={this.props.usersList}
                      delegates={task.delegatedTo}
                      onAdd={(usr: string) => {
                        this.handleDelegateChange(usr, 'POST');
                      }}
                      onRemove={(usr: string) => {
                        this.handleDelegateChange(usr, 'DELETE');
                      }}
                      ticketType={taskTicketType}
                      onGroupAdd={(usrGroup: any[]) => {
                        this.handleGroupDelegateChange(usrGroup, 'POST');
                      }}
                    />
                  </AccordionHeader>
                  <TagsWidget displayName={t('CASE_TITLE_TAGS')} widgetOpen />
                  {this.assetInfoFields !== undefined && (
                    <AssetContainer
                      onSave={(editedField: any, editedValue: any, detailGroup: any) => {
                        this.props.updateTicketCaseDetails(this.props.task.id, editedField, editedValue, detailGroup);
                      }}
                      fields={this.assetInfoFields}
                      fieldSetName={this.assetInfoDisplayName}
                      detailGroup={this.detailGroupMap.get(this.assetInfoDisplayName)}
                      values={task.case || {}}
                      task={this.props.task}
                      organizationTag={'CAT86'}
                      assetTypeTag={'CAT87'}
                      showAssetMeta={true}
                    />
                  )}
                  {!this.props.showEssentials && (
                    <AccordionHeader as="h4" active={true} title={t('CASE_TITLE_CUSTOMER')} icon="address card">
                      <CustomerContainer
                        onDetachEntity={(body: object) => {
                          this.props.removeEntityFromCase(this.props.task.id, body);
                        }}
                        onAttachEntity={(body: any) => {
                          body.taskType = this.props.task.taskType;
                          this.props.addEntityToCase(this.props.task.id, body);
                        }}
                        onSave={(
                          editedCustomer: any,
                          editedField: any,
                          editedValue: any,
                          object: any,
                          partial: boolean
                        ) => {
                          this.props.updateEntityDetails(
                            this.props.task.id,
                            this.props.task.taskType,
                            editedCustomer,
                            editedField,
                            editedValue,
                            object,
                            partial
                          );
                        }}
                        onSearchPreviousTickets={() => {
                          this.props.onSearchPreviousTickets(this.props.task.customer._id);
                        }}
                        fields={this.entityInfo}
                        additionalCustomerFieldSets={[]}
                        values={task.customer || {}}
                        task={this.props.task}
                        ticketTypes={this.props.ticketTypes}
                        entityType={'eeedoCustomer'}
                      />
                    </AccordionHeader>
                  )}
                  {!this.props.showEssentials && (
                    <AccordionHeader as="h4" active={true} title={t('CASE_TITLE_DETAILS')} icon="info circle">
                      <Info
                        onSave={(editedField: any, editedValue: any) => {
                          this.props.updateTicketCaseDetails(
                            this.props.task.id,
                            editedField,
                            editedValue,
                            'CaseDetails'
                          );
                        }}
                        fields={this.caseInfo}
                        values={task.case}
                      />
                    </AccordionHeader>
                  )}
                  {!this.props.showEssentials && (
                    <AccordionHeader as="h4" active={true} title="Markkinointi" icon="info circle">
                      <ExternalLinksList
                        links={[
                          {
                            href: 'https://veikkaus.fi',
                            text: 'Adobe Campaign -tiedot',
                            params: []
                          }
                        ]}
                        task={{} as Ticket}
                      />
                    </AccordionHeader>
                  )}
                  {!this.props.showEssentials && (
                    <AccordionHeader as="h4" active={true} title="Veikkaus Points" icon="info circle">
                      <ExternalLinksList
                        links={[
                          {
                            href: 'https://veikkaus.fi',
                            text: 'Veikkaus Points',
                            params: []
                          }
                        ]}
                        task={{} as Ticket}
                      />
                    </AccordionHeader>
                  )}
                  {!this.props.showEssentials && (
                    <AccordionHeader as="h4" active={true} title={t('CASE_TITLE_LINKS')} icon="info circle">
                      <ExternalLinksList
                        links={[
                          { href: 'https://veikkaus.fi', text: 'MIMIR', params: [] },
                          { href: 'https://veikkaus.fi', text: 'KOLLI', params: [] },
                          { href: 'https://veikkaus.fi', text: 'Helppis', params: [] },
                          { href: 'https://veikkaus.fi', text: 'Mutka', params: [] }
                        ]}
                        task={{} as Ticket}
                      />
                    </AccordionHeader>
                  )}
                  <AccordionHeader
                    as="h4"
                    active={true}
                    title={`${t('CASE_TITLE_ATTACHMENTS')} (${task.attachments.length})`}
                    icon="attach"
                  >
                    <Attachments
                      disabled={this.props.showEssentials ? true : false}
                      onUpload={(files: any) => {
                        files.forEach((file: File) => {
                          const data = new FormData();
                          data.append('attachments', file);
                          this.props.uploadFile(task.id, data);
                        });
                      }}
                      dropZoneEnabled={true}
                      onEdit={(attachmentId: string, body: AttachmentEdit) => {
                        this.props.editAttachment(task.id, attachmentId, body);
                      }}
                      onDeprecate={(attachmentId: string) => {
                        this.props.deprecateAttachment(task.id, attachmentId);
                      }}
                      onUnDeprecate={(attachmentId: string) => {
                        this.props.unDeprecateAttachment(task.id, attachmentId);
                      }}
                      attachments={task.attachments}
                    />
                  </AccordionHeader>
                </Dimmer.Dimmable>
              </Grid.Column>
            </Grid>
          </Dimmer.Dimmable>
        </div>
      );
    } else {
      return (
        // <Message error={true}>Configuration error!</Message>
        <div>...</div>
      );
    }
  }
}

export default withTranslation('translations')(SimpleCase);
