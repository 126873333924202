import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import { addRingCallRequest, addOCCallRequest, addMitelCallRequest } from '../actions/phoneActions';
import { switchDraftTabIndex, updateTicketTabState } from '../actions/draftActions';
import {
  onCommentSubmit,
  addTagToContent,
  sendEntityEvent,
  switchTicketChatAnchorStatus,
  updateChatTypingStatusToCustomer,
  updateGiosgWritingStatus,
  uploadFile,
  updateTicket
} from '../actions/ticketsActions';
import ReplyChannelTabBar from '../Components/ReplyMethods/ReplyChannelTabBar';
import { getCaseTabIndexList } from 'src/Utilities/getCaseTabIndexLIst';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';
import type { State } from 'src/types/initialState';
import type { TFunction } from 'i18next';

export interface ReplyChannelTabBarProps extends ReplyChannelTabBarReduxProps {
  t: TFunction;
}

const mapStateToProps = (state: State) => {
  const task = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!;

  return {
    replyTabIndexList: getCaseTabIndexList(state.userData.channels),
    drafts: (task?.id && state.drafts[task.id]) ?? {},
    ticketTypes: state.ticketTypes,
    mobileMode: state.mobileReducer.mobileMode,
    userData: state.userData,
    templates: state.templates.responseTemplates,
    ticket: task,
    tags: state.tags,
    suggestions: state.suggestions,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!,
    users: state.usersList.usersList
  };
};

const mapDispatchToProps = {
  updateTicketTabState,
  onSubmit: onCommentSubmit,
  switchChatAnchor: switchTicketChatAnchorStatus,
  addTag: addTagToContent,
  uploadFile,
  sendEntityEvent,
  updateGiosgWritingStatus,
  updateChatTypingStatusToCustomer,
  switchDraftTab: switchDraftTabIndex,
  addRingCallRequest,
  addOCCallRequest,
  addMitelCallRequest,
  ticketStopWorkingOn,
  updateTicket
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ReplyChannelTabBarReduxProps = ConnectedProps<typeof connector>;

export default connector(ReplyChannelTabBar);
