import React from 'react';
import { Translation, withTranslation } from 'react-i18next';
import cloneDeep from 'lodash/cloneDeep';
import { Icon, Menu, Tab } from 'semantic-ui-react';
import type { TabPaneProps } from 'semantic-ui-react/dist/commonjs/modules/Tab/TabPane';
import type { SemanticShorthandItem } from 'semantic-ui-react/dist/commonjs/generic';

import FeatureFlags from 'src/api/FeatureFlags';
import ReplyChat from './ReplyChat';
import ReplyCustomerPortal from './ReplyCustomerPortal';
import ReplyEmail from './ReplyEmail';
import ReplyFacebook from './ReplyFacebook';
import ReplyGiosg from './ReplyGiosg';
import ReplyIntra from './ReplyIntra';
import ReplySecureEmail from './ReplySecureEmail';
import ReplySms from './ReplySms';
import ReplyTampuuri from './ReplyTampuuri';
import ReplyWhatsapp from './ReplyWhatsapp';
import ReplySalesforce from './ReplySalesforce';
import ReplyEezy from './ReplyEezy';
import ReplyPhone from './ReplyPhone';
import SocketInstance from 'src/realTimeNotifications';
import ReplyViestiTytti from './ReplyViestiTytti';
import ReplyInstagram from './ReplyInstagram';
import { PropsChecker } from 'src/Components/Utilities/PropsChecker';
import { hasPhoneIntegrations } from 'src/Utilities/phoneIntegrations';
import { channelNameToType, channelTypeToName } from '../CommentIconContent/ChannelType';
import { Channels } from 'src/types/Channel';
import { checkMandatoryFieldsForTicketClosing } from 'src/Utilities/restrictions';
import { stopWorkingOn } from 'src/Utilities/workStatusParser';
import type { ReplyChannelTabBarProps } from 'src/containers/DraftsContainer';
import type { ReplyTabIndex } from 'src/types/Drafts';
import type { Entity } from 'src/types/Ticket';
import type { Field } from 'src/types/Info';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import ReplyD365 from 'src/Components/ReplyMethods/ReplyD365';

interface ReplyChannelTabBarState {
  activeIndex: string;
  chatTypeStatus: string;
  panes: {
    pane?: SemanticShorthandItem<TabPaneProps>;
    render?: () => React.ReactNode;
  }[];
}

export class ReplyChannelTabBar extends React.Component<ReplyChannelTabBarProps, ReplyChannelTabBarState> {
  constructor(props: ReplyChannelTabBarProps) {
    super(props);

    this.updateStateToRedux = this.updateStateToRedux.bind(this);

    this.state = {
      activeIndex: this.getDefaultActiveIndex(props),
      chatTypeStatus: '',
      panes: this.getPanes(props)
    };
  }

  private getActiveTicketTypeId = (props: ReplyChannelTabBarProps) => {
    const activeTicketType = props.ticketTypes.find((ticketType) => ticketType.name === props.ticket?.taskType);
    if (!activeTicketType) {
      throw new Error('TicketType mismatch, contact administrator');
    }

    return activeTicketType;
  };

  private checkIfChannelAllowed = (name: Channels) => {
    return !!this.props.replyTabIndexList.find((replyTab: ReplyTabIndex) => replyTab.name === name);
  };

  protected canCloseAsDone = () => {
    const { ticket, ticketTypes, tags } = this.props;
    if (ticket?.id === 'NEW') {
      return false;
    }
    const { status } = checkMandatoryFieldsForTicketClosing(ticket, ticketTypes, tags);
    return status === 'OK';
  };

  private closeAsDone = async (id: string, UID: string | undefined) => {
    if (!this.canCloseAsDone) {
      return;
    }
    await this.props.updateTicket(id, { status: 'done' }, true);

    if (UID !== undefined) {
      await this.props.ticketStopWorkingOn(...stopWorkingOn(UID, id));
    }
    SocketInstance.leaveRoom(id);
  };

  getPanes = (props: ReplyChannelTabBarProps) => {
    const { t } = props;
    const activeTicketType = this.getActiveTicketTypeId(props);
    let sendEntityEvent: typeof this.props.sendEntityEvent | undefined;

    if (props.ticketTypes.find((ticketType) => ticketType.name === props.ticket?.taskType)?.entityEventsEnabled) {
      sendEntityEvent = this.props.sendEntityEvent;
    }

    const panes = [];

    if (this.checkIfChannelAllowed(Channels.intra)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-intra">
            <Translation ns="translations">
              {(tr) => (
                <>
                  <Icon name="sticky note" />
                  {this.props.mobileMode || tr('REPLY_METHOD_INTRA')}
                </>
              )}
            </Translation>
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyIntra
              userData={this.props.userData}
              taskId={this.props.ticket?.id}
              task={this.props.ticket}
              ticketType={activeTicketType}
              entities={this.props.ticket?.entities}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.intra || {}}
              updateState={this.updateStateToRedux}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.email)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-email">
            <Translation ns="translations">
              {(tr) => (
                <>
                  <Icon name="mail" />
                  {this.props.mobileMode || tr('REPLY_METHOD_EMAIL')}
                </>
              )}
            </Translation>
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyEmail
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              senderEmails={this.getSenderEmails(this.props.ticket.taskType, this.props.ticketTypes)}
              subject={this.props.ticket.title}
              templates={this.props.templates}
              ticketType={activeTicketType}
              taskType={activeTicketType}
              attachments={this.props.ticket.attachments}
              to={this.getByFieldType('emailField')}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.email || {}}
              updateState={this.updateStateToRedux}
              suggestions={this.props.suggestions}
              uploadFile={this.props.uploadFile}
              sendEntityEvent={sendEntityEvent}
              users={this.props.users}
              canCloseAsDone={this.canCloseAsDone()}
              closeAsDone={this.closeAsDone}
              ticketTypes={this.props.ticketTypes}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.chat)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-chat">
            <Icon name="comments outline" />
            {this.props.mobileMode || t('REPLY_METHOD_CHAT')}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyChat
              userData={this.props.userData}
              taskId={this.props.ticket?.id}
              task={this.props.ticket}
              ticketType={activeTicketType}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              switchChatAnchor={this.props.switchChatAnchor}
              drafts={this.props.drafts.chat || {}}
              updateState={this.updateStateToRedux}
              entities={this.props.ticket?.entities}
              updateTypingStatusToCustomer={this.props.updateChatTypingStatusToCustomer}
              canCloseAsDone={this.canCloseAsDone()}
              closeAsDone={this.closeAsDone}
              ticketTypes={this.props.ticketTypes}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.sms)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-sms">
            <Icon name="mobile alternate" />
            {this.props.mobileMode || t('REPLY_METHOD_SMS')}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplySms
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              phoneNumbers={this.getByFieldType('phoneField')}
              ticketType={activeTicketType}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.sms || {}}
              updateState={this.updateStateToRedux}
              suggestions={this.props.suggestions}
            />
          );
        }
      });
    }

    if (FeatureFlags.isFlagOn('SECURE_MAIL_ENABLED') === true && this.checkIfChannelAllowed(Channels.secureMail)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-secureMail">
            <Translation ns="translations">
              {(tr) => (
                <>
                  <Icon name="lock" />
                  {this.props.mobileMode || tr('REPLY_METHOD_SECURE_MAIL')}
                </>
              )}
            </Translation>
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplySecureEmail
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              senderEmails={this.getSenderEmails(this.props.ticket.taskType, this.props.ticketTypes)}
              subject={this.props.ticket.title}
              templates={this.props.templates}
              ticketType={activeTicketType}
              attachments={this.props.ticket.attachments}
              to={this.getByFieldType('emailField')}
              onSubmit={this.onSubmit}
              /**
               * Desc: Secure email and email should share the state
               */
              drafts={this.props.drafts.email || {}}
              updateState={this.updateStateToRedux}
              suggestions={this.props.suggestions}
              uploadFile={this.props.uploadFile}
              sendEntityEvent={sendEntityEvent}
              users={this.props.users}
              canCloseAsDone={this.canCloseAsDone()}
              closeAsDone={this.closeAsDone}
              taskType={activeTicketType}
              ticketTypes={this.props.ticketTypes}
            />
          );
        }
      });
    }

    if (FeatureFlags.isFlagOn('FACEBOOK_CHAT_ENABLED') === true && this.checkIfChannelAllowed(Channels.facebook)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-facebook">
            <Icon name="facebook messenger" />
            {this.props.mobileMode || t('REPLY_METHOD_FACEBOOK')}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyFacebook
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              ticketType={activeTicketType}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.facebook || {}}
              updateState={this.updateStateToRedux}
              entities={this.props.ticket.entities}
            />
          );
        }
      });
    }

    if (FeatureFlags.isFlagOn('GIOSG_CHAT_ENABLED') === true && this.checkIfChannelAllowed(Channels.giosg)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-giosg">
            <Icon name="comments outline" />
            {this.props.mobileMode || 'Giosg'}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyGiosg
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              ticketType={activeTicketType}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.giosg || {}}
              updateState={this.updateStateToRedux}
              switchChatAnchor={this.props.switchChatAnchor}
              updateGiosgWritingStatus={this.props.updateGiosgWritingStatus}
              chatTypeStatus={this.state.chatTypeStatus}
              users={this.props.users}
              canCloseAsDone={this.canCloseAsDone()}
              closeAsDone={this.closeAsDone}
              ticketTypes={this.props.ticketTypes}
            />
          );
        }
      });
    }

    if (
      /// order of the panes is reliant on the order of their render additions here while the tabs index is formed elsewhere
      // in a similar but not same kind of code. This needs to be fixed. Had an issue of tab being resolved to tab index 7
      // ege customer portal but the active index opened a tampuuri tab because in reality the order had tampuuri being the
      // said index of tab. Switching the tabs additions here to match the other code fixed this
      FeatureFlags.isFlagOn('CUSTOMER_PORTAL_ENABLED') === true &&
      this.checkIfChannelAllowed(Channels.customerPortal)
    ) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-customerPortal">
            <Translation ns="translations">
              {(tr) => (
                <>
                  <Icon name="comments outline" />
                  {this.props.mobileMode || tr('REPLY_METHOD_CUSTOMER_PORTAL')}
                </>
              )}
            </Translation>
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyCustomerPortal
              addTag={this.addTag}
              userData={this.props.userData}
              activeTicketType={activeTicketType}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              subject={this.props.ticket.title}
              templates={this.props.templates}
              attachments={this.props.ticket.attachments}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.customerPortal || {}}
              updateState={this.updateStateToRedux}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.tampuuri)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-tampuuri">
            <Icon name="comments outline" />
            {/* {this.props.mobileMode || "Tampuuri"} */}
            Tampuuri
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyTampuuri
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              subject={this.props.ticket.title}
              templates={this.props.templates}
              ticketType={activeTicketType}
              taskType={activeTicketType}
              attachments={this.props.ticket.attachments}
              to={this.getByFieldType('tampuuriField')}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.tampuuri || {}}
              updateState={this.updateStateToRedux}
              suggestions={this.props.suggestions}
              uploadFile={this.props.uploadFile}
              sendEntityEvent={this.props.sendEntityEvent}
              users={this.props.users}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.whatsapp)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-whatsapp">
            <Icon name="whatsapp" />
            {this.props.mobileMode || 'WhatsApp'}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyWhatsapp
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              ticketType={activeTicketType}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.whatsapp || {}}
              updateState={this.updateStateToRedux}
              switchChatAnchor={this.props.switchChatAnchor}
              chatTypeStatus={this.state.chatTypeStatus}
              users={this.props.users}
              entities={this.props.ticket.entities}
              uploadFile={this.props.uploadFile}
              attachments={this.props.ticket.attachments}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.salesforce)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-salesforce">
            <Icon name="cloud" />
            {this.props.mobileMode || 'Salesforce'}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplySalesforce
              userData={this.props.userData}
              task={this.props.ticket}
              taskId={this.props.ticket.id}
              drafts={this.props.drafts.salesforce || {}}
              updateState={this.updateStateToRedux}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.d365)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-d365">
            <Icon name="microsoft" />
            {this.props.mobileMode || 'D365'}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyD365
              userData={this.props.userData}
              task={this.props.ticket}
              taskId={this.props.ticket.id}
              drafts={this.props.drafts.d365 || {}}
              updateState={this.updateStateToRedux}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.phone)) {
      const { hasElisaOCIntegration, hasElisaRingIntegration, hasMitelIntegration } = hasPhoneIntegrations();

      if (hasElisaOCIntegration || hasElisaRingIntegration || hasMitelIntegration) {
        panes.push({
          menuItem: (
            <Menu.Item key="reply-phone">
              <Icon name="phone" />
              {this.props.mobileMode || 'Phone'}
            </Menu.Item>
          ),
          render: () => (
            <ReplyPhone
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              entities={this.props.ticket.entities}
              phoneNumbers={this.getByFieldType('phoneField')}
              ticketType={activeTicketType}
              templates={this.props.templates}
              onSubmit={this.onSubmit}
              drafts={this.props.drafts.phone || {}}
              updateState={this.updateStateToRedux}
              suggestions={this.props.suggestions}
              ticketTypes={this.props.ticketTypes}
              addRingCallRequest={this.props.addRingCallRequest}
              addOCCallRequest={this.props.addOCCallRequest}
              addMitelCallRequest={this.props.addMitelCallRequest}
            />
          )
        });
      }
    }

    if (this.checkIfChannelAllowed(Channels.eezy)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-eezy">
            <Icon name="cloud" />
            {this.props.mobileMode || 'Eezy'}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyEezy
              drafts={this.props.drafts.eezy || {}}
              userData={this.props.userData}
              task={this.props.ticket}
              taskId={this.props.ticket.id}
              updateState={this.updateStateToRedux}
              entities={this.props.ticket.entities}
              templates={this.props.templates}
              ticketType={activeTicketType}
              users={this.props.users}
              attachments={this.props.ticket.attachments}
              uploadFile={this.props.uploadFile}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.viestitytti)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-viestitytti">
            <Icon name="cloud" />
            {this.props.mobileMode || 'Viesti-Tytti'}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyViestiTytti
              drafts={this.props.drafts.viestitytti || {}}
              userData={this.props.userData}
              task={this.props.ticket}
              taskId={this.props.ticket.id}
              updateState={this.updateStateToRedux}
              entities={this.props.ticket.entities}
              templates={this.props.templates}
              ticketType={activeTicketType}
              users={this.props.users}
            />
          );
        }
      });
    }

    if (this.checkIfChannelAllowed(Channels.instagram)) {
      panes.push({
        menuItem: (
          <Menu.Item key="reply-instagram">
            <Icon name="instagram" />
            {this.props.mobileMode || t('REPLY_METHOD_INSTAGRAM')}
          </Menu.Item>
        ),
        render: () => {
          return (
            <ReplyInstagram
              userData={this.props.userData}
              taskId={this.props.ticket.id}
              task={this.props.ticket}
              ticketType={activeTicketType}
              templates={this.props.templates}
              drafts={this.props.drafts.instagram || {}}
              updateState={this.updateStateToRedux}
              entities={this.props.ticket.entities}
              attachments={this.props.ticket.attachments}
              uploadFile={this.props.uploadFile}
            />
          );
        }
      });
    }

    return panes;
  };

  private isReadOnlyUser = (): boolean => {
    return this.props.user.role.name.includes('readOnly');
  };

  private updateStateToRedux(taskId: string, channel: string, state: any) {
    this.props.updateTicketTabState(taskId, { [channel]: state });
  }

  componentWillReceiveProps(nextProps: ReplyChannelTabBarProps) {
    if (
      this.props.ticket.id !== nextProps.ticket.id ||
      this.props.drafts.tabIndex !== nextProps.drafts.tabIndex ||
      this.props.ticket.channel !== nextProps.ticket.channel
    ) {
      const defaultActiveIndex = this.getDefaultActiveIndex(nextProps);
      this.setState({
        activeIndex: defaultActiveIndex
      });
    }
    if (this.props.ticket.chatTypeStatus) {
      this.setState({ chatTypeStatus: this.props.ticket.chatTypeStatus });
    }

    this.setState({
      panes: this.getPanes(nextProps)
    });
  }

  private getByFieldType = (fieldType: string): string => {
    const entities = this.props.ticket.entities;
    if (this.props.ticket?.entities == null) {
      return '';
    }

    const usedTicketType = this.props.ticketTypes.find((ticketType) => ticketType.name === this.props.ticket.taskType);

    const contactEntities = entities.filter((entity: Entity) =>
      usedTicketType?.entityRouting?.find(
        (eRoute) => eRoute.useAsOutboundContact === true && entity._type === eRoute.entityType
      )
    );
    let entityInfo: Field | Field[] | undefined;

    const correctEntityInfos = usedTicketType?.fieldSets.filter((fieldSet) => {
      if (fieldSet.entityTypes) {
        return (
          fieldSet.id === 'customerInfo' &&
          fieldSet.entityTypes?.find((eType) =>
            usedTicketType?.entityRouting?.find(
              (eRoute) => eRoute.useAsOutboundContact === true && eType === eRoute.entityType
            )
          )
        );
      } else {
        return fieldSet.id === 'customerInfo';
      }
    });

    if (!correctEntityInfos?.length) {
      return '';
    }

    let results: string[] = [];

    correctEntityInfos.forEach((correctEntityInfo): void | string => {
      entityInfo = correctEntityInfo[correctEntityInfo.id];

      if (!entityInfo || !contactEntities || contactEntities?.some((ce) => ce.isLoading)) {
        return '';
      }

      const field = (entityInfo as Field[]).find((field) => field.displayField === fieldType);

      if (field !== undefined) {
        const entityMapper = (entity: Entity) =>
          typeof field.object === 'string'
            ? // return value which is part of an object in the entity
              entity?.data[field.object]?.[field.value as string]
            : // return value which is a property of the entity
              entity?.data?.[field.value as string];

        results = contactEntities
          .map(entityMapper)
          // check that field has value
          .filter(Boolean);
      }
    });

    return results.join(',');
  };

  private getDefaultActiveIndex = (props: ReplyChannelTabBarProps) => {
    let defaultActiveIndex;
    if (props.drafts.tabIndex !== undefined) {
      defaultActiveIndex = channelTypeToName(props.drafts.tabIndex);
    } else {
      defaultActiveIndex = channelTypeToName(props.ticket.channel);
    }
    return defaultActiveIndex;
  };

  private translateActiveIndexNameToIndex(indexName: string | undefined) {
    const indexMatch = this.props.replyTabIndexList.find((index) => {
      return index.name === indexName;
    });
    return indexMatch?.index;
  }

  private translateTabToDraftIndex(index: number) {
    const indexMatch = this.props.replyTabIndexList.find((tabIndex) => {
      return tabIndex.index === index;
    });

    if (!indexMatch) {
      throw new Error(`No reply tab found with index ${index}`);
    }

    const name = indexMatch.name;
    return channelNameToType(name);
  }

  private handleTabChange = (id: number) => {
    this.props.switchDraftTab(this.props.ticket.id, id);
  };

  private getSenderEmails = (taskType: string, ticketTypes: TicketType[]) => {
    const senderEmails: SenderEmail[] = [];

    ticketTypes.forEach((ticketType) => {
      const senderEmail = cloneDeep(ticketType);

      if (senderEmail.name === taskType) {
        senderEmail.senderemail.default = true;
      }

      if (ticketType.additionalSenderEmails !== undefined && Array.isArray(ticketType.additionalSenderEmails)) {
        // merge multiples into senderEmails
        senderEmails.push(...ticketType.additionalSenderEmails, senderEmail.senderemail);
      } else {
        // default behavior
        senderEmails.push(senderEmail.senderemail);
      }
    });

    return senderEmails;
  };

  private onSubmit = (body: { [key: string]: any }) => {
    return this.props.onSubmit(this.props.ticket, body);
  };

  private addTag = (tagId: string) => {
    return this.props.addTag(this.props.ticket.id, tagId, false);
  };

  render() {
    return (
      <Tab
        panes={this.isReadOnlyUser() ? [] : this.state.panes}
        activeIndex={this.translateActiveIndexNameToIndex(this.state.activeIndex)}
        onTabChange={(e, d) => {
          const id = this.translateTabToDraftIndex(d.activeIndex as number);
          this.handleTabChange(id);
        }}
      />
    );
  }
}

export default PropsChecker(withTranslation('translations')(ReplyChannelTabBar));
