import classNames from 'classnames';
import type { VFC } from 'react';
import React from 'react';
import { Header, Input, Table } from 'semantic-ui-react';
import type { InfoFieldProps } from './InfoFieldProps';

interface ComplexListProps extends Pick<InfoFieldProps, 'field'> {
  fieldValue: { name: string; value: string }[];
  disabled: boolean;
  mongoSaveDisabled: boolean;
  integrationSaveDisabled: boolean;
}

const ComplexList: VFC<ComplexListProps> = ({
  field,
  fieldValue,
  disabled,
  mongoSaveDisabled,
  integrationSaveDisabled
}) => {
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  return (
    <>
      {fieldValue.map((fieldSubVal, index) => {
        const cellTitle = field.childPrefix ? field.childPrefix + fieldSubVal.name : fieldSubVal.name;

        return (
          <Table.Row key={`info-complex-list-row-${index}`}>
            <Table.Cell width={6}>
              <Header as="h5">{cellTitle}</Header>
            </Table.Cell>
            <Table.Cell width={10}>
              <Input
                disabled={disabled}
                className={className}
                size="mini"
                value={fieldSubVal.value || ''}
                fluid={true}
              />
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
};

export default React.memo(ComplexList);
