import _ from 'lodash';
import React from 'react';
import { t } from 'i18next';
import iziToast from 'izitoast';
import { Form, Dropdown, DropdownItemProps } from 'semantic-ui-react';

import ViestiTyttiApi from 'src/api/ViestiTyttiApi';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyTextArea from './ReplyTextArea';
import ReplyTemplates from './ReplyTemplates';
import { ReplyMethod } from './ReplyMethod';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import { Channels } from 'src/types/Channel';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { ReplyMethodProps } from './ReplyMethod';
import type { PersonalData, User } from 'src/types/User';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { TicketType } from 'src/types/TicketType';
import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';

interface ViestiTyttiFormPayload {
  title: string;
  content: string;
  sourceId: string;
  commentFieldIds: [number, number];
  threadStateId: number;
}

interface ReplyViestiTyttiProps extends ReplyMethodProps<ReplyViestiTyttiState> {
  drafts: ReplyViestiTyttiState;
  userData: PersonalData;
  task: Ticket;
  ticketType: TicketType;
  templates: ResponseTemplate[];
  entities: Entity[];
  users: User[];
}

export interface ReplyViestiTyttiState {
  isSubmitting: boolean;
  payload: ViestiTyttiFormPayload;
  selectedReplyTemplate: string | undefined;
}

class ReplyViestiTytti extends ReplyMethod<ReplyViestiTyttiProps, ReplyViestiTyttiState> {
  private commentFieldOptions: DropdownItemProps[] = [
    {
      text: 'Näkyy hakijalle, lähetä tiedote',
      value: '10,30'
    },
    {
      text: 'Sisäinen',
      value: '10,29'
    },
    {
      text: 'Näkyy hakijalle, ei tiedotetta',
      value: '10,31'
    }
  ];

  private threadStateOptions: DropdownItemProps[] = [
    {
      text: 'Uusi',
      value: 26
    },
    {
      text: 'Käsittelyssä',
      value: 27
    },
    {
      text: 'Uutta tietoa',
      value: 28
    },
    {
      text: 'Odottaa vastausta',
      value: 29
    },
    {
      text: 'Käsitelty',
      value: 30
    },
    {
      text: 'Suljettu',
      value: 31
    }
  ];

  constructor(props: ReplyViestiTyttiProps) {
    super(props);

    this.state = this.getInitialState(this.props.drafts);
  }

  getDraftChannel(): Channels {
    return Channels.viestitytti;
  }

  getDraftState(): Partial<ReplyViestiTyttiState> {
    return {
      payload: this.state.payload
    };
  }

  private getInitialState = (drafts: ReplyViestiTyttiState): ReplyViestiTyttiState => ({
    isSubmitting: false,
    selectedReplyTemplate: drafts.selectedReplyTemplate,
    payload: {
      ...{
        title: this.props.task.title,
        content: '',
        sourceId: '',
        commentFieldIds: (this.commentFieldOptions[0].value as string)
          .split(',')
          .map((element: string) => parseInt(element, 10)) as [number, number]
      },
      ...drafts.payload,
      threadStateId:
        (this.threadStateOptions.find((status) => status.text === this.props.task.case.threadStatus)
          ?.value as number) || 0
    }
  });

  private handleSetState = (fields: Partial<ReplyViestiTyttiState['payload']>) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          ...fields
        }
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  public clearFields = () =>
    this.setState(
      {
        payload: {
          title: this.props.task.title,
          content: '',
          sourceId: '',
          commentFieldIds: (this.commentFieldOptions[0].value as string)
            .split(',')
            .map((element: string) => parseInt(element, 10)) as [number, number],
          threadStateId:
            (this.threadStateOptions.find((status) => status.text === this.props.task.case.threadStatus)
              ?.value as number) || 0
        },
        selectedReplyTemplate: undefined
      },
      () => {
        this.saveDraft(this.state);
      }
    );

  public onSubmit = async () => {
    this.setState({
      isSubmitting: true
    });

    try {
      const commentWithRelativeForeignId = this.props.task.comments.find(
        (comment) => comment.foreignIdType === 'viestiTyttiIntegration' && comment.foreignId
      );
      const isNewMessageThread = !commentWithRelativeForeignId;

      await ViestiTyttiApi.sendReply({
        isNewMessageThread,
        content: this.state.payload.content,
        sourceId: isNewMessageThread ? this.state.payload.sourceId : this.props.task.originalContact!,
        ticketId: this.props.taskId,
        title: isNewMessageThread ? this.state.payload.title : this.props.task.title,
        commentFieldIds: this.state.payload.commentFieldIds,
        threadStateId: this.state.payload.threadStateId,
        ...(commentWithRelativeForeignId && {
          issueId: String(commentWithRelativeForeignId.foreignId)
        })
      });

      if (isNewMessageThread) {
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: 'New thread was created',
          timeout: 5000
        });
      }

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: 'Comment was created',
        timeout: 5000
      });
    } catch (error) {
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        timeout: 7500
      });
    }

    this.clearFields();
    this.setState({
      isSubmitting: false
    });
  };

  public isThreadAlreadyExist = () =>
    this.props.task.comments.find((comment) => comment.foreignIdType === 'viestiTyttiIntegration');

  render() {
    const isDisabled = this.isThreadAlreadyExist()
      ? this.state.isSubmitting || !this.state.payload.content.length
      : this.state.isSubmitting ||
      !this.state.payload.title.length ||
      !this.state.payload.content.length ||
      !this.state.payload.threadStateId ||
      !this.state.payload.sourceId.length;

    return (
      <Form reply={true} style={{ marginTop: '20px' }}>
        <Form.Group>
          {!this.isThreadAlreadyExist() && (
            <Form.Field width={10}>
              <label>{t('ADD_COMMENT_TITLE')}</label>

              <Form.Input
                onChange={(event, data) => this.handleSetState({ title: data.value })}
                type="text"
                fluid={true}
                value={this.state.payload.title}
              >
                <input />
              </Form.Input>

              {this.state.payload.title.length >= 900 && (
                <p style={{ color: 'red' }}>{t('general_reply.max_length_limit')} (900)</p>
              )}
            </Form.Field>
          )}

          <Form.Field width={this.isThreadAlreadyExist() ? 'sixteen' : 'six'}>
            <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>

            <ReplyTemplates
              userData={this.props.userData}
              ticketType={this.props.ticketType}
              templates={this.props.templates}
              task={this.props.task}
              channel={ChannelType.ViestiTytti}
              entities={this.props.entities}
              users={this.props.users}
              selectedOption={this.state.selectedReplyTemplate}
              setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
              setContent={(value) => this.handleSetState(value)}
              content={
                this.state.payload.title && this.state.payload.title.length > 900
                  ? this.state.payload.title.slice(900, -1) + ' \n\n ' + this.state.payload.content
                  : this.state.payload.content
              }
              discardHtml={true}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <label>{t('ADD_COMMENT_CONTENT')}</label>
          <ReplyTextArea
            content={this.state.payload.content}
            onChange={(value) => this.handleSetState({ content: value })}
            onKeyDown={(event: KeyDownEvent) => {
              if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                !isDisabled && this.onSubmit();
              }
            }}
          />
        </Form.Field>

        {!this.isThreadAlreadyExist() && (
          <Form.Field>
            <label>{t('viestitytti_reply.labels.customer_id')}</label>

            <Form.Input
              onChange={(event, data) => this.handleSetState({ sourceId: data.value })}
              type="text"
              fluid={true}
              value={this.state.payload.sourceId}
            >
              <input />
            </Form.Input>
          </Form.Field>
        )}

        <Form.Field>
          <label>{t('viestitytti_reply.labels.customer_field_type')}</label>

          <Dropdown
            fluid
            search
            selection
            options={this.commentFieldOptions}
            value={this.state.payload.commentFieldIds.join(',')}
            onChange={(event, data) => {
              this.handleSetState({
                commentFieldIds: (data.value as string).split(',').map((element: string) => parseInt(element, 10)) as [
                  number,
                  number
                ]
              });
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('viestitytti_reply.labels.thread_state')}</label>

          <Dropdown
            fluid
            search
            selection
            clearable
            options={this.threadStateOptions}
            value={this.state.payload.threadStateId}
            onChange={(event, data) => {
              this.handleSetState({ threadStateId: data.value as number });
            }}
          />
        </Form.Field>

        <ReplyControlButtons
          internal={true}
          disabled={isDisabled}
          loading={this.state.isSubmitting}
          onClear={this.clearFields}
          onSubmit={this.onSubmit}
        />
      </Form>
    );
  }
}

export default ReplyViestiTytti;
