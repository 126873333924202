import React from 'react';
import { cloneDeep, orderBy } from 'lodash';
import iziToast from 'izitoast';
import { Button, Dimmer, Dropdown, Grid, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { t, t as tr } from 'i18next';
import type { IziToast } from 'izitoast';

import AdvancedActions from '../TopBar/AdvancedActions';
import CloseAndReturnAsDoing from 'src/containers/CloseAndReturnAsDoingContainer';
import CopyToClipboardIcon from '../../generic/CopyToClipboardIcon';
import FeatureFlags from 'src/api/FeatureFlags';
import TicketsApi from 'src/api/TicketsApi';
import TitleTemplates from '../TitleTemplates';
import TopBarLabelList from '../TopBar/TopBarLabelList';
import WorkStatusHandlerContainer from 'src/containers/WorkStatusHandlerContainer';
import WorkStatusImageContainer from 'src/containers/WorkStatusImageContainer';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { StaticTabs } from 'src/types/TicketList';
import { checkMandatoryFieldsForTicketClosing } from 'src/Utilities/restrictions';
import { replaceWorkingOn, startWorkingOn, stopWorkingOn } from 'src/Utilities/workStatusParser';
import { typeToPrefix } from 'src/types/ContentTypes';
import type { AjaxCallStatus } from 'src/reducers/ajaxStatusReducer';
import type { StatusTypes, Ticket } from 'src/types/Ticket';
import type { TopBarHOCProps } from 'src/containers/TopBarContainer';
import type { User } from 'src/types/User';

import './TobBar.css';
import { getContentStatusOptions } from '../contentStatuses';

export interface TopBarProps extends TopBarHOCProps {
  simple?: boolean;
}

interface TopBarState {
  isEditingTitle: boolean;
  taskTitle: string;
  isMandatoryFieldRequirementModalOpen: boolean;
  unfilledMandatoryFields: string[];
}

export default class TopBar extends React.Component<TopBarProps, TopBarState> {
  user?: User;
  authorName: string;
  ticketTypeNames: { text: string; value: string; key: string }[];

  constructor(props: TopBarProps) {
    super(props);

    this.state = {
      isEditingTitle: false,
      taskTitle: this.props.task ? this.props.task.title : '',
      isMandatoryFieldRequirementModalOpen: false,
      unfilledMandatoryFields: []
    };

    this.setTicketTypeNames(props);
    this.defineUserElements(props);
  }

  onStartWorking = (UID: string) => {
    TicketsApi.startWorkingOn(...startWorkingOn(UID, this.props.task.id));
    this.handleStatusChange('doing');
  };

  onStopWorking = (UID: string) => TicketsApi.stopWorkingOn(...stopWorkingOn(UID, this.props.task.id));

  onReplaceWorking = (oldUID: string, newUID: string) => {
    oldUID = 'USR' + oldUID;
    TicketsApi.replaceWorkingOn(...replaceWorkingOn(this.props.task.id, oldUID, newUID));
  };

  getMandatoryFieldRequirementModal = () => {
    return (
      <Modal
        open={this.state.isMandatoryFieldRequirementModalOpen}
        onClose={() => this.setState({ isMandatoryFieldRequirementModalOpen: false })}
      >
        <Modal.Header>{t('MANDATORY_FIELD_REQUIREMENT_TITLE')}</Modal.Header>
        <Modal.Content>
          {t('MANDATORY_FIELD_REQUIREMENT_CONTENT')}
          <ul>
            {this.state.unfilledMandatoryFields.map((field: string) => {
              return <li>{t(field)}</li>;
            })}
          </ul>{' '}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            icon
            onClick={() => this.setState({ isMandatoryFieldRequirementModalOpen: false })}
            labelPosition="left"
          >
            <Icon name="check" />
            {tr('GENERAL_CLOSE')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  setTicketTypeNames = (props: TopBarProps) => {
    let metadata = props.ticketTypesMetadata;

    if (typeof metadata !== 'undefined') {
      // TODO MONGODB Remove type.canAdd === undefined when we stop using mongo
      metadata = metadata.filter(
        (type) => type.canAdd === undefined || type.canAdd || type.name === props.task.taskType
      );

      this.ticketTypeNames = metadata
        .filter((type) => type.allowed)
        .map((type, index) => ({
          text: type.name,
          value: type.name,
          key: `${type.name}-${index}`,
          // TODO MONGODB Remove type.canAdd !== undefined when we stop using mongo
          disabled: type.canAdd !== undefined && !type.canAdd
        }));

      if (FeatureFlags.isFlagOn('ALLOW_CHANGING_TO_FORBIDDEN_TICKET_TYPES')) {
        const ticketTypeNamesForbidden = metadata
          .filter((type) => !type.allowed)
          .map((type, index) => {
            return {
              text: type.name,
              value: type.name,
              key: `${type.name}-${index}`,
              icon: 'angle right',
              className: 'ticketTypeDropdownForbiddenTicketTypeName',
              // TODO MONGODB Remove type.canAdd !== undefined when we stop using mongo
              disabled: type.canAdd !== undefined && !type.canAdd
            };
          });

        this.ticketTypeNames = this.ticketTypeNames.concat(ticketTypeNamesForbidden);
      }
    }
  };

  // TODO: refactor to simply return userProfileLabel/userProfileContent Element without storing it under class property
  defineUserElements = (props: TopBarProps) => {
    const { users, task } = this.props;
    if (!users?.length || !task) {
      return;
    }

    this.user = props.users.find((usr: User) => usr.UID === props.task.createdByUser.trim());
  };

  componentWillReceiveProps(nextProps: TopBarProps) {
    if (!nextProps.task) {
      return;
    }
    if (nextProps.task && this.props.task && nextProps.task.id !== this.props.task.id) {
      this.setState({ isEditingTitle: false });
    }
    this.user = nextProps.users.find((usr: User) => usr.UID === nextProps.task.createdByUser.trim());

    this.authorName = `${((this.user || ({} as any)).profile || {}).firstName} ${
      ((this.user || ({} as any)).profile || {}).lastName
    } `;
    this.setTicketTypeNames(nextProps);

    this.defineUserElements(nextProps);
    if (!this.state.isEditingTitle) {
      this.setState({ taskTitle: nextProps.task.title });
    }
  }

  handleStatusChange(newStatus: StatusTypes) {
    if (this.props.task.id !== 'NEW') {
      if (newStatus === 'done') {
        const task = cloneDeep(this.props.task);
        task.status = newStatus;

        if (this.checkCloseAsDoneRequirements(this.props.task, this.props.user.UID, true)) {
          return this.props.updateTicket(task.id, { status: newStatus });
        }
        return;
      }

      return this.props.updateTicket(this.props.task.id, { status: newStatus });
    }
  }

  handleTypeChange = (typeToChangeTo: string) => {
    if (this.props.task) {
      if (
        this.props.ticketTypesMetadata.find((type) => {
          return type.name === typeToChangeTo && type.allowed === true;
        })
      ) {
        this.props.updateTicket(this.props.task.id, {
          taskType: typeToChangeTo
        });
      } else {
        iziToast.question({
          timeout: 0,
          close: false,
          overlay: true,
          id: 'question',
          zindex: 999,
          message: tr('TICKET_TYPE_IS_FORBIDDEN_AND_WILL_DISABLE_THE_TICKET'),
          position: 'center',
          buttons: [
            [
              `<button><b>${tr('GENERAL_ACCEPT')}</b></button>`,
              (instance: IziToast, toast: HTMLDivElement) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                let activeId: string = StaticTabs.MAIN_VIEW;
                const tabs = this.props.tabs.filter((tab) => {
                  return tab.id !== this.props.task.id && tab.id.substring(0, 3) === typeToPrefix('task');
                });

                if (tabs.length > 0) {
                  activeId = tabs[tabs.length - 1].id;
                  this.props.history.push(`/case/${activeId}`);
                }

                this.props.closeAndUpdateTicketType({
                  UID: this.props.user.UID,
                  ticketId: this.props.task.id,
                  taskType: typeToChangeTo,
                  activeId
                });
              },
              false
            ],
            [
              `<button>${tr('GENERAL_CANCEL')}</button>`,
              (instance: IziToast, toast: HTMLDivElement) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
              },
              false
            ]
          ]
        });
      }
    }
  };

  dueDateChange = (newDueDate: number) => {
    if (!this.props.task) {
      return;
    }
    if (typeof this.props.task.id !== 'undefined' && this.props.task.id !== 'NEW') {
      this.props.updateTicket(this.props.task.id, { dueDate: newDueDate });
    }
  };

  handleBlur = () => {
    if (this.props.task) {
      this.props.updateTicket(this.props.task.id, {
        title: this.state.taskTitle
      });
    }
  };

  copyTicketLink = () => {
    navigator.clipboard.writeText(window.location.origin + '/case/' + this.props.task.id);
    iziToast.info({
      message: tr('TICKET_LINK_COPIED')
    });
  };

  checkCloseAsDoneRequirements = (task: Ticket, UID: string, returnBoolean = false) => {
    const { ticketTypes, tags, closeAsDone } = this.props;
    const { fields, status } = checkMandatoryFieldsForTicketClosing(task, ticketTypes, tags);
    if (!returnBoolean && status === 'OK') {
      return closeAsDone(task.id, UID);
    } else if (returnBoolean && status === 'OK') {
      return true;
    } else {
      return this.setState({ unfilledMandatoryFields: fields, isMandatoryFieldRequirementModalOpen: true });
    }
  };

  render() {
    const { task, user } = this.props;
    if (!task || !user) {
      return null;
    }

    const ajaxCallInProgress = this.props.ajaxCalls.find((ajaxCall: AjaxCallStatus) => {
      return ajaxCall.name === 'UPDATE_TICKET' && ajaxCall.id === this.props.task.id;
    });

    const statusOptions = getContentStatusOptions("tickets", t);

    if (this.props.simple) {
      return (
        <div>
          {this.state.isEditingTitle && (
            <Input
              fluid={true}
              type="text"
              value={this.state.taskTitle}
              onBlur={() => {
                this.handleBlur();
                this.setState({ isEditingTitle: false });
              }}
            />
          )}
          {!this.state.isEditingTitle && (
            <Header
              onClick={() => {
                this.setState({ isEditingTitle: true });
              }}
              as="h22"
            >
              {task.title}
            </Header>
          )}
          Tekijä: {this.authorName} <span style={{ minWidth: '100' }} /> Määräaika:{' '}
          {getPrettyDate(task.dueDate, { format: DATE_TIME_FORMAT })}
          <p />
          Tila:
          <Dropdown
            disabled={this.props.user.role.id === 'ROL8'}
            style={{ marginLeft: 10, minWidth: '200px' }}
            selectOnBlur={false}
            defaultValue={task.status}
            selection={true}
            search={true}
            onChange={(event, data) => {
              this.handleStatusChange(data.value!.toString() as StatusTypes);
            }}
            options={statusOptions}
          />
        </div>
      );
    }
    return (
      <Translation ns="translations">
        {(tr) => (
          <Grid
            stackable={true}
            style={
              this.props.mobileMode || /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                ? { display: 'block' }
                : {}
            }
          >
            {this.getMandatoryFieldRequirementModal()}
            <Grid.Row columns={2}>
              <Grid.Column className="statusLabelColumn" width={10}>
                <Grid.Row>
                  <Grid.Column className="longTitle" style={{ minWidth: '300px', minHeight: '40px' }}>
                    {this.state.isEditingTitle && (
                      <Input
                        fluid={true}
                        type="text"
                        value={this.state.taskTitle}
                        onChange={(event) => {
                          this.setState({ taskTitle: event.target.value });
                        }}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                          this.setState(
                            {
                              taskTitle: event.target.value,
                              isEditingTitle: false
                            },
                            () => this.handleBlur()
                          );
                        }}
                      />
                    )}
                    {!this.state.isEditingTitle && (
                      <div className="titleContainer">
                        <Header
                          onClick={() => {
                            this.setState({ isEditingTitle: true });
                          }}
                          as="h2"
                        >
                          {this.state.taskTitle}
                        </Header>
                        <CopyToClipboardIcon text={this.state.taskTitle} />
                        <TitleTemplates
                          updateTitle={(value: string) => {
                            this.setState({ taskTitle: value, isEditingTitle: false }, () => this.handleBlur());
                          }}
                          title={this.state.taskTitle}
                          ticketType={this.props.task.taskType}
                          templates={this.props.titleTemplates}
                        />
                      </div>
                    )}
                  </Grid.Column>
                </Grid.Row>

                <TopBarLabelList
                  channels={this.props.channels}
                  dueDateChange={this.dueDateChange}
                  task={task}
                  userData={this.props.userData}
                  updateTicket={this.props.updateTicket}
                />

                <Grid.Row>
                  <Grid.Column>
                    <AdvancedActions />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>

              <Grid.Column className="statusButtonsDropdownsColumn" width={6}>
                <Dimmer.Dimmable>
                  <Dimmer
                    active={this.props.dimmer}
                    inverted={true}
                    style={{
                      marginLeft: '-15px',
                      marginTop: '11px',
                      height: '88%',
                      width: '800px'
                    }}
                  >
                    <div className="dimmerText">-</div>
                  </Dimmer>

                  <div className="topBarWorkStatusContainer">
                    <WorkStatusImageContainer
                      user={this.props.user}
                      id={this.props.task.id}
                      showStartWorkingOnButton={false}
                      userData={this.props.userData}
                    />
                    <Grid stackable className="topBarWorkStatusHandlers">
                      <Grid.Row
                        columns={2}
                        style={{
                          paddingTop: '13px',
                          paddingBottom: '0px'
                        }}
                      >
                        <Grid.Column
                          className="topBarButtonColumn"
                          style={{
                            paddingRight: '8px',
                            paddingLeft: '0px'
                          }}
                        >
                          <WorkStatusHandlerContainer
                            user={this.props.user}
                            caseId={task.id}
                            onStartWorking={this.onStartWorking}
                            onStopWorking={this.onStopWorking}
                            onReplaceWorking={this.onReplaceWorking}
                            userData={this.props.userData}
                          />
                        </Grid.Column>
                        <Grid.Column
                          className="topBarButtonColumn"
                          style={{
                            paddingRight: '8px',
                            paddingLeft: '0px'
                          }}
                        >
                          <Button
                            style={{ width: '100%' }}
                            positive={true}
                            id="closeTicketBtn"
                            className="topBarStopTicketWorkingBtn"
                            labelPosition="left"
                            disabled={
                              ((this.user === undefined || task.status === 'done') &&
                                this.props.workStatus === undefined) ||
                              !this.props.userData.permissions.includes('updateContent')
                            }
                            icon={true}
                            onClick={() => this.checkCloseAsDoneRequirements(this.props.task, this.props.user.UID)}
                          >
                            <Icon name="check" />
                            {tr('CLOSE_AS_DONE')}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns={2} style={{ paddingTop: '6px', paddingBottom: '6px' }}>
                        {FeatureFlags.isFlagOn('ENABLE_CHANGE_STATUS_TO_DOING') && (
                          <Grid.Column
                            className="topBarButtonColumn"
                            style={{
                              paddingRight: '8px',
                              paddingLeft: '0px'
                            }}
                          >
                            <CloseAndReturnAsDoing
                              closeAndReturnAsDoing={() =>
                                this.props.closeAndReturnAsDoing(this.props.task.id, this.props.user.UID)
                              }
                            />
                          </Grid.Column>
                        )}

                        {FeatureFlags.isFlagOn('ENABLE_RETURN_AS_NEW') && (
                          <Grid.Column
                            style={{
                              paddingRight: '8px',
                              paddingLeft: '0px'
                            }}
                          >
                            <Button
                              style={{ width: '100%' }}
                              primary={true}
                              labelPosition="left"
                              disabled={
                                ((this.user === undefined || task.status === 'todo') &&
                                  this.props.workStatus === undefined) ||
                                !this.props.userData.permissions.includes('updateContent')
                              }
                              icon={true}
                              onClick={() => this.props.returnAsNew(this.props.task.id, this.props.user.UID)}
                            >
                              <Icon name="level up alternate" />
                              {tr('RETURN_AS_NEW')}
                            </Button>
                          </Grid.Column>
                        )}
                      </Grid.Row>

                      <Grid.Row columns={2} style={{ paddingTop: '0px', paddingBottom: '9px' }}>
                        <Grid.Column
                          className="topBarButtonColumn"
                          style={{
                            paddingRight: '8px',
                            paddingLeft: '0px'
                          }}
                        >
                          <Dropdown
                            disabled={!this.props.userData.permissions.includes('updateContent')}
                            style={{ minWidth: '200px', width: '100%' }}
                            selectOnBlur={false}
                            value={task.status}
                            selection={true}
                            id="ticketStatusDropdown"
                            className="ticketStatusDropdown"
                            search={true}
                            onChange={(event, data) => {
                              this.handleStatusChange(data.value!.toString() as StatusTypes);
                            }}
                            options={statusOptions}
                          />
                        </Grid.Column>

                        <Grid.Column
                          className="topBarButtonColumn"
                          style={{
                            paddingRight: '8px',
                            paddingLeft: '0px'
                          }}
                        >
                          <Dropdown
                            style={{ minWidth: '200px', width: '100%' }}
                            selectOnBlur={false}
                            value={task.taskType}
                            selection={true}
                            id="ticketTypeDropdown"
                            className="ticketTypeDropdown"
                            loading={typeof ajaxCallInProgress !== 'undefined'}
                            disabled={
                              typeof ajaxCallInProgress !== 'undefined' ||
                              !this.props.userData.permissions.includes('updateContent')
                            }
                            placeholder={tr('PLACEHOLDER_TICKET_TYPE')}
                            onChange={(event, data) => {
                              this.handleTypeChange(data.value!.toString());
                            }}
                            options={orderBy(this.ticketTypeNames, ['text', 'value'])}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Dimmer.Dimmable>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Translation>
    );
  }
}
