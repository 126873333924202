import axios from 'axios';

import ApiConfig from './ApiConfig';
import type { Tag } from 'src/types/Tag';

const API_URL_GET_ALL_TAGS = '/tags';
const API_URL_PATCH_TAG = '/tag/';
const API_URL_CREATE_TAG = '/tags/';

export default class TagsApi {
  static getTags = async (): Promise<Tag[]> => {
    return axios.get(ApiConfig.getConfig().API_URL + API_URL_GET_ALL_TAGS).then((response) => {
      return response.data;
    });
  };
  static patchTag = async (data: any): Promise<any> => {
    return axios.patch(ApiConfig.getConfig().API_URL + API_URL_PATCH_TAG + data.id, data.data).then((response) => {
      return response.data;
    });
  };
  static deleteTag = async (id: string): Promise<any> => {
    return axios
      .delete(ApiConfig.getConfig().API_URL + '/tag/' + id, {
        data: {
          _id: id
        }
      })
      .then((response) => response.data);
  };
  static createTag = async (data: any): Promise<any> => {
    return axios.post(ApiConfig.getConfig().API_URL + API_URL_CREATE_TAG, data).then((response) => {
      return response.data;
    });
  };
}
