import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Popup } from 'semantic-ui-react';
import type { VFC } from 'react';

import { setShowImages } from 'src/reducers/commentsMenuReducer';
import type { State } from 'src/types/initialState';

const ImagesToggle: VFC = () => {
  const { t } = useTranslation();
  const contentId = useSelector<State, string | null>((state) => state.activeTicketTab);
  const showImagesMap = useSelector<State, Record<string, boolean>>((state) => state.commentsMenu.showImages);
  const dispatch = useDispatch();
  if (!contentId) {
    return null;
  }
  const showImages = showImagesMap[contentId];
  const onClick = () => {
    dispatch(setShowImages({ contentId, showImages: !showImages }));
  };

  return (
    <Popup
      trigger={
        <Button icon size="tiny" onClick={onClick}>
          <Icon color={showImages ? 'green' : 'grey'} name="images" />
        </Button>
      }
    >
      <Popup.Content>{showImages ? t('comments.popups.hide_images') : t('comments.popups.show_images')}</Popup.Content>
    </Popup>
  );
};

export default ImagesToggle;
