import {
  UPDATE_TICKET_TAB_STATE,
  APPEND_TO_DRAFT,
  SWITCH_DRAFT_TAB,
  FALSIFY_SCROLL,
  SCROLL_CASE_BOTTOM_INSTANTLY,
  FALSIFY_INSTANT_SCROLL
} from '../actions/index';
import * as _ from 'lodash';

export interface DraftsState {
  scroll: boolean;
  scrollInstantly: boolean;
}

const initialState: { drafts: DraftsState } = {
  drafts: {
    scroll: false,
    scrollInstantly: false
  }
};

const draftReducer = (state = initialState.drafts, action: any) => {
  switch (action.type) {
    case UPDATE_TICKET_TAB_STATE: {
      const newState = _.cloneDeep(state);
      newState[action.payload.id] = {
        ...newState[action.payload.id],
        ...action.payload.state
      };
      return newState;
    }

    case FALSIFY_SCROLL: {
      const newState = _.cloneDeep(state);
      newState.scroll = false;
      return newState;
    }

    case FALSIFY_INSTANT_SCROLL: {
      const newState = _.cloneDeep(state);
      newState.scrollInstantly = false;
      return newState;
    }

    case APPEND_TO_DRAFT: {
      const newState = _.cloneDeep(state);

      if (
        newState[action.payload.id] !== undefined &&
        newState[action.payload.id][action.payload.channel] !== undefined
      ) {
        const tempVariable = newState[action.payload.id][action.payload.channel];
        Object.keys(tempVariable).forEach((key: any) => {
          if (key === 'content') {
            tempVariable[key] += action.payload.payload[key] || '';
          } else {
            tempVariable[key] = action.payload.payload[key];
          }
        });
      } else {
        if (newState[action.payload.id] === undefined) {
          newState[action.payload.id] = {};
          newState[action.payload.id][action.payload.channel] = action.payload.payload;
        } else {
          newState[action.payload.id][action.payload.channel] = action.payload.payload;
        }
      }
      newState.scroll = true;
      return newState;
    }

    case SWITCH_DRAFT_TAB: {
      const tempState = _.cloneDeep(state);
      const foundDraft = tempState[action.payload.id];
      if (foundDraft === undefined) {
        tempState[action.payload.id] = { tabIndex: action.payload.tabIndex };
      } else {
        tempState[action.payload.id].tabIndex = action.payload.tabIndex;
      }

      return tempState;
    }

    case SCROLL_CASE_BOTTOM_INSTANTLY: {
      const newState = _.cloneDeep(state);
      newState.scrollInstantly = true;
      return newState;
    }

    default: {
      return state;
    }
  }
};

export default draftReducer;
