import Avatar from 'react-avatar';
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import type { VFC, CSSProperties } from 'react';

import { getProfileName, getUserById } from './utils';
import { getUserImage } from 'src/Utilities/images';
import type { User } from 'src/types/User';
import type { State } from 'src/types/initialState';

export type UserAvatarSize = '24' | '26' | '32' | '35' | '50' | '70';

interface UserAvatarProps {
  UID?: number | string;
  className?: string;
  imgClassName?: string;
  round?: boolean;
  size: UserAvatarSize;
  style?: CSSProperties;
  user?: User;
}

const UserAvatar: VFC<UserAvatarProps> = ({ UID, round, size, ...props }) => {
  const users = useSelector((state: State) => state.usersList.usersList);
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    if (!props.user) {
      setUser(getUserById(users, UID));
    }
  }, []);

  if (!user) {
    return <span>{t('ERROR_HAPPENED')}</span>;
  }

  const name = getProfileName(user);
  const { profileImage, avatarColor } = user?.profile || {};
  const hasImage = profileImage && !profileImage.includes('blank');
  const largeImage = parseInt(size) > 35;

  return hasImage && !avatarColor ? (
    <Image alt={name} circular={round} height={size} src={getUserImage(profileImage, largeImage)} />
  ) : (
    <Avatar name={name} round={round} size={size} color={avatarColor} {...props} />
  );
};

export default UserAvatar;
