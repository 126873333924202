import EnvSettings from 'src/api/EnvSettings';
import * as _ from 'lodash';
import { timestampPrefixToDate } from '../Components/Case/DateSelector';
import { FormattedSearch, SearchCriterion } from '../types/Search';

const formatMultiSearch = (searchValue: (string | number)[] | null | undefined, obj: SearchCriterion): void => {
  if (searchValue == null) {
    if (!Array.isArray(obj.value)) {
      obj.value = [obj.value];
    }
  } else if (typeof obj.value === 'string') {
    obj.value = searchValue.concat([obj.value]);
  } else {
    obj.value = searchValue.concat(obj.value);
  }
};

const formatTimestampPrefix = (acc: FormattedSearch, obj: SearchCriterion): void => {
  acc.basic[obj.param] = timestampPrefixToDate(obj.value);
};

export const formatSearch = (searchCriteria: SearchCriterion[], userId: string): FormattedSearch => {
  const baseObject: FormattedSearch = {
    basic: {
      limit: EnvSettings.getSettings().MAX_SEARCH_RESULTS
    },
    details: {},
    entity: {}
  };

  return searchCriteria.reduce((acc: FormattedSearch, objOrig) => {
    const obj = _.cloneDeep(objOrig);
    if (obj.param === 'workedBy') {
      obj.value = [parseInt(userId.slice(3), 10)];
    }

    if (obj.param === 'delegatedToMe') {
      obj.value = parseInt(userId.slice(3), 10).toString();
      obj.param = 'delegatedTo';
    }

    const param = obj.param as keyof FormattedSearch['basic'];
    switch (param) {
      case 'ticketTypesOr':
      case 'contactChannel':
      case 'statuses':
      case 'selectedTicketTagsAnd':
      case 'selectedTicketTagsOr':
      case 'selectedTicketTagCategoriesOr':
      case 'workedByUsers':
      case 'delegatedTo':
        formatMultiSearch(acc.basic?.[param], obj);
        break;
    }

    const timestampParams: (keyof FormattedSearch['basic'])[] = [
      'createdAfter',
      'createdBefore',
      'touchedAfter',
      'touchedBefore',
      'duedateAfter',
      'duedateBefore'
    ];
    if (timestampParams.includes(obj.param as keyof FormattedSearch['basic'])) {
      formatTimestampPrefix(acc, obj);
      return acc;
    }

    switch (obj.datagroup) {
      case 'basic':
        acc.basic[obj.param] = obj.value;
        break;
      case 'CaseDetails':
      case 'case':
      case 'detail':
        acc.details[obj.param] = obj.value;
        break;
      case 'entity':
        if (obj.object) {
          acc.entity[obj.object + '.' + obj.param] = obj.value;
        } else {
          acc.entity[obj.param] = obj.value;
        }
        break;
      default:
        break;
    }

    return acc;
  }, baseObject);
};
