import React from 'react';
import debounce from 'lodash/debounce';
import { Accordion, Button, Icon, Input, Radio, Segment } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { t } from 'i18next';
import type { StrictDropdownProps } from 'semantic-ui-react';
import type { TFunction } from 'i18next';

import DateSelector, { timestampPrefixToDate } from '../Case/DateSelector';
import LastContactAddressFilter from './LastContactAddressFilter';
import OriginalContactFilter from './OriginalContactFilter';
import OriginalDirectionDropdown from './OriginalDirectionDropdown';
import Sorting from '../Filter/Sorting';
import { ArrayDropdownFilter, StringDropdownFilter } from '../Filter/DropdownFilter';
import { filterTagsShownToUser } from 'src/Utilities/tags';
import { getURLFilterParams } from 'src/Utilities/helper';
import type { Category } from 'src/types/Category';
import type { Channel } from 'src/types/Channel';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { Direction, SortBy } from 'src/types/Sorting';
import type { IFilterItem, TabFilter } from 'src/types/Filter';
import type { MenuTab } from 'src/types/MenuTab';
import type { PersonalData, User } from 'src/types/User';
import type { Priority } from 'src/types/Priority';
import type { Tag } from 'src/types/Tag';
import type { TicketListTab } from 'src/types/TicketList';
import type { TicketType, TicketTypeMetadata } from 'src/types/TicketType';

import * as styles from './Filter.style';
import 'react-dates/lib/css/_datepicker.css';
import { orderBy } from 'lodash';

export interface IFilterStateToProps {
  ticketTypesMetadata: TicketTypeMetadata[];
  categories: Category[];
  personalData: PersonalData;
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  tab: MenuTab;
  usersList: User[];
  priorities: Priority[];
  filters: TabFilter;
  isUsingUrlParams: boolean;
  isFullWidthContainer: boolean;
  contentType?: ContentTypesFields;
}

export interface IFilterDispatchToProps {
  setFilter(args: IFilterItem): void;
  clearAllFilters?: (id: string) => void;
  setSorting?: (sorting: SortBy, direction: Direction) => void;
}

type IProps = IFilterStateToProps & IFilterDispatchToProps;

interface IState {
  titleFilter?: string;
  originalContactFilter?: string;
  lastContactAddressFilter?: string;
  isExtraOptionsOpen: boolean;
  focused: boolean;
}

type FilterParams = 'title' | 'originalContact' | 'lastContactAddress';

const defaultState: IState = {
  titleFilter: undefined,
  originalContactFilter: undefined,
  lastContactAddressFilter: undefined,
  isExtraOptionsOpen: false,
  focused: false
};

class Filter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      titleFilter: this.getParam('title'),
      originalContactFilter: this.getParam('originalContact'),
      lastContactAddressFilter: this.getParam('lastContactAddress'),
      isExtraOptionsOpen: false,
      focused: false
    };
  }

  private getParam = (param: FilterParams) => {
    if (this.props.isUsingUrlParams) {
      return getURLFilterParams(window.location.search)[param] || this.props.tab?.filters?.[param];
    } else {
      return this.props.filters[param];
    }
  };

  private changeFilter = (value: any, type: keyof TabFilter) => {
    if (this.props.isUsingUrlParams) {
      if (value === '' || Number.isNaN(value) || (Array.isArray(value) && value.length === 0)) {
        value = undefined;
      }
    }

    this.props.setFilter({
      id: this.props.tab.id,
      parameter: type,
      value: value
    });
  };

  private freeFilter = (value: string | boolean | undefined, property: FilterParams) => {
    this.props.setFilter({
      id: this.props.tab.id,
      parameter: property,
      value: value
    });
  };

  private ticketTypesToOptions = (ticketTypes: TicketType[]): StrictDropdownProps['options'] => {
    const options = ticketTypes.map(({ name }) => ({ text: name, value: name }));
    return orderBy(options, ['text', 'value']);
  };

  private channelOptions = (channels: Channel[], t: TFunction) => {
    const options = channels.map((channel) => ({
      text: t([`CHANNEL_${channel.channel.toUpperCase()}`, channel.channel]),
      value: channel.id,
      icon: channel.icon || 'question circle'
    }));

    return orderBy(options, ['text', 'value']);
  };

  private tagsOptions = (tags: Tag[]) => {
    const options = filterTagsShownToUser(tags, this.props.personalData.ticketTypes).map((x) => ({
      text: x.name,
      value: x.id
    }));

    return orderBy(options, ['text', 'value']);
  };

  private debounceFilter = debounce(
    (value: string | boolean | undefined, property: FilterParams) => this.freeFilter(value, property),
    500
  );

  private onChangeFilter = (
    filter: FilterParams,
    stateProperty: keyof IState,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ [stateProperty]: event.target.value } as any, () => {
      if (this.state[stateProperty] === '') {
        this.debounceFilter(undefined, filter);
      } else {
        this.debounceFilter(this.state[stateProperty], filter);
      }
    });
  };

  render() {
    const { filters } = this.props;
    const possibleDelegates = this.props.usersList
      .filter((user) => user.role.id !== 'ROL3')
      .map((user) => ({
        text: `${((user || {}).profile || {}).firstName} ${((user || {}).profile || {}).lastName} `,
        value: user.UID
      }));

    const possibleDelegatesSorted = orderBy(possibleDelegates, ['text', 'value']);

    const priorities = this.props.priorities.map((priority) => {
      return { text: t(priority.text), value: priority.value };
    });

    const dateFilters: {
      type: keyof TabFilter;
      label: string;
    }[] = [
      { type: 'dueDateAfter', label: t('SEARCH_BY_DUEDATE_AFTER_DATE_RANGE') },
      { type: 'dueDateBefore', label: t('SEARCH_BY_DUEDATE_BEFORE_DATE_RANGE') },
      { type: 'touchedAfter', label: t('SEARCH_BY_TOUCHED_AFTER_DATE_RANGE') },
      { type: 'touchedBefore', label: t('SEARCH_BY_TOUCHED_BEFORE_DATE_RANGE') },
      { type: 'createdAfter', label: t('SEARCH_BY_CREATED_AFTER_DATE_RANGE') },
      { type: 'createdBefore', label: t('SEARCH_BY_CREATED_BEFORE_DATE_RANGE') }
    ];

    return (
      <Translation ns="translations">
        {(tr) => (
          <Segment className="filterComponent" style={styles.filterComponent(this.props.isFullWidthContainer)}>
            <div style={styles.mainControlsWrapper}>
              <Button
                id="clear-filters"
                negative
                basic
                labelPosition="left"
                icon
                style={styles.clearFilter}
                onClick={() => {
                  this.setState({ ...defaultState });
                  this.props.clearAllFilters?.(this.props.tab.id);
                }}
              >
                <Icon name="delete" />
                {t('filter.clear')}
              </Button>

              {this.props.contentType === 'tickets' && (
                <Sorting
                  sorting={(this.props.tab as TicketListTab).sorting}
                  direction={(this.props.tab as TicketListTab).direction}
                  onChange={this.props.setSorting!}
                />
              )}
            </div>

            <ArrayDropdownFilter
              id={'FILTER_BY_TICKETTYPE'}
              options={this.ticketTypesToOptions(this.props.ticketTypes)}
              value={filters.taskType}
              placeholder={tr('FILTER_BY_TICKETTYPE')}
              onChange={(value) => this.changeFilter(value, 'taskType')}
              style={styles.defaultMargin}
            />
            <ArrayDropdownFilter
              id={'FILTER_BY_CHANNEL'}
              options={this.channelOptions(this.props.channels, tr)}
              value={filters.channel}
              placeholder={tr('FILTER_BY_CHANNEL')}
              onChange={(value) => this.changeFilter(value, 'channel')}
              style={styles.defaultMargin}
            />
            <ArrayDropdownFilter
              id={'FILTER_BY_TAG'}
              options={this.tagsOptions(this.props.tags)}
              value={filters.tags}
              placeholder={tr('FILTER_BY_TAG')}
              onChange={(value) => this.changeFilter(value, 'tags')}
              style={styles.defaultMargin}
            />
            <ArrayDropdownFilter
              id={'FILTER_BY_TAG_CATEGORY'}
              options={this.props.categories
                .filter((category) => !!category.tags.length)
                .map((category) => ({ text: category.name, value: category.id }))}
              value={filters.categories}
              placeholder={tr('FILTER_BY_TAG_CATEGORY')}
              onChange={(value) => {
                this.changeFilter(value, 'categories');
              }}
              style={styles.defaultMargin}
            />
            <Input
              id="FILTER_BY_TITLE"
              placeholder={tr('FILTER_BY_TITLE')}
              icon="search"
              value={this.state.titleFilter || ''}
              onChange={(e) => this.onChangeFilter('title', 'titleFilter', e)}
              fluid
              className="filterInputHover"
            />

            <br />

            <Radio
              id="FILTER_BY_WITHIN_TODAY"
              fluid={true}
              toggle
              label={tr('FILTER_BY_WITHIN_TODAY')}
              checked={filters.dueDateToday || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked || '', 'dueDateToday');
              }}
              style={styles.filterByWithinToday}
            />
            <br />
            <Radio
              id="FILTER_BY_ME"
              fluid={true}
              toggle
              label={tr('FILTER_BY_ME')}
              checked={filters.handledByMe || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked || '', 'handledByMe');
              }}
              style={styles.filterByMe}
            />
            <br />
            <Radio
              id="FILTER_BY_ME_OR_NOONE"
              fluid={true}
              toggle
              label={tr('FILTER_BY_ME_OR_NOONE')}
              checked={filters.handledByMeOrNoOne || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked !== true ? '' : true, 'handledByMeOrNoOne');
              }}
              style={{ marginLeft: 'auto', marginTop: '.75em' }}
            />
            <br />

            <Radio
              id="FILTER_BY_DELEGATED_TO_ME"
              fluid={true}
              toggle
              label={tr('FILTER_BY_DELEGATED_TO_ME')}
              checked={filters.delegatedToMe || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked || '', 'delegatedToMe');
              }}
              style={styles.radioMargins}
            />
            <br />
            <Radio
              id="FILTER_NON_DELEGATED"
              fluid={true}
              toggle
              label={tr('FILTER_NON_DELEGATED')}
              checked={filters.notDelegated || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked || '', 'notDelegated');
              }}
              style={styles.radioMargins}
            />
            <br />
            <Radio
              id="FILTER_BY_WITHIN_24H_DUE_DATE"
              fluid={true}
              toggle
              label={tr('FILTER_BY_WITHIN_24H_DUE_DATE')}
              checked={filters.dueDate24h || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked || '', 'dueDate24h');
              }}
              style={styles.radioMargins}
            />
            <br />
            <Radio
              id="FILTER_BY_DUE_DATE_IS_OVER"
              fluid={true}
              toggle
              label={tr('FILTER_BY_DUE_DATE_IS_OVER')}
              checked={filters.dueDateOver || false}
              onClick={(e, data) => {
                this.changeFilter(data.checked || '', 'dueDateOver');
              }}
              style={styles.radioMargins}
            />

            <Accordion>
              <Accordion.Title
                active={this.state.isExtraOptionsOpen}
                onClick={() => {
                  this.setState((previousState) => {
                    return {
                      isExtraOptionsOpen: !previousState.isExtraOptionsOpen
                    };
                  });
                }}
              >
                <Icon name="dropdown" id="SHOW_ADDITIONAL_FILTERS" />
                {`${tr('SHOW_ADDITIONAL_FILTERS')}`}
              </Accordion.Title>

              <Accordion.Content active={this.state.isExtraOptionsOpen}>
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_DELEGATE'}
                  options={possibleDelegatesSorted}
                  value={filters.delegates}
                  placeholder={tr('FILTER_BY_DELEGATE')}
                  onChange={(value) => this.changeFilter(value, 'delegates')}
                  style={styles.defaultMargin}
                />
                <StringDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_LAST_HANDLED_USER'}
                  options={possibleDelegatesSorted}
                  value={filters.handledBy}
                  placeholder={tr('FILTER_BY_LAST_HANDLED_USER')}
                  onChange={(value) => this.changeFilter(value, 'handledBy')}
                  style={styles.defaultMargin}
                />
                <StringDropdownFilter
                  id="GENERAL_SEARCH_NO_RESULTS_FILTER_BY_CREATED_USER"
                  options={possibleDelegatesSorted}
                  value={filters.createdBy}
                  placeholder={tr('FILTER_BY_CREATED_USER')}
                  onChange={(value) => this.changeFilter(value, 'createdBy')}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_PRIORITIES'}
                  options={priorities}
                  value={filters.priorities}
                  placeholder={tr('FILTER_BY_PRIORITIES')}
                  onChange={(value) => this.changeFilter(value, 'priorities')}
                  style={styles.defaultMargin}
                />
                {dateFilters.map((filter) => (
                  <div style={styles.defaultMargin}>
                    <DateSelector
                      value={filters[filter.type]}
                      onChange={(timestamp) => {
                        this.changeFilter(timestampPrefixToDate(timestamp), filter.type);
                      }}
                      textRight={filter.label}
                    />
                  </div>
                ))}
                <Radio
                  id="FILTER_NO_TAGS"
                  toggle
                  label={tr('FILTER_NO_TAGS')}
                  checked={!!filters.tagsNO}
                  onClick={(e, data) => {
                    this.changeFilter(data.checked || '', 'tagsNO');
                  }}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_TAGS_BY_AND'}
                  options={this.tagsOptions(this.props.tags)}
                  value={filters.tagAND}
                  placeholder={tr('FILTER_BY_TAGS_BY_AND')}
                  onChange={(value) => this.changeFilter(value, 'tagAND')}
                  sidePopup={tr('FILTER_BY_TAGS_BY_AND_HELP')}
                  disabled={filters.tagsNO}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id={'GENERAL_SEARCH_NO_RESULTS_FILTER_BY_TAGS_BY_NOT'}
                  options={this.tagsOptions(this.props.tags)}
                  value={filters.tagNOT}
                  placeholder={tr('FILTER_BY_TAGS_BY_NOT')}
                  onChange={(value) => this.changeFilter(value, 'tagNOT')}
                  sidePopup={tr('FILTER_BY_TAGS_BY_NOT_HELP')}
                  disabled={filters.tagsNO}
                  style={styles.defaultMargin}
                />
                {this.props.contentType !== 'infopages' && (
                  <>
                    <OriginalContactFilter
                      tr={tr}
                      originalContactFilter={this.state.originalContactFilter}
                      onChangeFilter={(e) => this.onChangeFilter('originalContact', 'originalContactFilter', e)}
                      style={styles.defaultMargin}
                    />
                    <LastContactAddressFilter
                      lastContactAddressFilter={this.state.lastContactAddressFilter}
                      onChangeFilter={(e) => this.onChangeFilter('lastContactAddress', 'lastContactAddressFilter', e)}
                      style={styles.defaultMargin}
                    />
                    <OriginalDirectionDropdown
                      filters={filters}
                      changeFilter={this.changeFilter}
                      style={styles.defaultMargin}
                    />
                  </>
                )}
                <ArrayDropdownFilter
                  id="FILTER_BY_NOT_TICKETTYPE"
                  options={this.ticketTypesToOptions(this.props.ticketTypes)}
                  value={filters.taskTypeNOT}
                  placeholder={tr('FILTER_BY_NOT_TICKETTYPE')}
                  onChange={(value) => this.changeFilter(value, 'taskTypeNOT')}
                  style={styles.defaultMargin}
                />
                <ArrayDropdownFilter
                  id="FILTER_BY_NOT_TAG_CATEGORIES"
                  options={this.props.categories
                    .filter((category) => !!category.tags.length)
                    .map((category) => ({ text: category.name, value: category.id }))}
                  value={filters.tagCategoriesNOT}
                  placeholder={tr('FILTER_NO_TAG_CATEGORIES')}
                  onChange={(value) => this.changeFilter(value, 'tagCategoriesNOT')}
                  style={styles.defaultMargin}
                />
              </Accordion.Content>
            </Accordion>
          </Segment>
        )}
      </Translation>
    );
  }
}

export default Filter;
