import { history } from '../reducers';
import { ContentTypePrefixes } from '../types/ContentTypes';

export const HistoryPrefixValues = ['case', 'infopage'];
export type HistoryPrefixes = typeof HistoryPrefixValues[number];

export const ContentTypePrefixesToHistoryPrefix: { [key in ContentTypePrefixes]: HistoryPrefixes } = {
  TSK: 'case',
  INF: 'infopage'
};

export const ticketIdToHistoryPrefix = (id: string): HistoryPrefixes => {
  return ContentTypePrefixesToHistoryPrefix[id.substring(0, 3)] ?? 'case';
};

export const buildTicketPathname = (id: string) => `/${ticketIdToHistoryPrefix(id)}/${id}`;

export const navigateToTicket = (id: string): void => {
  history.push({ pathname: buildTicketPathname(id), search: window.location.search });
};
