import { createSlice } from '@reduxjs/toolkit';
import { ChannelType } from 'src/types/Channel';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  CampaignSource,
  CheckedHeaders,
  CampaignConfigRecord,
  ImportResponseTemplate,
  ImportTemplate
} from 'src/types/Campaign';

export interface CampaignManagementState {
  step: number;
  checkedHeaders: CheckedHeaders[];
  importsList: CampaignConfigRecord[];
  templates: ImportTemplate[];
  importFile?: File;
  payload: {
    tags: string[];
    title: string;
    content: string;
    selectedTicketsIds: number[];
    campaignSource: CampaignSource;
    senderEmail: string;
    senderPhone: string;
    channel: ChannelType;
    scheduled?: number | null;
    templateId?: number;
    recordsAmount?: number;
    fileId?: number;
    responseTemplates?: ImportResponseTemplate[];
  };
}

const initialState: CampaignManagementState = {
  step: 1,
  importsList: [],
  checkedHeaders: [],
  templates: [],
  payload: {
    tags: [],
    title: '',
    content: '',
    senderEmail: '',
    senderPhone: '',
    channel: ChannelType.Email,
    selectedTicketsIds: [],
    campaignSource: 'customer'
  }
};

const campaignSlice = createSlice({
  name: 'campaignSlice',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setImportsList: (state, action: PayloadAction<CampaignConfigRecord[]>) => {
      state.importsList = action.payload;
    },
    addToImportList: (state, action: PayloadAction<CampaignConfigRecord>) => {
      if (!state.importsList.find((il) => il.id === action.payload.id)) {
        state.importsList.push(action.payload);
      }
    },
    updateImportsList: (state, action: PayloadAction<{ importConfig: CampaignConfigRecord; newRecord: boolean }>) => {
      const { newRecord, importConfig } = action.payload;

      if (newRecord) {
        state.importsList.push(action.payload.importConfig);
      } else {
        const index = state.importsList.findIndex((il) => il.id === importConfig.id);
        state.importsList[index] = action.payload.importConfig;
      }
    },
    setImportFile: (state, action: PayloadAction<File>) => {
      state.importFile = action.payload;
    },
    setCheckedHeaders: (state, action: PayloadAction<CheckedHeaders[]>) => {
      state.checkedHeaders = action.payload;
    },
    setCampaignPayloadProp: (state, action: PayloadAction<Partial<CampaignManagementState['payload']>>) => {
      state.payload = { ...state.payload, ...action.payload };
    },
    addCampaignResponseTemplate: (state, action: PayloadAction<ImportResponseTemplate>) => {
      if (!state.payload.responseTemplates?.length) {
        state.payload.responseTemplates = [];
      }

      state.payload.responseTemplates.push(action.payload);
    },
    updateCampaignResponseTemplateProp: (
      state,
      action: PayloadAction<{
        id: string;
        prop: keyof ImportResponseTemplate;
        value: any;
      }>
    ) => {
      const { id, prop, value } = action.payload;
      const templateIndex = state.payload.responseTemplates?.findIndex((t) => t._id === id);

      if (state.payload.responseTemplates?.length && typeof templateIndex === 'number') {
        state.payload.responseTemplates[templateIndex][prop] = value;
      }
    },
    removeCampaignResponseTemplate: (state, action: PayloadAction<string>) => {
      state.payload.responseTemplates = state.payload.responseTemplates?.filter((t) => t._id !== action.payload);
    },
    fetchCampaignTemplatesSuccess: (state, action: PayloadAction<ImportTemplate[]>) => {
      state.templates = action.payload;
    },
    resetCampaign: (state) => ({ ...initialState, templates: state.templates, importsList: state.importsList })
  }
});

export const {
  setStep,
  setImportsList,
  addToImportList,
  setImportFile,
  setCheckedHeaders,
  setCampaignPayloadProp,
  addCampaignResponseTemplate,
  updateCampaignResponseTemplateProp,
  removeCampaignResponseTemplate,
  resetCampaign,
  updateImportsList,
  fetchCampaignTemplatesSuccess
} = campaignSlice.actions;

export default campaignSlice.reducer;
