import React from 'react';
import iziToast from 'izitoast';
import { Icon } from 'semantic-ui-react';
import { t as tr } from 'i18next';

import type { IconProps } from 'semantic-ui-react';
import type { VFC } from 'react';

interface Props extends IconProps {
  text: string;
}

const CopyToClipboardIcon: VFC<Props> = ({
  text, size, link = true, ...props
}) => {
  const onClickCopy = () => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: tr('COPIED')
    });
  }

  return (
    <Icon
      name="copy"
      link={link}
      onClick={onClickCopy}
      size={size}
      {...props}
    />
  );
};

export default CopyToClipboardIcon;
