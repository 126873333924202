import _ from 'lodash';
import React from 'react';
import iziToast from 'izitoast';
import { t as tr } from 'i18next';
import { withRouter } from 'react-router-dom';
import { Button, Dropdown, Grid, Header, Icon } from 'semantic-ui-react';
import type { SemanticICONS } from 'semantic-ui-react';

import AssetContainer from 'src/containers/AssetContainer';
import DraftsContainer from 'src/containers/DraftsContainer';
import Comments from 'src/Components/Comments/Comments';
import TopBarGeneralInfo from 'src/Components/Case/TopBar/TopBarGeneralInfo';
import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import LoaderComponent from '../../Loader/Loader';
import ErrorBoundary from 'src/ErrorBoundary';
import AddCustomer from '../../Customer/AddCustomer';
import CustomerContainer from '../../Customer/CustomerContainer';
import AccordionHeader from '../AccordionHeader';
import Attachments from '../Attachments';
import CaseContentEditor from '../CaseContentEditor';
import Delegates from '../Delegates';
import ExternalLinksList from '../ExternalLinksList';
import InfoPageRelations from './Widget/InfoPageRelations';
import { CollectionFieldSets } from '../Widget/CollectionFieldSets/CollectionFieldSets';
import Info from '../Info/Info';
import TagsWidget from 'src/Components/Case/Widget/TagsWidget';
import { MetadataEditor } from '../Metadata/MetadataEditor';
import { formatSearch } from 'src/Utilities/search';
import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { Priority } from 'src/types/Priority';
import type { FieldSet } from 'src/types/TicketType';
import type { ReplyTabIndex } from 'src/types/Drafts';
import type { Channel } from 'src/types/Channel';
import type { Entity, Suggestion, Ticket } from 'src/types/Ticket';

import '../Case.css';

interface CaseProps {
  history: any;
  location: any;
  match: any;
  task: Ticket;
  usersList: any;
  userData: any;
  user: any;
  ticketTypes: any;
  externalLinks: any[];
  ticketChannels: any;
  ticketPriorities: any;
  mobileMode: boolean;
  autosuggestions: Suggestion[];
  replyTabIndexList: ReplyTabIndex[];
  widgetOrder: string[];

  fireTicketSearch(filter: any, _id?: string, searchCriteria?: any[]): void;
  addEntityToCase(ticketId: string, body: object, subEntitiesToGet?: any): Promise<void>;
  removeEntityFromCase(ticketId: string, body: object): void;
  fetchContent(id: string): void;
  getEntityById(ticketId: string, ticketType: string, entityId: string, entityType: string): void;
  updateTicketCaseDetails(ticketId: string, updateKey: string, updateValue: any, group: string): void;
  updateEntityDetails(ticketId: string, taskType: string, updateArgs: UpdateEntityDetail): void;
  updateTicket(ticket: Ticket): void;
  addDelegateGroupToContent(ticket: any, usrGroup: number[]): void;
  uploadFile(ticketId: string, file: any): void;
  deprecateAttachment(ticketId: string, attachmentId: string): void;
  unDeprecateAttachment(ticketId: string, attachmentId: string): void;
  editAttachment(ticketId: string, attachmentId: string, body: any): void;
  removeDelegateFromContent(ticket: any, usr: string): void;
  updateMetadata(id: number | string, metadata: object): void;
  addDelegateToContent(ticket: any, usr: string): void;
  onSearchPreviousTickets(entityId: string, entityType: string): void;
  openTab(id: string): void;
  changeEntityFields(data: any, id: any, entityDisplayName: string): void;
}

interface CaseState {
  entityFields: any[];
  handleInfo: any[];
  channelTypes: {
    text: string;
    value: number;
    icon: SemanticICONS;
  }[];
  ticketPriorityLevels: {
    text: string;
    value: number;
    icon: SemanticICONS;
    color: string;
  }[];
}

// TODO info pages merge this and case.tsx
class InfopageCase extends React.Component<CaseProps, CaseState> {
  entityInfo: any;
  additionalCustomerFieldSets: any;
  entityInfoHeader: any;
  entitySearchButton: string;
  assetInfoFields: any;
  assetInfoDisplayName: any;
  showAssetMeta: any;
  fieldMap: Map<string, any>;
  ticketTypes: Map<string, any>;
  detailGroupMap: Map<string, any>;
  ticketTypeNames: string[];
  fieldSetNames: string[];
  senderEmails: any[];
  private taskStatusOptions: {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[] = [
    {
      text: tr('INFOPAGE_STATUS_DRAFT'),
      icon: 'firstdraft',
      value: 'draft'
    },
    {
      text: tr('INFOPAGE_STATUS_INREVIEW'),
      icon: 'eye',
      value: 'inReview'
    },
    {
      text: tr('INFOPAGE_STATUS_WAITING'),
      icon: 'wait',
      value: 'waitingToBePublished'
    },
    {
      text: tr('INFOPAGE_STATUS_PUBLISHED'),
      icon: 'check',
      value: 'published'
    },
    {
      text: tr('INFOPAGE_STATUS_WASTE'),
      icon: 'trash',
      value: 'waste'
    }
  ];

  constructor(props: CaseProps) {
    super(props);

    this.entityInfo = [];
    this.additionalCustomerFieldSets = [];
    this.entityInfoHeader = tr('CASE_TITLE_CUSTOMER');
    this.entitySearchButton = '';
    this.assetInfoFields = [];
    this.assetInfoDisplayName = '';
    this.showAssetMeta = false;
    this.ticketTypes = new Map();
    this.fieldSetNames = [];
    this.ticketTypeNames = [];
    this.fieldMap = new Map();
    this.detailGroupMap = new Map();
    this.senderEmails = [];

    this.state = {
      entityFields: [],
      handleInfo: [],
      channelTypes: this.props.ticketChannels
        .filter((channel: Channel) => {
          return channel.active;
        })
        .map((channelType: Channel) => ({
          text: channelType.channel,
          value: channelType.id,
          icon: channelType.icon
        })),
      ticketPriorityLevels: this.props.ticketPriorities.map((ticketPriority: Priority) => ({
        text: tr(ticketPriority.text),
        value: ticketPriority.value,
        icon: ticketPriority.icon,
        color: ticketPriority.color
      }))
    };
  }

  shouldComponentUpdate(nextProps: any) {
    const diff = this.difference(nextProps, this.props);
    const taskDiff = nextProps.task && this.props.task ? this.difference(nextProps.task, this.props.task) : {};

    if (Object.keys(diff).length === 0 && Object.keys(taskDiff).length === 0) {
      return false;
    }
    return true;
  }

  componentWillReceiveProps(nextProps: CaseProps) {
    if (nextProps.task) {
      if (nextProps.task.entityFields) {
        this.entityInfo = nextProps.task.entityFields;
        this.entityInfoHeader = nextProps.task.entityFieldDisplayName;
      }

      if (nextProps.ticketTypes !== undefined && nextProps.ticketTypes.length > 0 && !nextProps.task.entityFields) {
        this.fieldSetNames = [];
        this.ticketTypeNames = [];
        this.fieldMap = new Map();
        this.ticketTypes = new Map();
        this.detailGroupMap = new Map();
        this.senderEmails = [];

        nextProps.ticketTypes.forEach((ticketType: any) => {
          this.ticketTypeNames.push(ticketType.name);
          this.ticketTypes.set(ticketType.name, ticketType.fieldSets);

          const senderEmail = _.cloneDeep(ticketType);
          senderEmail.senderemail.type = senderEmail.name;

          if (senderEmail.name === nextProps.task.taskType) {
            senderEmail.senderemail.default = true;
          }
          this.senderEmails.push(senderEmail.senderemail);
        });

        const ticketType = [...nextProps.ticketTypes].find((type: any) => {
          return type.name === nextProps.task.taskType;
        });

        const fieldSets = this.ticketTypes.get(nextProps.task.taskType);
        if (fieldSets && fieldSets.length > 0) {
          fieldSets.forEach((fieldSet: any) => {
            if (fieldSet.id === 'caseInfo') {
              this.entitySearchButton = fieldSet.customSearch;
            }
            if (fieldSet.id === 'customerInfo') {
              this.entityInfo = fieldSet[fieldSet.id];
              this.entityInfoHeader = ticketType.defaultFieldSet ? ticketType.defaultFieldSet : fieldSet.displayName;
              this.additionalCustomerFieldSets = fieldSet.additionalFieldSets;
            } else if (fieldSet.id === 'status') {
              this.setState({
                handleInfo: fieldSet[fieldSet.id]
              });
            } else if (fieldSet.id === 'assetInfo') {
              this.assetInfoFields = fieldSet[fieldSet.id];
              this.assetInfoDisplayName = fieldSet.displayName;
              if (fieldSet.showAssetMeta === true) {
                this.showAssetMeta = true;
              } else {
                this.showAssetMeta = false;
              }
              this.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
            } else {
              this.fieldSetNames.push(fieldSet.displayName);
              this.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
              this.fieldMap.set(fieldSet.displayName, fieldSet[fieldSet.id]);
            }
          });
        }
      }
    }
  }

  componentDidMount() {
    this.props.openTab(this.props.match.params.id);
    this.props.fetchContent(this.props.match.params.id);
  }

  private difference = (x: any, y: any) => {
    function changes(object: any, base: any) {
      return _.transform(object, function (result: any, value: any, key: any) {
        if (!_.isEqual(value, base[key])) {
          result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
        }
      });
    }

    return changes(x, y);
  };

  private handleChannelChange = (channel: any) => {
    if (this.props.task) {
      const task = _.cloneDeep(this.props.task);
      task.channel = channel;
      this.props.updateTicket(task);
    }
  };

  private handlePriorityChange = (priority: any) => {
    if (this.props.task) {
      const task = _.cloneDeep(this.props.task);
      task.priority = priority;
      this.props.updateTicket(task);
    }
  };

  private handleGroupDelegateChange = (usrGroup: number[], method: string) => {
    if (!this.props.task) {
      return;
    }
    if (typeof this.props.task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          break;
        case 'POST':
        default:
          this.props.addDelegateGroupToContent(this.props.task, usrGroup);
          break;
      }
    }
  };

  private handleDelegateChange = (usr: string, method: string) => {
    if (!this.props.task) {
      return;
    }

    if (typeof this.props.task.id !== 'undefined') {
      switch (method) {
        case 'DELETE':
          this.props.removeDelegateFromContent(this.props.task, usr);
          break;
        case 'POST':
        default:
          this.props.addDelegateToContent(this.props.task, usr);
          break;
      }
    }
  };

  private fireCaseInfoSearch = (fields: any[], values: any[]) => {
    const searchIncludedObjs = _.filter(fields, (field) => {
      return (field.params.includeToSearch = true);
    });

    const searchArray = searchIncludedObjs.map((x) => {
      return {
        value: values[x.value],
        datagroup: 'detail',
        name: x.value,
        object: false,
        param: x.value,
        text: ''
      };
    });

    const searchParams = formatSearch(searchArray, this.props.userData.UID);
    this.props.fireTicketSearch(searchParams);
  };

  private changeEntity = (entityName: string, id: string) => {
    const ticketTypes = _.cloneDeep(this.props.ticketTypes);
    const tType = ticketTypes.find((type: any) => {
      const fields = type.fieldSets.some((field: any) => field.displayName === entityName);
      return type.name === this.props.task.taskType && fields;
    });

    const data = _.filter(tType.fieldSets, (field) => {
      return field.displayName === entityName;
    });

    this.props.changeEntityFields(data[0][id], this.props.task.id, entityName);
  };

  private getWidget = (widget: string, task: Ticket, ind: number) => {
    const widgetOpen = true;
    switch (widget) {
      case 'Metadata':
        return (
          <MetadataEditor
            key={ind}
            disabled={false}
            metadata={task.metaData}
            onSave={(data: object) => {
              this.props.updateMetadata(task.id, data);
            }}
          />
        );
      case 'Priority':
        return (
          <AccordionHeader
            id="widget-priority"
            as="h4"
            active={widgetOpen}
            title={tr('CASE_PRIORITY')}
            icon="inbox"
            key={ind}
          >
            <Dropdown
              disabled={!this.props.userData.permissions.includes('updateContent')}
              selectOnBlur={false}
              value={task.priority}
              selection={true}
              id="priorityDropdown"
              onChange={(event: any, data: any) => {
                this.handlePriorityChange(data.value);
              }}
              options={this.state.ticketPriorityLevels}
            />
          </AccordionHeader>
        );
      case 'CaseChannel':
        return (
          <AccordionHeader as="h4" active={widgetOpen} title={tr('CASE_CHANNEL')} icon="inbox" key={ind}>
            <Dropdown
              disabled={!this.props.userData.permissions.includes('updateContent')}
              selectOnBlur={false}
              value={task.channel}
              selection={true}
              id={widget}
              onChange={(event: any, data: any) => {
                this.handleChannelChange(data.value);
              }}
              options={this.state.channelTypes}
            />
          </AccordionHeader>
        );
      case 'HandleStatus':
        return (
          this.state.handleInfo.length !== 0 && (
            <AccordionHeader as="h4" active={widgetOpen} title={tr('CASE_TITLE_HANDLESTATUS')} icon="edit" key={ind}>
              <Info
                onSave={(editedField: any, editedValue: any) => {
                  this.props.updateTicketCaseDetails(this.props.task.id, editedField, editedValue, 'CaseDetails');
                }}
                fields={this.state.handleInfo}
                values={task.case}
              />
            </AccordionHeader>
          )
        );
      case 'AttachEntityById': {
        const ticketType = [...this.props.ticketTypes].find((type: any) => {
          return type.name === this.props.task.taskType;
        });

        const actualEntities = ticketType.fieldSets.filter((ent: Entity) => {
          return ent.group === 'entity';
        });

        const { id, taskType, entities: taskEntities } = this.props.task;

        return (
          <AccordionHeader
            as="h4"
            active={widgetOpen && this.props.task && this.props.task.entities && this.props.task.entities.length === 0}
            title={actualEntities.length > 1 ? tr('CASE_TITLE_ATTACH_TO_TICKET') : tr('CASE_TITLE_ADD_ENTITY')}
            icon="edit"
            id={this.props.task.id}
            key={ind}
            render={(toggleAccordion: (...args: any[]) => any) => (
              <AddCustomer
                fieldSets={ticketType.fieldSets}
                dropDownValue={this.entityInfoHeader || ''}
                task={{
                  id,
                  taskType,
                  entities: taskEntities
                }}
                fields={this.entityInfo || []}
                changeEntity={(entity: string, id: string) => this.changeEntity(entity, id)}
                onSelect={(body: any) => {
                  if (body.personalIdentityCode) {
                    iziToast.warning({
                      title: tr('ADD_CUSTOMER_CREATE_TITLE'),
                      message: tr('ADD_CUSTOMER_CREATE_VALUE_NOT_ALLOWED', {
                        value: tr('ADD_CUSTOMER_FORBIDDEN_FIELDS_SSN')
                      }),
                      timeout: 20000,
                      close: true,
                      overlay: true,
                      closeOnEscape: true,
                      zindex: 999,
                      position: 'center'
                    });
                  } else {
                    body.taskType = this.props.task.taskType;
                    this.props.addEntityToCase(this.props.task.id, body).then(() => {
                      toggleAccordion();
                    });
                  }
                }}
              />
            )}
          />
        );
      }
      case 'CustomerInfo': {
        const selectedTicketType = this.props.ticketTypes.find((x: any) => x.name === this.props.task.taskType);
        const componentsToBeReturned: JSX.Element[] = [];

        const entities = _.sortBy(task.entities, ['_type', '_id']);

        const sortedEntities = entities.reduce((previousValue: any, currentValue: any) => {
          const previousArray =
            previousValue[currentValue._type] !== undefined ? previousValue[currentValue._type] : [];
          previousValue[currentValue._type] = [...previousArray, currentValue];
          return previousValue;
        }, {});

        Object.keys(sortedEntities).forEach((entityKey: string) => {
          let fields: FieldSet;
          fields = selectedTicketType?.fieldSets.find((fieldSet: FieldSet) => {
            return Array.isArray(fieldSet.entityTypes) && fieldSet.entityTypes.indexOf(entityKey) !== -1;
          });

          if (fields === undefined) {
            fields = selectedTicketType?.fieldSets.find((x: FieldSet) => x.id === 'customerInfo');
          }

          componentsToBeReturned.push(
            <ErrorBoundary key={sortedEntities[entityKey].id}>
              <div className={'customer-container-color'}>
                <AccordionHeader
                  as="h4"
                  active={widgetOpen}
                  title={`${fields.displayName} (${sortedEntities[entityKey].length})`}
                  icon="address card"
                  key={sortedEntities[entityKey].id}
                >
                  {sortedEntities[entityKey].map((obj: any) => {
                    return (
                      <CustomerContainer
                        fireSearch={(value: any, type: string) => {
                          const searchFormat = [
                            {
                              value: value,
                              datagroup: 'entity',
                              name: type,
                              object: false,
                              param: type,
                              text: ''
                            }
                          ];
                          const searchParams = formatSearch(searchFormat, this.props.userData.UID);

                          /**
                           * Logic implementation should be checked
                           * Params aren't matching destination arguments
                           */
                          this.props.fireTicketSearch(searchParams);
                        }}
                        generalDisable={!this.props.userData.permissions.includes('searchEntities')}
                        getEntityById={this.props.getEntityById}
                        personalData={this.props.userData}
                        onDetachEntity={(body: object) => {
                          this.props.removeEntityFromCase(this.props.task.id, body);
                        }}
                        onAttachEntity={(body: any) => {
                          body.taskType = this.props.task.taskType;
                          this.props.addEntityToCase(this.props.task.id, body);
                        }}
                        onSave={(updateArgs: UpdateEntityDetail) => {
                          this.props.updateEntityDetails(this.props.task.id, this.props.task.taskType, updateArgs);
                        }}
                        onSearchPreviousTickets={(_id: string, _type: string) => {
                          this.props.onSearchPreviousTickets(obj.data._id, _type);
                        }}
                        fields={fields[fields.id] || []}
                        additionalCustomerFieldSets={fields.additionalFieldSets}
                        values={[obj] || []}
                        task={this.props.task}
                        ticketTypes={this.props.ticketTypes}
                        entityType={entityKey}
                      />
                    );
                  })}
                </AccordionHeader>
              </div>
            </ErrorBoundary>
          );
        });

        return componentsToBeReturned;
      }
      case 'FieldSets':
        return this.fieldSetNames.map((fieldSetName, index) => {
          const classNameArray = fieldSetName.split(' ');
          let className = '';
          classNameArray.forEach((word, i) => {
            word = word.toLowerCase();
            if (i + 1 <= classNameArray.length) {
              word += '-';
            }
            className += word;
          });
          className += 'color';

          if (
            this.props.task.case[fieldSetName] !== undefined &&
            this.props.task.case[fieldSetName].type === 'collection'
          ) {
            return (
              <CollectionFieldSets
                taskCase={this.props.task.case}
                taskId={this.props.task.id}
                widgetOpen={widgetOpen}
                i={ind}
                fieldMap={this.fieldMap}
                className={className}
                index={index}
                fieldSetName={fieldSetName}
              />
            );
          } else {
            return (
              <div className={className} key={index}>
                <AccordionHeader key={index} as="h4" active={widgetOpen} title={fieldSetName} icon="info circle">
                  {this.entitySearchButton && (
                    <Button
                      icon={true}
                      color="blue"
                      labelPosition="left"
                      onClick={() => this.fireCaseInfoSearch(this.fieldMap.get(fieldSetName), task.case)}
                    >
                      <Icon name="search" />
                      {this.entitySearchButton}
                    </Button>
                  )}

                  <Info
                    onSave={(editedField: any, editedValue: any) => {
                      this.props.updateTicketCaseDetails(
                        this.props.task.id,
                        editedField,
                        editedValue,
                        this.detailGroupMap.get(fieldSetName)
                      );
                    }}
                    generalDisable={!this.props.userData.permissions.includes('modifyCaseDetails')}
                    fields={this.fieldMap.get(fieldSetName) || []}
                    values={task.case || {}}
                    fireSearch={(value: any, type: string) => {
                      const searchFormat = [
                        {
                          value: value,
                          datagroup: 'detail',
                          name: type,
                          object: false,
                          param: type,
                          text: ''
                        }
                      ];
                      const searchParams = formatSearch(searchFormat, this.props.userData.UID);
                      this.props.fireTicketSearch(searchParams, undefined, undefined);
                    }}
                  />
                </AccordionHeader>
              </div>
            );
          }
        });
      case 'Delegations': {
        const taskTicketType = this.props.ticketTypes.find((x: any) => x.name === this.props.task.taskType);
        const delegates = _.filter(task.delegatedTo, (delegate) => {
          return delegate !== 'USR1';
        });
        return (
          <AccordionHeader
            as="h4"
            active={widgetOpen}
            title={`${tr('CASE_TITLE_DELEGATIONS')} (${delegates.length})`}
            icon="add user"
          >
            <Delegates
              autosuggestions={this.props.autosuggestions}
              user={this.props.user}
              usersList={this.props.usersList}
              delegates={task.delegatedTo}
              onAdd={(usr: string) => {
                this.handleDelegateChange(usr, 'POST');
              }}
              onRemove={(usr: string) => {
                this.handleDelegateChange(usr, 'DELETE');
              }}
              onGroupAdd={(usrGroup: number[]) => {
                this.handleGroupDelegateChange(usrGroup, 'POST');
              }}
              ticketType={taskTicketType}
              userData={this.props.userData}
            />
          </AccordionHeader>
        );
      }
      case 'AssetInfo':
        return (
          this.assetInfoFields !== undefined && (
            <AssetContainer
              onSave={(editedField: any, editedValue: any, detailGroup: any) => {
                this.props.updateTicketCaseDetails(this.props.task.id, editedField, editedValue, detailGroup);
              }}
              fields={this.assetInfoFields}
              fieldSetName={this.assetInfoDisplayName}
              detailGroup={this.detailGroupMap.get(this.assetInfoDisplayName)}
              values={task.case || {}}
              task={this.props.task}
              organizationTag={'CAT86'}
              assetTypeTag={'CAT87'}
              showAssetMeta={true}
            />
          )
        );
      case 'TicketRelations':
        return <InfoPageRelations widgetOpen={widgetOpen} task={task} />;
      case 'Tags':
        return <TagsWidget displayName={tr('CASE_TITLE_TAGS')} widgetOpen={widgetOpen} />;
      case 'ExternalLinks':
        return (
          this.props.externalLinks &&
          this.props.externalLinks.map((linklist: any) => {
            if (
              linklist.ticketTypes &&
              linklist.ticketTypes.find((type: any) => {
                return type === task.taskType;
              })
            ) {
              return (
                <AccordionHeader
                  as="h4"
                  active={widgetOpen}
                  title={linklist.listname}
                  icon="info circle"
                  key={linklist._id}
                >
                  <ExternalLinksList links={linklist.links} task={task} />
                </AccordionHeader>
              );
            } else if (linklist.ticketTypes === undefined) {
              return (
                <AccordionHeader
                  as="h4"
                  active={widgetOpen}
                  title={linklist.listname}
                  icon="info circle"
                  key={linklist._id}
                >
                  <ExternalLinksList links={linklist.links} task={task} />
                </AccordionHeader>
              );
            } else {
              return <div />;
            }
          })
        );
      case 'Attachments': {
        const notDeprecatedAttachments = _.filter(task.attachments, (att) => {
          return !att.deprecated;
        });
        return (
          <AccordionHeader
            as="h4"
            active={widgetOpen}
            title={`${tr('CASE_TITLE_ATTACHMENTS')} (${notDeprecatedAttachments.length})`}
            icon="attach"
          >
            <Attachments
              user={this.props.user}
              onUpload={(files: any) => {
                files.forEach((file: File) => {
                  const data = new FormData();
                  data.append('attachments', file);
                  this.props.uploadFile(task.id, data);
                });
              }}
              dropZoneEnabled={this.props.userData.permissions.includes('updateContent')}
              onEdit={(attachmentId: string, body: any) => {
                this.props.editAttachment(task.id, attachmentId, body);
              }}
              onDeprecate={(attachmentId: string) => {
                this.props.deprecateAttachment(task.id, attachmentId);
              }}
              onUnDeprecate={(attachmentId: string) => {
                this.props.unDeprecateAttachment(task.id, attachmentId);
              }}
              attachments={task.attachments}
            />
          </AccordionHeader>
        );
      }
      default:
        return '';
    }
  };

  render() {
    if (this.props.task === undefined) {
      return LoaderComponent;
    }
    const task = this.props.task;

    return (
      <ErrorBoundary>
        <div id={this.props.mobileMode ? 'mobileElement topelement' : 'topelement'}>
          <Grid
            columns={2}
            divided={true}
            stackable={true}
            className={this.props.mobileMode ? 'mobileElement detailViewScrollContainer' : 'detailViewScrollContainer'}
          >
            <Grid.Column className={this.props.mobileMode ? 'case-left-side-mobile' : 'case-left-side'} width={10}>
              <div>
                <TopBarGeneralInfo contentType="infopage" />
                {this.props.mobileMode && (
                  <TopBarStatusButtons contentType="infopage" statusOptions={this.taskStatusOptions} />
                )}

                {this.props.userData.permissions.includes('updateContent') && <CaseContentEditor />}

                <Comments senderEmails={this.senderEmails} mobileMode={this.props.mobileMode} />

                {this.props.userData.permissions.includes('updateContent') && (
                  <>
                    <Header as="h3">
                      <Icon name="talk" />
                      {tr('CASE_ANSWER')}
                    </Header>

                    <ErrorBoundary>
                      <DraftsContainer />
                    </ErrorBoundary>
                  </>
                )}
              </div>
            </Grid.Column>

            <Grid.Column
              width={6}
              style={{ padding: 0 }}
              className={this.props.mobileMode ? 'case-right-side-mobile' : 'case-right-side'}
            >
              <div>
                {!this.props.mobileMode && (
                  <TopBarStatusButtons contentType="infopage" statusOptions={this.taskStatusOptions} />
                )}

                <ErrorBoundary>
                  {this.props.widgetOrder.map((widget, i) => (
                    <React.Fragment key={i}>{this.getWidget(widget, task, i)}</React.Fragment>
                  ))}
                </ErrorBoundary>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withRouter(InfopageCase);
