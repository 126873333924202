import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { State } from 'src/types/initialState';
import { Priority } from 'src/types/Priority';
import { Ticket } from 'src/types/Ticket';
import AccordionHeader from '../AccordionHeader';

interface PriorityProps extends PriorityReduxProps {
  task: Ticket;
  widgetOpen: boolean;
  ticketPriorityLevels: Partial<Priority>[];

  handlePriorityChange(priority: Priority): void;

  ind: number;
}

const PriorityWidget = ({
  task,
  ticketPriorityLevels,
  widgetOpen,
  handlePriorityChange,
  userData,
  ind
}: PriorityProps) => {
  const { t } = useTranslation();

  const priorities = ticketPriorityLevels
    .filter((priority) => priority.active || priority.value === task.priority)
    .map((priority) => ({
      text: priority.text,
      value: priority.value,
      icon: priority.icon,
      color: priority.color,
      disabled: !Boolean(priority.active)
    }));

  return (
    <AccordionHeader as="h4" active={widgetOpen} title={t('CASE_PRIORITY')} icon="inbox" key={`case-priority-${ind}`}>
      <Dropdown
        disabled={!userData.permissions.includes('updateContent')}
        selectOnBlur={false}
        value={task.priority}
        selection={true}
        id="priorityDropdown"
        onChange={(event: any, data: any) => {
          handlePriorityChange(data.value);
        }}
        options={priorities}
      />
    </AccordionHeader>
  );
};

const connector = connect((state: State) => ({
  userData: state.userData
}));

interface PriorityReduxProps extends ConnectedProps<typeof connector> {}

export default connector(PriorityWidget);
