import * as React from 'react';
import { connect } from 'react-redux';
import UserTimeLogs from '../../api/UserTimeLog/UserTimeLogs';
import { consoleLogUserTimeLogs } from '../../api/UserTimeLog/utils';
import { Ticket } from '../../types/Ticket';
import { ActiveTimeTrackingOptions, getTimeTrackingActionFromState } from './activeTimeTracking';

function mapStateToProps(state: any): ActiveTimeTrackingOptions {
  return {
    workStatus: state.workStatus.status,
    UID: state.userData.UID,
    activeTicketId: state.activeTicketTab,
    ticketDetails: state.detailedTickets.find((ticket: Ticket) => ticket.id === state.activeTicketTab)
  };
}

class ActiveTimeTrackingHandler extends React.Component<ActiveTimeTrackingOptions> {
  componentDidMount() {
    consoleLogUserTimeLogs('[userTimeLogs]: withActiveHandlingTracker componentDidMount', this.props);
    UserTimeLogs.onChangeActivelyHandledTicket('componentDidMount', getTimeTrackingActionFromState(this.props));
  }

  componentDidUpdate() {
    consoleLogUserTimeLogs('[userTimeLogs]: withActiveHandlingTracker componentDidUpdate', this.props);
    UserTimeLogs.onChangeActivelyHandledTicket('componentDidUpdate', getTimeTrackingActionFromState(this.props));
  }

  componentWillUnmount() {
    consoleLogUserTimeLogs('[userTimeLogs]: withActiveHandlingTracker componentWillUnmount', this.props);
    UserTimeLogs.onChangeActivelyHandledTicket('componentWillUnmount', getTimeTrackingActionFromState(this.props));
  }

  render() {
    return null;
  }
}

export default connect<ActiveTimeTrackingOptions>(mapStateToProps)(ActiveTimeTrackingHandler);
