import React from 'react';
import Axios from 'axios';
import { t } from 'i18next';
import iziToast from 'izitoast';
import fileDownload from 'js-file-download';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dropdown, Icon, List, Popup } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';
import type { DropdownItemProps, SemanticICONS } from 'semantic-ui-react';

import ApiConfig from 'src/api/ApiConfig';
import { openAttachmentsPreview } from 'src/actions/attachmentsActions';
import { getAttachmentSize } from 'src/Utilities/getAttachmentSize';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { isLightboxType } from 'src/Utilities/lightbox';
import type { Attachment } from 'src/types/Ticket';
import type { PersonalData } from 'src/types/User';

interface AttachmentProps extends Attachment, AttachmentItemReduxProps {
  key: string;
  personalData: PersonalData;

  onEdit: (id: string, body: AttachmentEdit) => void;
  onUpload: (files: File[]) => void;
  onDeprecate: (id: string) => void;
  onUnDeprecate: (id: string) => void;
  openFileDialog(event: React.MouseEvent<HTMLDivElement>, data: DropdownItemProps): void;
}

export interface AttachmentEdit {
  isQuarantined: boolean;
}

class AttachmentItem extends React.Component<AttachmentProps> {
  private editAttachment = (body: AttachmentEdit) => {
    this.props.onEdit(this.props.id, body);
  };

  private deprecateAttachment = () => {
    this.props.onDeprecate(this.props.id);
  };

  private unDeprecateAttachment = () => {
    this.props.onUnDeprecate(this.props.id);
  };

  private getAttachmentLink = () => ApiConfig.getConfig().API_URL + '/file/' + this.props.uri;

  private getPreviewLink = () => ApiConfig.getConfig().API_URL + '/file/' + this.props.previewUri;

  private downloadAttachment = () => {
    Axios.get(this.getAttachmentLink(), { responseType: 'blob', timeout: 0 }).then((res) => {
      fileDownload(res.data, this.props.fileName);
    });
  };

  private openInNewWindow = () => {
    window.open(this.getAttachmentLink(), '_blank')?.focus();
  };

  private proceedAttachment = () => {
    isLightboxType(this.props) ? this.props.openAttachmentsPreview(this.props.id) : this.openInNewWindow();
  };

  private handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (this.props.isQuarantined && this.props.personalData.permissions.includes('getQuarantinedAttachments')) {
      iziToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        backgroundColor: 'salmon',
        overlayClose: true,
        title: t('OPEN_ATTACHMENT_PROMPT_TITLE'),
        message: t('OPEN_ATTACHMENT_PROMPT_MESSAGE'),
        position: 'center',
        buttons: [
          [
            `<button><b>${t('YES')}</b></button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              this.proceedAttachment();
            },
            true
          ],
          [
            `<button>${t('GENERAL_CANCEL')}</button>`,
            function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            },
            false
          ]
        ]
      });
    } else if (this.props.isQuarantined && !this.props.personalData.permissions.includes('accessQuarantineFiles')) {
      return iziToast.error({
        message: t('OPEN_ATTACHMENT_PROMPT_NO_ACCESS')
      });
    } else if (!this.props.isQuarantined) {
      this.proceedAttachment();
    }
  };

  private getFileIcon = (): SemanticICONS => {
    if (this.props.isQuarantined) {
      return 'ban';
    } else {
      let fileCategory = '';
      let fileType = '';
      if (this.props.fileType) {
        [fileCategory, fileType] = this.props.fileType.split('/');
      }
      switch (fileCategory.toUpperCase()) {
        case 'IMAGE':
          return 'file image outline';
        case 'TEXT':
          switch (fileType.toUpperCase()) {
            case 'PLAIN':
              return 'file text outline';
            default:
              return 'file code outline';
          }
        case 'ARCHIVE':
          return 'file archive outline';
        case 'AUDIO':
          return 'file audio outline';
        case 'VIDEO':
          return 'file video outline';
        case 'APPLICATION':
          switch (fileType.toUpperCase()) {
            case 'PDF':
              return 'file pdf outline';
            case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT':
              return 'file word outline';
            default:
              return 'file outline';
          }
        default:
          return 'file outline';
      }
    }
  };

  render() {
    const { deprecated, isQuarantined } = this.props;
    const attachmentStatusIcon = deprecated ? 'add' : 'delete';
    const attachmentStatusLabelText = deprecated ? t('ATTACHMENT_ACTIVATE') : t('ATTACHMENT_DEPRECATE');
    const attachmentActiveOnClick = deprecated ? this.unDeprecateAttachment : this.deprecateAttachment;

    const attachmentQuarantineIcon = isQuarantined ? 'check cicrle' : 'dont';
    const attachmentQuarantineTextKey = isQuarantined ? 'ATTACHMENT_RELEASE_QUARANTINE' : 'ATTACHMENT_SET_QUARANTINE';
    const attachmentQuarantineOnClick = () => {
      this.editAttachment({
        isQuarantined: isQuarantined ? false : true
      });
    };

    return (
      <Translation ns="translations">
        {(tr) => (
          <List.Item key={this.props.id}>
            <List.Content floated="right">
              <Dropdown icon="chevron down" selectOnBlur={false} floating={true} button={true} className="icon">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item disabled={true} icon="edit" text={tr('ATTACHMENT_EDIT')} />
                  {isLightboxType(this.props) && !isQuarantined && (
                    <Dropdown.Item
                      icon="window restore"
                      text={tr('ATTACHMENT_OPEN_IN_NEW_TAB')}
                      onClick={this.openInNewWindow}
                    />
                  )}
                  <Dropdown.Item icon="download" text="Download" onClick={this.downloadAttachment} />
                  <Dropdown.Item
                    icon={attachmentStatusIcon}
                    text={attachmentStatusLabelText}
                    onClick={attachmentActiveOnClick}
                  />
                  <Dropdown.Item
                    icon={attachmentQuarantineIcon}
                    disabled={!this.props.personalData.permissions.includes('modifyQuarantineAttachments')}
                    text={tr(attachmentQuarantineTextKey)}
                    onClick={attachmentQuarantineOnClick}
                  />
                  <Dropdown.Item
                    disabled={true}
                    icon="upload"
                    text={tr('ATTACHMENT_UPLOAD_NEW')}
                    onClick={this.props.openFileDialog}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </List.Content>

            <List.Icon
              name={this.getFileIcon()}
              style={{ color: this.props.isQuarantined ? 'red' : '' }}
              verticalAlign="middle"
              size="large"
            />

            <List.Content style={{ maxWidth: '50px' }}>
              <List.Header>
                <a
                  style={{ wordWrap: 'break-word', color: this.props.isQuarantined ? 'red' : '' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={(e) => this.handleLinkClick(e)}
                  href="#"
                >
                  {`${this.props.fileName} `}
                  <Icon size="small" name="external" />
                </a>

                <Popup
                  on="hover"
                  disabled={!this.props.previewUri}
                  trigger={<Icon name="magnify" size="small" />}
                  content={<img src={this.getPreviewLink()} />}
                />
              </List.Header>

              <List.Description>
                {getPrettyDate(this.props.uploaded, { format: DATE_TIME_FORMAT })}
                <span style={{ float: 'right', marginLeft: '10px' }}>{getAttachmentSize(this.props.size)}</span>
              </List.Description>
            </List.Content>
          </List.Item>
        )}
      </Translation>
    );
  }
}

const connector = connect(undefined, { openAttachmentsPreview });

type AttachmentItemReduxProps = ConnectedProps<typeof connector>;

export default connector(AttachmentItem);
