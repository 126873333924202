import { createReducer } from '@reduxjs/toolkit';
import { openAttachmentsPreview, closeAttachmentsPreview } from '../actions/attachmentsActions';

const initialState: AttachmentsState = { previewAttachments: false };

export interface AttachmentsState {
  previewAttachments: boolean;
  attachmentId?: string;
}

const attachmentsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(openAttachmentsPreview, (draft, action) => {
      draft.previewAttachments = true;
      draft.attachmentId = action.payload;
    })
    .addCase(closeAttachmentsPreview, (draft) => {
      draft.previewAttachments = false;
      delete draft.attachmentId;
    });
});

export default attachmentsReducer;
