import classNames from 'classnames';
import type { ChangeEvent, KeyboardEvent, VFC } from 'react';
import React, { useState } from 'react';
import { Checkbox, Input, Table } from 'semantic-ui-react';
import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';
import type { Field } from 'src/types/Info';
import type { TicketType } from '../../../../types/TicketType';
import EntityApi from '../../../../api/EntityApi';

interface DefaultInfoFieldProps extends Pick<InfoFieldProps, 'onSaveData' | 'setValue'> {
  fields: Field[];
  field: Field;
  fieldValue: string;
  disabled: boolean;
  mongoSaveDisabled: boolean;
  integrationSaveDisabled: boolean;
  inputType: 'text' | 'number';
  ticketType: TicketType;
  taskId: string | number;
}

const DefaultInfoField: VFC<DefaultInfoFieldProps> = ({
  fields,
  field,
  fieldValue,
  disabled,
  inputType,
  mongoSaveDisabled,
  integrationSaveDisabled,
  onSaveData,
  setValue,
  ticketType,
  taskId
}) => {
  const [openInfoCheckboxActive, setOpenInfoCheckboxActive] = useState(new Set<string>());
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });
  let openField: Field | null | undefined = null;
  if (ticketType?.openInfoCheckbox) {
    openField = fields.find((field) => field.value === ticketType.openInfoCheckbox?.fieldValue);
  }

  const shouldRenderCheckbox = field.name && field.params?.openInfoCheckbox && openField;
  return (
    <Table.Cell width={10}>
      <div>
        <Input
          key={`info-field-${field.name}-${field.value}`}
          id={field.name}
          name={field.name}
          type={inputType}
          disabled={disabled}
          className={className}
          style={{
            width: shouldRenderCheckbox ? '75%' : undefined,
            display: shouldRenderCheckbox ? 'inline-flex' : undefined
          }}
          size="mini"
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              if (!integrationSaveDisabled || !mongoSaveDisabled) {
                onSaveData?.(field, e.currentTarget.value);
              }
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (!integrationSaveDisabled || !mongoSaveDisabled) {
              setValue?.(field, e.target.value);
            }
          }}
          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
            if (!integrationSaveDisabled || !mongoSaveDisabled) {
              onSaveData?.(field, e.target.value);
            }
          }}
          fluid={true}
          value={fieldValue || ''}
        />

        {shouldRenderCheckbox && (
          <Checkbox
            style={{ display: 'inline-flex', marginLeft: '10px', paddingTop: '4px' }}
            checked={openInfoCheckboxActive.has(field.name!)}
            onChange={(e, data) => {
              if (!field.name) {
                return;
              }

              const checked = new Set<string>([...openInfoCheckboxActive]);
              if (data.checked) {
                checked.add(field.name);
              } else {
                checked.delete(field.name);
              }

              if (ticketType?.openInfoCheckbox && checked.size >= ticketType.openInfoCheckbox?.threshold) {
                EntityApi.verify(taskId.toString(), [...checked]);
                setOpenInfoCheckboxActive(new Set<string>());
              } else {
                setOpenInfoCheckboxActive(checked);
              }
            }}
          />
        )}
      </div>
    </Table.Cell>
  );
};

export default DefaultInfoField;
