import iziToast from 'izitoast';
import * as React from 'react';
import { Translation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

import ErrorBoundary from '../../ErrorBoundary';
import AccordionHeader from '../Case/AccordionHeader';
import Info from '../Case/Info/Info';
import EntityStatus from './EntityStatus';
import isEntityReadOnly from 'src/Utilities/isEntityReadOnly';
import type { Tag } from 'src/types/Tag';
import type { Entity, EntityResponse, Ticket } from 'src/types/Ticket';
import type { FieldSet, TicketType } from 'src/types/TicketType';
import type { PersonalData } from 'src/types/User';
import type { Field } from 'src/types/Info';

interface CustomerContainerProps {
  fields: Field[];
  additionalCustomerFieldSets: any;
  values: any;
  ticketTypes: TicketType[];
  entityType: string;
  task: Ticket;
  personalData?: PersonalData;
  generalDisable?: boolean;

  // TODO: typing
  onSave: Function;
  onAttachEntity: Function;
  onDetachEntity: Function;
  onSearchPreviousTickets: Function;
  getEntityById?: Function;
  fireSearch?(...args: any[]): any;
}

interface GetValueByDisplayFieldArgs {
  fieldSet?: FieldSet;
  entityData?: object;
  fieldName: 'name1' | 'name2' | 'name3';
}

class CustomerContainer extends React.Component<CustomerContainerProps, {}> {
  private detachCustomer = (_id: string, _type: string) => {
    this.props.onDetachEntity({ _id, _type });
  };

  private getEntityIcon = (entity: EntityResponse) => {
    if (!entity.data) {
      return undefined;
    }

    return entity.data.mongoCustomer ? 'eeedoCustomer' : 'integrationCustomer';
  };

  private getValueByDisplayField = (params: GetValueByDisplayFieldArgs): string => {
    if (!params.fieldSet || !params.entityData) {
      return '';
    }

    const field = params.fieldSet.customerInfo?.find((field) => field.displayField === params.fieldName);

    if (!field) {
      return '';
    }

    const value = params.entityData[field.value.toString()];

    if (value === null || value === undefined) {
      return '';
    }

    return value.toString();
  };

  private getDisplayedComponent = () => {
    const entities: EntityResponse[] = this.props.values;
    const selectedTicketType = this.props.ticketTypes.find((x) => x.name === this.props.task.taskType);
    const matchTicketType = this.props.values[0]._type;

    const correctFieldSet: FieldSet | undefined = selectedTicketType?.fieldSets.find(({ id, entityTypes }: FieldSet) =>
      Array.isArray(entityTypes) ? entityTypes.indexOf(matchTicketType) !== -1 : id === 'customerInfo'
    );

    return entities.map((entity, index) => {
      const name1Value = this.getValueByDisplayField({
        fieldName: 'name1',
        fieldSet: correctFieldSet,
        entityData: entity.data
      });

      const name2Value = this.getValueByDisplayField({
        fieldName: 'name2',
        fieldSet: correctFieldSet,
        entityData: entity.data
      });

      const name3Value = this.getValueByDisplayField({
        fieldName: 'name3',
        fieldSet: correctFieldSet,
        entityData: entity.data
      });

      return (
        <Translation ns="translations" key={`customer-container-${index}`}>
          {(t) => (
            <div className="ui fluid card">
              <AccordionHeader
                id={`customer-container-accordion-${index}`}
                key={`customer-container-accordion-${index}`}
                as="h4"
                active={true}
                title={`${name1Value} ${name2Value} ${name3Value}`}
                icon="user circle"
                customIconClassname={this.getEntityIcon(entity)}
              >
                <div className="content">
                  {(entity.hasAccess !== false || entity.hasAccess === undefined) && (
                    <React.Fragment>
                      <div className="customerButtonContainer">
                        <Button
                          disabled={this.props.generalDisable}
                          icon={true}
                          color="blue"
                          labelPosition="left"
                          className="customerShowTicketsBtn"
                          onClick={() => this.props.onSearchPreviousTickets(String(entity._id), this.props.entityType)}
                        >
                          <Icon name="search" />
                          {t('ADD_CUSTOMER_SHOW_CUSTOMER_TICKETS')}
                        </Button>
                        <Button
                          disabled={this.props.generalDisable}
                          icon={true}
                          color="red"
                          labelPosition="left"
                          className="customerDetachInfoBtn"
                          onClick={() => {
                            iziToast.warning({
                              title: t('ADD_CUSTOMER_CHANGE_NOTIFICATION_TITLE'),
                              message: t('ADD_CUSTOMER_CHANGE_NOTIFICATION_DESCRIPTION'),
                              timeout: 20000,
                              close: true,
                              overlay: true,
                              closeOnEscape: true,
                              zindex: 999,
                              position: 'center',
                              buttons: [
                                [
                                  '<button>' + t('YES') + '</button>',
                                  (instance, toast) => {
                                    this.detachCustomer(entity._id, this.props.entityType);
                                    instance.hide(
                                      {
                                        message: '',
                                        transitionOut: 'fadeOut'
                                      },
                                      toast,
                                      'button'
                                    );
                                  },
                                  true
                                ],
                                [
                                  '<button>' + t('NO') + '</button>',
                                  (instance, toast) => {
                                    instance.hide(
                                      {
                                        message: '',
                                        transitionOut: 'fadeOut'
                                      },
                                      toast,
                                      'button'
                                    );
                                  },
                                  true
                                ]
                              ]
                            });
                          }}
                        >
                          <Icon name="delete" />
                          {t('ENTITY_INFORMATION_DETACH')}
                        </Button>
                        <Button
                          style={{ float: 'right', marginLeft: 'auto' }}
                          icon={true}
                          color="grey"
                          disabled={this.props.generalDisable}
                          className="customerDetachInfoBtn"
                          onClick={() => {
                            if (this.props.getEntityById) {
                              this.props.getEntityById(
                                this.props.task.id,
                                this.props.task.taskType,
                                entity._id,
                                entity._type
                              );
                            }
                          }}
                        >
                          <Icon name="redo alternate" />
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                  <EntityStatus
                    entity={entity}
                    getEntityById={(_id: string, _type: string) => {
                      if (this.props.getEntityById) {
                        this.props.getEntityById(this.props.task.id, this.props.task.taskType, _id, _type);
                      }
                    }}
                  >
                    <React.Fragment>
                      <Info
                        entity={entity}
                        generalDisable={this.props.generalDisable}
                        editDisable={isEntityReadOnly(entity, selectedTicketType)}
                        fireSearch={this.props.fireSearch}
                        disableInputIfNotMongoCustomer={true}
                        onSave={this.onSaveData}
                        taskId={this.props.task.id}
                        fields={correctFieldSet ? correctFieldSet[correctFieldSet.id] : this.props.fields}
                        values={entity.data}
                        taskType={this.props.task.taskType}
                        searchableFields={false}
                      />
                      {this.getAdditionalCustomerFields(this.props.task, entity)}
                    </React.Fragment>
                  </EntityStatus>
                </div>
              </AccordionHeader>
            </div>
          )}
        </Translation>
      );
    });
  };

  private searchValueRecursively = (searchedObject: any, parameterArray: string[]) => {
    parameterArray.forEach((parameterKey: string) => {
      if (searchedObject !== undefined && searchedObject !== null) {
        searchedObject = searchedObject[parameterKey];
      }
    });
    return searchedObject;
  };

  private getAdditionalCustomerFields = (task: any, entity: Entity) => {
    if (this.props.additionalCustomerFieldSets !== undefined && entity !== undefined && entity['data'] !== undefined) {
      return this.props.additionalCustomerFieldSets.map((fieldSet: any, index: number) => {
        const fielsetNameClassNameArray = fieldSet.displayName.split(' ');
        let fielsetNameClassName = '';
        fielsetNameClassNameArray.forEach((word: string, i: number) => {
          word = word.toLowerCase();
          if (i + 1 <= fielsetNameClassNameArray.length) {
            word += '-';
          }
          fielsetNameClassName += word;
        });
        fielsetNameClassName += 'color';
        const subFieldset =
          entity['data'][fieldSet.displayName] ||
          entity['data'][fieldSet.id] ||
          (entity['data'].subEntities &&
            entity['data'].subEntities[
              Object.keys(entity['data'].subEntities).find((subEntityKey) => {
                if (
                  entity['data'].subEntities[subEntityKey].data &&
                  Object.keys(entity['data'].subEntities[subEntityKey].data).find((subEntityVariableKey) => {
                    return subEntityVariableKey === fieldSet.displayName || subEntityVariableKey === fieldSet.id;
                  })
                ) {
                  return true;
                } else {
                  return false;
                }
              }) || 'noMatch'
            ]?.data[fieldSet.displayName || fieldSet.id]);

        if (subFieldset !== undefined) {
          if (subFieldset.type === 'collection') {
            const fieldValueArray = subFieldset.data;
            let additionalAccordionOpen = true;
            if (fieldSet.openByDefault === false) {
              additionalAccordionOpen = false;
            }
            return (
              <div className={fielsetNameClassName} key={`customer-container-additionsl-field-${index}`}>
                <AccordionHeader
                  key={`customer-container-additional-field-accordion-${index * 10}`}
                  as="h4"
                  active={additionalAccordionOpen}
                  title={fieldSet.displayName}
                  icon="info circle"
                >
                  {fieldValueArray.map((dataSet: any, i: number) => {
                    if (entity['data'].mongoCustomer) {
                      dataSet.mongoCustomer = entity['data'].mongoCustomer;
                    }
                    const parsedAccordionTitle = subFieldset.subHeaderValue;
                    const regex = /\[(.*?)\]/;
                    let accordionTitle = '';

                    if (parsedAccordionTitle.split('|').length > 1) {
                      parsedAccordionTitle.split('|').forEach((fieldParam: any) => {
                        const fieldParamRegexTest = fieldParam.match(regex);
                        if (fieldParamRegexTest !== null && fieldParamRegexTest[1].length > 0) {
                          accordionTitle += fieldParamRegexTest[1];
                        } else if (fieldParam.toString().split('.').length > 1) {
                          accordionTitle += this.searchValueRecursively(dataSet, fieldParam.toString().split('.'));
                        } else {
                          accordionTitle += dataSet[fieldParam.toString()];
                        }
                      });
                    } else {
                      const fieldValueToString = parsedAccordionTitle.toString();
                      const fieldParamRegexTest = fieldValueToString.match(regex);
                      if (fieldParamRegexTest !== null && fieldParamRegexTest[1].length > 0) {
                        accordionTitle += fieldParamRegexTest[1];
                      } else if (fieldValueToString.split('.').length > 1) {
                        accordionTitle = this.searchValueRecursively(dataSet, fieldValueToString.split('.'));
                      } else {
                        accordionTitle = dataSet[fieldValueToString];
                      }
                    }

                    const childrenOpenByDefault =
                      fieldSet.childrenOpenByDefault !== undefined ? fieldSet.childrenOpenByDefault : true;
                    return (
                      <div className={fielsetNameClassName} key={`customer-container-info-wrapper-${index + i}`}>
                        <AccordionHeader
                          key={`customer-container-info-wrapper-accordion-${index + 10}`}
                          as="h4"
                          active={childrenOpenByDefault}
                          title={accordionTitle}
                          icon="info circle"
                        >
                          <Info
                            fields={fieldSet[fieldSet.id] || []}
                            values={dataSet || {}}
                            disableInputIfNotMongoCustomer={true}
                          />
                        </AccordionHeader>
                      </div>
                    );
                  })}
                </AccordionHeader>
              </div>
            );
          } else {
            if (entity['data'].mongoCustomer) {
              entity['data'][fieldSet.displayName].mongoCustomer = entity['data'].mongoCustomer;
            }
            return (
              <div className={fielsetNameClassName} key={`customer-container-info-wrapper-${index}`}>
                <AccordionHeader
                  key={`customer-container-info-wrapper-accordion-${index * 10 * 20}`}
                  as="h4"
                  active={true}
                  title={fieldSet.displayName}
                  icon="info circle"
                >
                  <Info
                    fields={fieldSet[fieldSet.id] || []}
                    values={subFieldset || {}}
                    generalDisable={this.props.generalDisable}
                    disableInputIfNotMongoCustomer={true}
                  />
                </AccordionHeader>
              </div>
            );
          }
        } else {
          return <div />;
        }
      });
    }
  };

  private onSaveData = (
    fieldName: string,
    valueToSave: string | boolean | Tag[] | null | undefined,
    object: any,
    partial: boolean,
    field: string,
    optionText: string,
    group?: string,
    entityType?: string,
    entityId?: string
  ) => {
    if (typeof entityId !== 'undefined' && entityType !== 'undefined') {
      this.props.onSave({
        entityId,
        entityType,
        fieldName,
        valueToSave,
        object,
        partial
      });
    }
  };

  render() {
    return <ErrorBoundary>{this.getDisplayedComponent()}</ErrorBoundary>;
  }
}

export default CustomerContainer;
