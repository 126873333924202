import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { NotificationRule } from 'src/types/User';

const API_URL_NOTIFICATION = '/user/notifications';

export default class NotificationApi {
  static createNotification = async (notificationPayload: Partial<NotificationRule>): Promise<NotificationRule> => {
    return axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}${API_URL_NOTIFICATION}`, {
        ...notificationPayload,
        conditions: JSON.stringify(notificationPayload.conditions),
        soundConfig: JSON.stringify(notificationPayload.soundConfig),
        popupConfig: JSON.stringify(notificationPayload.popupConfig)
      })
      .then((response) => {
        return response.data;
      });
  };

  static deleteNotification = async (notificationId: number): Promise<{ msg: string }> => {
    return axiosWithRetry
      .delete(`${ApiConfig.getConfig().API_URL}${API_URL_NOTIFICATION}/${notificationId}`)
      .then((response) => {
        return response.data;
      });
  };

  static updateNotification = async (
    notificationId: number,
    notificationPayload: Partial<NotificationRule>
  ): Promise<NotificationRule> => {
    return axiosWithRetry
      .patch(`${ApiConfig.getConfig().API_URL}${API_URL_NOTIFICATION}/${notificationId}`, {
        ...notificationPayload,
        conditions: JSON.stringify(notificationPayload.conditions),
        soundConfig: JSON.stringify(notificationPayload.soundConfig),
        popupConfig: JSON.stringify(notificationPayload.popupConfig)
      })
      .then((response) => {
        return response.data;
      });
  };
}
