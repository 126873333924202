import React, { useEffect, useRef } from 'react';
import { Input } from 'semantic-ui-react';
import type { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input/Input';
import type { VFC } from 'react';

interface Props {
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void;
  value: string;
}

const TopBarTitleInput: VFC<Props> = ({ value, onBlur, onChange }) => {
  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return <Input fluid ref={inputRef} type="text" value={value} onBlur={onBlur} onChange={onChange} />;
};

export default TopBarTitleInput;
