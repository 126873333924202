import Dropzone from 'react-dropzone';
import React from 'react';
import classNames from 'classnames';
import { Header, Icon } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import type { DropEvent } from 'react-dropzone';

import { MAX_ATTACHMENT_SIZE } from '../../config';
import { onDropRejected } from '../ReplyMethods/UploadErrorHandler';
import './AttachmentDropzone.css';

type UploadFunction = (acceptedOrRejected: File[], event: DropEvent) => void;

interface AttachmentDropzoneProps {
  onUpload: UploadFunction;
  dropzoneRef: any;
  enabled: boolean;
}

class AttachmentDropzone extends React.Component<AttachmentDropzoneProps> {
  render() {
    return (
      <Translation ns="translations">
        {(t) =>
          this.props.enabled ? (
            <Dropzone
              onDropAccepted={this.props.onUpload}
              ref={(node: React.ReactNode) => {
                this.props.dropzoneRef(node);
              }}
              onDropRejected={onDropRejected}
              multiple={true}
              maxSize={MAX_ATTACHMENT_SIZE}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  className={classNames('AttachmentDropzone-Basic', isDragActive && 'AttachmentDropzone-Active')}
                  {...getRootProps()}
                >
                  <input name="attachments" {...getInputProps()} />
                  <Header as="h4" icon={true}>
                    <Icon name="cloud upload" />
                    {t('ATTACHMENT_DROPZONE_TITLE')}
                    <Header.Subheader>{t('ATTACHMENT_DROPZONE_DESCRIPTION')}</Header.Subheader>
                  </Header>
                </div>
              )}
            </Dropzone>
          ) : null
        }
      </Translation>
    );
  }
}

export default AttachmentDropzone;
