import React from 'react';
import { Accordion } from 'semantic-ui-react';
import { getSearchParams, getTicket, getTicketType } from 'src/Utilities/helper';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import type { History } from 'history';

import EnreachVoiceIntegration from 'src/Utilities/intergrations/callEnreachVoice';
import {
  findPhoneConfiguration,
  getPhoneConfigTags,
  getPhoneConfigTicketType,
  parsePhoneConfiguration
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import type { CallRequestPayload } from 'src/actions/phoneActions';
import type { EnreachVoiceEvent, EnreachStatus } from 'src/Utilities/intergrations/callEnreachVoice';
import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { PersonalData } from 'src/types/User';
import type { TicketType } from 'src/types/TicketType';
import type { phoneConfiguration } from 'src/types/PhoneConfiguration';

interface EnreachVoiceProps {
  callRequests: CallRequestPayload[];
  configurations: phoneConfiguration[];
  history: History;
  isOpen: boolean;
  ticketTypes: TicketType[];
  userData: PersonalData;

  handleIncomingPhoneCall: (callObject: IntegrationData) => Promise<void>;
  setIsOpen: (active: boolean) => void;
  setEnreachVoiceLoggedIn: (loggedIn: boolean) => void;
  setEnreachVoiceStatus: (status: EnreachStatus) => void;
}

class EnreachVoiceComponent extends React.Component<EnreachVoiceProps> {
  enreachVoiceIntegration: EnreachVoiceIntegration | null = null;

  constructor(props: EnreachVoiceProps) {
    super(props);
  }

  componentDidMount() {
    this.enreachVoiceIntegration = new EnreachVoiceIntegration();

    this.enreachVoiceIntegration.on('onload', this.handleEnreachVoiceOnLoad);
    this.enreachVoiceIntegration.on('call-incoming', this.handleIncomingCall);
    this.enreachVoiceIntegration.on('logged-in', this.props.setEnreachVoiceLoggedIn);
    this.enreachVoiceIntegration.on('status', this.props.setEnreachVoiceStatus);
  }

  componentWillUnmount() {
    this.enreachVoiceIntegration?.destroy();
  }

  private handleEnreachVoiceOnLoad = () => {
    this.addCustomStyles();
  };

  private handleIncomingCall = async (event: Event) => {
    const { queueId, phoneNumber: originalPhoneNumber, callId, callDirection } = (event as EnreachVoiceEvent).detail;
    const direction = callDirection === 'outcoming' ? 'out' : 'in';

    const normalizedPhoneNumber = normalizePhoneNumber(originalPhoneNumber);
    const ticketType = getTicketType(
      queueId,
      this.props.ticketTypes,
      this.props.userData.userPreferences.defaultTicketType
    );

    const configuration = findPhoneConfiguration(this.props.configurations, queueId);
    const parsedConfiguration = parsePhoneConfiguration(configuration);
    const configTicketType = getPhoneConfigTicketType(parsedConfiguration, this.props.ticketTypes) || ticketType;
    const configTags = getPhoneConfigTags(parsedConfiguration);

    const confObject = configuration
      ? {
          ...parsedConfiguration,
          ticketType: configTicketType,
          tags: configTags
        }
      : undefined;
    const ticketData = getTicket(ticketType, confObject);
    const searchObject = getSearchParams(normalizedPhoneNumber, configTicketType);
    const UID = this.props.userData.UID;
    const history = this.props.history;

    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'enreachVoiceId',
        updateValue: callId,
        group: 'CaseDetails'
      }
    ];

    const callObject: IntegrationData = {
      normalizedPhoneNumber,
      UID,
      ticketData: {
        ...ticketData,
        direction
      },
      searchObject,
      history,
      detailsObjects,
      direction
    };

    if (!configuration || (configuration && !parsedConfiguration.noTicket)) {
      await this.props.handleIncomingPhoneCall(callObject);
    }
  };

  private addCustomStyles = () => {
    const webrtc = EnreachVoiceIntegration.getWebRTC();
    const style = document.createElement('style');
    style.innerHTML = '.phone__wrapper { width: 100vw !important };';

    const webrtcClientContainer = webrtc?.shadowRoot?.querySelector('bvfb-webrtc-client-container');
    const webrtcClient = webrtcClientContainer?.shadowRoot?.querySelector('bvfb-webrtc-client');
    webrtcClient?.shadowRoot?.prepend(style);
  };

  private toggleAccordion = () => {
    this.props.setIsOpen(!this.props.isOpen);
  };

  render() {
    return (
      <>
        <Accordion.Title className="ticketlist ticketlist_OC" active={this.props.isOpen} onClick={this.toggleAccordion}>
          Voice
        </Accordion.Title>
        <Accordion.Content active={this.props.isOpen}>
          <div>
            <iframe
              allowFullScreen={false}
              frameBorder={0}
              id="EnreachVoice"
              scrolling="yes"
              src="/enreachVoice.html"
              title="Voice"
              allow="microphone"
              style={{
                width: '100%',
                height: '552px',
                flex: '1 1 auto',
                display: 'flex'
              }}
            />
          </div>
        </Accordion.Content>
      </>
    );
  }
}

export default EnreachVoiceComponent;
