import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Dimmer, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import type { EntityResponse } from 'src/types/Ticket';

interface EntityStatusProps {
  entity: EntityResponse;
  children: any;
  getEntityById: Function;
}

const EntityStatus = (props: EntityStatusProps) => {
  if (props.entity.isLoading) {
    return (
      <Translation ns="translations">
        {(t) => (
          <Segment>
            <Loader active={props.entity.isLoading} indeterminate={true} inline="centered">
              {t('ENTITY_LOADING_DATA')}
            </Loader>
          </Segment>
        )}
      </Translation>
    );
  } else if (props.entity.hasAccess === false) {
    return (
      <Translation ns="translations">
        {(t) => (
          <Message info icon data-test="EntityStatusNoAccessMessage">
            <Icon name="key" />
            <Message.Header>
              {t('ENTITY_USER_HAS_NO_ACCESS_TITLE')}
              <p>{t('ENTITY_USER_HAS_NO_ACCESS_DESCRIPTION')}</p>
            </Message.Header>
          </Message>
        )}
      </Translation>
    );
  } else if (props.entity.errorMessage) {
    return (
      <Translation ns="translations">
        {(t) => (
          <React.Fragment>
            <Message error icon data-test="EntityStatusErrorMessage">
              <Icon name="warning sign" />
              <Message.Header>
                {`${t('ENTITY_ENCOUNTERED_ERROR_TITLE')} (${props.entity.statusCode})`}
                <p>{t('ENTITY_ENCOUNTERED_ERROR_DESCRIPTION')}</p>
              </Message.Header>
            </Message>
            <Button
              onClick={() => {
                props.getEntityById(props.entity._id, props.entity._type);
              }}
              primary={true}
              labelPosition="left"
              icon={true}
            >
              <Icon name="redo" />
              {t('INIT_LOAD_BUTTON_RETRY')}
            </Button>
          </React.Fragment>
        )}
      </Translation>
    );
  } else if (props.entity.isLoading) {
    return (
      <Translation ns="translations">
        {(t) => (
          <>
            <Dimmer inverted active={props.entity.isLoading} data-test="EntityStatusLoader">
              <Loader indeterminate content={t('ENTITY_LOADING_DATA')} />
            </Dimmer>

            {props.children}
          </>
        )}
      </Translation>
    );
  } else {
    return props.children;
  }
};

export default EntityStatus;
