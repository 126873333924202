import type { AxiosError } from 'axios';

import ApiConfig from './ApiConfig';
import type { PersonalData, User, Role, PasswordPolicy, LoginStrategy } from '../types/User';
import type { ChangePasswordData } from '../types/ApiRequests';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';

const API_URL_GET_ALL_USERS = '/usersWithData';
const API_URL_GET_USER = '/user';
const API_URL_GET_ROLES = '/roles';
const API_URL_CREATE_USER = '/users';
const API_URL_CHANGE_PASSWORD = '/password';
const API_URL_PATCH_USER_PROFILE = '/users';
const API_URL_PATCH_USER_NOTIFICATIONS = '/user/notifications';
const API_URL_USERS_PERSONAL = '/personalData';
const API_URL_PASSWORD_POLICY = '/passwordPolicy';
const API_URL_PASSWORD_POLICY_NO_AUTH = '/passwordPolicyNoAuth';

type CustomTicketOrdering = {
  fixPerTicketType: {
    id: number;
    UID: number;
    ticketTypeId: number;
    ticketTypeName: string;
    duedate_fix: number;
  }[];
};

export type CustomTicketOrderingInput = {
  fixPerTicketType: {
    ticketTypeId: number;
    duedate_fix: number;
  }[];
};

export default class UsersApi {
  static getUsers = (): Promise<User[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_GET_ALL_USERS).then((response) => response.data);
  };

  static getUser = (id: number): Promise<User> => {
    return axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL + API_URL_GET_USER}/${id}`)
      .then((response) => response.data.user);
  };

  static getRoles = (): Promise<Role[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_GET_ROLES).then((response) => response.data);
  };

  static getPersonalData = (): Promise<PersonalData> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_USERS_PERSONAL).then((response) => response.data);
  };

  static createUser = (data: any): Promise<User> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_CREATE_USER, data)
      .then((response) => response.data);
  };

  static changePassword = (data: ChangePasswordData): Promise<any> => {
    return axiosWithRetry
      .put(ApiConfig.getConfig().API_URL + API_URL_CHANGE_PASSWORD, data)
      .then((response) => response.data);
  };

  static patchUserProfile = (data: any): Promise<any> => {
    return axiosWithRetry
      .patch(ApiConfig.getConfig().API_URL + API_URL_PATCH_USER_PROFILE + '/' + data.UID + '/profile', data.data)
      .then((response) => {
        return response.data;
      });
  };

  static addTicketType = (user: number, ticketType: number) => {
    return axiosWithRetry
      .put(`${ApiConfig.apiUrl}${API_URL_PATCH_USER_PROFILE}/${user}/ticketType/${ticketType}`)
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  static removeTicketType = (user: number, ticketType: number) => {
    return axiosWithRetry
      .delete(`${ApiConfig.apiUrl}${API_URL_PATCH_USER_PROFILE}/${user}/ticketType/${ticketType}`)
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  static addChannel = (user: number, channel: number) => {
    return axiosWithRetry
      .put(`${ApiConfig.apiUrl}${API_URL_PATCH_USER_PROFILE}/${user}/channel/${channel}`)
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  static removeChannel = (user: number, channel: number) => {
    return axiosWithRetry
      .delete(`${ApiConfig.apiUrl}${API_URL_PATCH_USER_PROFILE}/${user}/channel/${channel}`)
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  static addForbiddenLoginStrategy = (user: number, strategy: LoginStrategy) => {
    return axiosWithRetry
      .put(`${ApiConfig.apiUrl}${API_URL_PATCH_USER_PROFILE}/${user}/forbiddenLoginStrategy/${strategy}`)
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  static removeForbiddenLoginStrategy = (user: number, strategy: LoginStrategy) => {
    return axiosWithRetry
      .delete(`${ApiConfig.apiUrl}${API_URL_PATCH_USER_PROFILE}/${user}/forbiddenLoginStrategy/${strategy}`)
      .catch((error: AxiosError) => {
        throw error;
      });
  };

  static patchUserNotifications = (data: any): Promise<any> => {
    return axiosWithRetry
      .put(ApiConfig.getConfig().API_URL + API_URL_PATCH_USER_NOTIFICATIONS, data.data)
      .then((response) => {
        return response.data;
      });
  };

  static getPasswordPolicy = (): Promise<PasswordPolicy> => {
    return axiosWithRetry
      .get<PasswordPolicy>(ApiConfig.getConfig().API_URL + API_URL_PASSWORD_POLICY)
      .then((response) => {
        switch (typeof response.data) {
          case 'string': {
            return JSON.parse(response.data);
          }

          default: {
            return response.data;
          }
        }
      });
  };

  static getPasswordPolicyNoAuth = (token: string, uid: number): Promise<PasswordPolicy> => {
    const url = new URL(ApiConfig.getConfig().API_URL + API_URL_PASSWORD_POLICY_NO_AUTH);
    if (token) {
      url.searchParams.set('token', token);
      url.searchParams.set('UID', uid.toString());
    }

    return axiosWithRetry.get<PasswordPolicy>(url.toString()).then((response) => response.data);
  };

  static getCustomTicketOrdering = async (uid: number): Promise<CustomTicketOrdering> => {
    const url = `${ApiConfig.getConfig().API_URL}/users/${uid}/customTicketOrdering`;
    const res = await axiosWithRetry.get(url);
    return res.data;
  };

  static setCustomTicketOrdering = async (uid: number, data: CustomTicketOrderingInput) => {
    const url = `${ApiConfig.getConfig().API_URL}/users/${uid}/customTicketOrdering`;
    await axiosWithRetry.post(url, data);
  };
}
