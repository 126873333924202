import React from 'react';
import { t } from 'i18next';
import iziToast from 'izitoast';
import { Translation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';

import { getSearchParams, getTicket, getTicketType } from '../../Utilities/helper';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import type { phoneConfiguration } from 'src/types/PhoneConfiguration';
import type { IntegrationData, UpdateDetailsObject } from '../../handlers/handlePhoneCall';
import type { TicketType } from '../../types/TicketType';
import type { PersonalData, User } from '../../types/User';

interface UrlActionsProps {
  ticketTypes: TicketType[];
  url: string;
  history: any;
  userData: PersonalData;
  match: any;
  configurations: any;
  user: User;
  handleIncomingPhoneCall(data: IntegrationData): Promise<void>;
}

export default class UrlActions extends React.Component<UrlActionsProps> {
  componentDidMount() {
    const { params } = this.props.match;
    const url = new URL(window.location.href).searchParams;
    if (this.props.user.role.name.includes('readOnly')) {
      iziToast.error({
        message: t('PHONECONFIGURATION_READONLY_ROLE_LIMIT'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
      return null;
    }
    switch (params.type) {
      case 'phoneContact': {
        const phoneNumber = decodeURIComponent((window.location.search.split('phoneNumber=')[1] || '').split('&')[0]);
        const serviceName = decodeURIComponent((window.location.search.split('serviceName=')[1] || '').split('&')[0]);
        const direction = decodeURIComponent((window.location.search.split('direction=')[1] || '').split('&')[0]);
        const ocTicketId = url.get('ocTicketId');
        return this.handleIncomingPhoneCall(phoneNumber, serviceName, ocTicketId, direction);
      }
      default: {
        return null;
      }
    }
  }

  escapeRegExp = (s: string) => {
    return s.replace(/[-/\\$*+?.()|[\]{}]/g, '\\$&');
  };

  handleIncomingPhoneCall = (
    originalPhoneNumber: string,
    lastServiceName: string | null,
    lastOCTicketId?: string | null,
    direction?: string | null
  ) => {
    const normalizedPhoneNumber = normalizePhoneNumber(originalPhoneNumber);
    const ticketType = getTicketType(
      lastServiceName,
      this.props.ticketTypes,
      this.props.userData.userPreferences.defaultTicketType
    );

    const configuration: phoneConfiguration = this.props.configurations.find((conf: phoneConfiguration) => {
      if (lastServiceName) {
        const escapedValue = this.escapeRegExp(lastServiceName);
        const regex = new RegExp(escapedValue, 'g');
        return conf.value.match(regex);
      } else {
        return null;
      }
    });

    let configTicketType = ticketType;
    let configTags;
    let parsedConfiguration: any = {};

    if (configuration) {
      parsedConfiguration =
        typeof configuration.configuration === 'string'
          ? JSON.parse(configuration.configuration)
          : configuration.configuration;

      configTicketType =
        configuration && parsedConfiguration.taskType
          ? this.props.ticketTypes.find((tType: TicketType) => {
              return tType.id === parseInt(parsedConfiguration.taskType, 10);
            })
          : ticketType;

      configTags =
        configuration && parsedConfiguration.tags
          ? parsedConfiguration.tags.map((tag: any) => {
              return `TAG${tag}`;
            })
          : undefined;
    }

    const confObject = configuration
      ? {
          ...parsedConfiguration,
          ticketType: configTicketType,
          tags: configTags
        }
      : undefined;

    const ticketData = getTicket(ticketType, confObject);
    const searchObject = getSearchParams(normalizedPhoneNumber, configTicketType);
    const UID = this.props.userData.UID;
    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'ocTicketId',
        updateValue: lastOCTicketId,
        group: 'CaseDetails'
      }
    ];

    if (lastServiceName !== null) {
      detailsObjects.push({
        updateKey: 'ocServiceName',
        updateValue: lastServiceName,
        group: 'CaseDetails'
      });
    }

    if (configuration && parsedConfiguration.noTicket) {
      iziToast.error({
        message: t('PHONECONFIGURATION_NO_TICKET'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
    }

    if (!configuration || (configuration && !parsedConfiguration.noTicket)) {
      this.props.handleIncomingPhoneCall({
        UID,
        history: this.props.history,
        normalizedPhoneNumber,
        searchObject,
        ticketData,
        detailsObjects,
        direction: direction === 'out' ? 'out' : 'in'
      });
    }
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <div className="urlActionLoaderContainer">
            <Loader content={t('LOADING')} active inline />
          </div>
        )}
      </Translation>
    );
  }
}
