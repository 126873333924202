import React from 'react';

import type {
  SurveyQuestionAnswerAnswer,
  SurveyQuestionAnswerQuestion,
  SurveyQuestionAnswerQuestionConfiguration
} from 'src/types/Survey';
import Smiley from './Types/Smiley';
import Select from './Types/Select';

import './Survey.css';

interface SurveyAnswerProps {
  answer: SurveyQuestionAnswerAnswer;
  question: SurveyQuestionAnswerQuestion;
}

const SurveyAnswer = ({ question, answer }: SurveyAnswerProps) => (
  <div className="survey-widget-reply">
    <h4 className="survey-widget-question">{question.title}</h4>

    {renderAnswerType(question.configuration?.type, question.configuration, answer.answer)}
    <div className={`${answer.informalAnswer ? 'survey-informal' : 'hidden'}`}>{answer.informalAnswer}</div>
  </div>
);

const renderAnswerType = (
  type: string | undefined,
  data: SurveyQuestionAnswerQuestionConfiguration,
  value: number | undefined
): any => {
  if (type === 'smileys' && value !== undefined) {
    return <Smiley configuration={data} value={value} />;
  } else if ((type === 'select' || type === 'requestContact') && data !== undefined && value !== undefined) {
    return <Select type={type} data={data} value={value} />;
  }
};

export default SurveyAnswer;
