import React from 'react';

import PhoneIntegrations from '../PhoneIntegrations';
import TicketListTab from '../ticketList/TicketListTab';
import ErrorBoundary from 'src/ErrorBoundary';
import TicketListTabBarContainer from 'src/containers/TicketListTabBarContainer';
import ChannelType from '../CommentIconContent/ChannelType';
import type { PersonalData } from 'src/types/User';

interface IProps {
  userPersonalData: PersonalData;
  onSettingsPage: boolean;
}

const TicketsSidebar = (props: IProps) => {
  const userData = props.userPersonalData;
  const permissions = userData.permissions;
  const channels = userData.channels;

  if (
    !props.onSettingsPage &&
    (permissions.includes('showTicketlist') || permissions.includes('allowPhoneIntegrations'))
  ) {
    return (
      <div className="sideBar">
        <ErrorBoundary>
          <TicketListTabBarContainer />
        </ErrorBoundary>

        <div className="flexSideView">
          {permissions.includes('showTicketlist') && (
            <ErrorBoundary>
              <TicketListTab contentType="tickets" />
            </ErrorBoundary>
          )}
          {permissions.includes('allowPhoneIntegrations') && channels.includes(ChannelType.Phone) && (
            <ErrorBoundary>
              <PhoneIntegrations />
            </ErrorBoundary>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default TicketsSidebar;
