import { LOCATION_CHANGE } from 'connected-react-router';
import type { Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { ACTIVATE_TAB } from '../actions';
import { setInfopagelistFilter, clearInfopagelistFilter } from '../actions/infoPagesActionsRTK';
import { setCommentsSorting } from 'src/reducers/commentsMenuReducer';
import { clearTicketlistFilter, setTicketlistFilter, setTicketlistSorting } from '../actions/ticketListTabActionsRTK';
import { navigateToTicket } from '../Utilities/history';
import { addParamsToURL } from '../Utilities/url';
import { DefaultCommentsSorting, DefaultTicketsOrdering } from 'src/types/Sorting';
import type { State } from 'src/types/initialState';
import type { MenuTab } from 'src/types/MenuTab';
import type { TicketListTab } from 'src/types/TicketList';
import type { UrlParamsMap } from 'src/types/Url';

const [defaultDirection, defaultSorting] = DefaultTicketsOrdering;
const paramSpecificActions = [
  clearTicketlistFilter.type,
  setTicketlistFilter.type,
  setTicketlistSorting.type,
  setInfopagelistFilter.type,
  clearInfopagelistFilter.type,
  setCommentsSorting.type,
  LOCATION_CHANGE
];

export const urlSyncMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, State>) => (next) => (action) => {
  const returnValue = next(action);

  try {
    if (ACTIVATE_TAB === action.type) {
      // TODO: works but is hacky, the correct way is to use Link component from react router in TicketlistItem and Tabs
      navigateToTicket(action.payload as string);
    }

    if (paramSpecificActions.includes(action.type)) {
      const { commentsMenu, ticketListTabs, infoPageListTabs, router } = api.getState();
      const isInfopage = !!router?.location?.pathname?.includes?.('infopage');
      const tabs: MenuTab[] = isInfopage ? [...infoPageListTabs.values()] : Object.values(ticketListTabs);
      const activeTab = tabs.find((t) => t.activeTab);

      if (activeTab) {
        const params: UrlParamsMap = { ...activeTab.filters };

        const commentsSorting = commentsMenu.sorting;
        if (commentsSorting !== DefaultCommentsSorting) {
          params.comments = commentsSorting;
        }
        if ((activeTab as TicketListTab).sorting !== defaultSorting) {
          params.sorting = (activeTab as TicketListTab).sorting;
        }
        if ((activeTab as TicketListTab).direction !== defaultDirection) {
          params.direction = (activeTab as TicketListTab).direction;
        }

        addParamsToURL(params, true);
      }
    }
  } catch (error) {
    // not sure if worth to log something
  }

  return returnValue;
};
