import { t } from 'i18next';
import _ from 'lodash';
import React from 'react';
import iziToast from 'izitoast';
import Avatar from 'react-avatar';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import { Accordion, Comment, Icon, Popup } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';
import type { Action } from 'redux';
import type { Dispatch } from 'react';

import FeatureFlags from 'src/api/FeatureFlags';
import { appendToDraft, switchDraftTabIndex } from 'src/actions/draftActions';
import SuggestionEditorWidgetContainer from 'src/containers/SuggestionEditorWidgetContainer';
import CommentEditorWidgetContainer from 'src/containers/CommentEditorWidgetContainer';
import { WhatsAppCommentButtons } from '../CommentEditorWidget/WhatsAppCommentButtons';
import CommentText from './CommentText';
import UserPopup from '../User/UserPopup';
import UserAvatar from '../User/UserAvatar';
import AttachmentCard from '../Attachments/AttachmentCard';
import CopyToClipboardIcon from '../generic/CopyToClipboardIcon';
import CommentIcon from '../CommentIcon/CommentIcon';
import ErrorBoundary from 'src/ErrorBoundary';
import { ModalWindow } from '../Versions/DisplayWindow';
import CommentIconContent from '../CommentIconContent/CommentIconContent';
import HeaderEmailPreview from '../CommentIconContent/HeaderEmailPreview';
import { parseContent } from 'src/Utilities/parseUtils';
import { sanitizeHTML } from 'src/Utilities/sanitize';
import { hasImageExtension } from 'src/Utilities/images';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getCommentAttachmentsIds, getCommentSender } from 'src/Components/Utilities/comments';
import { Channels } from 'src/types/Channel';
import ChannelType from '../CommentIconContent/ChannelType';
import type { User } from 'src/types/User';
import type { Channel } from 'src/types/Channel';
import type { ReplyTabIndex } from 'src/types/Drafts';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { Comment as TicketComment, Ticket } from 'src/types/Ticket';

import './UserComment.css';

interface OwnProps extends Partial<TicketComment> {
  user: User;
  task: Ticket;
  taskId: string;
  replyToEmailEnabled: boolean;
  channels: Channel[];
  title: string | null;
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  botButtonClickedState: undefined | TicketComment;
  replyTabIndexList: ReplyTabIndex[];
  isLastComment?: boolean;
}

interface UserCommentProps extends ConnectedProps<typeof connector>, OwnProps {}

interface CommentState {
  isCommentExpanded: boolean;
  isIntegratorUserReply: boolean;
  integratorUserReplyName: string | null;
  parsedContent: string;
  isMetadataSourceOpen: boolean;
  isImageAccordionOpen: boolean;
}

class UserComment extends React.PureComponent<UserCommentProps, CommentState> {
  // TODO: move to the component state
  private chatComment: boolean;
  private commentSentReceivedClass: 'sentComment' | 'receivedComment' | 'suggestionComment' | '';
  private channelTypeName: string;
  private suggestionComment: boolean;

  constructor(props: UserCommentProps) {
    super(props);
    this.state = {
      isCommentExpanded: false,
      isIntegratorUserReply: false,
      integratorUserReplyName: null,
      parsedContent: '',
      isMetadataSourceOpen: false,
      isImageAccordionOpen: false
    };

    if (this.props.type === 'suggestion') {
      this.commentSentReceivedClass = '';
      this.suggestionComment = true;
    } else {
      switch (this.props.direction) {
        case 'out':
          this.commentSentReceivedClass = 'sentComment';
          break;
        case 'in':
          this.commentSentReceivedClass = 'receivedComment';
          break;
        default:
          this.commentSentReceivedClass = '';
          break;
      }
    }

    switch (this.props.channel) {
      case ChannelType.Email:
        this.channelTypeName = 'email';
        break;
      case ChannelType.Sms:
        this.channelTypeName = 'sms';
        break;
      case ChannelType.Internal:
        this.channelTypeName = 'internal';
        break;
      case ChannelType.Chat:
        this.channelTypeName = 'chat';
        break;
      case ChannelType.Webform:
        this.channelTypeName = 'webform';
        break;
      default:
        this.channelTypeName = 'other';
        break;
    }

    this.chatComment =
      props.content?.substring(0, 22) === '[customer_closed_chat]' ||
      props.content?.substring(0, 31) === '[chat_was_closed_automatically]';
  }

  componentWillReceiveProps(newProps: OwnProps) {
    /**
     * Check if incoming comment comes from integrator
     */
    if ([5, 20, 21, 22].includes(newProps.channel!) && newProps.direction === 'in' && newProps.task.entities.length) {
      const targetEntity = newProps.task.entities[0].data;

      if (targetEntity?.firstName || targetEntity?.lastName) {
        const entityName = `${targetEntity?.firstName || ''} ${targetEntity?.lastName || ''}`;
        this.setState({
          isIntegratorUserReply: true,
          integratorUserReplyName: entityName
        });
      }
    }
  }

  private updateParsedContent = (parsedContent: string) => {
    this.setState({ parsedContent });
  };

  private getAttachmentsList = () => {
    const source = !_.isEmpty(_.get(this.props, ['metaData', 'originalTicketData', 'data', 'attachments']))
      ? _.get(this.props, ['metaData', 'originalTicketData', 'data', 'attachments'])
      : _.get(this.props, ['metaData', 'attachments']);
    const taskAttachments = _.get(this.props, ['task', 'attachments']);
    const commentAttachmentsFilenames: string[] = _.map(source, (att) => att.fileName || att.filename);
    const commentAttachmentsCIDs: string[] = _.map(source, (att) => String(att.cid).replace(/\s/g, ''));

    return taskAttachments.filter(
      (taskAttachment) =>
        commentAttachmentsCIDs.includes(String(taskAttachment.cId)) ||
        commentAttachmentsFilenames.includes(taskAttachment.fileName)
    );
  };

  private attachmentView = () => {
    const attachmentFiles = this.getAttachmentsList().filter((attachment) => !hasImageExtension(attachment.fileName));
    if (attachmentFiles.length) {
      return (
        <div className="userComment__attachmentCardWrapper">
          {attachmentFiles.map((attachment) => (
            <AttachmentCard attachment={attachment} />
          ))}
        </div>
      );
    }
    return null;
  };

  private attachmentImagesView = () => {
    const attachmentImages = this.getAttachmentsList().filter((attachment) => hasImageExtension(attachment.fileName));
    if (attachmentImages.length) {
      return (
        <Accordion>
          <Accordion.Title
            active={this.state.isImageAccordionOpen}
            onClick={() => this.setState({ isImageAccordionOpen: !this.state.isImageAccordionOpen })}
          >
            <Icon name="dropdown" />
            {t('IMAGES')}
          </Accordion.Title>
          <Accordion.Content active={this.state.isImageAccordionOpen} className="userComment__attachmentCardWrapper">
            {attachmentImages.map((attachment) => (
              <AttachmentCard attachment={attachment} />
            ))}
          </Accordion.Content>
        </Accordion>
      );
    }
    return null;
  };

  /**
   *  funtion return a string for Preview email called by openHTMLMetaData()
   *   @date : number
   *   @return {string} html to concatenate for openHTMLMetaData()
   * */
  private metaDataEmail = (date: number): string => {
    const datePreview = renderToString(
      <b>
        {getPrettyDate(date, { format: DATE_TIME_FORMAT })}
        <hr />
      </b>
    );
    const message = renderToString(
      <>
        <HeaderEmailPreview
          channels={this.props.channels}
          ticketTypes={this.props.ticketTypes}
          channel={this.props.channel!}
          title={this.props.title}
          metaData={this.props.metaData!}
        />
        <br />
      </>
    );

    return `${datePreview}${message}`;
  };

  /**
   * modifications added for preview email
   * if there is an image in the core of the email, it skips it
   * cid : signature image / embed image too - in order to avoid bug
   * note : - IMAGE_UPLOAD_VIA_EDITOR this flag was off, if there is a loading uploading an image, try to click outside
   *        - attachment is not affected
   * @param html
   */
  private openHTMLMetaData = (html: string) => {
    const newHtml = parseContent(html, this.props.task.attachments, false);
    const sanitizedHTML = sanitizeHTML(newHtml);
    const htmlTab = window.open('about:blank', '_blank');

    htmlTab?.document.write(sanitizedHTML);
    htmlTab?.focus();
  };

  private expandComment = (expandable: boolean) => {
    if (expandable) {
      this.setState({ isCommentExpanded: true });
    }
  };

  private toggleExpand = () => {
    this.setState((prevState: CommentState) => ({
      isCommentExpanded: !prevState.isCommentExpanded
    }));
  };

  private getChannelData = (channelId: number) => {
    return this.props.channels.find((channel) => channel.id === channelId);
  };

  private copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: t('COPIED')
    });
  };

  private renderEmailAddresses = () => {
    return (
      <>
        <div className="flexBreak"></div>
        <div className="userComment__emailAddressesWrapper">
          {this.props.metaData?.to?.length ? (
            <div>
              <strong>{t('COMMENT_MAIL_ORIGINAL_RECIPIENTS')}</strong>:{' '}
              {this.props.metaData.to.map((to, index) => (
                <Popup
                  position="top center"
                  trigger={
                    <span onClick={() => this.copyToClipboard(to)}>
                      {to} {this.props.metaData?.to?.length! - 1 === index || '; '}
                    </span>
                  }
                  content={'Copy email'}
                  on="hover"
                  basic
                  size="small"
                />
              ))}
            </div>
          ) : null}

          {this.props.metaData?.cc?.length ? (
            <div>
              <strong>{t('COMMENT_MAIL_CC')}</strong>:{' '}
              {this.props.metaData.cc.map((cc, index) => (
                <Popup
                  position="top center"
                  trigger={
                    <span onClick={() => this.copyToClipboard(cc)}>
                      {cc} {this.props.metaData?.cc?.length! - 1 === index ? '' : '; '}
                    </span>
                  }
                  content={'Copy email'}
                  on="hover"
                  basic
                  size="small"
                />
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  render() {
    const { user } = this.props;
    const channelData = this.getChannelData(this.props.channel!);
    const entityData = _.get(this.props, ['metaData', 'originalEntity']);
    const htmlMetaData = _.get(this.props, ['metaData', 'html']);

    const hasAttachments =
      !_.isEmpty(_.get(this.props, ['metaData', 'originalTicketData', 'data', 'attachments'])) ||
      !_.isEmpty(_.get(this.props, ['metaData', 'attachments']));
    const hasHTMLMetadata = !_.isEmpty(htmlMetaData);

    return (
      <Comment
        className={
          (this.props.isLastComment ? 'lightGrey' : '') + ' ' + (this.suggestionComment ? 'suggestionComment' : '')
        }
      >
        {this.state.isMetadataSourceOpen && (
          <ModalWindow
            title={t('comments.titles.source_preview')}
            onClickClose={() => this.setState({ isMetadataSourceOpen: false })}
          >
            <pre>{JSON.stringify(this.props.metaData, undefined, 4)}</pre>
          </ModalWindow>
        )}

        <UserPopup UID={user.UID} disabled={this.state.isIntegratorUserReply}>
          <div style={{ float: 'left', marginRight: '7px' }}>
            {this.state.isIntegratorUserReply && this.state.integratorUserReplyName ? (
              <Avatar name={this.state.integratorUserReplyName} size="35" />
            ) : (
              <UserAvatar user={user} size="35" />
            )}
          </div>
        </UserPopup>

        <Comment.Content
          style={{ color: (channelData && channelData.color) || '', marginLeft: '3rem' }}
          className={`${this.commentSentReceivedClass} ${this.channelTypeName}CommentIcon`}
        >
          <div style={{ display: 'flex' }}>
            <div className="userComment__metadataWrapper">
              <Comment.Author
                as="a"
                onClick={() =>
                  this.copyToClipboard(
                    getCommentSender({
                      entityData,
                      channelData,
                      user,
                      metaData: this.props.metaData,
                      direction: this.props.direction
                    })
                  )
                }
              >
                {this.state.isIntegratorUserReply ? (
                  <>
                    <i>
                      <Icon name={'user'} />
                    </i>
                    {this.state.integratorUserReplyName}
                  </>
                ) : (
                  getCommentSender({
                    entityData,
                    channelData,
                    user,
                    metaData: this.props.metaData,
                    direction: this.props.direction
                  })
                )}
              </Comment.Author>

              <Comment.Metadata>
                <div>{getPrettyDate(this.props.created, { format: DATE_TIME_FORMAT })}</div>
                <div className={this.channelTypeName || 'other CommentIcon'}>
                  <Popup
                    className="commentContentPopup"
                    position="top center"
                    trigger={
                      <i>
                        <CommentIcon
                          style={{
                            color: (channelData && channelData.color) || ''
                          }}
                          channel={channelData}
                          originalDirection={this.props.direction!}
                        />
                      </i>
                    }
                    content={
                      <ErrorBoundary>
                        <CommentIconContent
                          channels={this.props.channels}
                          ticketTypes={this.props.ticketTypes}
                          channel={this.props.channel!}
                          title={this.props.title}
                          metaData={this.props.metaData!}
                        />
                      </ErrorBoundary>
                    }
                    on={'hover'}
                    hoverable={true}
                  />
                </div>

                {!this.state.isIntegratorUserReply && (
                  <CopyToClipboardIcon
                    text={hasHTMLMetadata ? htmlMetaData : this.props.content}
                    className="icon-medium"
                    style={{ opacity: 1 }}
                  />
                )}

                {(hasHTMLMetadata || this.props.content) &&
                  this.props.type === 'normal' &&
                  FeatureFlags.isFlagOn('ENABLE_COMMENT_HTML') && (
                    <div className={this.channelTypeName || 'other CommentIcon'}>
                      <span
                        title={t(['OPEN_COMMENT_HTML', 'Open HTML'])}
                        className="hoverClick"
                        onClick={() => {
                          if (hasHTMLMetadata) {
                            this.openHTMLMetaData(this.metaDataEmail(this.props.created!) + htmlMetaData);
                          } else if (this.props.content) {
                            this.openHTMLMetaData(this.metaDataEmail(this.props.created!) + this.props.content);
                          }
                        }}
                      >
                        <i>
                          <Icon
                            className={'icon-medium'}
                            style={{
                              color: (channelData && channelData.color) || ''
                            }}
                            name={'file code'}
                          />
                        </i>
                      </span>
                    </div>
                  )}

                {this.props.channel === ChannelType.Email && this.props.metaData && (
                  <i onClick={() => this.setState({ isMetadataSourceOpen: true })}>
                    <Icon
                      className={'icon-medium'}
                      style={{
                        color: (channelData && channelData.color) || '',
                        cursor: 'pointer'
                      }}
                      name="info circle"
                    />
                  </i>
                )}

                {/* TODO : have to change the icon of webform />*/}
                {this.props.channel === ChannelType.Webform && this.props.replyToEmailEnabled && (
                  <div className="Comment-Content images-max-w-95">
                    <ErrorBoundary>
                      <CommentEditorWidgetContainer
                        senderEmails={this.props.senderEmails}
                        created={this.props.created!}
                        title={this.props.title || ''}
                        comment={this.props.content!}
                        taskId={this.props.taskId}
                        metaData={this.props.metaData}
                        isHTML={false}
                        attachmentIds={getCommentAttachmentsIds(this.props.metaData, this.props.task)}
                      />
                    </ErrorBoundary>
                  </div>
                )}

                <WhatsAppCommentButtons channel={this.props.channel!} onReplyClick={this.props.setWhatsAppDraft} />

                {this.props.type === 'suggestion' && (
                  <div className="Comment-Content images-max-w-95">
                    <ErrorBoundary>
                      <SuggestionEditorWidgetContainer
                        channel={this.props.channel!}
                        senderEmails={this.props.senderEmails}
                        created={this.props.created!}
                        title={this.props.title || ''}
                        comment={this.props.content!}
                        taskId={this.props.taskId}
                        metaData={this.props.metaData}
                        isHTML={false}
                        attachmentIds={getCommentAttachmentsIds(this.props.metaData, this.props.task)}
                        replyTabIndexList={this.props.replyTabIndexList}
                      />
                    </ErrorBoundary>
                  </div>
                )}
              </Comment.Metadata>

              {this.props.channel === ChannelType.Email && this.renderEmailAddresses()}
            </div>

            {this.props.channel === ChannelType.Email && this.props.replyToEmailEnabled && (
              <div style={{ marginLeft: 'auto', marginRight: '5px' }}>
                <ErrorBoundary>
                  <CommentEditorWidgetContainer
                    senderEmails={this.props.senderEmails}
                    created={this.props.created!}
                    title={this.props.title || ''}
                    comment={this.state.parsedContent}
                    taskId={this.props.taskId}
                    metaData={this.props.metaData}
                    isHTML={htmlMetaData}
                    attachmentIds={getCommentAttachmentsIds(this.props.metaData, this.props.task)}
                    buttonsType={this.props.isLastComment ? 'primary' : 'basic'}
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>

          {hasAttachments && (
            <div style={{ margin: '10px 0' }}>
              <ErrorBoundary>{this.attachmentView()}</ErrorBoundary>
              <ErrorBoundary>{this.attachmentImagesView()}</ErrorBoundary>
            </div>
          )}

          <CommentText
            content={this.props.content}
            isChatComment={this.chatComment}
            isCommentExpanded={this.state.isCommentExpanded}
            isLastComment={!!this.props.isLastComment}
            botButtonClickedState={this.props.botButtonClickedState}
            metaData={this.props.metaData}
            type={this.props.type!}
            attachments={this.props.task.attachments}
            expandComment={this.expandComment}
            toggleExpand={this.toggleExpand}
            onParsedContentChange={this.updateParsedContent}
          />
        </Comment.Content>
      </Comment>
    );
  }
}

const connector = connect(undefined, (dispatch: Dispatch<Action>, ownProps: OwnProps) => ({
  setWhatsAppDraft: () => {
    const reply = `\n\n*RE:* _${ownProps.content}_`;
    dispatch(appendToDraft({ content: reply }, ownProps.taskId, Channels.whatsapp));
    dispatch(switchDraftTabIndex(ownProps.taskId, ChannelType.WhatsApp));
  }
}));

export default connector(UserComment);
