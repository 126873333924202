import { AxiosResponse } from 'axios';

import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { IGeneralSalesforceData, SalesforceResponseStatus } from 'src/types/Salesforce';

interface CreateSalesforceRecordParameters {
  ticketId: string;
  payload: { [key: string]: any };
}

export default class SalesforceApi {
  static getContacts = async (stringToSearch: string): Promise<IGeneralSalesforceData[]> =>
    await axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}/salesforce/contacts`, {
        params: { searchTerm: stringToSearch }
      })
      .then((response: AxiosResponse<IGeneralSalesforceData[]>) => response.data)
      .catch((error) => {
        throw error;
      });

  static getAccounts = async (stringToSearch: string): Promise<IGeneralSalesforceData[]> =>
    await axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}/salesforce/accounts`, {
        params: { searchTerm: stringToSearch }
      })
      .then((response: AxiosResponse<IGeneralSalesforceData[]>) => response.data)
      .catch((error) => {
        throw error;
      });

  static getUsers = async (stringToSearch: string): Promise<IGeneralSalesforceData[]> =>
    await axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}/salesforce/users`, {
        params: { searchTerm: stringToSearch }
      })
      .then((response: AxiosResponse<IGeneralSalesforceData[]>) => response.data)
      .catch((error) => {
        throw error;
      });

  static createLead = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/salesforce/lead`, parameters)
      .then((response: AxiosResponse<SalesforceResponseStatus>) => response.data)
      .catch((error) => {
        throw error;
      });

  static createTask = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/salesforce/task`, parameters)
      .then((response: AxiosResponse<SalesforceResponseStatus>) => response.data)
      .catch((error) => {
        throw error;
      });

  static createOpportunity = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/salesforce/opportunity`, parameters)
      .then((response: AxiosResponse<SalesforceResponseStatus>) => response.data)
      .catch((error) => {
        throw error;
      });

  static logAnEmail = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/salesforce/LogAnEmail`, parameters)
      .then((response: AxiosResponse<SalesforceResponseStatus>) => response.data)
      .catch((error) => {
        throw error;
      });

  static logACall = async (parameters: CreateSalesforceRecordParameters): Promise<SalesforceResponseStatus> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/salesforce/LogACall`, parameters)
      .then((response: AxiosResponse<SalesforceResponseStatus>) => response.data)
      .catch((error) => {
        throw error;
      });
}
