import React from 'react';
import { Button } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';
import type { VFC } from 'react';

import FeatureFlags from 'src/api/FeatureFlags';

interface RPBProps {
  disabled?: boolean;
  disabledCloseAsDone?: boolean;
  internal?: boolean;
  loading?: boolean;
  onClear: () => void;
  onSaveDraft?: () => void;
  onSubmit: () => void;
  onSubmitAndCloseAsDone?: () => void;
}

const ReplyControlButtons: VFC<RPBProps> = ({
  disabled,
  disabledCloseAsDone,
  internal,
  loading,
  onClear,
  onSaveDraft,
  onSubmit,
  onSubmitAndCloseAsDone
}) => {
  const onClickSend = () => {
    if (!disabled) {
      onSubmit();
    }
  };

  const onClickSendAndClose = () => {
    if (
      FeatureFlags.isFlagOn('SHOW_SEND_AND_CLOSE_AS_DONE') &&
      !disabled &&
      !disabledCloseAsDone &&
      onSubmitAndCloseAsDone
    ) {
      onSubmitAndCloseAsDone();
    }
  };

  useHotkeys('ctrl+enter', onClickSend, undefined, [onClickSend]);
  useHotkeys('alt+s', onClickSendAndClose, undefined, [onClickSendAndClose]);

  return (
    <Translation ns="translations">
      {(t) => (
        <React.Fragment>
          <Button
            as={'span'}
            content={t('GENERAL_CLEAR')}
            icon="eraser"
            labelPosition="left"
            negative
            onClick={onClear}
          />

          <Button
            content={internal ? t('GENERAL_SAVE') : t('GENERAL_SEND')}
            disabled={disabled}
            icon={internal ? 'save' : 'send'}
            id="commentReplySaveBtn"
            labelPosition="left"
            loading={loading}
            onClick={onClickSend}
            primary
          />

          {onSubmitAndCloseAsDone && FeatureFlags.isFlagOn('SHOW_SEND_AND_CLOSE_AS_DONE') && (
            <Button
              content={t('GENERAL_SEND_AND_CLOSE')}
              disabled={disabled || disabledCloseAsDone}
              icon="check"
              id="commentReplySaveBtn"
              labelPosition="left"
              loading={loading}
              onClick={onClickSendAndClose}
              positive
            />
          )}

          {onSaveDraft && (
            <Button
              content={t('GENERAL_SAVE_DRAFT')}
              floated="right"
              icon="save"
              id="descriptionFieldEditSaveDraftBtn"
              labelPosition="left"
              onClick={onSaveDraft}
            />
          )}
        </React.Fragment>
      )}
    </Translation>
  );
};

export default ReplyControlButtons;
