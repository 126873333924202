import { FETCH_ASSETS_SUCCESS } from '../actions/index';

const assets: any = [];

const assetsReducer = (state = assets, action: any) => {
  switch (action.type) {
    case FETCH_ASSETS_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
};

export default assetsReducer;
