import { connect } from 'react-redux';
import LoginComponent from '../LoginComponent';
import { login } from '../actions/authActions';
const mapStateToProps = (state: any) => {
  return {
    isUserAuthenticated: state.auth.loggedIn,
    hasEnteredIncorrectLoginCredentials: state.auth.hasEnteredIncorrectLoginCredentials,
    initialAddress: state.auth.initialAddress,
    activationSuccess: state.auth.activationSuccess
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logUserIn: (user: string, password: string) => dispatch(login(user, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
