import { t } from 'i18next';
import * as React from 'react';
import { Comment as SemanticComment, Divider } from 'semantic-ui-react';
import { connect } from 'react-redux';

import UserComment from '../Comment/UserComment';
import AutomaticComment from '../Comment/AutomaticComment';
import ChannelType from '../CommentIconContent/ChannelType';
import ChatTypingStatus from '../Case/ChatTypingStatus';
import { botButtonClickedState } from 'src/Utilities/comments';
import { sortComments } from 'src/Utilities/sortComments';
import { Direction } from 'src/types/Sorting';
import { isChatAnchored } from 'src/types/Ticket';
import { commentOutImages } from 'src/Utilities/parseUtils';
import FeatureFlags from 'src/api/FeatureFlags';
import type { Comment, Ticket } from 'src/types/Ticket';
import type { Channel } from 'src/types/Channel';
import type { ReplyTabIndex } from 'src/types/Drafts';
import type { PersonalData, User } from 'src/types/User';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { ConnectedProps } from 'react-redux';
import type { State } from 'src/types/initialState';

interface DiscussionProps extends ConnectedProps<typeof connector> {
  comments: Comment[];
  users: User[];
  task: Ticket;
  taskId: string;
  replyToEmailEnabled: boolean;
  channels: Channel[];
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  user: PersonalData;
  replyTabIndexList: ReplyTabIndex[];
  direction: Direction;
}

interface DiscussionState {
  isCommentExpanded: boolean;
  chatTyping: boolean;
}

class Discussion extends React.Component<DiscussionProps, DiscussionState> {
  private messagesEnd = React.createRef<HTMLDivElement>();

  constructor(props: DiscussionProps) {
    super(props);
    this.state = {
      isCommentExpanded: false,
      chatTyping: false
    };
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: DiscussionProps) {
    if (this.props.comments.length !== prevProps.comments.length) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 750);
    }
  }

  private scrollToBottom = () => {
    if (
      this.messagesEnd !== null &&
      isChatAnchored(this.props.task) &&
      (this.props.task.channel === ChannelType.Chat || this.props.task.channel === ChannelType.Giosg)
    ) {
      this.messagesEnd.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  private getComments = () => {
    const { comments, direction } = this.props;

    let sortedComments = sortComments(comments);

    const suggestionComments = comments.filter((comment) => {
      return comment.type === 'suggestion';
    });

    const appendableSuggestionComments = suggestionComments.filter((suComment) => {
      return (
        sortedComments.find((comment) => {
          return suComment.metaData?.responseTo === comment.id;
        }) !== undefined
      );
    });

    sortedComments = sortedComments.filter((sComment) => {
      return (
        appendableSuggestionComments.find((ASComment) => {
          return ASComment.id === sComment.id;
        }) === undefined
      );
    });

    sortedComments = direction === Direction.DESC ? sortedComments : sortedComments.reverse();

    const sortedAppendedComments = [] as Comment[];

    sortedComments.forEach((comment) => {
      sortedAppendedComments.push(comment);
      appendableSuggestionComments.forEach((ASComment) => {
        if (ASComment.metaData?.responseTo === comment.id) {
          sortedAppendedComments.push(ASComment);
        }
      });
    });
    return sortedAppendedComments;
  };

  /**
   * div for toogle expanse button - expanse comments
   * @param text
   */
  private expanseDiv = (text: string) => {
    if (this.props.task.channel !== ChannelType.Chat && this.props.task.channel !== ChannelType.Giosg) {
      return (
        <p
          onClick={() =>
            this.setState({
              isCommentExpanded: !this.state.isCommentExpanded
            })
          }
          style={{ textAlign: 'center' }}
        >
          <span className="label ui">{text}</span>
        </p>
      );
    } else {
      return undefined;
    }
  };

  /**
   * Previous div for the map of comments, nove here for more lisibility
   * typescript : any - already there, probably because comment can be : mail, sms, internal, fb etc...
   * @param comment
   * @param isLastComment
   */
  private renderUserComment = (
    comment: Comment,
    isLastComment: boolean,
    botButtonClickedState: undefined | Comment
  ) => {
    const usersFlattened = this.props.users.reduce((users, user) => {
      users[user.UID] = user;
      return users;
    }, {});

    const isEnableImagesFilter =
      !FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES') &&
      ((FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES_TOGGLE') && !this.props.showImages) ||
        !FeatureFlags.isFlagOn('ENABLE_COMMENT_IMAGES_TOGGLE'));

    const commentProps = {
      ...comment,
      content: isEnableImagesFilter ? commentOutImages(comment.content) : comment.content
    };

    if (comment.type === 'automatic') {
      return (
        <AutomaticComment
          ticketTypes={this.props.ticketTypes}
          channels={this.props.channels}
          usersData={this.props.users}
          attachments={this.props.task.attachments}
          contentType={this.props.task.type}
          user={usersFlattened[comment.createdByUser]}
          {...commentProps}
        />
      );
    }

    return (
      <UserComment
        isLastComment={isLastComment}
        task={this.props.task}
        ticketTypes={this.props.ticketTypes}
        senderEmails={this.props.senderEmails}
        user={usersFlattened[comment.createdByUser]}
        channels={this.props.channels}
        taskId={this.props.taskId}
        {...commentProps}
        key={comment.id}
        replyToEmailEnabled={this.props.replyToEmailEnabled}
        botButtonClickedState={botButtonClickedState}
        replyTabIndexList={this.props.replyTabIndexList}
      />
    );
  };

  /**
   * component that display comments only if it's not expanded
   * it displays 1, 2, ...., N-1, N
   */
  private renderUserCommentNotExpanded = ({
    comment,
    index,
    isLastComment,
    botButtonClickedState,
    commentsCount
  }: {
    comment: Comment;
    index: number;
    isLastComment: boolean;
    botButtonClickedState: undefined | Comment;
    commentsCount: number;
  }) => {
    return (
      <>
        {index <= 1 || index >= commentsCount - 2
          ? this.renderUserComment(comment, isLastComment, botButtonClickedState)
          : ''}
        {index === 2 && this.expanseDiv(`⇣ ${t('DISPLAY_ALL_MESSAGES')} (${commentsCount - 4}) ⇣`)}
      </>
    );
  };

  private isChatTicket(channel: number): boolean {
    switch (channel) {
      case ChannelType.Chat:
      case ChannelType.Giosg:
        return true;
    }

    return false;
  }

  render() {
    const comments = this.getComments();

    return (
      <SemanticComment.Group style={{ marginTop: '0px', maxWidth: '100%', width: '100%' }}>
        <div className="discussionPanel">
          {comments.length > 4 &&
            (!this.state.isCommentExpanded
              ? this.expanseDiv(`⇣ ${comments.length - 4} ${t('HIDDEN_MESSAGES_POSTFIX')} ⇣`)
              : this.expanseDiv(` ${t('HIDE_MESSAGES')} ⇣`))}

          {comments.map((comment, index) => {
            let isLastComment: boolean;
            if (this.props.direction === 'DESC') {
              isLastComment = index + 1 === comments.length;
            } else {
              isLastComment = index === 0;
            }

            return (
              <React.Fragment key={comment.type + comment.content?.substring(0, 3) + index}>
                {this.props.task.channel !== ChannelType.Chat &&
                this.props.task.channel !== ChannelType.Giosg &&
                !this.state.isCommentExpanded &&
                comment.type === 'automatic' &&
                comments.length > 4 ? (
                  <>
                    {this.renderUserCommentNotExpanded({
                      comment,
                      index,
                      isLastComment,
                      botButtonClickedState: botButtonClickedState(comment, comments),
                      commentsCount: comments.length
                    })}
                  </>
                ) : (
                  <>
                    {this.renderUserComment(comment, isLastComment, botButtonClickedState(comment, comments))}
                    <Divider style={{ margin: 0 }} />
                  </>
                )}
              </React.Fragment>
            );
          })}
          <div ref={this.messagesEnd} />
        </div>

        {this.isChatTicket(this.props.task.channel) ? <ChatTypingStatus /> : null}

        {this.state.isCommentExpanded && comments.length > 4 && this.expanseDiv(`⇡ ${t('HIDE_MESSAGES')} ⇡`)}
      </SemanticComment.Group>
    );
  }
}

const connector = connect((state: State) => ({
  showImages: !!state.commentsMenu.showImages[state.activeTicketTab!]
}));

export default connector(Discussion);
