import {
  FETCH_AUTO_SUGGESTIONS_SUCCESS,
  CREATE_AUTO_SUGGESTIONS_SUCCESS,
  DELETE_AUTO_SUGGESTIONS_SUCCESS,
  UPDATE_AUTO_SUGGESTIONS_SUCCESS
} from 'src/actions/index';
import type { Suggestion } from 'src/types/Ticket';

type SuggestionState = { suggestions: Suggestion[] };
const initialState: SuggestionState = {
  suggestions: []
};

const suggestionsReducer = (state = initialState.suggestions, action: any) => {
  switch (action.type) {
    case FETCH_AUTO_SUGGESTIONS_SUCCESS: {
      return action.payload.suggestions;
    }
    case CREATE_AUTO_SUGGESTIONS_SUCCESS: {
      return [...state, action.payload.suggestion];
    }
    case UPDATE_AUTO_SUGGESTIONS_SUCCESS: {
      return [
        ...state.filter((suggestion) => suggestion.id !== action.payload.suggestion.id),
        action.payload.suggestion
      ];
    }
    case DELETE_AUTO_SUGGESTIONS_SUCCESS: {
      return state.filter((suggestion) => suggestion.id === action.payload.id);
    }
    default:
      return state;
  }
};

export default suggestionsReducer;
