interface FeatureFlagsParameters {
  SECURE_MAIL_ENABLED: string;
  ENABLE_TICKET_LIST_FILTERING: string;
  DISABLE_TASK_CONTENTS: string;
  ENABLE_OC_CLICK_TO_CALL: string;
  ENABLE_RING_CLICK_TO_CALL: string;
  ENABLE_MITEL_CLICK_TO_CALL: string;
  ENABLE_ENREACH_VOICE_CLICK_TO_CALL: string;
  ENABLE_CLICK_TO_SEARCH: string;
  CHAT_ANCHOR_TICKET_BY_DEFAULT: string;
  CHAT_SHOW_AGENT_TYPING_STATUS: string;
  FACEBOOK_CHAT_ENABLED: string;
  GIOSG_CHAT_ENABLED: string;
  ENABLE_RETURN_AS_NEW: string;
  ENABLE_TICKET_TYPE_MANAGEMENT: string;
  EXPORT_DATA: string;
  ENABLE_START_WORKING_ON_FROM_TICKETLIST: string;
  ENABLE_EGAIN: string;
  ENABLE_LINK_PARSE: string;
  ENABLE_COMMENT_HTML: string;
  CUSTOMER_PORTAL_ENABLED: string;
  ENABLE_CHANGE_STATUS_TO_DOING: string;
  ENABLE_SURVEY_SETTINGS: string;
  ENABLE_SURVEY_TICKET_INFO: string;
  ENABLE_PASSWORD_RESET: string;
  ENABLE_EXCLUSIVE_SEARCH: string;
  ALLOW_CHANGING_TO_FORBIDDEN_TICKET_TYPES: string;
  ENABLE_START_WORKING_ON_FROM_TICKETLIST_BY_WHOLE_ELEMENT: string;
  ENABLE_TICKETLIST_PREVIEW: string;
  ENABLE_TABBAR_PREVIEW: string;
  ENABLE_ADVANCED_TAG_FILTERING: string;
  ALLOW_TAG_ADDITION_FROM_TASK: string;
  ON_CALL_OPEN_CALL_UI: string;
  IMAGE_UPLOAD_VIA_EDITOR: string;
  ENABLE_TICKETLIST_CREATED_TIMESTAMP: string;
  ENABLE_TICKETLIST_TOUCHED_TIMESTAMP: string;
  ENABLE_TICKETLIST_DUEDATE_TIMESTAMP: string;
  ENABLE_TICKETLIST_ORIGINAL_CONTACT: string;
  ENABLE_TICKETLIST_LAST_CONTACT_ADDRESS: string;
  ENABLE_TICKETLIST_ORIGINAL_DIRECTION: string;
  ENABLE_USER_TIME_LOGGING: string;
  ENABLE_SEARCH_DEFAULT_TERMS: string;
  EMBEDDED_ATTACHMENTS_LIST_CLOSED: string;
  ENABLE_IMG_PARSE: string;
  ENABLE_SOUND_NOTIFICATION: string;
  ENABLE_KNOWLEDGE_BASE: string;
  HTTPS_LINK_PARSE: string;
  EMAIL_SEND_FROM_TOP: string;
  EMAIL_SEND_FROM_BOTTOM: string;
  LIMIT_CLICKTOSEARCH_ENTITYTYPES: string;
  ENABLE_CUSTOM_USER_TICKET_ORDERING_BY_DUEDATE: string;
  ENABLE_ELISAOC_SHOW_STATUS_WHEN_MINIMIZED: string;
  SHOW_SEND_AND_CLOSE_AS_DONE: string;
  OPEN_EXTERNAL_LINK_ON_PHONE_CALL: string;
  AUTOMATIC_CLOSING_TICKETS: string;
  ENABLE_COMMENT_IMAGES: string;
  ENABLE_COMMENT_IMAGES_TOGGLE: string;
}

export type TFeatureFlag = keyof FeatureFlagsParameters;

export default class FeatureFlags {
  static flags: TFeatureFlag[] = [];

  static setFlags(incomingFlags: TFeatureFlag[]) {
    this.flags = incomingFlags;
  }

  static isFlagOn(flag: TFeatureFlag) {
    return this.flags.includes(flag);
  }
}
