import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { DefaultCommentsSorting } from '../types/Sorting';
import { getURLParam } from '../Utilities/helper';
import type { Direction } from '../types/Sorting';

export interface SortingState {
  sorting: Direction;
  showEvents: boolean;
  showImages: Record<string, boolean>;
}

const param = getURLParam<Direction>(window.location.search, 'comments');
export const commentsMenuInitialState: SortingState = {
  sorting: param ?? DefaultCommentsSorting,
  showEvents: false,
  showImages: {}
};

const commentsMenuReducers = createSlice({
  name: 'commentsMenu',
  initialState: commentsMenuInitialState,
  reducers: {
    setCommentsSorting: (draft, action: PayloadAction<Direction>) => {
      draft.sorting = action.payload;
    },
    setShowEvents: (draft, action: PayloadAction<boolean>) => {
      draft.showEvents = action.payload;
    },
    setShowImages: (draft, action: PayloadAction<{ contentId: string; showImages: boolean }>) => {
      draft.showImages[action.payload.contentId] = action.payload.showImages;
    }
  }
});

export const { setCommentsSorting, setShowEvents, setShowImages } = commentsMenuReducers.actions;

export default commentsMenuReducers.reducer;
