import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';

import FeatureFlags from 'src/api/FeatureFlags';
import AttachmentDropzone from './AttachmentDropzone';
import { AttachmentEdit } from './AttachmentItem';
import AttachmentsAccordion from '../Attachments/AttachmentsAccordion';
import { hasImageExtension } from 'src/Utilities/images';
import { PersonalData, Roles, User } from 'src/types/User';
import type { Attachment } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

import './Attachments.css';

interface AttachmentsProps {
  attachments: Array<Attachment>;
  onUpload: (files: unknown) => void;
  onEdit: (id: string, body: AttachmentEdit) => void;
  onDeprecate: (id: string) => void;
  onUnDeprecate: (id: string) => void;
  dropZoneEnabled: boolean;
  user: User;
  personalData: PersonalData;
}

declare global {
  interface Window {
    dropzoneRef: any;
  }
}

window.dropzoneRef = window.dropzoneRef || {};

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    personalData: state.userData
  };
};

class Attachments extends React.Component<AttachmentsProps> {
  private uploadFile = (acceptedFiles: File[]) => {
    this.props.onUpload(acceptedFiles);
  };

  private editAttachment = (attachmentId: string, body: AttachmentEdit) => {
    this.props.onEdit(attachmentId, body);
  };

  private deprecateAttachment = (attachmentId: string) => {
    this.props.onDeprecate(attachmentId);
  };

  private unDeprecateAttachment = (attachmentId: string) => {
    this.props.onUnDeprecate(attachmentId);
  };

  private openFileDialog = () => {
    window.dropzoneRef.open();
  };

  render() {
    const deprecated: Attachment[] = [];
    const images: Attachment[] = [];
    const others: Attachment[] = [];

    for (const attachment of this.props.attachments) {
      if (attachment.deprecated) {
        deprecated.push(attachment);
      } else if (hasImageExtension(attachment.fileName)) {
        images.push(attachment);
      } else {
        others.push(attachment);
      }
    }

    const userRole = this.props.user?.role.id;
    let isAdmin = true;
    if (userRole && !Roles.isAdminLike(userRole)) {
      isAdmin = false;
    }

    return (
      <React.Fragment>
        {this.props.attachments.length > 0 && (
          <>
            <AttachmentsAccordion
              initialOpen={true}
              title={t('ATTACHMENTS')}
              attachments={others}
              uploadFile={this.uploadFile}
              editAttachment={this.editAttachment}
              deprecateAttachment={this.deprecateAttachment}
              unDeprecateAttachment={this.unDeprecateAttachment}
              openFileDialog={this.openFileDialog}
              personalData={this.props.personalData}
            />
            <AttachmentsAccordion
              initialOpen={!FeatureFlags.isFlagOn('EMBEDDED_ATTACHMENTS_LIST_CLOSED')}
              title={t('IMAGES')}
              attachments={images}
              uploadFile={this.uploadFile}
              editAttachment={this.editAttachment}
              deprecateAttachment={this.deprecateAttachment}
              unDeprecateAttachment={this.unDeprecateAttachment}
              openFileDialog={this.openFileDialog}
              personalData={this.props.personalData}
            />
            {isAdmin && (
              <AttachmentsAccordion
                initialOpen={false}
                title={t('DELETED_ATTACHMENTS')}
                attachments={deprecated}
                uploadFile={this.uploadFile}
                editAttachment={this.editAttachment}
                deprecateAttachment={this.deprecateAttachment}
                unDeprecateAttachment={this.unDeprecateAttachment}
                openFileDialog={this.openFileDialog}
                personalData={this.props.personalData}
              />
            )}
          </>
        )}
        <AttachmentDropzone
          enabled={this.props.dropZoneEnabled}
          dropzoneRef={(node: any) => {
            window.dropzoneRef = node;
          }}
          onUpload={this.uploadFile}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Attachments);
