import type { TicketType } from 'src/types/TicketType';
import type { phoneConfiguration, Configuration } from 'src/types/PhoneConfiguration';

const escapeRegExp = (s: string) => {
  return s.replace(/[-/\\$*+?.()|[\]{}]/g, '\\$&');
};

export const findPhoneConfiguration = (configurations: phoneConfiguration[], serviceName?: string) => {
  if (!serviceName) {
    return;
  }

  return configurations.find((configuration) => {
    const escapedValue = escapeRegExp(serviceName);
    return configuration.value.match(new RegExp(escapedValue, 'g'));
  });
};

export const parsePhoneConfiguration = (configuration?: phoneConfiguration): Configuration | Partial<Configuration> => {
  if (!configuration) {
    return {};
  }

  return typeof configuration.configuration === 'string'
    ? JSON.parse(configuration.configuration)
    : configuration.configuration;
};

export const getPhoneConfigTicketType = (
  parsedConfiguration: Configuration | Partial<Configuration>,
  ticketTypes: TicketType[]
) => {
  const taskType = parsedConfiguration.taskType;
  if (!taskType) {
    return;
  }

  return ticketTypes.find((ticketType) => ticketType.id === parseInt(String(taskType), 10));
};

export const getPhoneConfigTags = (parsedConfiguration: Configuration | Partial<Configuration>) => {
  if (!parsedConfiguration.tags) {
    return;
  }

  return parsedConfiguration.tags.map((tag) => {
    if (typeof tag === 'string' && tag.includes('TAG')) {
      return tag;
    }
    return `TAG${tag}`;
  });
};
