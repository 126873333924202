import { addComment } from 'src/actions/commentsActions';
import TicketsApi from 'src/api/TicketsApi';
import { startWorkingOn } from 'src/Utilities/workStatusParser';
import { activateTab, addTab } from 'src/actions/tabActionsRTK';
import {
  updateTicketCaseDetails,
  activateTicket,
  addEntityToCase,
  addTicket,
  createContent,
  searchCustomerByDetail
} from 'src/actions/ticketsActions';
import SocketInstance from 'src/realTimeNotifications';
import { history } from 'src/reducers';

interface ReduxData {
  dispatch: any;
}

export interface UpdateDetailsObject {
  updateKey: string;
  updateValue: any;
  group: string;
}

export interface IntegrationData {
  normalizedPhoneNumber: string;
  ticketData: any;
  UID: string;
  searchObject: object;
  detailsObjects?: UpdateDetailsObject[];
  history: any;
  direction?: 'in' | 'out';
  integrationPayload?: {
    foreignId: string;
    foreignIdType: string;
  };
}

export enum PhoneIntegrationType {
  MITEL = 'Mitel',
  ELISA_RING = 'ElisaRing',
  ELISA_OC = 'ElisaOC',
  ENREACH_VOICE = 'EnreachVoice'
}

export const handleIncomingPhoneCall = async (reduxData: ReduxData, integrationData: IntegrationData) => {
  const { dispatch } = reduxData;
  const { normalizedPhoneNumber, ticketData, searchObject, UID, direction, integrationPayload } = integrationData;

  ticketData.originalContact = normalizedPhoneNumber;

  const ticket = await dispatch(createContent(ticketData));
  if (ticket && ticket.id !== 'new_oc') {
    // now with  ticket data create some tabs
    SocketInstance.joinRoom(ticket.id);
    dispatch(addTicket(ticket));
    dispatch(addTab({ id: ticket.id, type: 'tickets' }));
    dispatch(activateTicket(ticket.id));
    dispatch(activateTab(ticket.id));
    TicketsApi.startWorkingOn(...startWorkingOn(UID, ticket.id));

    await dispatch(
      addComment(ticket.id, {
        content: `[original_phonenumber:${normalizedPhoneNumber}]`,
        direction: direction,
        ...(integrationPayload && {
          foreignId: integrationPayload.foreignId,
          foreignIdType: integrationPayload.foreignIdType
        })
      })
    );

    if (integrationData.detailsObjects) {
      const detailsObjects = integrationData.detailsObjects;
      dispatch(
        updateTicketCaseDetails(
          ticket.id,
          'CaseDetails',
          detailsObjects.map((detailsObject) => ({
            type: detailsObject.updateKey,
            value: detailsObject.updateValue
          }))
        )
      );
    }

    // search for entity
    const entityResults = await dispatch(
      searchCustomerByDetail({
        ...searchObject,
        taskType: ticketData.taskType
      })
    );

    if (entityResults.length === 1) {
      // add entity to case
      dispatch(
        addEntityToCase(ticket.id, {
          _id: entityResults[0]._id,
          taskType: ticket.taskType,
          _type: entityResults[0]._type
        })
      );
    } else if (entityResults.length === 0) {
      // add entity based on originalNumber, ticketType etc.
      dispatch(
        addEntityToCase(ticket.id, {
          taskType: ticketData.taskType,
          ...searchObject
        })
      );
    } else {
      // add entity to case
      dispatch(
        addEntityToCase(ticket.id, {
          _id: entityResults[0]._id,
          taskType: ticket.taskType,
          _type: entityResults[0]._type
        })
      );
      // add comment multiple customers
      dispatch(
        addComment(ticket.id, {
          content: '[had_multiple_entities]'
        })
      );
    }
    history.push('/case/' + ticket.id);
  } else {
    history.push('/');
    Promise.reject('no ticket');
  }
};
