import axios from 'axios';
import ApiConfig from './ApiConfig';
import type { TicketArchivalUploadData } from '../types/Archival';

const API_URL_SEND_ENTITY_EVENT = '/archival/submit';

export default class ArchivalApi {
  static sendArchivalRequest = (data: TicketArchivalUploadData): Promise<any> => {
    return axios.post(ApiConfig.getConfig().API_URL + API_URL_SEND_ENTITY_EVENT, data).then((response) => {
      return response;
    });
  };
}
