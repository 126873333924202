import storage from 'redux-persist/lib/storage';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import type { EnhancedStore } from '@reduxjs/toolkit';
import type { PersistConfig } from 'redux-persist';

import reducers, { history } from './reducers';
import { urlSyncMiddleware } from './middlewares/urlSyncMiddleware';
import { socketInitMiddleware } from './middlewares/socketInitMiddleware';
import { commentsMenuInitialState } from './reducers/commentsMenuReducer';
import type { State } from './types/initialState';

const migrations = {
  1: (state: State): State => ({ ...state, commentsMenu: commentsMenuInitialState })
};

const persistConfig: PersistConfig<State> = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['tabsPersist', 'commentsMenu'],
  migrate: createMigrate(migrations as any, { debug: false })
};

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }),
  routerMiddleware(history),
  urlSyncMiddleware,
  socketInitMiddleware
];

const persistedReducer = persistReducer(persistConfig, reducers as any);

export const store: EnhancedStore<State> = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware
});

export const persistor = persistStore(store);
