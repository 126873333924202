import { t } from 'i18next';
import moment from 'moment';
import { connect } from 'react-redux';

import InfopageCase from '../Components/Case/InfopageCase/InfopageCase';
import { Task } from '../api/Task';
import type { UpdateEntityDetail } from '../api/TicketsApi';
import { addTab } from '../actions/tabActionsRTK';
import {
  addDelegateGroupToContent,
  addDelegateToContent,
  addEntityToCase,
  changeEntity,
  deprecateAttachment,
  editAttachment,
  fetchTicket,
  fetchTickets,
  fetchTicketsByEntity,
  getEntityById,
  removeDelegateFromContent,
  removeEntityFromCase,
  unDeprecateAttachment,
  updateEntityDetails,
  updateMetadata,
  updateTicket,
  updateSingleTicketCaseDetail,
  uploadFile
} from '../actions/ticketsActions';
import SocketInstance from '../realTimeNotifications';
import { activateContentListTab, addContentListTab, setContentListSearch } from 'src/api/CaseListApi';
import { convertCaseNaming } from 'src/Utilities/helper';
import { Channels } from '../types/Channel';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State, ownProps: any) => {
  const id = ownProps.match.params.id;
  let task: any = new Task();

  if (state.userData && state.userData.userPreferences && state.userData.userPreferences.defaultTicketType) {
    task.taskType = state.userData.userPreferences.defaultTicketType;
  }

  if (id !== 'new' && id !== 'new_oc') {
    task = state.detailedTickets.find((ticket: any) => {
      return ticket.id === state.activeTicketTab;
    });
  }

  return {
    task,
    usersList: state.usersList.usersList,
    userData: state.userData,
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
    ticketTypes: state.ticketTypes,
    ticketChannels: state.channels,
    ticketPriorities: state.ticketPriorities,
    externalLinks: state.linkLists,
    mobileMode: state.mobileReducer.mobileMode,
    autosuggestions: state.suggestions,
    replyTabIndexList: [
      {
        name: Channels.intra,
        index: 0
      }
    ],
    widgetOrder: [
      'CaseChannel',
      'Priority',
      'HandleStatus',
      'FieldSets',
      'Delegations',
      'TicketRelations',
      'Tags',
      'AttachEntityById',
      'CustomerInfo',
      'ExternalLinks',
      'Attachments'
    ]
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fireTicketSearch: (filter: any, _id: string, searchCriteria: any[]) => {
      const id = _id ? _id : '' + moment().unix() + '';
      dispatch(addContentListTab(id, t('TAB_NAME_SEARCH'), 'tickets'));
      dispatch(setContentListSearch(id, searchCriteria, 'tickets'));
      dispatch(activateContentListTab(id, 'tickets'));
      dispatch(fetchTickets(filter, id));
    },
    addEntityToCase: (ticketId: string, body: object, subEntitiesToGet?: any) => {
      return dispatch(addEntityToCase(ticketId, body, subEntitiesToGet));
    },
    removeEntityFromCase: (ticketId: string, body: object) => {
      dispatch(removeEntityFromCase(ticketId, body));
    },
    fetchContent: (id: string) => {
      dispatch(fetchTicket({ id, type: 'infopage' }));
    },
    getEntityById: (ticketId: string, ticketType: string, entityId: string, entityType: string) => {
      dispatch(getEntityById(ticketId, ticketType, entityId, entityType));
    },
    updateTicketCaseDetails: (ticketId: string, updateKey: string, updateValue: any, group: string) => {
      dispatch(updateSingleTicketCaseDetail(ticketId, updateKey, updateValue, group));
    },
    updateEntityDetails: (ticketId: string, taskType: string, updateArgs: UpdateEntityDetail) => {
      dispatch(updateEntityDetails(ticketId, taskType, updateArgs));
    },
    updateTicket: (ticket: any) => {
      dispatch(updateTicket(ticket.id, ticket));
    },
    updateMetadata: (id: number, metadata: object) => {
      dispatch(updateMetadata(id, metadata));
    },
    addDelegateToContent: (ticket: any, usr: string) => {
      dispatch(addDelegateToContent(ticket.id, usr));
    },
    removeDelegateFromContent: (ticket: any, usr: string) => {
      dispatch(removeDelegateFromContent(ticket.id, usr));
    },
    addDelegateGroupToContent: (ticket: any, usrGroup: number[]) => {
      dispatch(addDelegateGroupToContent(ticket.id, usrGroup));
    },
    uploadFile: (ticketId: string, file: any) => {
      dispatch(uploadFile(ticketId, file));
    },
    editAttachment: (ticketId: string, attachmentId: string, body: any) => {
      dispatch(editAttachment(ticketId, attachmentId, body));
    },
    deprecateAttachment: (ticketId: string, attachmentId: string) => {
      dispatch(deprecateAttachment(ticketId, attachmentId));
    },
    unDeprecateAttachment: (ticketId: string, attachmentId: string) => {
      dispatch(unDeprecateAttachment(ticketId, attachmentId));
    },
    onSearchPreviousTickets: (entityId: string, entityType: string) => {
      dispatch(addContentListTab(entityId, 'TAB_NAME_CUSTOMER #' + entityId.toString().substring(0, 3), 'infopages'));
      dispatch(activateContentListTab(entityId, 'infopages'));
      dispatch(fetchTicketsByEntity(entityId, entityId, entityType, 'infopage'));
    },
    openTab: (id: string) => {
      SocketInstance.joinRoom(convertCaseNaming(id, 'string', 'infopage') as string);
      dispatch(addTab({ id, type: 'infopages' }));
    },
    changeEntityFields: (data: any, id: any, entityDisplayName: string) => {
      dispatch(changeEntity(data, id, entityDisplayName));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfopageCase);
