import { phone } from 'phone';
import EnvSettings from 'src/api/EnvSettings';

export enum COUNTRY_CODES {
  FIN = 'fin',
  BEL = 'bel'
}

/**
 * Normalize phone number to E.164 form, eg: +358407530715
 */
export function normalizePhoneNumber(num: string, defaultCountryCode = COUNTRY_CODES.FIN): string {
  const countryCode = EnvSettings.getSettings().PHONE_COUNTRY_CODE || defaultCountryCode;
  const country = typeof num === 'string' && num.startsWith('+') ? '' : countryCode;

  const result = phone(num, { country });

  return result.isValid ? result.phoneNumber : num;
}
