import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import AssetsApi from 'src/api/AssetsApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_ASSETS_SUCCESS, FETCH_ASSETS_FAILURE, FETCH_ASSET_SUCCESS, FETCH_ASSET_FAILURE } from './index';
import { initialRequestSuccess, initialRequestFailure } from './initActions';
import { updateTicketSuccess } from './ticketsActions';

export const loadAssetsSuccess = (assets: any[]) => {
  return { type: FETCH_ASSETS_SUCCESS, payload: assets };
};
export const loadAssetsFailure = (assets: any[]) => {
  return { type: FETCH_ASSETS_FAILURE, payload: assets };
};

export const loadAssetSuccess = (asset: any) => {
  return { type: FETCH_ASSET_SUCCESS, asset };
};
export const loadAssetFailure = (asset: any) => {
  return { type: FETCH_ASSET_FAILURE, asset };
};

export const fetchAssets: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (filter: string) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_ASSETS' }));
    return AssetsApi.getAssets()
      .then((assets) => {
        dispatch(loadAssetsSuccess(assets));
        dispatch(initialRequestSuccess('assets'));
      })
      .catch((error) => {
        console.error('Failed to fetch all assets', error);
        dispatch(loadAssetsFailure(error));
        dispatch(initialRequestFailure('assets', error));
      });
  };
};

export const fetchAsset: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (
  assetID: string,
  ticketID: string,
  fieldSetName: string
) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_ASSET' }));
    return AssetsApi.getAsset(assetID)
      .then((asset) => {
        dispatch(updateTicketSuccess(ticketID, { updateKey: fieldSetName, updateValue: asset, group: 'CaseDetails' }));
      })
      .catch((error) => {
        console.error('Failed to fetch a asset', error);
        dispatch(updateTicketSuccess(ticketID, { updateKey: fieldSetName, updateValue: false, group: 'CaseDetails' }));
      });
  };
};
