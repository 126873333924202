import FeatureFlags from '../FeatureFlags';

export function unifyTaskId(id: any): string | null {
  if (typeof id === 'number') return `${id.toString()}`;
  if (typeof id === 'string') {
    const sid = id.toUpperCase();
    if (sid.startsWith('TSK')) {
      return sid.substring(3);
    }
    return sid;
  }
  return null;
}

export function unifyUserId(id: any): number | null {
  if (typeof id === 'number') return id;
  if (typeof id === 'string') {
    const sid = id.toUpperCase();
    if (sid.startsWith('USR')) {
      const value = parseInt(sid.substring(3));
      if (!isNaN(value)) return value;
    } else {
      const value = parseInt(sid);
      if (!isNaN(value)) return value;
    }
  }
  return null;
}

export function getCurrentSeconds(): number {
  return Math.floor(new Date().getTime() / 1000);
}

export function consoleLogUserTimeLogs(...args: any[]) {
  if (!FeatureFlags.isFlagOn('ENABLE_USER_TIME_LOGGING') || !localStorage.getItem('debugUserTimeLogs')) return;

  console.log(...args);
}
