import { connect } from 'react-redux';
import type { AnyAction } from 'redux';
import type { History } from 'history';
import type { ThunkDispatch } from 'redux-thunk';

import TabBar from '../TabBar';
import { defaultTask } from '../api/Task';
import SocketInstance from '../realTimeNotifications';
import { activateTab, closeTab } from '../actions/tabActionsRTK';
import {
  activateTicket,
  createContent,
  deActivateTicket,
  fetchTickets,
  scrollCaseBottomInstantly
} from '../actions/ticketsActions';
import { CREATE_INFO_PAGE, CREATE_TICKET } from 'src/actions';
import { StaticTabs } from 'src/types/TicketList';
import { defaultInfoPage } from 'src/api/InfoPage';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

interface IProps {
  contentType: ContentTypesFields;
}

const mapStateToProps = (state: State, ownProps: IProps) => {
  return {
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
    tabs: state.ticketTabs.filter((tab) => tab.type === ownProps.contentType),
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    tickets: state.detailedTickets,
    isCreateTabInProgress: state.ajaxCallsInProgress.some((ajaxCall) => (
      [CREATE_TICKET, CREATE_INFO_PAGE].includes(ajaxCall.name)
    ))
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  let onCreateNewContent: (defaultTaskType: string, history: History) => void;

  switch (ownProps.contentType) {
    case 'tickets': {
      onCreateNewContent = async (defaultTaskType: string, history: History) => {
        const newTask = defaultTask(defaultTaskType);
        newTask.direction = null as unknown as string;

        const ticketData = await dispatch(createContent(newTask, true));
        history.push(`/case/${ticketData.id}`);
      };
      break;
    }
    case 'infopages': {
      onCreateNewContent = async (defaultTaskType: string, history: History) => {
        const newPage = defaultInfoPage(defaultTaskType);
        newPage.direction = null as unknown as string;

        const ticketData = await dispatch(createContent(newPage, true, 'infopages'));
        history.push(`/infopage/${ticketData.id}`);
      };
      break;
    }
  }

  return {
    method: ownProps.contentType,
    onCreateNewContent,
    onTabClose: (id: string, activeId?: string) => {
      dispatch(deActivateTicket(id));
      if (activeId) {
        dispatch(activateTicket(activeId));
        dispatch(activateTab(activeId));
      }
      SocketInstance.leaveRoom(id);
      dispatch(closeTab(id));
    },
    onActivateTab: (id: string) => {
      dispatch(activateTicket(id));
      dispatch(activateTab(id));
    },
    scrollCaseBottomInstantly: () => {
      dispatch(scrollCaseBottomInstantly());
    },
    refreshMainView: () => {
      // TODO typing
      (dispatch as ThunkDispatch<Promise<any>, any, AnyAction>)(fetchTickets({}, StaticTabs.MAIN_VIEW, false));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabBar);
