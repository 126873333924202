import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import ApiConfig from './api/ApiConfig';
import axios from 'axios';

class Internationalization {
  static config = i18n.use(Backend).use(initReactI18next);

  static init = async () => {
    await Internationalization.config.init({
      // have a common namespace used around the full app
      ns: ['translations'],
      defaultNS: 'translations',
      fallbackLng: ApiConfig.getConfig().DEFAULT_LANGUAGE || 'en',
      debug: false,
      interpolation: {
        escapeValue: false // not needed for react!!
      },
      react: {
        useSuspense: true
      }
    });
  };

  static fetchTranslations = async (url: string) => {
    try {
      const transFile = await axios.get(url);
      transFile.data.forEach((file: any) => {
        Internationalization.config.addResourceBundle(file.lanq, file.namespace, file.translations, true, true);
      });
    } catch (error) {
      console.log('Failed to load custom translations url', error);
      throw error;
    }
  };
}

export default Internationalization;
