import { isEqual, sortBy } from 'lodash';
import iziToast from 'izitoast';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, Checkbox, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import type { SemanticICONS } from 'semantic-ui-react';
import type { TFunction, TFunctionKeys } from 'i18next';

import FeatureFlags from 'src/api/FeatureFlags';
import { activateContentListTab, addContentListTab, setContentListSearch } from 'src/api/CaseListApi';
import { fetchTickets } from 'src/actions/ticketsActions';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import Info from '../Case/Info/Info';
import SearchConditionList from '../Search/SearchConditionList';
import SearchControls from '../Search/SearchControls';
import TextSearch from './TextSearch';
import { SearchEntitiesAccordion } from './SearchEntitiesAccordion';
import { SearchDateRanges, defaultDates } from '../Search/SearchDateRanges';
import { camelToSnakeCase } from 'src/Utilities/casing';
import { filterTagsShownToUser } from 'src/Utilities/tags';
import { formatSearch } from 'src/Utilities/search';
import { StaticTabs } from 'src/types/TicketList';
import { TicketDirectionValues } from 'src/types/Ticket';
import type { Tag } from 'src/types/Tag';
import type { Channel } from 'src/types/Channel';
import type { MenuTab } from 'src/types/MenuTab';
import type { TabFilter } from 'src/types/Filter';
import type { Category } from 'src/types/Category';
import type { TicketType } from 'src/types/TicketType';
import type { State } from 'src/types/initialState';
import type { FormattedSearch, SearchCriterion } from 'src/types/Search';
import type { PersonalData, User } from 'src/types/User';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { Field } from 'src/types/Info';
import type { TicketStatuses } from '../Case/contentStatuses';
import { getContentStatusOptions } from '../Case/contentStatuses';

interface SearchProps {
  contentType: ContentTypesFields;
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  tagCategories: Category[];
  users: User[];
  personalData: PersonalData;
  searchCriteria: SearchCriterion[];
  tabId: string;
  language: string;
  filters: { [x: string]: any };
  noDefaultDates?: boolean;

  onSubmit: (filter: FormattedSearch, id: string, searchParams: any[]) => Promise<any>;
  closePane?: () => void;
  t: TFunction;
}

interface SearchState {
  tags: Tag[];
  selectedTicketWords: string;
  titleSearch: string;
  statuses: TicketStatuses[];
  originalContactSearch: string;
  emailCc: string;
  lastContactAddress: string;
  emailTo: string;
  emailFrom: string;
  searchToggleAndOr: boolean;
  searchCriteria: SearchCriterion[];
}

interface OwnProps {
  contentType: ContentTypesFields;
  closePane?: () => void;
  t: TFunction;
}

interface StateProps {
  ticketTypes: TicketType[];
  channels: Channel[];
  tags: Tag[];
  tagCategories: Category[];
  users: User[];
  personalData: PersonalData;
  tabId: string;
  searchCriteria: SearchCriterion[];
  language: string;
  filters: TabFilter;
}

interface DispatchProps {
  onSubmit(filter: FormattedSearch, id: string, searchParams: any[]): Promise<any>;
}

type SearchField = Field & {
  value: keyof FormattedSearch['basic'] | keyof TabFilter;
  infoValue?: unknown;
};

interface ConditionalElement {
  conditional: boolean;
  element: () => JSX.Element;
}

type TextSearches = {
  [key: string]: (
    | [keyof Partial<SearchState>, string]
    | SearchField
    | ConditionalElement
    | SearchField[]
    | JSX.Element
  )[];
};

type TextSearchesAutocomplete = (keyof Partial<SearchState>)[];

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const tabs: MenuTab[] = {
    tickets: Object.values(state.ticketListTabs),
    infopages: [...state.infoPageListTabs.values()]
  }[ownProps.contentType];

  const activeTab = tabs.find((tab) => tab.activeTab)!;

  return {
    users: state.usersList.usersList,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    tabId: activeTab.id,
    searchCriteria: activeTab.searchCriteria,
    tags: state.tags,
    tagCategories: state.categories,
    personalData: state.userData,
    language: state.userData.language,
    filters: activeTab.filters!
  };
}

function mapDispatchToProps(dispatch: any, ownProps: OwnProps): DispatchProps {
  return {
    onSubmit: (filter: FormattedSearch, id: string, searchCriteria: SearchCriterion[]) => {
      if (id === StaticTabs.MAIN_VIEW) {
        id = moment().unix().toString();
      }

      const types = filter?.basic.type as ContentTypesFields[];
      const type = types[0];
      const { t } = ownProps;

      dispatch(addContentListTab(id, t('TAB_NAME_SEARCH'), type));
      dispatch(activateContentListTab(id, type));
      dispatch(setContentListSearch(id, searchCriteria, type));
      ownProps.closePane?.();

      switch (type) {
        case 'tickets':
          return dispatch(fetchTickets(filter, id, true));
        case 'infopages':
          return dispatch(fetchInfoPages(filter, id, true));
      }
    }
  };
}

class Search extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);

    this.state = {
      tags: [],
      selectedTicketWords: '',
      titleSearch: '',
      originalContactSearch: '',
      emailTo: '',
      statuses: [],
      emailFrom: '',
      emailCc: '',
      lastContactAddress: '',
      searchToggleAndOr: true,
      searchCriteria:
        props.searchCriteria && props.searchCriteria.length > 0
          ? props.searchCriteria
          : this.getDefaultSearchCriteria(this.props)
    };
  }

  componentWillReceiveProps(nextProps: SearchProps) {
    if (!isEqual(nextProps.searchCriteria, this.props.searchCriteria)) {
      this.setState({
        searchCriteria:
          nextProps.searchCriteria.length > 0 ? nextProps.searchCriteria : this.getDefaultSearchCriteria(nextProps)
      });
    }
  }

  private getDefaultSearchCriteria = (props: SearchProps) => {
    const { t } = this.props;
    const ticketTypeFromParams = props.searchCriteria?.find(
      (criteria: SearchCriterion) => criteria.param === 'ticketTypesOr'
    );

    const ticketType = props.ticketTypes.find((tType: TicketType) => tType.name === ticketTypeFromParams?.value);

    const searchTicketType = ticketType
      ? ticketType
      : props.ticketTypes.find(
          (tType: TicketType) => tType.id === props.personalData.userPreferences.defaultTicketType
        );

    const defaultSearch = [];
    if (FeatureFlags.isFlagOn('ENABLE_SEARCH_DEFAULT_TERMS')) {
      defaultSearch.push({
        name: t('search.ticket.type_or'),
        param: 'ticketTypesOr',
        text: String(searchTicketType?.name),
        value: searchTicketType?.name,
        datagroup: 'basic',
        object: false
      });

      const { touchedAfter } = defaultDates();
      defaultSearch.push({
        name: t(`search.date.touched_after`),
        param: 'touchedAfter',
        text: touchedAfter,
        value: touchedAfter,
        datagroup: 'basic',
        object: false
      });
    }

    return defaultSearch;
  };

  onClear = () => {
    const { t } = this.props;
    iziToast.warning({ message: t('search.reset') });
    this.setState({
      searchCriteria: []
    });
  };

  onSubmit = () => {
    const { t } = this.props;
    iziToast.info({
      message: t('search.in_progress'),
      displayMode: 1,
      timeout: false,
      id: 'searchToast',
      target: '.toastSearchTarget'
    });
    if (Object.keys(this.props.filters).length > 0) {
      iziToast.info({
        message: t('search.in_progress'),
        timeout: 7500,
        displayMode: 1,
        id: 'toastSearchFilterTarget',
        target: '.toastSearchFilterTarget'
      });
    }

    const searchParams = formatSearch(this.state.searchCriteria, this.props.personalData.UID);
    searchParams.basic.type = [this.props.contentType];
    this.props.onSubmit(searchParams, this.props.tabId, this.state.searchCriteria);
  };

  onSave = (
    param: keyof FormattedSearch['basic'],
    value: string | boolean | Tag[] | null | undefined,
    object: any,
    partial: boolean,
    name: string,
    text?: string,
    datagroup?: string,
    entityType?: string
  ) => {
    if (datagroup === undefined) {
      datagroup = 'basic';
    }
    if (param === 'selectedTicketWords') {
      this.setState({
        tags: value as Tag[]
      });
    }
    if (param === 'searchToggleAndOr') {
      this.setState({
        searchToggleAndOr: value as boolean
      });
    }

    this.setState(
      (previousState: any) => {
        const newSearchCriteria = [...previousState.searchCriteria];
        const oldSearchCriterionIndex = newSearchCriteria.findIndex((criterion: SearchCriterion) => {
          return criterion.param === param;
        });

        const oldSearchCriterion = newSearchCriteria[oldSearchCriterionIndex];
        if (value == null || value === '' || value === false) {
          const nonDeleteParams = ['selectedTicketTagsAnd', 'selectedTicketTagsOr', 'selectedTicketTagCategoriesOr'];
          if (oldSearchCriterion && !nonDeleteParams.includes(param)) {
            this.onDelete(oldSearchCriterion);
          }

          return previousState;
        }

        if (oldSearchCriterion) {
          const multipleSearchParams: (keyof FormattedSearch['basic'])[] = [
            'ticketTypesOr',
            'contactChannel',
            'selectedTicketTagsAnd',
            'selectedTicketTagsOr',
            'statuses',
            'selectedTicketTagCategoriesOr',
            'workedByUsers',
            'delegatedTo'
          ];
          if (multipleSearchParams.includes(param)) {
            newSearchCriteria.push({
              param,
              value,
              text,
              name,
              datagroup,
              object
            });
          } else {
            const fallbackCriteria = { ...oldSearchCriterion };
            fallbackCriteria.value = value;
            if (text) {
              fallbackCriteria.text = text;
            }
            newSearchCriteria[oldSearchCriterionIndex] = fallbackCriteria;
          }
        } else {
          newSearchCriteria.push({
            param,
            value,
            text,
            name,
            datagroup,
            object
          });
        }

        return {
          searchCriteria: newSearchCriteria
        };
      },
      () => {
        if (entityType) {
          this.onSubmit();
        }
      }
    );
  };

  onDelete = (item: SearchCriterion) => {
    this.setState((previousState) => ({
      searchCriteria: previousState.searchCriteria.filter((criterion) => criterion !== item)
    }));
  };

  translateSearchCriteriaToFieldValues = (searchCriteria: Array<SearchCriterion>) => {
    const fieldValues = {};
    searchCriteria.forEach((searchCriterion: SearchCriterion) => {
      fieldValues[searchCriterion.param] = searchCriterion.value;
    });
    return fieldValues;
  };

  // TODO make the whole component use hooks
  SearchPanel = (props: SearchProps) => {
    const t = this.props.t as (key: TFunctionKeys | TFunctionKeys[]) => string;
    const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({
      'search.terms': true,
      'search.basic_data': true
    });

    const isExclusiveSearchChecked =
      this.state.searchCriteria.find((criteria) => {
        return criteria.param === 'searchToggleAndOr';
      }) != null;

    const ticketTypes = sortBy(
      props.ticketTypes.map(
        (x) => ({
          name: x.name,
          value: x.name
        }),
        'value'
      )
    );

    const channels = props.channels.map((x) => ({
      name: t([`CHANNEL_${x.channel.toUpperCase()}`, x.channel]),
      value: x.id,
      icon: (x.icon || 'question circle') as SemanticICONS
    }));

    const tags = filterTagsShownToUser(props.tags, props.personalData.ticketTypes).map((x) => ({
      name: x.name,
      value: x.id
    }));

    const tagCategories = props.tagCategories.map((category) => ({
      name: category.name,
      value: category.id
    }));

    const users = props.users.map((user) => ({
      name: `${user.profile.firstName} ${user.profile.lastName}`,
      value: user.UID.substring(3)
    }));

    const statuses = getContentStatusOptions(this.props.contentType, t).map((x) => ({
      name: x.text,
      value: x.value,
      icon: x.icon as SemanticICONS
    }));

    const directions = TicketDirectionValues.map((dir) => ({
      name: t(`search.direction.${dir}`),
      value: dir
    }));

    const criteriaTicketTypes: string[] = this.state.searchCriteria
      .filter((c) => c.param === 'ticketTypesOr')
      ?.map((c) => c.value);
    const filteredTicketTypes = props.ticketTypes.filter((type) => criteriaTicketTypes.includes(type.name));
    if (filteredTicketTypes.length === 0) {
      const defaultType = props.ticketTypes.find((type) => props.personalData.ticketTypes.includes(type.id))!;
      filteredTicketTypes.push(defaultType);
    }

    const searches: TextSearches = {
      'search.basic_data': [
        ['titleSearch', t('search.ticket.title')],
        ['selectedTicketWords', t('search.ticket.content')],
        [
          {
            name: t('search.ticket.type_or'),
            value: 'ticketTypesOr',
            options: ticketTypes
          } as any,
          {
            name: t('search.ticket.channel'),
            value: 'contactChannel',
            options: channels
          },
          {
            name: t('search.ticket.tags_and'),
            value: 'selectedTicketTagsAnd',
            options: tags,
            infoValue: undefined
          },
          {
            name: t('search.ticket.tags_or'),
            value: 'selectedTicketTagsOr',
            options: tags,
            infoValue: undefined
          },
          {
            name: t('GENERAL_STATUS'),
            value: 'statuses',
            options: statuses
          },
          {
            name: t('search.ticket.tag_categories_or'),
            value: 'selectedTicketTagCategoriesOr',
            options: tagCategories,
            infoValue: undefined
          }
        ],
        {
          conditional: FeatureFlags.isFlagOn('ENABLE_EXCLUSIVE_SEARCH'),
          element: () => (
            <Checkbox
              toggle={true}
              style={{ marginTop: '13px' }}
              checked={isExclusiveSearchChecked}
              onChange={(e, data) => {
                this.onSave('searchToggleAndOr', data.checked, false, true, '', t('search.exclusive'), 'basic');
              }}
              label={t('search.exclusive')}
            />
          )
        }
      ],
      'search.dates': [
        <SearchDateRanges
          noDefaultDates={this.props.noDefaultDates}
          values={this.state.searchCriteria}
          onChange={(date, timestamp) => {
            this.onSave(
              date,
              timestamp,
              undefined,
              false,
              t(`search.date.${camelToSnakeCase(date)}`),
              timestamp,
              'basic'
            );
          }}
        />
      ],
      'search.addresses': [
        ['originalContactSearch', t('search.original_contact')],
        ['lastContactAddress', t('search.email.last_contact_address')],
        ['emailTo', t('search.email.recipient')],
        ['emailFrom', t('search.email.sender')],
        ['emailCc', t('search.email.cc')]
      ],
      'search.ticket.details': [
        [
          {
            name: t('search.ticket.author'),
            value: 'ticketAuthorId',
            options: users
          },
          {
            name: t('search.last_edited_by'),
            value: 'ticketEditedById',
            options: users
          },
          {
            name: t('search.ticket.dont_show_done'),
            value: 'showOnlyNotReady',
            switch: [
              { name: t('YES'), value: true },
              { name: t('NO'), value: false }
            ]
          },
          {
            name: t('search.worked_by_me'),
            value: 'workedBy',
            switch: [
              { name: t('YES'), value: true },
              { name: t('NO'), value: false }
            ]
          },
          {
            name: t('search.delegated_to_me'),
            value: 'delegatedToMe',
            switch: [
              { name: t('YES'), value: true },
              { name: t('NO'), value: false }
            ]
          },
          {
            name: t('search.worked_by_users'),
            value: 'workedByUsers',
            options: users,
            infoValue: undefined
          },
          {
            name: t('search.delegated_to_users'),
            value: 'delegatedTo',
            options: users,
            infoValue: undefined
          },
          {
            name: t('search.original_direction'),
            value: 'originalDirection',
            options: directions
          }
        ]
      ],
      'search.entities': [
        // TODO special parameters passed to Info.tsx
        <SearchEntitiesAccordion
          ticketTypes={filteredTicketTypes}
          defaultTicketType={props.personalData.userPreferences.defaultTicketType}
          onSave={this.onSave}
          criteriaToFieldValues={this.translateSearchCriteriaToFieldValues}
          criteria={this.state.searchCriteria}
        />
      ]
    };

    const autocompleteSearches: TextSearchesAutocomplete = ['emailTo', 'emailFrom', 'emailCc', 'lastContactAddress'];

    return (
      <Segment className="searchComponent">
        <SearchControls onSubmit={this.onSubmit} onClear={this.onClear} />
        <Divider />
        <Accordion>
          <Accordion.Title
            active={openAccordions['search.terms']}
            onClick={() =>
              setOpenAccordions((old) => ({
                ...old,
                'search.terms': !old['search.terms']
              }))
            }
          >
            <Header as="h4">
              <Icon name="dropdown" />
              {`${t('search.terms')} (${this.state.searchCriteria.length})`}
            </Header>
          </Accordion.Title>
          <Accordion.Content
            active={openAccordions['search.terms']}
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
          >
            <SearchConditionList size="large" items={this.state.searchCriteria} onDelete={this.onDelete} />
          </Accordion.Content>
        </Accordion>
        {Object.entries(searches).map((search) => {
          const key = search[0];
          const value = search[1];

          return (
            <Accordion>
              <Accordion.Title
                active={openAccordions[key]}
                onClick={() =>
                  setOpenAccordions((old) => ({
                    ...old,
                    [key]: !old[key]
                  }))
                }
              >
                <Header as="h4">
                  <Icon name="dropdown" />
                  {t(key)}
                </Header>
              </Accordion.Title>
              <Accordion.Content active={openAccordions[key]}>
                {value.map((field) => {
                  if (Array.isArray(field)) {
                    if (typeof field[0] === 'string') {
                      field = field as [keyof SearchState, string];
                      const key = field[0];
                      const name = field[1];
                      const autocomplete = autocompleteSearches.includes(key);

                      return (
                        <TextSearch
                          id={key as keyof FormattedSearch['basic']} // TODO typing
                          name={name}
                          onSave={this.onSave.bind(this)}
                          onSet={(value) => {
                            // Check TextSearches type
                            this.setState({ [key]: value } as any);
                          }}
                          autocomplete={autocomplete}
                        />
                      );
                    } else {
                      field = field as SearchField[];
                      const values = field.reduce((obj, f) => {
                        obj[f.value] = f.infoValue;
                        return obj;
                      }, {});

                      return (
                        <Info
                          fields={field}
                          values={values}
                          onSave={this.onSave}
                          params={{ ignoreFieldDisabling: true }}
                          language={props.language}
                        />
                      );
                    }
                  }

                  if ('conditional' in field && 'element' in field) {
                    if (!field.conditional) {
                      return <></>;
                    }

                    return field.element();
                  }

                  if ('value' in field && 'infoValue' in field) {
                    return (
                      <Info
                        fields={[field]}
                        values={{ [field.value]: field.infoValue }}
                        onSave={this.onSave}
                        params={{ ignoreFieldDisabling: true }}
                        language={props.language}
                      />
                    );
                  }

                  return field;
                })}
              </Accordion.Content>
            </Accordion>
          );
        })}
      </Segment>
    );
  };

  render() {
    return <this.SearchPanel {...this.props} />;
  }
}

export default withTranslation('translations')(
  connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps)(Search)
);
