import { t as tr } from 'i18next';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';
import type { AnyAction } from 'redux';

import {
  changeEntity,
  deprecateAttachment,
  editAttachment,
  fetchTickets,
  unDeprecateAttachment,
  updateMetadata,
  updateTicket,
  updateSingleTicketCaseDetail,
  uploadFile
} from 'src/actions/ticketsActions';
import FeatureFlags from 'src/api/FeatureFlags';
import { activateContentListTab, addContentListTab, setContentListSearch } from 'src/api/CaseListApi';
import AssetContainer from 'src/containers/AssetContainer';
import ArchivalContainer from 'src/containers/ArchivalContainer';
import AccordionHeader from '../AccordionHeader';
import Attachments from '../Attachments';
import ExternalLinksList from '../ExternalLinksList';
import { MetadataEditor } from '../Metadata/MetadataEditor';
import AttachEntityById from './AttachEntityById';
import CaseChannel from './CaseChannel';
import CustomerInfo from './CustomerInfo';
import Delegations from './Delegations';
import FieldSets from './FieldSets';
import HandleStatus from './HandleStatus';
import PriorityWidget from './PriorityWidget';
import AttachmentsLightbox from '../AttachmetsLightbox/AttachmentsLightbox';
import SurveyContainer from './Survey/SurveyContainer';
import TagsWidget from 'src/Components/Case/Widget/TagsWidget';
import TicketRelations from './TicketRelationsWidget/TicketRelations';
import { getWidgetOrder } from 'src/Components/Utilities/widgets';
import { formatSearch } from 'src/Utilities/search';
import SuggestedArticles from 'src/Components/Case/InfopageCase/Widget/SuggestedArticles/SuggestedArticles';
import type { AttachmentEdit } from '../AttachmentItem';
import type { FieldSet } from 'src/types/TicketType';
import type { State } from 'src/types/initialState';
import type { Channel } from 'src/types/Channel';
import type { SearchCriterion } from 'src/types/Search';
import type { RelationOptions } from 'src/types/LinkedTickets';
import type { Ticket } from 'src/types/Ticket';
import type { Field } from 'src/types/Info';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WidgetsProps extends WidgetsReduxProps {}

interface WidgetDataState {
  assetInfoDisplayName: string;
  assetInfoFields: Field[];
  detailGroupMap: Map<string, string>;
  entityInfo: Field[];
  entityInfoHeader: string;
  entitySearchButton: string;
  fieldMap: Map<string, Field>;
  fieldSetNames: string[];
  handleInfo: Field[];
  fieldSetsMap: Map<string, FieldSet[]>;
}

const Widgets = ({
  task,
  channelTypes,
  ticketPriorities,
  ticketTypes,
  userData,
  externalLinks,
  updateMetadata,
  changeEntity,
  fireTicketSearch,
  uploadFile,
  editAttachment,
  deprecateAttachment,
  unDeprecateAttachment,
  updateTicket
}: WidgetsProps) => {
  const { t } = useTranslation();
  const [widgetData, setWidgetData] = useState({
    assetInfoDisplayName: '',
    assetInfoFields: [],
    detailGroupMap: new Map(),
    entityInfo: [],
    entityInfoHeader: t('CASE_TITLE_CUSTOMER'),
    entitySearchButton: '',
    fieldMap: new Map(),
    fieldSetNames: [],
    handleInfo: [],
    fieldSetsMap: new Map()
  } as WidgetDataState);

  const ticketPriorityLevels = ticketPriorities.map((ticketPriority) => ({
    ...ticketPriority,
    text: t(ticketPriority.text)
  }));

  useEffect(() => {
    const widgetDataPayload: WidgetDataState = {
      ...widgetData,
      entityInfo: [],
      handleInfo: [],
      fieldSetsMap: new Map()
    };

    if (task.entityFields) {
      widgetDataPayload.entityInfo = task.entityFields;
      widgetDataPayload.entityInfoHeader = task.entityFieldDisplayName ?? '';
    }

    if (ticketTypes?.length > 0 && !task.entityFields) {
      ticketTypes.forEach((ticketType: any) => {
        widgetDataPayload.fieldSetsMap.set(ticketType.name, ticketType.fieldSets);
      });

      widgetDataPayload.detailGroupMap = new Map();
      widgetDataPayload.fieldMap = new Map();
      widgetDataPayload.fieldSetNames = [];

      const ticketType = ticketTypes.find((type) => type.name === task.taskType)!;
      const fieldSets = widgetDataPayload.fieldSetsMap.get(task.taskType);
      if (fieldSets?.length) {
        fieldSets.forEach((fieldSet) => {
          if (fieldSet.id === 'caseInfo') {
            widgetDataPayload.entitySearchButton = fieldSet.customSearch!;
          }

          if (fieldSet.id === 'customerInfo') {
            widgetDataPayload.entityInfo = fieldSet[fieldSet.id]!;
            widgetDataPayload.entityInfoHeader = ticketType.defaultFieldSet
              ? ticketType.defaultFieldSet
              : fieldSet.displayName;
          } else if (fieldSet.id === 'status') {
            widgetDataPayload.handleInfo = fieldSet[fieldSet.id];
          } else if (fieldSet.id === 'assetInfo') {
            widgetDataPayload.assetInfoDisplayName = fieldSet.displayName;
            widgetDataPayload.assetInfoFields = fieldSet[fieldSet.id];
            widgetDataPayload.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
          } else {
            widgetDataPayload.fieldSetNames.push(fieldSet.displayName);
            widgetDataPayload.detailGroupMap.set(fieldSet.displayName, fieldSet.group);
            widgetDataPayload.fieldMap.set(fieldSet.displayName, fieldSet[fieldSet.id]);
          }
        });
      }
    }

    setWidgetData(widgetDataPayload);
  }, [task.entityFields, task.taskType, ticketTypes]);

  const relationOptions: RelationOptions = [
    {
      text: t('TICKET_RELATIONS_CHOICE_PARENT_TO_CHILD'),
      value: 'parentToChild'
    },
    {
      text: t('TICKET_RELATIONS_CHOICE_CHILD_TO_PARENT'),
      value: 'childToParent'
    }
  ];

  const handleChannelChange = (channel: number) => {
    if (task) {
      updateTicket(task.id, {
        ...task,
        channel
      });
    }
  };

  const handlePriorityChange = (priority: any) => {
    if (task) {
      updateTicket(task.id, {
        ...task,
        priority
      });
    }
  };

  const fireCaseInfoSearch = (fields: any[], values: any[]) => {
    const searchIncludedObjs = filter(fields, (field) => (field.params.includeToSearch = true));
    const searchArray: SearchCriterion[] = searchIncludedObjs.map((x) => ({
      value: values[x.value],
      datagroup: 'detail',
      name: x.value,
      object: false,
      param: x.value,
      text: ''
    }));

    fireTicketSearch(searchArray, userData.UID);
  };

  const changeEntityFields = (entityName: string, id: string) => {
    const tType = ticketTypes.find(
      (type) => type.name === task.taskType && type.fieldSets.some((field) => field.displayName === entityName)
    );
    const data = filter(tType!.fieldSets, (field) => field.displayName === entityName);

    changeEntity(data[0][id], task.id, entityName);
  };

  const {
    assetInfoDisplayName,
    assetInfoFields,
    detailGroupMap,
    entityInfo,
    entityInfoHeader,
    entitySearchButton,
    fieldMap,
    fieldSetNames,
    handleInfo
  } = widgetData;

  return (
    <>
      {getWidgetOrder(task, ticketTypes).map((widget, index) => {
        const widgetOpen = !widget.defaultClosed;
        const widgetName = widget.name;

        return (
          <React.Fragment key={`case-widget-${index}`}>
            {widgetName === 'SuggestedArticles' && FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') && (
              <SuggestedArticles id="suggested-articles-widget" key={`suggested-articles-widget-${index}`} />
            )}

            {widgetName === 'Metadata' && (
              <MetadataEditor
                key={`case-metadata-editor-${index}`}
                disabled={false}
                metadata={task.metaData}
                onSave={(data: object) => {
                  updateMetadata(task.id as any, data);
                }}
              />
            )}

            {widgetName === 'Priority' && (
              <PriorityWidget
                task={task}
                ticketPriorityLevels={ticketPriorityLevels}
                widgetOpen={widgetOpen}
                handlePriorityChange={handlePriorityChange}
                ind={index}
              />
            )}

            {widgetName === 'CaseChannel' && (
              <CaseChannel
                task={task}
                channelTypes={channelTypes}
                widgetOpen={widgetOpen}
                ind={index}
                handleChannelChange={handleChannelChange}
                widget={widget}
              />
            )}

            {widgetName === 'HandleStatus' && handleInfo.length !== 0 && (
              <HandleStatus task={task} widgetOpen={widgetOpen} ind={index} handleInfo={handleInfo} />
            )}

            {widgetName === 'AttachEntityById' && (
              <AttachEntityById
                id="widget-attach-entity-by-id"
                task={task}
                widgetOpen={widgetOpen}
                ind={index}
                entityInfoHeader={entityInfoHeader}
                entityInfo={entityInfo}
                changeEntity={changeEntityFields}
              />
            )}

            {widgetName === 'CustomerInfo' && (
              <CustomerInfo
                task={task}
                widgetOpen={widgetOpen}
                fireSearch={(value: any, type: string) => {
                  const searchFormat: SearchCriterion[] = [
                    {
                      value: value,
                      datagroup: 'entity',
                      name: type,
                      object: false,
                      param: type,
                      text: ''
                    }
                  ];
                  if (FeatureFlags.isFlagOn('LIMIT_CLICKTOSEARCH_ENTITYTYPES')) {
                    searchFormat.push({
                      param: 'ticketTypesOr',
                      name: 'ticketTypesOr',
                      value: task.taskType,
                      datagroup: 'basic'
                    });
                  }

                  fireTicketSearch(searchFormat, userData.UID);
                }}
              />
            )}

            {widgetName === 'FieldSets' && (
              <FieldSets
                task={task}
                fireTicketSearch={fireTicketSearch}
                widgetOpen={widgetOpen}
                fieldSetNames={fieldSetNames}
                ind={index}
                entitySearchButton={entitySearchButton}
                fieldMap={fieldMap}
                detailGroupMap={detailGroupMap}
                fireCaseInfoSearch={fireCaseInfoSearch}
              />
            )}

            {widgetName === 'Delegations' && <Delegations task={task} widgetOpen={widgetOpen} widget={widget} />}

            {widgetName === 'AssetInfo' && (
              <AssetContainer
                onSave={(editedField: any, editedValue: any, detailGroup: any) => {
                  updateSingleTicketCaseDetail(task.id, editedField, editedValue, detailGroup);
                }}
                fieldSetName={assetInfoDisplayName}
                fields={assetInfoFields}
                detailGroup={detailGroupMap.get(assetInfoDisplayName)}
                values={task.case || {}}
                task={task}
                organizationTag={'CAT86'}
                assetTypeTag={'CAT87'}
                showAssetMeta={true}
              />
            )}

            {widgetName === 'TicketRelations' && (
              <div className={'TicketRelationsContainer'}>
                <AccordionHeader
                  compact={true}
                  as="h4"
                  active={widgetOpen}
                  title={t('CASE_TITLE_TICKETRELATIONS')}
                  icon="info circle"
                  key={'case-ticket-relation-accordion-header-1'}
                >
                  <TicketRelations
                    type={'task'}
                    task={task}
                    relationOptions={relationOptions}
                    createText={t('CASE_CREATE_RELATED_TICKET')}
                    attachPlaceholderText={t('CASE_ADD_TICKET_RELATION_BY_ID')}
                    parentsText={t('CASE_TICKETRELATIONS_PARENTS')}
                    childrenText={t('CASE_TICKETRELATIONS_CHILDREN')}
                    sameOriginText={t('CASE_TICKETRELATIONS_SAME_ORIGIN')}
                  />
                </AccordionHeader>
              </div>
            )}

            {widgetName === 'Tags' && (
              <TagsWidget
                displayName={widget.displayName || t('CASE_TITLE_TAGS')}
                widget={widget}
                widgetOpen={widgetOpen}
              />
            )}

            {widgetName === 'Archive' && (
              <AccordionHeader
                id="widget-archive"
                as="h4"
                active={widgetOpen}
                title={t('CASE_TITLE_ARCHIVE')}
                icon="archive"
              >
                <ArchivalContainer />
              </AccordionHeader>
            )}
            {widgetName === 'ExternalLinks' &&
              externalLinks &&
              externalLinks
                .filter(
                  (linklist) =>
                    linklist.ticketTypes?.find?.((type) => type === task.taskType) || linklist.ticketTypes === undefined
                )
                .map((linklist) => (
                  <AccordionHeader
                    as="h4"
                    active={widgetOpen}
                    title={linklist.listname}
                    icon="info circle"
                    key={`case-external-link-accordion-header-${linklist._id}`}
                  >
                    <ExternalLinksList links={linklist.links} task={task} />
                  </AccordionHeader>
                ))}

            {widgetName === 'Attachments' && (
              <>
                <AttachmentsLightbox ticketId={task.id} attachments={task.attachments} />
                <AccordionHeader
                  as="h4"
                  active={widgetOpen}
                  title={`${t('CASE_TITLE_ATTACHMENTS')} (${
                    filter(task.attachments, (att) => !att['deprecated']).length
                  })`}
                  icon="attach"
                >
                  <Attachments
                    onUpload={(files: File[]) => {
                      files.forEach((file: File) => {
                        const data = new FormData();
                        data.append('attachments', file);
                        uploadFile(task.id, data);
                      });
                    }}
                    dropZoneEnabled={userData.permissions.includes('updateContent')}
                    onEdit={(attachmentId: string, body: AttachmentEdit) => {
                      editAttachment(task.id, attachmentId, body);
                    }}
                    onDeprecate={(attachmentId: string) => {
                      deprecateAttachment(task.id, attachmentId);
                    }}
                    onUnDeprecate={(attachmentId: string) => {
                      unDeprecateAttachment(task.id, attachmentId);
                    }}
                    attachments={task.attachments}
                  />
                </AccordionHeader>
              </>
            )}

            {widgetName === 'Surveys' && FeatureFlags.isFlagOn('ENABLE_SURVEY_TICKET_INFO') === true && (
              <AccordionHeader as="h4" active={widgetOpen} title={`${t('CASE_SURVEYS')}`} icon="talk">
                <SurveyContainer taskId={task.id} />
              </AccordionHeader>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const connector = connect(
  (state: State) => {
    const task = state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!;

    return {
      task,
      userData: state.userData,
      externalLinks: state.linkLists,
      ticketPriorities: state.ticketPriorities,
      channelTypes: state.channels
        .filter((channel: Channel) => channel.active)
        .map((channelType: Channel) => ({
          text: channelType.channel,
          value: channelType.id,
          icon: channelType.icon
        })),
      ticketTypes: state.ticketTypes
    };
  },
  (dispatch: ThunkDispatch<State, any, AnyAction>) => ({
    updateMetadata: (id: number, metadata: { [key: string]: any }) => {
      dispatch(updateMetadata(id, metadata));
    },
    updateTicketCaseDetails: (ticketId: string, updateKey: string, updateValue: any, group: string) => {
      dispatch(updateSingleTicketCaseDetail(ticketId, updateKey, updateValue, group));
    },
    uploadFile: (ticketId: string, file: FormData) => {
      dispatch(uploadFile(ticketId, file));
    },
    editAttachment: (ticketId: string, attachmentId: string, body: AttachmentEdit) => {
      dispatch(editAttachment(ticketId, attachmentId, body));
    },
    deprecateAttachment: (ticketId: string, attachmentId: string) => {
      dispatch(deprecateAttachment(ticketId, attachmentId));
    },
    unDeprecateAttachment: (ticketId: string, attachmentId: string) => {
      dispatch(unDeprecateAttachment(ticketId, attachmentId));
    },
    updateTicket: (id: string, ticket: Partial<Ticket>, closeAfterUpdate?: boolean) => {
      dispatch(updateTicket(id, ticket, closeAfterUpdate));
    },
    fireTicketSearch: (searchCriteria: SearchCriterion[], uid: string) => {
      const id = String(moment().unix());
      const searchParams = formatSearch(searchCriteria, uid);
      dispatch(addContentListTab(id, tr('TAB_NAME_SEARCH'), 'tickets'));
      dispatch(setContentListSearch(id, searchCriteria, 'tickets'));
      dispatch(activateContentListTab(id, 'tickets'));
      dispatch(fetchTickets(searchParams, id));
    },
    changeEntity: (data: any, id: any, entityDisplayName: string) => {
      dispatch(changeEntity(data, id, entityDisplayName));
    }
  })
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WidgetsReduxProps extends ConnectedProps<typeof connector> {}

export default connector(Widgets);
