import { ADD_CALL_REQUEST, REMOVE_CALL_REQUEST } from '../actions/index';
import { CallRequestPayload } from '../actions/phoneActions';

const initialState: CallRequestPayload[] = [];

const phoneReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_CALL_REQUEST: {
      return [
        ...state.filter((request) => {
          const payload = action as CallRequestPayload;
          const sameNumber = request.phoneNumber === payload.phoneNumber;
          const sameTicket = request.ticketId === payload.ticketId;
          return !sameNumber || !sameTicket;
        }),
        action.payload
      ];
    }
    case REMOVE_CALL_REQUEST: {
      return [
        ...state.filter((request) => {
          const payload = action as CallRequestPayload;
          const sameNumber = request.phoneNumber === payload.phoneNumber;
          const sameTicket = request.ticketId === payload.ticketId;
          return !sameNumber || !sameTicket;
        })
      ];
    }
    default:
      return state;
  }
};

export default phoneReducer;
