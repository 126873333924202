import classNames from 'classnames';
import type { ChangeEvent, VFC } from 'react';
import React from 'react';
import { Input, Table } from 'semantic-ui-react';
import type { InfoFieldProps } from './InfoFieldProps';

interface PhoneNumberProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  fieldValue: any;
  inputType: 'text' | 'number';
  disabled: boolean;
  mongoSaveDisabled: boolean;
  integrationSaveDisabled: boolean;
}

const PhoneNumber: VFC<PhoneNumberProps> = ({
  field,
  fieldValue,
  inputType,
  disabled,
  mongoSaveDisabled,
  integrationSaveDisabled,
  setValue,
  onSaveData
}) => {
  const inputClassNames = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  return (
    <Table.Cell>
      <Input
        type={inputType}
        disabled={disabled}
        className={inputClassNames}
        size="mini"
        onChange={(e) => {
          if (!integrationSaveDisabled && !mongoSaveDisabled) {
            setValue?.(field, e.target.value);
          }
        }}
        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
          if (!integrationSaveDisabled && !mongoSaveDisabled) {
            onSaveData?.(field, e.target.value);
          }
        }}
        value={fieldValue || ''}
        fluid={true}
      />
    </Table.Cell>
  );
};

export default React.memo(PhoneNumber);
