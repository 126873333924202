import axios from 'axios';
import ApiConfig from './ApiConfig';
import { ResponseTemplate } from '../types/ResponseTemplate';

export default class ResponseTemplatesApi {
  static getTemplateTypeParams = (type: string, entityId: string, entityType: string, ticketId: string): any => {
    return axios
      .get(
        ApiConfig.getConfig().API_URL +
          '/responseTemplates/type/' +
          type +
          '/' +
          entityId +
          '/' +
          entityType +
          '/' +
          ticketId
      )
      .then((response) => response.data);
  };
  static getTemplate = (id: string): Promise<ResponseTemplate> => {
    return axios.get(ApiConfig.getConfig().API_URL + 'responseTemplates/' + id).then((response) => response.data);
  };
  static createTemplate = (template: ResponseTemplate): Promise<ResponseTemplate> => {
    return axios.post(ApiConfig.getConfig().API_URL + '/responseTemplates', template).then((response) => response.data);
  };
  static updateTemplate = (template: ResponseTemplate): Promise<ResponseTemplate> => {
    return axios
      .put(ApiConfig.getConfig().API_URL + '/responseTemplates/' + template._id, template)
      .then((response) => response.data);
  };
  static deleteTemplate = (id: string): Promise<any> => {
    return axios
      .delete(ApiConfig.getConfig().API_URL + '/responseTemplates/' + id, {
        data: {
          _id: id
        }
      })
      .then((response) => response.data);
  };
}
