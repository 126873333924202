import axios from 'axios';
import type { AxiosResponse } from 'axios';

import ApiConfig from './ApiConfig';
import type { ListArticle } from '../types/Articles';
import type { KnowledgeBaseSettingsData } from 'src/types/KnowledgeBaseSettings';

const extractData = <T>(response: AxiosResponse<T>) => response.data;

export default class KBApi {
  static getSettings = (): Promise<KnowledgeBaseSettingsData> =>
    axios
      .get(`${ApiConfig.getConfig().API_URL}/knowledge/settings`)
      .then(extractData)
      .catch((error) => {
        console.error('Could not get kb settings');
        throw error;
      });

  static updateSettings = (data: {
    oldSettings: KnowledgeBaseSettingsData;
    newSettings: KnowledgeBaseSettingsData;
  }): Promise<KnowledgeBaseSettingsData> =>
    axios
      .put<KnowledgeBaseSettingsData>(`${ApiConfig.getConfig().API_URL}/knowledge/settings`, data)
      .then(extractData)
      .catch((error) => {
        console.error('Could not update kb settings');
        throw error;
      });

  static getRelatedArticles = ({
    articleId,
    term,
    signal
  }: {
    articleId: string;
    term?: string;
    signal?: AbortSignal;
  }) =>
    axios
      .post<ListArticle[]>(`${ApiConfig.getConfig().API_URL}/knowledge/suggested`, { articleId, term }, { signal })
      .then(extractData)
      .then((items) =>
        items.map((i) => ({ ...i, tags: typeof i.tags === 'string' ? (i.tags as never as string).split(',') : i.tags }))
      );

  static searchArticles = (term: string, signal?: AbortSignal) =>
    axios
      .post<ListArticle[]>(`${ApiConfig.getConfig().API_URL}/knowledge/search`, { term }, { signal })
      .then(extractData);
}
