import * as React from 'react';
import { Form, StrictDropdownProps } from 'semantic-ui-react';
import iziToast from 'izitoast';
import { t as tr } from 'i18next';

interface TitleTemplate {
  _id: string;
  ticketTypes: string[];
  content: string;
}

interface TitleTemplatesProps {
  className?: string;
  title: string;
  ticketType: string;
  templates: Array<TitleTemplate>;

  // TODO typing
  updateTitle: Function;
}

const onChange = (update: StrictDropdownProps['value'], updateStateFunction: Function, content: string) => {
  if (update && content && content.length > 0 && content !== '') {
    iziToast.question({
      timeout: 0,
      close: false,
      overlay: true,
      id: 'question',
      zindex: 999,
      message: tr('TEMPLATE_TEXT_AREA_ALREADY_HAS_TEXT'),
      position: 'center',
      buttons: [
        [
          `<button><b>${tr('GENERAL_REPLACE')}</b></button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
            updateStateFunction(update);
          },
          true
        ],
        [
          `<button>${tr('GENERAL_CONCAT_END')}</button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const concatted = content + update;
            updateStateFunction(concatted);
          },
          false
        ],
        [
          `<button>${tr('GENERAL_CONCAT_START')}</button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const concatted = update + content;
            updateStateFunction(concatted);
          },
          false
        ],
        [
          `<button>${tr('GENERAL_CANCEL')}</button>`,
          (instance, toast) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
          },
          false
        ]
      ]
    });
  } else {
    updateStateFunction(update);
  }
};

const TitleTemplates = (props: TitleTemplatesProps) => {
  const titleTemplates = props.templates
    .filter((template) => template.ticketTypes.find((ticketType) => ticketType === props.ticketType))
    .map((template) => ({ key: template._id, text: template.content, value: template.content }));

  return (
    <Form.Dropdown
      className={'titleEditorDropdown'}
      onChange={(event, selection) => {
        onChange(selection.value, props.updateTitle, props.title);
      }}
      options={titleTemplates}
      trigger={<div />}
      style={{ margin: '5px 10px' }}
    />
  );
};

export default TitleTemplates;
