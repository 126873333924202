import { AxiosResponse } from 'axios';

import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';

export default class FacebookApi {
  static sendReply = async (parameters: {
    content: string;
    instagramCustomerId: string;
    pageId: string;
    replyToId: string;
    ticketId: string;
    imageID?: string;
  }): Promise<any> =>
    await axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}/facebook/answerInstagram`, parameters)
      .then((response: AxiosResponse<any>) => response.data);
}
