///<reference path= "../../../node_modules/react-froala-wysiwyg/lib/index.d.ts" />
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/fi.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import type { CSSProperties } from 'react';
import React from 'react';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import { connect } from 'react-redux';
import type { Dispatch } from '@reduxjs/toolkit';

import ApiConfig from 'src/api/ApiConfig';
import { fileUploaded } from 'src/actions/ticketsActions';
import type { Ticket } from 'src/types/Ticket';

import './ReplyEditor.css';

interface ReplyEditorProps extends DispatchProps {
  value: string;
  ticket?: Ticket;
  editorLanguage?: string;
  style?: CSSProperties;
  component?: string;
  textOnly?: boolean;
  addToolbarButtons?: boolean;
  pastePlain?: boolean;
  enableImages?: boolean;
  enableImageUpload?: boolean;

  onChange: (value: string) => void;
  onKeyDown?: (event: KeyDownEvent) => void;
  onAttachmentUploaded?: (attachment: AttachmentResponse) => void;
}

interface DispatchProps {
  attachmentUploaded: (ticketId: string, attachment: { attachmentId: string; secret: string }) => void;
}

export interface KeyDownEvent {
  key: string;
  keyCode: number; // see https://keycode.info/
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

interface AttachmentUploadResponse {
  msg: string;
  files: AttachmentResponse[];
  status: string;
  link: string;
}

interface AttachmentResponse {
  attachmentId: string;
  cId: string;
  destination: string;
  encoding: string;
  fieldname: string;
  filename: string;
  isQuarantined: boolean;
  mimetype: string;
  originalname: string;
  path: string;
  secret: string;
  size: number;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    attachmentUploaded: (ticketId: string, attachment: { attachmentId: string; secret: string }) => {
      dispatch(fileUploaded(ticketId, attachment) as any);
    }
  };
};

class ReplyEditorClass extends React.Component<ReplyEditorProps> {
  static defaultProps = {
    enableImages: true,
    enableImageUpload: true
  };

  private handleChange = (value: string) => {
    /**
     * Use case: if user typed something and removed everything from content
     * Froala will replace empty content value with "<br>" or "${ZERO WIDTH SPACE}<br>"
     * https://www.fileformat.info/info/unicode/char/200b/index.htm
     * We should treat this content as empty
     */
    const checkedValue =
      (value.charCodeAt(0) === 8203 && value.replace(/<br>/g, '').trim().length === 1) ||
      value.replace(/<br>/g, '').trim().length === 0
        ? ''
        : value;
    this.props.onChange(checkedValue);
  };

  render() {
    const language = () => {
      switch (this.props.editorLanguage) {
        case 'fi':
          return 'fi';
        case 'en':
          return 'en_us';
        case 'be':
          return 'en_us';
        default:
          return 'en_us';
      }
    };

    const editorPlugins = () => {
      if (this.props.textOnly) {
        return ['codeview'];
      } else {
        const plugins = [
          'align',
          'codeBeautifier',
          'codeView',
          'colors',
          'draggable',
          'embedly',
          'emoticons',
          'entities',
          'file',
          'fontAwesome',
          'fontFamily',
          'fontSize',
          'inlineStyle',
          'inlineClass',
          'lineBreaker',
          'lineHeight',
          'link',
          'lists',
          'paragraphFormat',
          'paragraphStyle',
          'quote',
          'save',
          'table',
          'url',
          'wordPaste'
        ];

        if (this.props.enableImages) {
          plugins.push('image');
        }

        return plugins;
      }
    };

    const editorButtons = () => {
      //let imageUploadEnabled = FeatureFlags.isFlagOn("IMAGE_UPLOAD_VIA_EDITOR") ? "insertImage" : undefined;
      return {
        moreText: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'strikeThrough',
            'fontFamily',
            'fontSize',
            'textColor',
            'backgroundColor',
            'clearFormatting'
          ]
        },
        moreParagraph: {
          buttons: ['formatOLSimple', 'formatUL', 'paragraphFormat', 'outdent', 'indent', 'quote'],
          buttonsVisible: 2
        },
        moreRich: {
          buttons: ['insertLink', 'insertTable', 'insertImage', 'fontAwesome', 'embedly', 'insertHR']
        },
        moreMisc: {
          buttons: ['undo', 'html'],
          align: 'right',
          buttonsVisible: 2
        }
      };
    };

    return (
      <div className={'sticky-froala-toolbar'}>
        <FroalaEditorComponent
          config={{
            imageAddNewLine: true,
            imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif', 'webp', 'png;base64', 'jpg;base64', 'jpeg;base64'],
            imageUpload: this.props.enableImageUpload,
            imageUploadMethod: 'POST',
            imageUploadParam: 'attachments',
            imageUploadURL: `${ApiConfig.getConfig().API_URL}/upload/attachment`,
            requestsWithCredentials: true,
            key: 'oc1F2vD1G2I1A2A1B5mEZXQUVJb1EZf1IWIAUKLJZMBQuD3E2D1C1C4G1B3F1A11B8==',
            placeholderText: '',
            attribution: false,
            htmlUntouched: false,
            charCounterCount: false,
            language: language(),
            pluginsEnabled: editorPlugins(),
            enter: 2,
            toolbarSticky: false,
            // htmlDoNotWrapTags: ['br', 'hr'], // this causes errors working with lists
            faButtons: ['fontAwesomeBack', '|'],
            requestWithCredentials: true,
            toolbarButtons: this.props.addToolbarButtons ?? true ? editorButtons() : {},
            events: {
              'image.uploaded': (raw: string) => {
                const response = JSON.parse(raw) as AttachmentUploadResponse;

                try {
                  if (this.props.ticket) {
                    this.props.attachmentUploaded(this.props.ticket.id, response.files[0]);
                  }
                } catch (e) {
                  console.error('Error uploading froala attachment');
                  console.error(e);
                  return false;
                }

                this.props.onAttachmentUploaded?.(response.files[0]);
                return true;
              },
              keydown: this.props.onKeyDown
            },
            pastePlain: this.props.pastePlain
          }}
          tag="textarea"
          onModelChange={this.handleChange}
          model={this.props.value}
        />
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ReplyEditorClass);
