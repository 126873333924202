import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { LinkList } from 'src/types/Widgets';

export default class LinkListsApi {
  static getLinkLists = (): Promise<LinkList[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + '/linklists').then((response) => {
      return response.data;
    });
  };
}
