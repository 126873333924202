import * as React from 'react';
import { t } from 'i18next';
import { Translation } from 'react-i18next';
import { DropdownItemProps, Form } from 'semantic-ui-react';

import FeatureFlags from 'src/api/FeatureFlags';
import { KnowledgeBank } from './KnowledgeBank';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyTemplates from './ReplyTemplates';
import ChannelType from '../CommentIconContent/ChannelType';
import { ReplyMethod, ReplyMethodProps } from './ReplyMethod';
import ReplyTextArea from './ReplyTextArea';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import FormDropzoneDropdown from './components/FormDropzoneDropdown';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';
import { DATE_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { Channels } from 'src/types/Channel';
import { Entity, isChatAnchored, Ticket, UploadFileResult } from 'src/types/Ticket';
import type { Attachment } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';

interface ReplyWhatsappProps extends ReplyMethodProps<ReplyWhatsappState> {
  userData: PersonalData;
  ticketType: TicketType;
  templates: Array<ResponseTemplate>;
  entities: Entity[];
  drafts: ReplyWhatsappState;
  task: Ticket;
  chatTypeStatus: string;
  users: User[];
  attachments: Array<Attachment>;

  // TODO: typing
  switchChatAnchor: Function;
  onSubmit: Function;
  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
}

type ReplyWhatsappState = {
  content: string;
  isLoading: boolean;
  selectedAttachmentIds: string[];
  selectedReplyTemplate: string | undefined;
};

class ReplyWhatsapp extends ReplyMethod<ReplyWhatsappProps, ReplyWhatsappState> {
  private channel = ChannelType.WhatsApp;
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplyWhatsappProps) {
    super(props);
    this.state = this.getInitialState(this.props.drafts);
  }

  getDraftChannel(): Channels {
    return Channels.whatsapp;
  }

  getDraftState(state: ReplyWhatsappState): Partial<ReplyWhatsappState> {
    return {
      content: state.content,
      selectedAttachmentIds: state.selectedAttachmentIds,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  /**
   * Return sender's whatsappId (phone number)
   */
  private getWaId = (): string | null => {
    const numbers: string[] = this.props.task.entities.filter((ent) => !!ent.data?.phone).map((ent) => ent.data.phone);
    if (numbers.length > 0) {
      return numbers[0];
    }
    return null;
  };

  /**
   * Return outgoint whatsappId (phone number)
   * It's extracted from comment metadata where `recipient` field should contain
   * that number
   */
  private getOutgoingId = (sender: string): string | null => {
    const numbers: string[] = this.props.task.comments
      .filter(
        (cmt) =>
          cmt.direction === 'in' &&
          cmt.metaData &&
          typeof cmt.metaData.phone === 'string' &&
          cmt.metaData.phone === sender
      )
      .map((cmt) => cmt.metaData?.recipient as string);
    if (numbers.length > 0) {
      return numbers[0];
    }
    return null;
  };

  private getAttachmentOpts = (): DropdownItemProps[] => {
    return this.props.attachments
      .filter((att: Attachment) => !att.deprecated)
      .map((attachment: Attachment) => {
        const option = {
          text: attachment.fileName,
          value: attachment.id,
          uploaded: attachment.uploaded,
          isQuarantined: attachment.isQuarantined,
          description: getPrettyDate(attachment.uploaded, { format: DATE_FORMAT }),
          label: attachment.isQuarantined ? { color: 'red', empty: true, circular: true } : ''
        };
        return option;
      });
  };

  private getSelectedAttachments = (): Attachment[] => {
    return this.props.attachments.filter((att: Attachment) => this.state.selectedAttachmentIds.includes(att.id));
  };

  private getInitialState = (drafts: ReplyWhatsappState) => {
    const state: ReplyWhatsappState = {
      isLoading: false,
      content: drafts.content || '',
      selectedAttachmentIds: drafts.selectedAttachmentIds || [],
      selectedReplyTemplate: drafts.selectedReplyTemplate || undefined
    };
    return state;
  };

  private submitMessage = async () => {
    const waId = this.getWaId();
    if (!waId || this.state.isLoading || !this.state.content.length) {
      return;
    }
    const sender = this.getOutgoingId(waId);
    if (!sender) {
      return;
    }
    this.setState({ isLoading: true });
    const bodyOfRequest = {
      content: this.state.content,
      channel: this.channel,
      direction: 'out',
      sendAsWhatsappMessage: true,
      to: [waId],
      sender: sender,
      attachments: this.getSelectedAttachments()
    };
    const response = await this.props.onSubmit(bodyOfRequest, false);
    if (response !== false) {
      this.clearFields();
    }
    this.setState({ isLoading: false });
  };

  private clearFields = () => {
    this.setState(
      {
        content: '',
        selectedAttachmentIds: [],
        selectedReplyTemplate: undefined
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  componentWillReceiveProps(nextProps: ReplyWhatsappProps) {
    if (this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts));
    }
  }

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    const temp: string[] = this.state.selectedAttachmentIds;

    return Promise.all(
      attachmentFiles.map((file: File) => {
        const data = new FormData();
        data.append('attachments', file);
        return this.props.uploadFile(this.props.taskId, data).then((files: any[]) => {
          const attachmentIdArray = files.map((att: any) => {
            return att.attachmentId;
          });

          const allIds = [...temp, ...attachmentIdArray];

          this.updateState({ selectedAttachmentIds: allIds });
          return files;
        });
      })
    );
  };

  render() {
    const attachments = this.getAttachmentOpts();
    attachments.push({
      text: t('ATTACHMENT_ADD_DROPDOWN'),
      value: 'ADD_ATTACHMENT'
    });
    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            <Form.Field>
              <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
              <ReplyTemplates
                channel={this.channel}
                entities={this.props.entities}
                userData={this.props.userData}
                ticketType={this.props.ticketType}
                templates={this.props.templates}
                task={this.props.task}
                selectedOption={this.state.selectedReplyTemplate}
                setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                setContent={(value) => this.updateState({ ...value })}
                insertAtCursor={(value, content) => {
                  value.content = insertAtCursor(this.editor, content);
                  this.updateState({ ...value });
                }}
                content={this.state.content}
                users={this.props.users}
                discardHtml={true}
              />
            </Form.Field>
            {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
              <Form.Field>
                <KnowledgeBank
                  title={this.state.content}
                  id={this.props.taskId}
                  extraArguments={this.props.ticketType.knowledgeBank}
                  content={this.state.content}
                  updateState={(value: string) => {
                    this.updateState({ content: value });
                  }}
                />
              </Form.Field>
            )}
            <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
            <Form.Field>
              <label>{t('ADD_COMMENT_CONTENT')}</label>
              <ReplyTextArea
                ref={(ref) => (this.editor = ref)}
                content={this.state.content}
                onChange={(content) => {
                  this.updateState({ content });
                }}
                onKeyDown={(event: KeyDownEvent) => {
                  if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                    this.submitMessage();
                  }
                }}
              />
            </Form.Field>

            <FormDropzoneDropdown
              attachments={this.props.attachments}
              onChangeAttachments={(addedAttachments) => this.updateState({ selectedAttachmentIds: addedAttachments })}
              onDropAccepted={this.onUploadAttachment}
              options={attachments}
              value={this.state.selectedAttachmentIds}
            />

            <div style={{ display: 'flex', paddingBottom: '10px' }}>
              <Form.Checkbox
                style={{ marginTop: '25px', paddingLeft: '27px' }}
                toggle={true}
                onClick={() => {
                  this.props.switchChatAnchor(this.props.task.id);
                }}
                checked={isChatAnchored(this.props.task)}
                label={t('ANCHOR_CHAT')}
              />
            </div>
            <ReplyControlButtons
              disabled={this.state.isLoading || !this.state.content.length}
              loading={this.state.isLoading}
              onClear={this.clearFields}
              onSaveDraft={() => this.saveVCDraft(false)}
              onSubmit={this.submitMessage}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default ReplyWhatsapp;
