import React, { useState } from 'react';
import { List, Accordion, Icon } from 'semantic-ui-react';

import AttachmentItem, { AttachmentEdit } from '../Case/AttachmentItem';
import { PersonalData } from 'src/types/User';
import type { Attachment } from 'src/types/Ticket';

interface AttachmentsAccordionProps {
  initialOpen: boolean;
  title: string;
  attachments: Attachment[];
  uploadFile: (files: File[]) => void;
  editAttachment: (id: string, body: AttachmentEdit) => void;
  deprecateAttachment: (id: string) => void;
  unDeprecateAttachment: (id: string) => void;
  openFileDialog: () => void;
  personalData: PersonalData;
}

const AttachmentsAccordion = (props: AttachmentsAccordionProps) => {
  const [open, setOpen] = useState(props.initialOpen);

  return (
    <Accordion>
      <Accordion.Title
        active={open}
        onClick={() => {
          setOpen((old) => !old);
        }}
      >
        <Icon name="dropdown" />
        {`${props.title} (${props.attachments.length})`}
      </Accordion.Title>
      <Accordion.Content active={open}>
        <List relaxed={true} divided={true} verticalAlign="middle">
          {props.attachments.map((attachment) => (
            <AttachmentItem
              {...attachment}
              key={attachment.id}
              onUpload={props.uploadFile}
              onEdit={props.editAttachment}
              onDeprecate={props.deprecateAttachment}
              onUnDeprecate={props.unDeprecateAttachment}
              openFileDialog={props.openFileDialog}
              personalData={props.personalData}
              isQuarantined={attachment.isQuarantined}
            />
          ))}
        </List>
      </Accordion.Content>
    </Accordion>
  );
};

export default AttachmentsAccordion;
