import { FETCH_TICKET_TYPES_SUCCESS } from '../actions/index';
import type { TicketType } from 'src/types/TicketType';

const initialState: TicketType[] = [];

const typesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_TICKET_TYPES_SUCCESS: {
      const parsedTicketTypes = action.payload.ticketTypes.map((ticketType: TicketType) => {
        return { ...ticketType, id: typeof ticketType.id === 'string' ? parseInt(ticketType.id) : ticketType.id };
      });
      return parsedTicketTypes;
    }
    default:
      return state;
  }
};

export default typesReducer;
