import ApiConfig from 'src/api/ApiConfig';
import ReplyEmail from './ReplyEmail';
import { serializeContent } from 'src/Utilities/parseUtils';
import { trimAndSplitRecipients } from '../Utilities/recipients';
import type { ReplyEmailProps } from './ReplyEmail';
import type { Comment } from 'src/types/Ticket';

class ReplySecureEmail extends ReplyEmail {
  constructor(props: ReplyEmailProps) {
    super(props);
  }

  /**
   *  submitEmail function : encrypt email in replySecureEmail Component
   *  in the mongo db, cv-dev->TicketTypes (id:1 support) additionnal data : secureMailTitlePrefix : "*salaa*" was added
   *  so if the ticketType.secureMailTitlePrefix exists, we add its contents into the subject
   */
  protected submitEmail = (closeAsDone: boolean) => async () => {
    this.setState({ isLoading: true }, async () => {
      let to = trimAndSplitRecipients(this.state.to)?.filter((to) => to);
      const apiurl: string = ApiConfig.getConfig().API_URL;
      // just add +.s at recipient like previously according to end of API URL and if secureMailTitlePrefix exists
      if (to !== undefined && apiurl.endsWith('yhkodit')) {
        to = to.map((recipient: string) => {
          return recipient + '.s';
        });
      }
      // add ending at end of recipients if ticketType.secureMailAddressMailPostfix exist (Note : this.props.ticketType.secureMailTitlePrefix || this.props.ticketType.secureMailTitlePostfix don't add anything at the end of recipient mail)
      if (to !== undefined && this.props.ticketType.secureMailAddressMailPostfix) {
        to = to.map((recipient: string) => {
          return recipient + this.props.ticketType.secureMailAddressMailPostfix;
        });
      }

      let mailsubject =
        this.state.subject && this.state.subject?.length > 900 ? this.state.subject.slice(0, 900) : this.state.subject;
      if (this.props.ticketType.secureMailTitlePostfix) {
        mailsubject = this.state.subject + this.props.ticketType.secureMailTitlePostfix;
      }
      if (this.props.ticketType.secureMailTitlePrefix) {
        mailsubject = this.props.ticketType.secureMailTitlePrefix + this.state.subject;
      } else if (apiurl.endsWith('tys')) {
        mailsubject = 'TURVAPOSTI ' + this.state.subject;
      }

      const contentWithCids = serializeContent(this.state.content, this.props.attachments);
      const bodyOfRequest = {
        content:
          this.state.subject && this.state.subject?.length > 900
            ? this.state.subject.slice(900, -1) + ' \n\n ' + contentWithCids
            : contentWithCids,
        sendAsMail: true,
        subject: mailsubject,
        channel: this.channel,
        direction: 'out',
        metaData: {
          cc: this.state.cc.length > 0 ? trimAndSplitRecipients(this.state.cc)?.filter((cc) => cc) : undefined,
          bcc: this.state.bcc.length > 0 ? trimAndSplitRecipients(this.state.bcc)?.filter((bcc) => bcc) : undefined,
          title: mailsubject,
          from: this.state.from,
          to: to,
          attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds)
        },
        from: this.state.from,
        to: to,
        html: contentWithCids,
        cc: this.state.cc.length > 0 ? trimAndSplitRecipients(this.state.cc)?.filter((cc) => cc) : undefined,
        attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds),
        bcc: this.state.bcc.length > 0 ? trimAndSplitRecipients(this.state.bcc)?.filter((bcc) => bcc) : undefined,
        title: mailsubject.trim()
      };
      const request = this.props.onSubmit(bodyOfRequest);
      request
        .then((response: Comment) => {
          if (response) {
            if (closeAsDone) {
              this.props.closeAsDone(this.props.taskId, this.props.userData.UID);
            }
            this.clearFields();
          }
        })
        .catch((error: any) => {
          console.error('Failed to save ticket', error);
        });
    });
  };
}

export default ReplySecureEmail;
