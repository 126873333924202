import { t } from 'i18next';
import iziToast from 'izitoast';
import queryString from 'query-string';

import SocketInstance from 'src/realTimeNotifications';
import ApiConfig from './ApiConfig';
import EnvSettings from './EnvSettings';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { CaseType } from 'src/types/TicketList';
import type { Channel } from 'src/types/Channel';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { Tag } from 'src/types/Tag';
import type { Comment, Ticket, TicketListTicket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { TitleTemplate } from 'src/types/TitleTemplate';
import type { ImportTemplate, UploadedFileData } from 'src/types/Campaign';
import type { FormattedSearch } from 'src/types/Search';
import type { TicketArchivalUploadData } from '../types/Archival';

const API_URL_SINGLE_TICKET = '/case/';
const API_URL_TICKET_TYPES = '/ticketTypes';
const API_URL_RESPONSE_TEMPLATES = '/responseTemplates';
const API_URL_TITLE_TEMPLATES = '/titleTemplates';
const API_URL_CHANNEL_TYPES = '/channelTypes';
const API_URL_SINGLE_UPDATE_TICKET = '/cases/';
const API_URL_LINKED_TICKETS = '/case/';
const API_URL_TICKETS = '/casesWithData';
const API_URL_SEARCH_TICKETS = '/search';
const API_URL_TICKETS_BY_ENTITY = '/cases/entities/';
const API_URL_ENTITY_BY_SEARCH = '/entities/';
const API_URL_UPDATE_TICKET_DETAIL = '/case/';
const API_URL_ADD_COMMENT = '/cases/';
const API_URL_SINGLE_TICKET_BASE = '/cases/';
const API_URL_TAGS = '/tags/';
const API_URL_CASE_IMPORT_TEMPLATES = '/caseImportTemplates/';
const API_URL_ADD_ENTITY_TO_CASE = '/cases/';
const API_URL_REMOVE_ENTITY_FROM_CASE = '/cases/';
const API_URL_UPDATE_ENTITY_DETAIL = '/entities/';
const API_URL_ATTACHMENT_UPLOAD = '/upload';
const API_URL_ATTACHMENT_ATTACH = '/cases/';
const API_URL_ATTACHMENT_EDIT = '/cases/';
const API_URL_ATTACHMENT_DEPRECATE = '/cases/';
const API_URL_ATTACHMENT_UNDEPRECATE = '/cases/';
const API_URL_CHAT_STATUS = '/chats/status/';

export interface UpdateEntityDetail {
  entityId: string;
  entityType: string;
  fieldName: string;
  object: string;
  partial: boolean;
  valueToSave: any;
}

export default class TicketsApi {
  static getTickets = async (searchParams: { searchterms: FormattedSearch }): Promise<TicketListTicket[]> => {
    if (searchParams.searchterms.basic !== undefined) {
      searchParams.searchterms.basic.type = ['task'];
      return TicketsApi.searchTickets<TicketListTicket>(searchParams);
    } else {
      return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_TICKETS).then((response) => {
        SocketInstance.resendMessagesAfterTimestamp(parseInt(response.headers['cache-creation'], 10));

        return response.data;
      });
    }
  };

  static getTicketTypes = (): Promise<TicketType[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_TICKET_TYPES).then((response) => {
      return response.data;
    });
  };

  static getResponseTemplates = (): Promise<ResponseTemplate[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_RESPONSE_TEMPLATES).then((response) => {
      return response.data;
    });
  };

  static getTitleTemplates = (): Promise<TitleTemplate[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_TITLE_TEMPLATES).then((response) => {
      return response.data;
    });
  };

  static getChannelTypes = (): Promise<Channel[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_CHANNEL_TYPES).then((response) => {
      return response.data;
    });
  };

  static updateGiosgWritingStatus = (chat_id: string, agentName: string, room_id: string): Promise<Channel[]> => {
    return axiosWithRetry
      .put(ApiConfig.getConfig().API_URL + API_URL_CHAT_STATUS + 'giosg', { chat_id, agentName, room_id })
      .then((response) => response.data);
  };

  static updateChatTypingStatusToCustomer = (connectionId: string): Promise<Channel[]> => {
    return axiosWithRetry
      .put(ApiConfig.getConfig().API_URL + API_URL_CHAT_STATUS + 'elisachat', { connectionId })
      .then((response) => response.data);
  };

  static searchTickets = <T = Ticket>(searchParams: { searchterms: FormattedSearch }): Promise<T[]> => {
    if (searchParams.searchterms.basic.selectedTicketTagsAnd && !searchParams.searchterms.basic.searchToggleAndOr) {
      // inclusive tag search; disable searching for default tags in order not to get all content
    } else {
      searchParams.searchterms.basic.orTags = ApiConfig.getConfig().API_MAIN_CONTENT_TAGS;
    }

    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_SEARCH_TICKETS, {
        ...searchParams
      })
      .then((response) => {
        if (response.data.length >= EnvSettings.getSettings().MAX_SEARCH_RESULTS) {
          iziToast.warning({
            timeout: 0,
            close: false,
            overlay: true,
            id: 'question',
            zindex: 999,
            message: `${t('MAX_SEARCH_LIMIT', {
              limit: EnvSettings.getSettings().MAX_SEARCH_RESULTS
            })}.`,
            position: 'center',
            buttons: [
              [
                `<button><b>${t('GENERAL_CLOSE')}</b></button>`,
                (instance: any, toast: any) => {
                  instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
                },
                true
              ]
            ]
          });

          return response.data;
        } else {
          return response.data;
        }
      })
      .catch(() => {
        return [{ id: 'Error' }];
      });
  };

  static getTicketsByEntity = (
    entityId: string,
    entityType: string,
    caseType: CaseType = 'task'
  ): Promise<TicketListTicket[]> => {
    const query = queryString.stringify({
      entityType,
      caseType
    });

    return axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}${API_URL_TICKETS_BY_ENTITY}${entityId}?${query}`)
      .then((response) => response.data);
  };

  static searchEntityByDetails = (params: any) => {
    const searchUri = Object.keys(params)
      .map((key) => key + '=' + encodeURIComponent(params[key]))
      .join('&');
    return axiosWithRetry
      .get(`${ApiConfig.getConfig().API_URL}${API_URL_ENTITY_BY_SEARCH}search?mode=approximate&${searchUri}`)
      .then((response) => response.data);
  };

  static getTicket = async (id: string, queryParams?: { [key: string]: any }): Promise<Ticket> => {
    const query = queryParams ? `?${queryString.stringify(queryParams)}` : '';
    return axiosWithRetry
      .get(ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET + id.substring(3) + query, { timeout: 15000 })
      .then((response) => response.data);
  };

  static makeTicket = (task: any): Promise<Ticket> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_SINGLE_UPDATE_TICKET, {
        title: task.title,
        type: task.type,
        content: task.content,
        channel: task.channel || 4,
        originalDirection: task.direction || 'out',
        dueDate: task.dueDate,
        status: task.status,
        lastContact: task.lastContact,
        tagIds: task.tags ? task.tags : ApiConfig.getConfig().API_MAIN_CONTENT_TAGS,
        delegatedToUserIds: task.delegatedTo,
        taskType: task.taskType,
        ocTicketId: task.case.ocTicketId || 0,
        originalContact: task.originalContact
      })
      .then((response) => {
        response.data.case = {};
        response.data.product = {};
        return response.data;
      });
  };

  static fetchLinkedTickets = (ticketId: string): Promise<Ticket[]> => {
    return axiosWithRetry
      .get(ApiConfig.getConfig().API_URL + API_URL_LINKED_TICKETS + ticketId.substring(3) + '/linkedTickets')
      .then((response) => response.data);
  };

  static addRelatedTicketToTicket = (id: string, targetId: any, type: string): Promise<Ticket[]> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_LINKED_TICKETS + id.substring(3) + '/linkedTickets', {
        targetId: targetId,
        type: type
      })
      .then((response) => response.data);
  };

  static removeTicketLinking = (id: string, targetId: any): Promise<Ticket[]> => {
    return axiosWithRetry
      .delete(ApiConfig.getConfig().API_URL + API_URL_LINKED_TICKETS + id + '/linkedTickets/' + targetId)
      .then((response) => response.data);
  };

  static updateMetadata = (id: number | string, metadata: object): Promise<any> => {
    return axiosWithRetry
      .patch(ApiConfig.getConfig().API_URL + API_URL_SINGLE_UPDATE_TICKET + id, {
        metaData: JSON.stringify(metadata)
      })
      .then((response) => response.data);
  };

  static updateTicket = (id: string, task: Partial<Ticket>): Promise<TicketListTicket> => {
    return axiosWithRetry
      .patch(ApiConfig.getConfig().API_URL + API_URL_SINGLE_UPDATE_TICKET + id, {
        title: task.title,
        content: task.content,
        dueDate: task.dueDate,
        status: task.status,
        taskType: task.taskType,
        channel: task.channel,
        priority: task.priority,
        weight: task.weight
      })
      .then((response) => response.data);
  };

  static updateSingleTicketDetail = async (ticketId: string, updateKey: string, updateValue: any, group: string) => {
    return axiosWithRetry
      .put(
        ApiConfig.getConfig().API_URL + API_URL_UPDATE_TICKET_DETAIL + ticketId.substring(3) + '/details/' + updateKey,
        {
          group: group,
          type: updateKey,
          value: updateValue
        }
      )
      .then((response) => response.data);
  };

  static updateTicketDetails = async (ticketId: string, group: string, items: { type: string; value: any }[]) => {
    return axiosWithRetry
      .put(`${ApiConfig.getConfig().API_URL}${API_URL_UPDATE_TICKET_DETAIL}${ticketId.substring(3)}/details`, {
        group,
        items
      })
      .then((response) => response.data);
  };

  static updateEntityDetail = (taskType: string, updateArgs: UpdateEntityDetail): Promise<any> => {
    const { valueToSave, partial, object, fieldName, entityType, entityId } = updateArgs;
    let updateValueObj = valueToSave;
    const updateKeyNew = partial ? object : fieldName;

    const updateURL =
      ApiConfig.getConfig().API_URL + API_URL_UPDATE_ENTITY_DETAIL + entityType + '/' + entityId + '/' + updateKeyNew;
    if (partial) {
      updateValueObj = {};
      updateValueObj[fieldName] = valueToSave;
    }
    return axiosWithRetry
      .put(updateURL, {
        type: updateKeyNew,
        value: updateValueObj,
        partial,
        taskType: taskType
      })
      .then((response) => response.data);
  };

  static addComment = (ticketId: string, data: object): Promise<Comment> => {
    const query = `?${queryString.stringify({ type: ticketId.substring(0, 3) })}`;

    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_ADD_COMMENT + ticketId.substring(3) + '/comments' + query, {
        ...data
      })
      .then((response) => response.data);
  };

  static addDelegateToContent = (ticketId: string, userId: string): Promise<any> => {
    return axiosWithRetry
      .post(
        ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId.substring(3) + '/delegatedTo/' + userId,
        {
          type: ticketId.substring(0, 3)
        }
      )
      .then((response) => response.data);
  };

  static removeDelegateFromContent = (ticketId: string, userId: string): Promise<any> => {
    return axiosWithRetry
      .delete(
        ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId.substring(3) + '/delegatedTo/' + userId,
        {
          data: { type: ticketId.substring(0, 3) }
        }
      )
      .then((response) => response.data);
  };

  static addGroupDelegateToContent = (ticketId: string, userGroup: number[]): Promise<any> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId.substring(3) + '/groupDelegatedTo/', {
        userGroup: userGroup
      })
      .then((response) => response.data);
  };

  static removeGroupDelegateFromContent = (ticketId: string, userGroup: number[]): Promise<any> => {
    return axiosWithRetry
      .post(
        ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId.substring(3) + '/groupDelegatedTo/delete',
        userGroup
      )
      .then((response) => response.data);
  };

  static archiveTicket = (ticketId: number, ticketArchivalData: TicketArchivalUploadData): Promise<any> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId + '/archive', ticketArchivalData)
      .then((response) => response.data);
  };

  static addTagToContent = (ticketId: string, tagId: string, addnew: boolean): Promise<any> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId.substring(3) + '/tags/' + tagId, {
        addnew,
        type: ticketId.substring(0, 3)
      })
      .then((response) => response.data);
  };

  static removeTagFromContent = (ticketId: string, userId: string): Promise<any> => {
    return axiosWithRetry
      .delete(ApiConfig.getConfig().API_URL + API_URL_SINGLE_TICKET_BASE + ticketId.substring(3) + '/tags/' + userId, {
        data: {
          type: ticketId.substring(0, 3)
        }
      })
      .then((response) => response.data);
  };

  static addEntityToCase = (ticketId: string, body: object): Promise<any> => {
    const _body = body as any;
    if (_body._id === '') {
      delete _body._id;
    }
    const payload = {
      ..._body
    };
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_ADD_ENTITY_TO_CASE + ticketId + '/entities', payload)
      .then((response) => response.data);
  };

  static removeEntityFromCase = (
    ticketId: string,
    body: {
      _id: string;
      _type: string;
    }
  ): Promise<any> => {
    const url = `${ApiConfig.getConfig().API_URL}${API_URL_REMOVE_ENTITY_FROM_CASE}${ticketId}/entities/${body._id}`;
    return axiosWithRetry
      .delete(url, {
        data: body
      })
      .then((response) => response.data);
  };

  static getTags = (): Promise<Tag[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_TAGS).then((response) => {
      return response.data;
    });
  };

  static getCaseImportTemplates = async (): Promise<ImportTemplate[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_CASE_IMPORT_TEMPLATES).then((response) => {
      return response.data;
    });
  };

  static uploadFile = (file: FormData, uploadType = 'attachment'): Promise<UploadedFileData> => {
    return axiosWithRetry
      .post(ApiConfig.getConfig().API_URL + API_URL_ATTACHMENT_UPLOAD + '/' + uploadType, file)
      .then((response) => response.data);
  };

  static attachAttachmentToCase = (attachmentId: string, ticketId: string, secret: string): Promise<any> => {
    return axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}${API_URL_ATTACHMENT_ATTACH}${ticketId}/attachments/${attachmentId}`, {
        secret
      })
      .then((response) => response.data);
  };

  static editAttachment = (ticketId: string, attachmentId: string, body: any): Promise<any> => {
    return axiosWithRetry
      .patch(
        `${ApiConfig.getConfig().API_URL}${API_URL_ATTACHMENT_EDIT}${ticketId.substring(
          3
        )}/attachments/${attachmentId.substring(3)}`,
        body
      )
      .then((response) => response.data);
  };

  static deprecateAttachment = (ticketId: string, attachmentId: string): Promise<any> => {
    return axiosWithRetry
      .put(`${ApiConfig.getConfig().API_URL}${API_URL_ATTACHMENT_DEPRECATE}${ticketId}/attachments/${attachmentId}`, {})
      .then((response) => response.data);
  };

  static unDeprecateAttachment = (ticketId: string, attachmentId: string): Promise<any> => {
    return axiosWithRetry
      .delete(
        `${ApiConfig.getConfig().API_URL}${API_URL_ATTACHMENT_UNDEPRECATE}${ticketId}/attachments/${attachmentId}`,
        {}
      )
      .then((response) => response.data);
  };

  static startWorkingOn = (ticketId: number, UID: number) => {
    return axiosWithRetry.post(`${ApiConfig.getConfig().API_URL}/cases/${ticketId}/workstatus`, {
      UID
    });
  };

  static replaceWorkingOn = (ticketId: number, previousUID: number, nextUID: number) => {
    return axiosWithRetry.patch(`${ApiConfig.getConfig().API_URL}/cases/${ticketId}/workstatus`, {
      previousUID,
      nextUID
    });
  };

  static stopWorkingOn = async (ticketId: number, UID: number) => {
    return axiosWithRetry.delete(`${ApiConfig.getConfig().API_URL}/cases/${ticketId}/workstatus`, {
      data: { UID }
    });
  };

  static fetchTicketPriorities = () => {
    return axiosWithRetry.get(`${ApiConfig.getConfig().API_URL}/ticketPriorities`).then((response) => {
      return response.data;
    });
  };

  static mergeTicketsInto = (mainTicketId: string, ticketsToMerge: string[]) =>
    axiosWithRetry
      .post(`${ApiConfig.getConfig().API_URL}${API_URL_SINGLE_TICKET}${mainTicketId}/merge`, ticketsToMerge)
      .then((response) => response.data);
}
