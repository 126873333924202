import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import TabBar from '../TabBar';
import { activateContentListTab } from 'src/api/CaseListApi';
import { closeContentListTab } from 'src/actions/ticketListTabActionsRTK';
import { fetchTickets } from '../actions/ticketsActions';
import { StaticTabs } from 'src/types/TicketList';
import type { State } from 'src/types/initialState';
import type { TabBarMethod } from 'src/types/TabBar';

const mapStateToProps = (state: State) => {
  return {
    mainTab: state.ticketListTabs[StaticTabs.MAIN_VIEW],
    tabs: Object.values(state.ticketListTabs).filter((t) => t.id !== StaticTabs.MAIN_VIEW),
    personalData: state.userData,
    ticketTypes: state.ticketTypes
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onTabClose: (id: string, activeId?: string) => {
      if (activeId) {
        dispatch(activateContentListTab(activeId, 'tickets'));
      }
      dispatch(closeContentListTab(`${id}`, 'tickets'));
    },
    method: 'contentLists' as TabBarMethod,
    onActivateTab: (id: string) => {
      dispatch(activateContentListTab(id, 'tickets'));
    },
    refreshMainView: () => {
      // TODO typing
      (dispatch as ThunkDispatch<Promise<any>, any, AnyAction>)(fetchTickets({}, StaticTabs.MAIN_VIEW, false));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabBar);
