import { path, uniq } from 'ramda';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { ConnectedProps } from 'react-redux';

import { setCommentsSorting } from 'src/reducers/commentsMenuReducer';
import Discussion from './Discussion';
import CommentsMenu from './CommentsMenu';
import CommentFiltersModal from './CommentFiltersModal';
import { getCommentSender } from 'src/Components/Utilities/comments';
import { filterComments } from 'src/Utilities/comments';
import { getCaseTabIndexList } from 'src/Utilities/getCaseTabIndexLIst';
import { Direction } from 'src/types/Sorting';
import type { State } from 'src/types/initialState';
import type { SenderEmail } from 'src/types/TicketType';

const { ASC, DESC } = Direction;

interface CommentsProps extends ConnectedProps<typeof connector> {
  senderEmails: SenderEmail[];
  mobileMode: boolean;
}

export const Comments = ({
  sorting,
  mobileMode,
  task,
  ticketTypes,
  ticketChannels,
  usersList,
  userData,
  replyTabIndexList,
  senderEmails,
  commentFilters,
  setCommentsSorting,
  showEvents,
  ...props
}: CommentsProps) => {
  const [totalCommentsAmount, setTotalCommentsAmount] = useState(task.comments.length);
  const [isCommentFiltersModalOpen, setCommentFiltersModalOpen] = useState(false);
  const [comments, setComments] = useState(task.comments);

  useEffect(() => {
    setComments(
      showEvents
        ? filterComments(task.comments, commentFilters)
        : filterComments(
            task.comments.filter((comment) => comment.type !== 'automatic'),
            commentFilters
          )
    );
  }, [showEvents, commentFilters, task.comments]);

  useEffect(() => {
    setTotalCommentsAmount(
      showEvents ? task.comments.length : task.comments.filter((comment) => comment.type !== 'automatic').length
    );
  }, [showEvents]);

  const onDirectionClick = () => {
    setCommentsSorting(sorting === DESC ? ASC : DESC);
  };
  const getCommentAuthors = () =>
    uniq(
      task.comments.map((comment) =>
        getCommentSender({
          entityData: path(['metaData', 'originalEntity'], comment),
          channelData: props.channels.find((channel) => channel.id === comment.channel),
          user: usersList.reduce((users, user) => {
            users[user.UID] = user;
            return users;
          }, {})[comment.createdByUser],
          metaData: comment.metaData,
          direction: comment.direction
        })
      )
    );

  return (
    <div style={{ marginTop: '20px' }}>
      <CommentsMenu
        caseId={task.id}
        direction={sorting}
        mobileMode={mobileMode}
        onDirectionClick={onDirectionClick}
        showedCommentsLength={comments.length}
        setCommentFiltersModalOpen={(state) => setCommentFiltersModalOpen(state)}
      />

      <Discussion
        task={task}
        ticketTypes={ticketTypes}
        senderEmails={senderEmails}
        users={usersList}
        channels={ticketChannels || []}
        comments={comments}
        taskId={task.id}
        replyToEmailEnabled={true}
        user={userData}
        replyTabIndexList={replyTabIndexList}
        direction={sorting}
      />

      {isCommentFiltersModalOpen ? (
        <CommentFiltersModal
          caseId={task.id}
          commentAuthors={getCommentAuthors()}
          showedCommentsLength={comments.length}
          totalCommentsAmount={totalCommentsAmount}
          setCommentFiltersModalOpen={setCommentFiltersModalOpen}
        />
      ) : null}
    </div>
  );
};

const connector = connect(
  (state: State) => ({
    sorting: state.commentsMenu.sorting,
    ticketTypes: state.ticketTypes,
    usersList: state.usersList.usersList,
    ticketChannels: state.channels,
    userData: state.userData,
    replyTabIndexList: getCaseTabIndexList(state.userData?.channels ?? []),
    task: state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!,
    channels: state.channels,
    commentFilters: state.commentFilters[state.activeTicketTab!],
    showEvents: state.commentsMenu.showEvents
  }),
  { setCommentsSorting }
);

export default connector(Comments);
