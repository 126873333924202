import type { ActionCreator, AnyAction } from 'redux';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import EnreachVoiceIntegration from 'src/Utilities/intergrations/callEnreachVoice';
import { ADD_CALL_REQUEST } from './index';
import { REMOVE_CALL_REQUEST } from './index';
import { addComment } from 'src/actions/commentsActions';
import { callElisaOC } from 'src/Utilities/intergrations/callElisaOC';
import { callElisaRing } from 'src/Utilities/intergrations/callElisaRing';
import { callMitel } from 'src/Utilities/intergrations/callMitel';
import type { State } from 'src/types/initialState';
import type { TicketType } from 'src/types/TicketType';

export interface CallRequestPayload {
  phoneNumber: string;
  ticketId: number;
  fromClickToCall?: boolean;
}

export const addCallRequest = (payload: CallRequestPayload) => {
  return { type: ADD_CALL_REQUEST, payload };
};

export const removeCallRequest = (payload: CallRequestPayload) => {
  return { type: REMOVE_CALL_REQUEST, payload };
};

export const addRingCallRequest: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  phoneNumber: string
) => {
  return (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    const commentBody = {
      channel: ChannelType.Phone,
      content: `${'[user_called_to]'}: ${phoneNumber}`,
      direction: 'out'
    };

    dispatch(addComment(ticketId, commentBody));
    dispatch(
      addCallRequest({
        phoneNumber,
        ticketId: parseInt(ticketId.substring(3), 10)
      })
    );

    callElisaRing(phoneNumber);
  };
};

export const addOCCallRequest: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  phoneNumber: string,
  taskType: string,
  ticketTypes: TicketType[]
) => {
  return (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    const commentBody = {
      channel: ChannelType.Phone,
      content: `${'[user_called_to]'}: ${phoneNumber}`,
      direction: 'out'
    };

    dispatch(addComment(ticketId, commentBody));
    dispatch(
      addCallRequest({
        phoneNumber,
        ticketId: parseInt(ticketId.substring(3), 10),
        fromClickToCall: true
      })
    );
    const ticketType = ticketTypes.find((tType: TicketType) => {
      return tType.name === taskType;
    });
    const serviceName = ticketType?.OCoutboundServiceName?.value;

    callElisaOC(phoneNumber, serviceName);
  };
};

export const addMitelCallRequest: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  phoneNumber: string
) => {
  return (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    const commentBody = {
      channel: ChannelType.Phone,
      content: `${'[user_called_to]'}: ${phoneNumber}`,
      direction: 'out'
    };

    dispatch(addComment(ticketId, commentBody));
    dispatch(
      addCallRequest({
        phoneNumber,
        ticketId: parseInt(ticketId.substring(3), 10)
      })
    );

    callMitel(phoneNumber);
  };
};

export const addEnreachVoiceCallRequest: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  phoneNumber: string
) => {
  return (dispatch: ThunkDispatch<State, any, AnyAction>) => {
    const commentBody = {
      channel: ChannelType.Phone,
      content: `${'[user_called_to]'}: ${phoneNumber}`,
      direction: 'out'
    };

    dispatch(addComment(ticketId, commentBody));
    dispatch(
      addCallRequest({
        phoneNumber,
        ticketId: parseInt(ticketId.substring(3), 10)
      })
    );

    EnreachVoiceIntegration.callEnreachVoice(phoneNumber);
  };
};
