import React from 'react';

import EnvSettings from 'src/api/EnvSettings';
import { CommentWidgetButtons } from './CommentWidgetButtons';
import { newLinesToBr } from 'src/Utilities/parseUtils';
import {
  getReplyContent,
  getReplyAllContent,
  getForwardContent,
  getReplyContentSimple,
  getForwardContentSimple
} from 'src/Utilities/replyContent';
import type { SenderEmail } from 'src/types/TicketType';
import type { CommentEditorProps } from './types';

interface CommentEditorWidgetProps extends CommentEditorProps {
  isHTML: boolean;
  attachmentIds: string[];
  buttonsType?: 'basic' | 'primary';
}

const defineToParameter = (props: CommentEditorWidgetProps) => {
  if (props.metaData?.headers?.['reply-to']) {
    if (props.metaData.headers['reply-to'].value) {
      return props.metaData.headers['reply-to'].value[0].address;
    } else {
      let emailTag = props.metaData.headers['reply-to'].text.match(/<(.*?)>/);
      return emailTag[0];
    }
  } else {
    return props.metaData.from;
  }
};

const CommentEditorWidget = (props: CommentEditorWidgetProps) => {
  const metaDataIsValidJson = props.metaData !== null && props.metaData !== undefined;
  const hasToAddresses = props.metaData?.to !== undefined && Array.isArray(props.metaData?.to);
  // for replyAll : extra condition added because missing
  const hasFromAddresses =
    props.metaData?.from !== undefined && (Array.isArray(props.metaData?.from) || props.metaData?.from);

  const replyToEmail = (data: object) => {
    props.replyToEmail(data, props.taskId);
  };

  const handleReply = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getReplyContent(props)) : getReplyContent(props),
      subject: 'RE: ' + (props.title !== '' ? props.title : props.metaData.title),
      to: defineToParameter(props)
    });
  };

  const handleReplyForward = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getForwardContent(props)) : getForwardContent(props),
      subject: 'FW: ' + (props.title !== '' ? props.title : props.metaData.title),
      to: '',
      selectedAttachmentIds: props.attachmentIds
    });
  };

  const handleReplySimple = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getReplyContentSimple(props)) : getReplyContentSimple(props)
    });
  };

  const handleForwardSimple = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getForwardContentSimple(props)) : getForwardContentSimple(props),
      to: ''
    });
  };

  if (metaDataIsValidJson && hasToAddresses && hasFromAddresses) {
    let filteredRecipients = props.metaData.to.filter((recipient: string | null) => {
      if (typeof recipient === 'string') {
        return true;
      } else {
        return false;
      }
    });

    if (EnvSettings.getSettings().FILTER_SYSTEM_EMAIL === 'true') {
      filteredRecipients = filteredRecipients.filter((recipient: string) => {
        if (
          props.senderEmails.find((systemEmail: SenderEmail) => {
            return systemEmail.email.toLowerCase() === recipient.toLowerCase();
          }) === undefined
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    filteredRecipients = [props.metaData.from].concat(filteredRecipients).join(', ');

    const handleReplyAll = () => {
      replyToEmail({
        content: !props.isHTML ? newLinesToBr(getReplyAllContent(props)) : getReplyAllContent(props),
        subject: 'RE: ' + (props.title || props.metaData.title),
        to: filteredRecipients,
        cc: props.metaData.cc ? props.metaData.cc.join(', ') : undefined
      });
    };

    return (
      <CommentWidgetButtons
        onReplyClick={handleReply}
        onReplyAllClick={handleReplyAll}
        onReplyForwardClick={handleReplyForward}
        buttonsType={props.buttonsType}
      />
    );
  } else {
    return <CommentWidgetButtons onReplyClick={handleReplySimple} onReplyForwardClick={handleForwardSimple} />;
  }
};

export default CommentEditorWidget;
