import React from 'react';
import _ from 'lodash';
import iziToast from 'izitoast';
import { Form } from 'semantic-ui-react';
import { t } from 'i18next';
import type { DropdownItemProps } from 'semantic-ui-react';

import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import EezyApi from 'src/api/EezyApi';
import FormDropzoneDropdown from './components/FormDropzoneDropdown';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyTemplates from './ReplyTemplates';
import ReplyTextArea from './ReplyTextArea';
import { Channels } from 'src/types/Channel';
import { DATE_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { ReplyMethod } from './ReplyMethod';
import type { Entity, Ticket, Attachment, UploadFileResult } from 'src/types/Ticket';
import type { PersonalData, User } from 'src/types/User';
import type { ReplyMethodProps } from './ReplyMethod';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { TicketType } from 'src/types/TicketType';
import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';

interface EezyFormPayload {
  subject: string;
  content: string;
  selectedAttachmentURIs: string[];
  isSetReplyAsDone: boolean;
}

interface ReplyEezyProps extends ReplyMethodProps<ReplyEezyState> {
  drafts: ReplyEezyState;
  userData: PersonalData;
  task: Ticket;
  ticketType: TicketType;
  templates: ResponseTemplate[];
  entities: Entity[];
  users: User[];
  attachments: Attachment[];

  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
}

export interface ReplyEezyState {
  isSubmitting: boolean;
  attachmentsOptions: DropdownItemProps[];
  payload: EezyFormPayload;
  selectedReplyTemplate: string | undefined;
}

class ReplyEezy extends ReplyMethod<ReplyEezyProps, ReplyEezyState> {
  constructor(props: ReplyEezyProps) {
    super(props);

    this.state = this.getInitialState(this.props.drafts);
  }

  componentWillReceiveProps(nextProps: ReplyEezyProps) {
    if (!_.isEqual(this.props.attachments, nextProps.attachments)) {
      this.setState({ attachmentsOptions: this.getAttachmentsOptions(nextProps.attachments) });
    }
  }

  getDraftChannel(): Channels {
    return Channels.eezy;
  }

  getDraftState(): Partial<ReplyEezyState> {
    return {
      payload: this.state.payload,
      selectedReplyTemplate: this.state.selectedReplyTemplate
    };
  }

  private getInitialState = (drafts: ReplyEezyState): ReplyEezyState => ({
    isSubmitting: false,
    attachmentsOptions: this.getAttachmentsOptions(this.props.attachments),
    selectedReplyTemplate: drafts.selectedReplyTemplate || undefined,
    payload: {
      ...{ subject: `Vs: ${this.props.task.title}`, content: '', selectedAttachmentURIs: [], isSetReplyAsDone: true },
      ...drafts.payload
    }
  });

  private handleSetState = (fields: Partial<ReplyEezyState['payload']>) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          ...fields
        }
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    return Promise.all(
      attachmentFiles.map(async (file) => {
        const data = new FormData();
        data.append('attachments', file);

        return this.props.uploadFile(this.props.taskId, data).then((files) => {
          const attachmentURIArray = files.map((att) => att.path);
          const allIds = [...this.state.payload.selectedAttachmentURIs, ...attachmentURIArray];

          this.handleSetState({ selectedAttachmentURIs: allIds });
          return files;
        });
      })
    );
  };

  private getAttachmentsOptions = (attachments: Attachment[]) => {
    const options: DropdownItemProps[] = attachments
      .filter((att) => !att.deprecated)
      .map((attachment) => ({
        text: attachment.fileName,
        value: attachment.uri,
        uploaded: attachment.uploaded,
        isQuarantined: attachment.isQuarantined,
        description: getPrettyDate(attachment.uploaded, { format: DATE_FORMAT }),
        label: attachment.isQuarantined ? { color: 'red', empty: true, circular: true } : ''
      }));

    options.push({
      text: t('ATTACHMENT_ADD_DROPDOWN'),
      value: 'ADD_ATTACHMENT'
    });

    return options;
  };

  public clearFields = () =>
    this.setState(
      {
        selectedReplyTemplate: undefined,
        payload: {
          subject: `Vs: ${this.props.task.title}`,
          content: '',
          selectedAttachmentURIs: [],
          isSetReplyAsDone: true
        }
      },
      () => {
        this.saveDraft(this.state);
      }
    );

  public onSubmit = async () => {
    if (this.state.isSubmitting || !this.state.payload.subject.length || !this.state.payload.content.length) {
      return;
    }

    this.setState({
      isSubmitting: true
    });

    try {
      const commentsWithRelativeForeignId = this.props.task.comments.filter(
        (comment) => comment.foreignIdType === 'eezyExtraMessage' && comment.foreignId
      );
      const replyToComment = commentsWithRelativeForeignId[commentsWithRelativeForeignId.length - 1]!;
      const newERPReply = await EezyApi.sendReply({
        ...this.state.payload,
        replyToId: parseInt(replyToComment.foreignId!, 10),
        content: `${this.state.payload.content} \n\n${
          replyToComment.title ? replyToComment.title : '-- Viestisi Eezylle --'
        } \n\n ${replyToComment.content}`,
        ticketId: this.props.taskId
      });

      if (this.state.payload.isSetReplyAsDone) {
        iziToast.success({
          title: t('OK'),
          icon: 'icon check',
          message: t('eezy_reply.toasts.message_completed', { replyId: newERPReply.replyId }),
          timeout: 5000
        });
      }

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: t('eezy_reply.toasts.message_created', { replyId: newERPReply.replyId, taskId: this.props.taskId }),
        timeout: 5000
      });
    } catch (error) {
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: t('eezy_reply.toasts.message_failed', { taskId: this.props.taskId }),
        timeout: 7500
      });
    }

    this.clearFields();
    this.setState({
      isSubmitting: false
    });
  };

  render() {
    return (
      <Form reply={true} style={{ marginTop: '20px' }}>
        <Form.Group>
          <Form.Field width={11}>
            <label>{t('ADD_COMMENT_TITLE')}</label>

            <Form.Input
              onChange={(event, data) => this.handleSetState({ subject: data.value })}
              type="text"
              fluid={true}
              value={this.state.payload.subject}
            >
              <input />
            </Form.Input>

            {this.state.payload.subject.length >= 900 && (
              <p style={{ color: 'red' }}>{t('general_reply.max_length_limit')} (900)</p>
            )}
          </Form.Field>

          <Form.Field width={7}>
            <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>

            <ReplyTemplates
              userData={this.props.userData}
              ticketType={this.props.ticketType}
              templates={this.props.templates}
              task={this.props.task}
              channel={ChannelType.Eezy}
              entities={this.props.entities}
              users={this.props.users}
              selectedOption={this.state.selectedReplyTemplate}
              setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
              setContent={(value) => this.handleSetState(value)}
              content={
                this.state.payload.subject && this.state.payload.subject.length > 900
                  ? this.state.payload.subject.slice(900, -1) + ' \n\n ' + this.state.payload.content
                  : this.state.payload.content
              }
            />
          </Form.Field>
        </Form.Group>

        <Form.Field>
          <FormDropzoneDropdown
            attachments={this.props.attachments}
            onChangeAttachments={(addedAttachments) =>
              this.handleSetState({ selectedAttachmentURIs: addedAttachments })
            }
            onDropAccepted={this.onUploadAttachment}
            options={this.state.attachmentsOptions}
            value={this.state.payload.selectedAttachmentURIs}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('ADD_COMMENT_CONTENT')}</label>

          <ReplyTextArea
            content={this.state.payload.content}
            onChange={(value) => this.handleSetState({ content: value })}
            onKeyDown={(event: KeyDownEvent) => {
              if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                this.onSubmit();
              }
            }}
          />
        </Form.Field>

        <Form.Checkbox
          toggle={true}
          onClick={(event, data) => this.handleSetState({ isSetReplyAsDone: data.checked })}
          checked={this.state.payload.isSetReplyAsDone}
          label={t('eezy_reply.labels.set_as_done')}
        />

        <ReplyControlButtons
          internal={true}
          disabled={this.state.isSubmitting || !this.state.payload.subject.length || !this.state.payload.content.length}
          loading={this.state.isSubmitting}
          onClear={this.clearFields}
          onSubmit={this.onSubmit}
        />
      </Form>
    );
  }
}

export default ReplyEezy;
