import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

export const REPLY_DEBOUNCE_TIME = 100;

interface ReplyTextAreaProps {
  onChange(value: string): void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  content: string;
}

const ReplyTextArea = React.forwardRef<HTMLTextAreaElement, ReplyTextAreaProps>(
  ({ onChange, onKeyDown, content }, ref) => {
    const [value, setValue] = useState(content);

    useEffect(() => {
      setValue(content);
    }, [content]);

    const onValueChange = useCallback(
      _.debounce((value) => {
        onChange(value);
      }, REPLY_DEBOUNCE_TIME),
      [onChange]
    );

    return (
      <TextareaAutosize
        rows={3}
        minRows={3}
        ref={ref as any}
        onKeyDown={onKeyDown}
        style={{
          borderRadius: '5px',
          marginTop: '10px',
          marginBottom: '10px',
          wordBreak: 'break-word'
        }}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          e.preventDefault();
          setValue(e.target?.value);
          onValueChange(e.target?.value);
        }}
        autoFocus
      />
    );
  }
);

export default ReplyTextArea;
