import * as React from 'react';
import { Segment, Loader, List, Header, Grid, Icon, Button } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { t } from 'i18next';

import ApiConfig from 'src/api/ApiConfig';
import { performUserLogoutWhenTokenHasExpired, refreshToken } from 'src/actions/authActions';
import { accessTokenRefresher } from 'src/Utilities/token';
import { store } from 'src/store';
import type { InitialLoadProps } from 'src/containers/InitialLoadContainer';

interface InitialLoadState {
  showChildren: boolean;
}

export type initialRequestsTypes =
  | 'assets'
  | 'users'
  | 'ticketTypes'
  | 'responseTemplates'
  | 'titleTemplates'
  | 'channelTypes'
  | 'linkLists'
  | 'personalData'
  | 'tags'
  | 'categories'
  | 'envSettings'
  | 'featureFlags'
  | 'roles'
  | 'caseImportTemplates'
  | 'priorities'
  | 'PhoneConfigurations'
  | 'chatStatuses'
  | 'PostIntegrationConfiguration';

export interface InitialRequestState {
  type: initialRequestsTypes;
  isLoading: boolean;
  isCompleted: boolean;
  error: null | Error;
}

class InitialLoad extends React.Component<InitialLoadProps, InitialLoadState> {
  state: InitialLoadState = {
    showChildren: false
  };

  getIcon = (initialRequest: InitialRequestState) => {
    if (initialRequest.isCompleted) {
      return <List.Icon name="check" color="green" size="big" verticalAlign="middle" />;
    } else if (initialRequest.isLoading) {
      return (
        <List.Icon verticalAlign="middle">
          <Loader inline={true} active={true} indeterminate={true} />
        </List.Icon>
      );
    } else if (initialRequest.error !== null) {
      return <List.Icon name="warning circle" size="big" color="red" verticalAlign="middle" />;
    } else {
      return <List.Icon name="question" size="big" color="yellow" verticalAlign="middle" />;
    }
  };

  getStatus = (initialRequest: InitialRequestState) => {
    if (initialRequest.isCompleted) {
      return t('INIT_LOAD_REQUEST_STATE_COMPLETED');
    } else if (initialRequest.isLoading) {
      return t('INIT_LOAD_REQUEST_STATE_LOADING');
    } else if (initialRequest.error !== null) {
      return (
        // eslint-disable-next-line
        <a onClick={() => this.fetchAgain(initialRequest)}>
          <Icon name="redo" size="small" />
          {t('INIT_LOAD_REQUEST_STATE_ERROR')}
        </a>
      );
    } else {
      return t('INIT_LOAD_REQUEST_STATE_UNKNOWN');
    }
  };

  fetchAgain = (reqState: InitialRequestState) => {
    this.props.setInitialRequestToLoading(reqState.type);
    switch (reqState.type) {
      case 'users':
        this.props.fetchUsers();
        break;
      case 'ticketTypes':
        this.props.fetchTicketTypes();
        break;
      case 'channelTypes':
        this.props.fetchChannelTypes();
        break;
      case 'responseTemplates':
        this.props.fetchResponseTemplates();
        break;
      case 'titleTemplates':
        this.props.fetchTitleTemplates();
        break;
      case 'linkLists':
        this.props.fetchLinkLists();
        break;
      case 'tags':
        this.props.fetchTags();
        break;
      case 'categories':
        this.props.fetchCategories();
        break;
      case 'personalData':
        this.props.fetchPersonalData();
        break;
      case 'assets':
        this.props.fetchAssets();
        break;
      case 'envSettings':
        this.props.fetchEnvSettings();
        break;
      case 'featureFlags':
        this.props.fetchFeatureFlags();
        break;
      case 'roles':
        this.props.fetchRoles();
        break;
      case 'caseImportTemplates':
        this.props.fetchCaseImportTemplates();
        break;
      case 'PhoneConfigurations':
        this.props.fetchPhoneConfigurations();
        break;
      case 'chatStatuses':
        this.props.fetchChats();
        break;
      default:
        console.error('Could not fetch again this type!');
    }
  };

  async componentDidMount() {
    const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');
    if (accessTokenExpiration && parseInt(accessTokenExpiration) < Math.floor(Date.now() / 1000)) {
      this.props.refreshToken();
    }

    if (accessTokenExpiration) {
      accessTokenRefresher(() => store.dispatch(refreshToken(true) as any)).catch(() =>
        store.dispatch(performUserLogoutWhenTokenHasExpired() as any)
      );
    }

    // Dispatch all requests here!
    this.props.fetchEnvSettings();
    this.props.fetchFeatureFlags();
    this.props.fetchUsers();
    this.props.fetchTicketTypes();
    this.props.fetchAutoSuggestions();
    this.props.fetchChannelTypes();
    this.props.fetchResponseTemplates();
    this.props.fetchTitleTemplates();
    this.props.fetchLinkLists();
    this.props.fetchTags();
    this.props.fetchCategories();
    this.props.fetchChats();
    this.props.fetchRoles();
    this.props.fetchTicketPriorities();
    if (
      ApiConfig.getConfig().ASSET_API_URL !== undefined &&
      ApiConfig.getConfig().ASSET_API_URL !== '' &&
      ApiConfig.getConfig().ASSET_API_URL !== null
    ) {
      this.props.fetchAssets();
    }
    this.props.fetchPersonalData();
    this.props.fetchCaseImportTemplates();
    this.props.fetchPriorities();
    this.props.fetchPhoneConfigurations();
  }

  sortStatuses = (a: InitialRequestState, b: InitialRequestState) => {
    return a.type > b.type ? 1 : -1;
  };

  render() {
    const completedRequests = this.props.initialRequests.filter(
      (reqStatus: InitialRequestState) => reqStatus.isCompleted
    );
    const hasErrors =
      this.props.initialRequests.filter((reqStatus: InitialRequestState) => reqStatus.error !== null).length > 0;
    const allHasCompleted = completedRequests.length === this.props.initialRequests.length;

    if (this.state.showChildren === true || allHasCompleted) {
      return this.props.children;
    } else {
      return (
        <Translation ns="translations">
          {(tr) => (
            <Segment
              style={{
                width: '75%',
                position: 'absolute',
                top: '10%',
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                backgroundColor: '#14375a'
              }}
              className="intialLoad"
            >
              <Grid>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <div className="logo" style={{ width: '360px', height: '80px' }} />
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    <Header align="center" as="h2" style={{ color: 'white' }}>
                      {tr('EEEDO_CUSTOMER_SERVICE_MODULE')}
                      <Header.Subheader style={{ color: 'white' }}>
                        {import.meta.env.VITE_CONTAINER_IMAGE}
                        <br />
                        {hasErrors && tr('INITIAL_LOAD_ERRORS')}
                        {!hasErrors && tr('INITIAL_LOAD_LOADING_FILES')}
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <List relaxed={true} style={{ backgroundColor: 'white', padding: '20px' }}>
                {this.props.initialRequests.sort(this.sortStatuses).map((initialRequest: InitialRequestState) => (
                  <List.Item key={initialRequest.type}>
                    {this.getIcon(initialRequest)}
                    <List.Content>
                      <List.Header>{tr('INIT_LOAD_TITLE_' + initialRequest.type)}</List.Header>
                      <List.Description>{this.getStatus(initialRequest)}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
              {hasErrors && (
                <div
                  style={{
                    padding: '20px',
                    backgroundColor: 'white',
                    width: '100%',
                    color: 'red'
                  }}
                >
                  <p>
                    <Icon name="warning sign" />
                    {t('INITIAL_LOAD_DESCRIPTION_RECOMMEND_LOGOUT')}
                  </p>
                </div>
              )}
              <div
                className="initialLoadBtnContainer"
                style={{
                  padding: '20px',
                  backgroundColor: 'white',
                  width: '100%'
                }}
              >
                <Button
                  negative={true}
                  onClick={() => this.setState({ showChildren: true })}
                  labelPosition="left"
                  icon={true}
                >
                  <Icon name="warning sign" />
                  {t('INIT_LOAD_CONTINUE_ANYWAY')}
                </Button>
                <Button
                  className="initialLoadLogOutBtn"
                  primary={true}
                  onClick={() => this.props.logout()}
                  labelPosition="left"
                  icon={true}
                  floated="right"
                >
                  <Icon name="log out" />
                  {t('MAIN_TOPBAR_LOGOUT')}
                </Button>
              </div>
            </Segment>
          )}
        </Translation>
      );
    }
  }
}

export default InitialLoad;
