import { FETCH_PERSONAL_DATA_SUCCESS, REMOVE_ALL_NOTIFICATIONS } from '../actions/index';

const initialState = {
  data: {}
};

const userDataReducer = (state = initialState.data, action: any) => {
  switch (action.type) {
    case FETCH_PERSONAL_DATA_SUCCESS: {
      return action.payload;
    }
    case REMOVE_ALL_NOTIFICATIONS: {
      return {
        ...state,
        userChannelNotifications: []
      };
    }
    default:
      return state;
  }
};

export default userDataReducer;
