import type { Ticket } from 'src/types/Ticket';
import type { TicketType, Widget, WidgetName } from 'src/types/TicketType';

const defaultWidgetOrder: WidgetName[] = [
  'SuggestedArticles',
  'CaseChannel',
  'Priority',
  'HandleStatus',
  'Delegations',
  'Tags',
  'AttachEntityById',
  'CustomerInfo',
  'FieldSets',
  'ExternalLinks',
  'Surveys',
  'Attachments'
];

const defaultWidgets: Widget[] = defaultWidgetOrder.map((name) => ({ name, defaultClosed: false }));

export const getWidgetOrder = (task?: Ticket, ticketTypes?: TicketType[]) => {
  const taskTypeData = (ticketTypes || []).find(({ name }) => name === task?.taskType);
  if (!taskTypeData || !taskTypeData.widgetOrder) {
    return defaultWidgets;
  }

  const taskTypeWidgetOrder = taskTypeData.widgetOrder.map((widget) =>
    typeof widget === 'string' ? ({ name: widget, defaultClosed: false } as Widget) : widget
  );

  return [
    ...taskTypeWidgetOrder,
    ...defaultWidgets.filter(({ name }) => !taskTypeWidgetOrder.some((widget) => widget.name === name))
  ];
};
