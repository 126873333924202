import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Divider } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';

import AdvancedActions from 'src/Components/Case/TopBar/AdvancedActions';
import { updateTicket } from 'src/actions/ticketsActions';
import TitleTemplates from '../TitleTemplates';
import TopBarLabelList from './TopBarLabelList';
import TopBarCopyDropdown from './TopBarCopyDropdown';
import TopBarTitleInput from 'src/Components/Case/TopBar/TopBarTitleInput';
import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';
import type { ContentTypes } from 'src/types/ContentTypes';

import './TopBarGeneralInfo.css';

interface OwnProps {
  refContext?: React.RefObject<HTMLElement>;
}

interface TopBarGeneralInfoProps extends TopBarGeneralInfoHOCProps {
  contentType: ContentTypes;
}

interface TopBarGeneralInfoState {
  isEditingTitle: boolean;
  taskTitle: string;
  savedTitle: string;
}

class TopBarGeneralInfo extends React.Component<TopBarGeneralInfoProps, TopBarGeneralInfoState> {
  constructor(props: TopBarGeneralInfoProps) {
    super(props);

    const title = this.props.task ? this.props.task.title : '';
    this.state = {
      isEditingTitle: false,
      taskTitle: title,
      savedTitle: title
    };
  }

  componentWillReceiveProps(nextProps: TopBarGeneralInfoProps) {
    if (nextProps.task && this.props.task && nextProps.task.id !== this.props.task.id) {
      this.setState({ isEditingTitle: false });
    }

    if (!this.state.isEditingTitle) {
      this.setState({
        taskTitle: nextProps.task.title,
        savedTitle: nextProps.task.title
      });
    }
  }

  private dueDateChange = (newDueDate: number) => {
    if (!this.props.task) {
      return;
    }
    if (typeof this.props.task.id !== 'undefined' && this.props.task.id !== 'NEW') {
      this.props.updateTicket(this.props.task.id, { dueDate: newDueDate });
    }
  };

  private handleBlur = () => {
    if (this.props.task) {
      this.props.updateTicket(this.props.task.id, {
        title: this.state.taskTitle
      });
    }
  };

  render() {
    const topBarRefHeight = document.getElementById('topBarGeneralInfo')?.clientHeight;
    if (topBarRefHeight) {
      document.documentElement.style.setProperty('--topBarHeight', `${topBarRefHeight - 5}px`);
    }

    return (
      <Grid.Column width={10} className="statusLabelColumn topBarGeneralInfo" id="topBarGeneralInfo">
        <Grid.Row>
          <Grid.Column className="longTitle" style={{ minWidth: '300px', minHeight: '40px' }}>
            {this.state.isEditingTitle ? (
              <TopBarTitleInput
                value={this.state.taskTitle}
                onChange={(event) => {
                  this.setState({ taskTitle: event.target.value });
                }}
                onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const oldTitle = this.state.savedTitle;
                  const newTitle = event.target.value;

                  this.setState(
                    {
                      taskTitle: newTitle,
                      savedTitle: newTitle,
                      isEditingTitle: false
                    },
                    () => {
                      if (newTitle === oldTitle) {
                        return;
                      }

                      this.handleBlur();
                    }
                  );
                }}
              />
            ) : (
              <div
                className="titleContainer"
                onClick={() => {
                  this.setState({ isEditingTitle: true });
                }}
              >
                <Header as="h2">{this.state.taskTitle}</Header>

                {!!this.props.titleTemplates.length && (
                  <TitleTemplates
                    updateTitle={(value: string) => {
                      this.setState(
                        {
                          taskTitle: value,
                          savedTitle: value,
                          isEditingTitle: false
                        },
                        () => this.handleBlur()
                      );
                    }}
                    title={this.state.taskTitle}
                    ticketType={this.props.task.taskType}
                    templates={this.props.titleTemplates}
                  />
                )}

                <TopBarCopyDropdown
                  contentType={this.props.contentType}
                  text={this.state.taskTitle}
                  caseId={this.props.task.id}
                  taskCreated={this.props.task.created}
                />
              </div>
            )}
          </Grid.Column>
        </Grid.Row>

        <TopBarLabelList
          channels={this.props.channels}
          dueDateChange={this.dueDateChange}
          task={this.props.task}
          userData={this.props.userData}
          updateTicket={this.props.updateTicket}
          showWeight={this.props.contentType === 'infopage'}
        />

        <Grid.Row>
          <Grid.Column>
            <AdvancedActions />
          </Grid.Column>
        </Grid.Row>

        <Divider style={{ margin: '0.5rem 0 1rem' }} />
      </Grid.Column>
    );
  }
}

const mapStateToProps = (state: State, ownProps: OwnProps) => {
  return {
    mobileMode: state.mobileReducer.mobileMode,
    task: state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)!,
    ticketTypesMetadata: state.ticketTypesMetadata,
    userData: state.userData,
    titleTemplates: state.templates.titleTemplates,
    channels: state.channels,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTicket: (id: string, data: Partial<Ticket>) => {
      dispatch(updateTicket(id, data));
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TopBarGeneralInfoHOCProps = ConnectedProps<typeof connector>;

export default connector(TopBarGeneralInfo);
