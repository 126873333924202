import { START_AJAX_CALL } from '../actions';

export interface AjaxCallStatus {
  name: string;
  id?: string;
}

const initialState = {
  ajaxCalls: []
};

const actionTypeEndsIn = (actionType: string, ending: string) => {
  if (actionType.endsWith(ending)) {
    return true;
  } else {
    return false;
  }
};

const ajaxStatusReducer = (state = initialState.ajaxCalls, action: any) => {
  if (action.type === START_AJAX_CALL) {
    return [...state, action.callType];
  } else if (actionTypeEndsIn(action.type, 'SUCCESS')) {
    let completed = action.type.substring(0, action.type.indexOf('_SUCCESS'));
    return [...state.filter((callType: AjaxCallStatus) => callType.name !== completed)];
  } else if (actionTypeEndsIn(action.type, 'ERROR')) {
    let errored = action.type.substring(0, action.type.indexOf('_ERROR'));
    return [...state.filter((callType: AjaxCallStatus) => callType.name !== errored)];
  } else if (actionTypeEndsIn(action.type, 'FAILURE')) {
    let failed = action.type.substring(0, action.type.indexOf('_FAILURE'));
    return [...state.filter((callType: AjaxCallStatus) => callType.name !== failed)];
  }
  return state;
};

export default ajaxStatusReducer;
