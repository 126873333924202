import _ from 'lodash';
import type { fetchTicketSuccess } from 'src/actions/ticketsActionsRTK';

import {
  ADD_COMMENT_SUCCESS,
  ADD_TICKET,
  ADDED_ATTACHMENT_TO_TICKET,
  CLOSE_TAB,
  CREATE_TICKET_SUCCESS,
  REMOVE_TICKET,
  SWITCH_TICKET_CHAT_ANCHOR_STATUS,
  UPDATE_CHAT_VISITOR_TYPE_STATUS,
  UPDATE_METADATA_SUCCESS,
  UPDATE_TICKET_SUCCESS,
  UPDATE_TICKET_TAB_STATE,
  FETCH_TICKET_SUCCESS,
  GET_ENTITY,
  GET_ENTITY_BY_ID_FAILURE,
  GET_ENTITY_BY_ID_SUCCESS,
  GET_SUB_ENTITY_BY_ID_FAILURE,
  GET_SUB_ENTITY_BY_ID_SUCCESS,
  SAVE_ENTITY_CHANGE,
  START_AJAX_CALL
} from '../actions';
import { switchChatAnchored } from 'src/types/Ticket';
import { StaticTabs } from 'src/types/TicketList';
import type { Ticket, Entity, EntityResponse } from 'src/types/Ticket';

const initialState = {
  detailedTickets: [],
  activeTicketTab: null
};

export interface TicketWithActiveProperty extends Ticket {
  active: boolean;
  chatAnchored: boolean;
}

const ticketReducer = (state: TicketWithActiveProperty[] = initialState.detailedTickets, action: any) => {
  switch (action.type) {
    case UPDATE_CHAT_VISITOR_TYPE_STATUS: {
      const { composing_status: chatStatus, ticketId: changedTypeStatusTicketId } = action.payload.data as {
        chatType: string;
        chat_id: string;
        composing_status: string;
        ticketId: string;
      };

      const currentChatTicketStatus = state.find((ticket) => ticket.id === changedTypeStatusTicketId);
      if (currentChatTicketStatus) {
        const newTicket = { ...currentChatTicketStatus, chatTypeStatus: chatStatus };
        return [...state.filter((ticket) => ticket.id !== changedTypeStatusTicketId), newTicket];
      } else {
        return state;
      }
    }
    case ADD_TICKET: {
      return [...state, _.cloneDeep(action.payload.ticket)];
    }

    case SWITCH_TICKET_CHAT_ANCHOR_STATUS: {
      let detailedTickets = _.cloneDeep(state);
      detailedTickets = detailedTickets.map((ticket: TicketWithActiveProperty) => {
        if (ticket.id === action.payload) {
          switchChatAnchored(ticket);
        }
        return ticket;
      });
      return detailedTickets;
    }

    case CLOSE_TAB: {
      if (action.payload.id === StaticTabs.MAIN_VIEW) {
        return state;
      }

      return state.filter((ticket: Ticket) => ticket.id !== action.payload);
    }

    // is already switched to RTK in ticketListTabReducer
    case FETCH_TICKET_SUCCESS: {
      const { payload } = action as ReturnType<typeof fetchTicketSuccess>;
      const oldTicket = state.find((ticket: Ticket) => ticket.id === payload.id);

      const entities = (payload.entities || []).map((entity) => ({
        ...oldTicket?.entities.find(({ _id, _type }) => _id === entity._id && _type === entity._type),
        ...entity,
        isLoading: true
      }));

      const newTicket = {
        ...payload,
        entities
      };

      return [...state.filter((ticket) => ticket.id !== action.payload.id), newTicket];
    }

    case ADDED_ATTACHMENT_TO_TICKET: {
      const oldTicket = state.find((ticket: Ticket) => {
        return ticket.id === action.ticketId;
      });

      if (oldTicket) {
        const newTicket: Ticket = _.cloneDeep(oldTicket);
        newTicket.attachments = [...newTicket.attachments, action.payload];
        return [...state.filter((ticket: Ticket) => ticket.id !== action.ticketId), newTicket];
      } else {
        return state;
      }
    }

    case REMOVE_TICKET: {
      return [...state.filter((ticket: Ticket) => ticket.id !== action.ticketId)];
    }

    case UPDATE_METADATA_SUCCESS: {
      const updatedTicket = state.find((tk: Ticket) => tk.id === action.payload.id);
      if (!updatedTicket) {
        return state;
      }

      const newTicket = _.cloneDeep(updatedTicket);
      newTicket.metaData = action.payload.metaData;
      return [...state.filter((ticket: Ticket) => ticket.id !== action.payload.id), newTicket];
    }

    case UPDATE_TICKET_SUCCESS: {
      const ticketReference = state.find((tk) => tk.id === action.ticketId);
      if (!ticketReference) {
        return state;
      }
      const updatedTicket = _.cloneDeep(ticketReference);
      switch (action.payload.group) {
        case 'CaseDetails':
          updatedTicket.case[action.payload.updateKey] = action.payload.updateValue;
          break;
        case 'Entities':
          if (action.payload.type === 'ADD') {
            if (updatedTicket.entities === undefined) {
              updatedTicket.entities = [];
            }
            const data = {
              hasAccess: true,
              isLoading: false,
              _id: action.payload.data['_id'],
              _type: action.payload.data['_type'],
              data: action.payload.data,
              ticketId: action.ticketId
            };
            updatedTicket.entities = [...updatedTicket.entities, data];
          } else if (action.payload.type === 'REMOVE') {
            updatedTicket.entities = [
              ...updatedTicket.entities.filter((entity) => {
                return entity._id !== action.payload.entityId || entity._type !== action.payload.entityType;
              })
            ];
          } else {
            throw new Error('Should not go here..');
          }
          break;
        case 'EntityDetails': {
          const entities = _.cloneDeep(updatedTicket.entities);
          let updatedEntity = _.cloneDeep(
            entities.find(
              (entity) => entity._id === action.payload.entityId && entity._type === action.payload.entityType
            )
          );
          if (updatedEntity === undefined) {
            updatedEntity = { data: {} } as Entity;
          }

          if (action.payload.partial) {
            if (updatedEntity.data[action.payload.object] === undefined) {
              updatedEntity.data[action.payload.object] = {};
            }
            updatedEntity.data[action.payload.object][action.payload.fieldName] = action.payload.valueToSave;
          } else {
            updatedEntity.data[action.payload.fieldName] = action.payload.valueToSave;
          }
          updatedTicket.entities = [
            ...updatedTicket.entities.filter(
              (entity) => entity._id !== action.payload.entityId || entity._type !== action.payload.entityType
            ),
            updatedEntity
          ];
          break;
        }
        case 'Delegate':
          if (action.payload.remove) {
            updatedTicket.delegatedTo = updatedTicket.delegatedTo.filter((delegate) => {
              return delegate !== action.payload.usr;
            });
          } else {
            if (action.payload.usr) {
              updatedTicket.delegatedTo.push(action.payload.usr);
            } else {
              action.payload.userGroup.map((usr: string) => {
                return updatedTicket.delegatedTo.push(usr);
              });
            }
          }
          break;
        case 'Tag':
          if (action.payload.remove) {
            updatedTicket.tags = updatedTicket.tags.filter((tag) => {
              return tag !== action.payload.id;
            });
          } else {
            updatedTicket.tags.push(action.payload.id);
          }
          break;
        default:
          break;
      }

      return [...state.filter((ticket: Ticket) => ticket.id !== action.ticketId), updatedTicket];
    }

    case ADD_COMMENT_SUCCESS: {
      const targetticket = _.cloneDeep(state.find((ticket: Ticket) => ticket.id === action.payload.ticketId));
      if (targetticket) {
        targetticket.comments = [...targetticket.comments, action.payload.comment];

        return [...state.filter((ticket: Ticket) => ticket.id !== action.payload.ticketId), targetticket];
      }

      return state;
    }
    case CREATE_TICKET_SUCCESS: {
      return [
        ...state.filter((ticket: Ticket) => ticket.id !== 'new' && ticket.id !== 'new_oc'),
        _.cloneDeep(action.ticket)
      ];
    }

    case UPDATE_TICKET_TAB_STATE: {
      // map all tickets
      const newState = _.cloneDeep(state);
      return newState.map((ticket: any) => {
        // find correct ticket by ticketId
        if (ticket.id !== action.payload.ticketId) {
          return ticket;
        }

        return {
          ...ticket,
          state: {
            ...ticket.state,
            ...action.payload.state
          }
        };
      });
    }

    case SAVE_ENTITY_CHANGE: {
      const oldTicket = state.find((ticket: Ticket) => {
        return ticket.id === action.payload.id;
      });

      if (oldTicket) {
        const newTicket: Ticket = _.cloneDeep(oldTicket);
        newTicket.entityFields = {};
        newTicket.entityFields = action.payload.data;
        newTicket.entityFieldDisplayName = action.payload.entityDisplayName;
        return [...state.filter((ticket: Ticket) => ticket.id !== action.payload.id), newTicket];
      } else {
        return state;
      }
    }

    case START_AJAX_CALL: {
      if (action.callType.name !== GET_ENTITY) {
        return state;
      }
      const { params } = action.callType.payload;
      const isLoading = (ticket: TicketWithActiveProperty, entity: Entity) =>
        ticket.id === params.ticketId && entity._id === params.entityId && entity._type === params.entityType;

      return state.map((ticket) => ({
        ...ticket,
        entities: ticket.entities.map((entity) => ({
          ...entity,
          ...(isLoading(ticket, entity) && { isLoading: true })
        }))
      }));
    }

    case GET_ENTITY_BY_ID_FAILURE:
    case GET_ENTITY_BY_ID_SUCCESS: {
      // get ticket
      // update entity based on response
      const targetTicket = _.cloneDeep(state.find((ticket: Ticket) => ticket.id === action.payload.ticketId));
      if (!targetTicket) {
        return state;
      }

      const payload = {
        ...action.payload,
        isLoading: false
      };

      targetTicket.entities = [
        ...targetTicket.entities.filter(
          (entity: Entity | EntityResponse) =>
            entity._id !== action.payload._id || entity._type !== action.payload._type
        ),
        payload
      ];

      return [...state.filter((ticket: Ticket) => ticket.id !== action.payload.ticketId), targetTicket];
    }

    case GET_SUB_ENTITY_BY_ID_SUCCESS: {
      // get ticket
      // patch entity based on response
      const targetTicket = _.cloneDeep(state.find((ticket: Ticket) => ticket.id === action.payload.ticketId));
      if (!targetTicket) {
        return state;
      }

      const targetEntity = targetTicket.entities.find(
        (entity: Entity | EntityResponse) =>
          entity._id === action.payload.parentEntityId && entity._type === action.payload.parentEntityType
      );
      if (targetEntity?.data && !targetEntity.errorMessage) {
        if (!targetEntity.data.subEntities) {
          targetEntity.data.subEntities = {} as any;
        }
        targetEntity.data.subEntities[action.payload._id + action.payload._type] = action.payload;
      }

      return [...state.filter((ticket: Ticket) => ticket.id !== action.payload.ticketId), targetTicket];
    }

    case GET_SUB_ENTITY_BY_ID_FAILURE: {
      // get ticket
      // patch entity based on response
      const targetTicket = _.cloneDeep(state.find((ticket) => ticket.id === action.payload.ticketId));
      if (!targetTicket) {
        return state;
      }

      const targetEntity = targetTicket.entities.find(
        (entity: Entity | EntityResponse) =>
          entity._id === action.payload.parentEntityId && entity._type === action.payload.parentEntityType
      );
      if (targetEntity?.data && !targetEntity.errorMessage) {
        if (!targetEntity.data.subEntities) {
          targetEntity.data.subEntities = {} as any;
        }
        targetEntity.data.subEntities[action.payload._id + action.payload._type] = action.payload;
      }

      return [...state.filter((ticket: Ticket) => ticket.id !== action.payload.ticketId), targetTicket];
    }

    default: {
      return state;
    }
  }
};

export default ticketReducer;
