import { t } from 'i18next';
import moment from 'moment';
import iziToast from 'izitoast';
import throttle from 'lodash/throttle';
import { Socket } from 'socket.io-client';
import { Middleware, MiddlewareAPI, AnyAction, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { FETCH_PERSONAL_DATA_SUCCESS } from 'src/actions';
import { fetchTags } from 'src/actions/tagActions';
import { fetchChats } from 'src/actions/chatActions';
import { refreshToken, logout } from 'src/actions/authActions';
import { fetchCategories } from 'src/actions/categoryActions';
import { fetchInfoPages } from 'src/actions/infoPagesActions';
import { fetchPriorities } from 'src/actions/priorityActions';
import { fetchAutoSuggestions } from 'src/actions/suggestionActions';
import {
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTicketTypes,
  fetchTicket,
  updateTicketsChatVisitorTypeStatus,
  fetchTickets
} from 'src/actions/ticketsActions';
import { fetchPersonalData, fetchUsers } from 'src/actions/userActions';
import { addHighlightToTab, closeTab } from 'src/actions/tabActionsRTK';
import { showDisconnectedNotification } from 'src/actions/notificationsActions';
import { removeTicketFromTicketlist, updateTicketInTicketlist } from 'src/actions/ticketsActionsRTK';
import { updateInfoPageInList, removeInfopageFromInfopagelist } from 'src/actions/infoPagesActionsRTK';
import { updateWorkStatus, UpdateWorkStatusData } from 'src/actions/workStatusActions';
import SocketInstance from 'src/realTimeNotifications';
import NotificationHandler from 'src/Utilities/NotificationHandler';
import { StaticTabs } from 'src/types/TicketList';
import type { TicketListTicket } from 'src/types/Ticket';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { TicketType } from 'src/types/TicketType';
import type { State } from 'src/types/initialState';

interface TicketRealtime extends TicketListTicket {
  type: ContentTypes;
  deprecated: number;
}
interface ContentUpdatedData {
  ticketId: string;
  ticket: TicketRealtime;
  UID: number;
  lastAckedMessage: number;
  messageIdentifier: string;
}
interface API extends MiddlewareAPI<ThunkDispatch<State, any, AnyAction>, State> {}

const throttledUsersUpdate = throttle((dispatch) => {
  dispatch(fetchUsers());
}, 30000);

const throttledAutoSuggestionsUpdate = throttle((dispatch) => {
  dispatch(fetchAutoSuggestions());
}, 30000);

const handleDeprecated = (ticket: TicketRealtime, api: API) => {
  const isTabOpen = api
    .getState()
    .ticketTabs.map((t) => t.id)
    .includes(ticket.id);

  if (isTabOpen) {
    api.dispatch(closeTab(ticket.id));
  }

  api.dispatch(removeTicketFromTicketlist({ ticketId: ticket.id, id: StaticTabs.MAIN_VIEW }));
};

const handleContentByContentType = (data: ContentUpdatedData, api: API) => {
  const ticket = data.ticket;

  switch (ticket.type) {
    case 'infopage':
      api.dispatch(updateInfoPageInList(ticket, StaticTabs.MAIN_VIEW));
      break;
    case 'task':
      api.dispatch(updateTicketInTicketlist({ ticket, id: StaticTabs.MAIN_VIEW }));
      break;
    default:
      console.error('This type does not exist', data);
      break;
  }
};

export const socketInitMiddleware: Middleware = (api: API) => (next) => (action) => {
  const returnValue = next(action);
  const NotificationHandlerInstance = new NotificationHandler(api as Store);

  if (FETCH_PERSONAL_DATA_SUCCESS === action.type) {
    SocketInstance.initialize(action.payload.UID, {
      disconnect: () => (reason: Socket.DisconnectReason) => {
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          if (localStorage.getItem('loggedIn')) {
            api.dispatch(logout());
          }
        } else {
          // Disconnected due to network issues or service inaccessibility
          api.dispatch(refreshToken());
        }

        SocketInstance.socket.io.opts.query = {
          UID: SocketInstance._userId,
          ...(!!localStorage.getItem('lastAckedMessage') && {
            lastAckedMessage: localStorage.getItem('lastAckedMessage')
          })
        };
        api.dispatch(showDisconnectedNotification(true));
      },

      connect: () => () => {
        localStorage.setItem('lastAckedMessage', String(moment().valueOf()));
        api.dispatch(showDisconnectedNotification(false));
      },

      connect_error: () => () => {
        api.dispatch(refreshToken());
        api.dispatch(showDisconnectedNotification(true));
      },

      contentUpdated: (acknowledgeMessage) => (data: ContentUpdatedData) => {
        const { ticketId: updatedTicketId, ticket, UID, lastAckedMessage, messageIdentifier } = data;
        const type = ticket?.type;
        const reduxState = api.getState();
        localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);

        const detailedTicketToRefresh = reduxState.detailedTickets.find((ticket) => ticket.id === updatedTicketId);

        if (detailedTicketToRefresh && !ticket.deprecated) {
          api.dispatch(
            fetchTicket({
              id: updatedTicketId,
              closeTicketAfterFail: false,
              shouldActivateTicket: false,
              forceFetch: true,
              type
            })
          );
        }

        if (
          parseInt(reduxState.userData.UID.substring(3), 10) !== UID &&
          reduxState.ticketTabs.find((tab) => tab.id === updatedTicketId)
        ) {
          api.dispatch(addHighlightToTab(updatedTicketId));
        }

        if (ticket?.deprecated === 1) {
          handleDeprecated(ticket, api);
        } else {
          handleContentByContentType(data, api);
        }

        NotificationHandlerInstance.handleTicket(ticket);
        acknowledgeMessage?.(messageIdentifier);
      },

      personalDataUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchPersonalData());
          acknowledgeMessage?.(messageIdentifier);
        },

      responseTemplatesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchResponseTemplates());
          acknowledgeMessage?.(messageIdentifier);
        },

      updateTicketsChatVisitorTypeStatus:
        (acknowledgeMessage) =>
        (payload: {
          message: string;
          data: { [key: string]: any };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          try {
            const { message, data, lastAckedMessage, messageIdentifier } = payload;

            localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
            api.dispatch(
              updateTicketsChatVisitorTypeStatus({
                data,
                message
              })
            );
            acknowledgeMessage?.(messageIdentifier);
          } catch (error) {
            console.error('updateTicketsChatVisitorTypeStatus: ', error);
          }
        },

      tagsUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchTags());
          acknowledgeMessage?.(messageIdentifier);
        },

      channelsUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchChannelTypes());
          acknowledgeMessage?.(messageIdentifier);
        },

      categoriesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchCategories());
          acknowledgeMessage?.(messageIdentifier);
        },

      caseTicketTypeChanged:
        (acknowledgeMessage) =>
        async (payload: {
          data: { caseId: number; type: string; ticketType: number };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          const isUserHasAccessToTicketType = api
            .getState()
            .ticketTypes.find((ticketType: TicketType) => ticketType.id === data.ticketType);

          if (!isUserHasAccessToTicketType) {
            switch (data.type) {
              case 'task': {
                const ticketId = `TSK${payload.data.caseId}`;
                const ticketIsOpen =
                  api.getState().ticketTabs.filter((tab) => {
                    return tab.id === ticketId && tab.activeTab;
                  }).length > 0;

                if (ticketIsOpen) {
                  api.dispatch(closeTab(ticketId));
                  iziToast.error({
                    title: `${t('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Ticket ${ticketId} was closed due to the lack of permissions`,
                    timeout: 5000
                  });
                }
                api.dispatch(
                  removeTicketFromTicketlist({
                    ticketId,
                    id: StaticTabs.MAIN_VIEW
                  })
                );
                break;
              }

              case 'infopage': {
                const infopageId = `INF${payload.data.caseId}`;
                const infopageIsOpen =
                  api.getState().ticketTabs.filter((tab) => {
                    return tab.id === infopageId && tab.activeTab;
                  }).length > 0;

                if (infopageIsOpen) {
                  api.dispatch(closeTab(infopageId));
                  iziToast.error({
                    title: `${t('ERROR')}!`,
                    icon: 'icon delete',
                    message: `Infopage ${infopageIsOpen} was closed due to the lack of permissions`,
                    timeout: 5000
                  });
                }
                api.dispatch(removeInfopageFromInfopagelist(infopageId, StaticTabs.MAIN_VIEW));
                break;
              }

              default: {
                break;
              }
            }
          }
          acknowledgeMessage?.(messageIdentifier);
        },

      statusUpdates: () => (data: UpdateWorkStatusData) => {
        api.dispatch(updateWorkStatus(data));
      },

      usersUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          throttledUsersUpdate(api.dispatch);
        },

      ticketTypesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchTicketTypes());
          acknowledgeMessage?.(messageIdentifier);
        },

      userTicketTypeChanged:
        (acknowledgeMessage) =>
        async (payload: {
          data: { type: 'add' | 'remove'; name: string };
          lastAckedMessage: number;
          messageIdentifier: string;
        }) => {
          const { data, lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          SocketInstance.emit('updateRooms', { roomName: data.name, type: data.type });

          await api.dispatch(refreshToken(false));
          await api.dispatch(fetchTicketTypes());
          api.dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW));
          api.dispatch(fetchInfoPages(undefined, StaticTabs.MAIN_VIEW, false));

          acknowledgeMessage?.(messageIdentifier);
        },

      autoSuggestionsUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          acknowledgeMessage?.(messageIdentifier);

          throttledAutoSuggestionsUpdate(api.dispatch);
        },

      chatStatusesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchChats());
          acknowledgeMessage?.(messageIdentifier);
        },

      prioritiesUpdated:
        (acknowledgeMessage) =>
        (payload: { data: { [key: string]: any }; lastAckedMessage: number; messageIdentifier: string }) => {
          const { lastAckedMessage, messageIdentifier } = payload;

          localStorage.setItem('lastAckedMessage', `${lastAckedMessage}`);
          api.dispatch(fetchPriorities());
          acknowledgeMessage?.(messageIdentifier);
        }
    });
  }

  return returnValue;
};
