import type { SemanticICONS } from 'semantic-ui-react';

import { getPhoneIcon } from './getPhoneIcon';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import type { Channel } from 'src/types/Channel';
import type { TicketDirection } from 'src/types/Ticket';

export function iconName(channel: Channel | undefined, direction: TicketDirection): SemanticICONS {
  if (!channel) {
    return 'question';
  }

  if (channel.id === ChannelType.Phone) {
    return getPhoneIcon(direction) ?? 'question';
  }

  return channel.icon || 'question';
}
