import React from 'react';
import classNames from 'classnames';
import iziToast from 'izitoast';
import { Accordion } from 'semantic-ui-react';
import { t } from 'i18next';
import type { History } from 'history';

import FeatureFlags from 'src/api/FeatureFlags';
import { getSearchParams, getTicket, getTicketType } from 'src/Utilities/helper';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import {
  findPhoneConfiguration,
  getPhoneConfigTags,
  getPhoneConfigTicketType,
  parsePhoneConfiguration
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import styles from './ElisaOC.module.css';
import type { CallRequestPayload } from 'src/actions/phoneActions';
import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { PersonalData } from 'src/types/User';
import type { TicketDirection } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { phoneConfiguration } from 'src/types/PhoneConfiguration';

interface ElisaOCProps {
  ticketTypes: TicketType[];
  url: string;
  history: History;
  userData: PersonalData;
  configurations: phoneConfiguration[];
  callRequests: CallRequestPayload[];
  isOpen: boolean;

  setIsOpen: (active: boolean) => void;
  removeCallRequest: (ticketId: number, phoneNumber: string) => void;
  handleIncomingPhoneCall: (callObject: IntegrationData) => Promise<void>;
}

interface ElisaOCState {
  lastOCTicketId: null | string;
  lastServiceName: null | string;
  lastPhoneNumber: null | string;
  direction: null | TicketDirection;
}

interface OCContactDetailsPayload {
  ticketId: string;
  serviceName: string;
  data: {
    direction: string;
    contactInfo: string;
  };
}

class ElisaOCComponent extends React.Component<ElisaOCProps, ElisaOCState> {
  constructor(props: ElisaOCProps) {
    super(props);

    this.state = {
      lastOCTicketId: null,
      lastServiceName: null,
      lastPhoneNumber: null,
      direction: null
    };
  }

  componentWillMount() {
    window.addEventListener('message', this.handleElisaEvents);
    window['__testElisaOCEvent'] = (phoneNumber: string | number, direction?: TicketDirection) => {
      const normalizedPhoneNumber = normalizePhoneNumber(
        typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber
      );
      const eData = {
        type: 'ocContactDetails',
        payload: {
          ticketId: 'test',
          serviceName: 'test',
          data: {
            direction: direction === 'out' ? 'Contact.Out' : 'Contact.In',
            contactInfo: normalizedPhoneNumber,
            startPathName: '',
            contactType: 'Manual'
          }
        }
      };
      const event = new MessageEvent('message', {
        data: eData
      });
      window.dispatchEvent(event);
    };

    window['__testElisaOC'] = (phoneNumber: string) => {
      if (!phoneNumber) {
        console.error('Please specify phoneNumber');
        return;
      }
      const normalizedPhoneNumber = normalizePhoneNumber(phoneNumber);
      this.setState(
        {
          lastPhoneNumber: normalizedPhoneNumber,
          lastOCTicketId: 'test',
          lastServiceName: 'test'
        },
        () => {
          this.handleIncomingPhoneCall();
        }
      );
    };
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleElisaEvents);
    delete window['__testElisaOC'];
    delete window['__testElisaOCEvent'];
  }

  private handleOutgoingPhoneCall = (normalizedPhoneNumber: string, ticketId: number) => {
    this.props.removeCallRequest(ticketId, normalizedPhoneNumber);
  };

  private handleElisaEvents = (event: MessageEvent) => {
    const callRequest = this.props.callRequests.find((request) => {
      // format numbers so they match!
      const callRequestNumber = normalizePhoneNumber(request.phoneNumber);
      const eventnumber = event.data.payload.data ? event.data?.payload?.data?.contactInfo : event.data.payload;
      const eventNumber = normalizePhoneNumber(eventnumber);

      const isFromClickToCall = request.fromClickToCall;

      return isFromClickToCall && callRequestNumber === eventNumber;
    });
    const customerPhoneNumber = normalizePhoneNumber(event.data.contactInfo || '');

    if (event.data !== 'undefined' && event.data.type !== 'undefined') {
      switch (event.data.type) {
        case 'ocContactDetails': {
          const payload: OCContactDetailsPayload = event.data.payload;
          const { ticketId, serviceName, data } = payload;
          const commentDirection = data.direction === 'Contact.Out' ? 'out' : 'in';
          const phoneNumber = data.contactInfo;
          this.setState(
            {
              lastOCTicketId: ticketId,
              lastPhoneNumber: phoneNumber,
              lastServiceName: serviceName,
              direction: commentDirection
            },
            () => {
              if (!callRequest) {
                // if event is NOT found in callRequests -> create new ticket
                this.handleIncomingPhoneCall();
              } else {
                // if event IS found in callRequests -> add outward call comment to that ticket
                this.handleOutgoingPhoneCall(customerPhoneNumber, callRequest.ticketId);
              }
            }
          );
          break;
        }
        default:
          // do nothing..
          break;
      }
    }
  };

  /*
        type: "ocContactDetails"
        payload:
            ticketId: "20200417122302438001"
            serviceName: "Testipalvelu"
            data:
                contactInfo: "0503425621"
                startPathName: ""
                contactType: "Manual"
                direction: "Contact.Out"

        outboundissa:

        serviceName => kampanjan nimi (pitänee prefixata)
        contactType: "OutboundCampaign" (?)
    */

  private handleIncomingPhoneCall = async () => {
    if (
      this.state.lastServiceName === null ||
      this.state.lastOCTicketId === null ||
      this.state.lastPhoneNumber === null
    ) {
      return;
    }

    const originalPhoneNumber = this.state.lastPhoneNumber;
    const normalizedPhoneNumber = normalizePhoneNumber(originalPhoneNumber);
    const ticketType = getTicketType(
      this.state.lastServiceName,
      this.props.ticketTypes,
      this.props.userData.userPreferences.defaultTicketType
    );

    const configuration = findPhoneConfiguration(this.props.configurations, this.state.lastServiceName);
    const parsedConfiguration = parsePhoneConfiguration(configuration);
    const configTicketType = getPhoneConfigTicketType(parsedConfiguration, this.props.ticketTypes) || ticketType;
    const configTags = getPhoneConfigTags(parsedConfiguration);

    const confObject = configuration
      ? {
          ...parsedConfiguration,
          ticketType: configTicketType,
          tags: configTags
        }
      : undefined;

    const ticketData = getTicket(ticketType, confObject);
    const searchObject = getSearchParams(normalizedPhoneNumber, configTicketType);
    const UID = this.props.userData.UID;
    const history = this.props.history;
    const direction = this.state.direction !== null ? this.state.direction : undefined;

    ticketData.direction = direction || 'in';

    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'ocTicketId',
        updateValue: this.state.lastOCTicketId,
        group: 'CaseDetails'
      }
    ];

    if (this.state.lastServiceName !== null) {
      detailsObjects.push({
        updateKey: 'ocServiceName',
        updateValue: this.state.lastServiceName,
        group: 'CaseDetails'
      });
    }

    const callObject: IntegrationData = {
      normalizedPhoneNumber,
      UID,
      ticketData,
      searchObject,
      history,
      detailsObjects,
      direction
    };

    if (FeatureFlags.isFlagOn('ON_CALL_OPEN_CALL_UI')) {
      this.props.setIsOpen(true);
    }

    if (configuration && parsedConfiguration.noTicket) {
      iziToast.error({
        message: t('PHONECONFIGURATION_NO_TICKET'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
    }

    if (!configuration || (configuration && !parsedConfiguration.noTicket)) {
      await this.props.handleIncomingPhoneCall(callObject);
    }

    this.setState({
      lastServiceName: null,
      lastPhoneNumber: null,
      lastOCTicketId: null,
      direction: null
    });
  };

  private toggleAccordion = () => {
    this.props.setIsOpen(!this.props.isOpen);
  };

  private shouldShowMinimizedVersion = () => {
    return FeatureFlags.isFlagOn('ENABLE_ELISAOC_SHOW_STATUS_WHEN_MINIMIZED');
  };

  render() {
    return (
      <>
        <Accordion.Title className="ticketlist ticketlist_OC" active={this.props.isOpen} onClick={this.toggleAccordion}>
          Elisa OC
        </Accordion.Title>
        <Accordion.Content
          active={this.props.isOpen || this.shouldShowMinimizedVersion()}
          className={classNames(!this.props.isOpen && styles.minimized)}
        >
          <div>
            <iframe
              title="ElisaOC"
              id="ElisaOC"
              scrolling="yes"
              style={{
                width: '100%',
                height: '50vh',
                flex: '1 1 auto',
                display: 'flex'
              }}
              src={this.props.url}
              frameBorder={0}
              allowFullScreen={false}
            />
          </div>
        </Accordion.Content>
      </>
    );
  }
}

export default ElisaOCComponent;
