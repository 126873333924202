import React from 'react';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { connect } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ConnectedProps } from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';

import {
  getEntityById,
  removeEntityFromCase,
  addEntityToCase,
  updateEntityDetails,
  fetchTicketsByEntity
} from 'src/actions/ticketsActions';
import { activateContentListTab, addContentListTab } from 'src/api/CaseListApi';
import ErrorBoundary from 'src/ErrorBoundary';
import CustomerContainer from 'src/Components/Customer/CustomerContainer';
import AccordionHeader from '../AccordionHeader';
import useOpenExternalLink from 'src/hooks/useOpenExternalLink';
import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { FieldSet } from 'src/types/TicketType';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

interface CustomerInfoProps extends ConnectedProps<typeof connector> {
  widgetOpen: boolean;
  task: Ticket;

  fireSearch(value: any, type: string): void;
}

const CustomerInfo = ({
  task,
  widgetOpen,
  fireSearch,
  // redux
  userData,
  ticketTypes,
  getEntityById,
  removeEntityFromCase,
  addEntityToCase,
  updateEntityDetails,
  onSearchPreviousTickets
}: CustomerInfoProps) => {
  const selectedTicketType = ticketTypes.find((t) => t.name === task.taskType);
  const fieldSets = selectedTicketType?.fieldSets || [];

  const entities = sortBy(task.entities, ['_type', '_id']);

  const sortedEntities = entities.reduce((previousValue, currentValue) => {
    const previousArray = previousValue[currentValue._type] !== undefined ? previousValue[currentValue._type] : [];
    previousValue[currentValue._type] = [...previousArray, currentValue];
    return previousValue;
  }, {} as Record<string, Entity[]>);

  useOpenExternalLink({
    selectedTicketType,
    values: get(sortedEntities, [get(task, ['entities', 0, '_type'], ''), 0, 'data'], {}),
    userData,
    task
  });

  return (
    <>
      {Object.keys(sortedEntities).map((entityKey, idx) => {
        const fields =
          fieldSets.find(({ entityTypes }: FieldSet) => !!entityTypes?.includes?.(entityKey)) ??
          fieldSets.find(({ id }: FieldSet) => id === entityKey) ??
          fieldSets.find(({ id }: FieldSet) => id === 'customerInfo');

        return (
          <ErrorBoundary key={`case-customer-info-error-boundary-${idx}`}>
            <div className={'customer-container-color'}>
              <AccordionHeader
                as="h4"
                active={widgetOpen}
                title={`${fields?.displayName} (${sortedEntities[entityKey].length})`}
                icon="address card"
                key={`case-customer-info-${idx}`}
                id={`case-customer-info-${idx}`}
              >
                {sortedEntities[entityKey].map((entity, idx) => {
                  return (
                    <CustomerContainer
                      key={`case-customer-container-${idx}`}
                      fireSearch={fireSearch}
                      generalDisable={!userData.permissions.includes('searchEntities')}
                      getEntityById={getEntityById}
                      personalData={userData}
                      onDetachEntity={(body: { _id: string; _type: string }) => {
                        removeEntityFromCase(task.id, body);
                      }}
                      onAttachEntity={(body: any) => {
                        addEntityToCase(task.id, { ...body, taskType: task.taskType });
                      }}
                      onSave={(updateArgs: UpdateEntityDetail) => {
                        updateEntityDetails(task.id, task.taskType, updateArgs);
                      }}
                      onSearchPreviousTickets={onSearchPreviousTickets}
                      fields={(fields && fields[fields.id]) || []}
                      additionalCustomerFieldSets={fields?.additionalFieldSets}
                      values={[entity] || []}
                      task={task}
                      ticketTypes={ticketTypes}
                      entityType={entityKey}
                    />
                  );
                })}
              </AccordionHeader>
            </div>
          </ErrorBoundary>
        );
      })}
    </>
  );
};

const connector = connect(
  (state: State) => ({
    ticketTypes: state.ticketTypes,
    userData: state.userData
  }),
  (dispatch: ThunkDispatch<State, any, AnyAction>) => ({
    getEntityById: (ticketId: string, taskType: string, entityId: string, entityType: string) => {
      dispatch(getEntityById(ticketId, taskType, entityId, entityType));
    },
    removeEntityFromCase: (
      ticketId: string,
      body: {
        _id: string;
        _type: string;
      }
    ) => {
      dispatch(removeEntityFromCase(ticketId, body));
    },
    addEntityToCase: (
      ticketId: string,
      body: {
        taskType: string;
        _id: string;
        _type: string;
      }
    ) => {
      dispatch(addEntityToCase(ticketId, body));
    },
    updateEntityDetails: (ticketId: string, taskType: string, updateArgs: UpdateEntityDetail) => {
      dispatch(updateEntityDetails(ticketId, taskType, updateArgs));
    },
    onSearchPreviousTickets: (entityId: string, entityType: string) => {
      dispatch(addContentListTab(entityId, 'TAB_NAME_CUSTOMER #' + entityId.toString().substring(0, 3), 'tickets'));
      dispatch(activateContentListTab(entityId, 'tickets'));
      dispatch(fetchTicketsByEntity(entityId, entityId, entityType));
    }
  })
);

export default connector(CustomerInfo);
