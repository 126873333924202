import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import { TabFilter } from 'src/types/Filter';
import { TicketListTab } from '../types/TicketList';

// I fear to touch it, but seems to be doing the same as getURLParam from helpers.ts in more perversed way
export const getParameterByName = (name: string, url: string) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const absolutifyUrls = (params: { content: string; domain: string }): string => {
  const regex = new RegExp(/(src|href)=["'](\/system\/.*?)["']/, 'gi');
  const modifiedContent = params.content.replace(regex, '$1="' + params.domain + '$2"');

  return modifiedContent;
};

type URLParams = TabFilter | Pick<TicketListTab, 'sorting' | 'direction'>;

export const addParamsToURL = (params: URLParams, skip = false) => {
  // cloning object because of this issue https://github.com/inertiajs/inertia/issues/775
  const filters = cloneDeep(params);
  const queryStringParams: Parameters<typeof queryString.stringify> = [filters];
  if (skip) {
    queryStringParams.push({
      skipNull: true,
      skipEmptyString: true
    });
  }

  const url = `${window.location.origin}${window.location.pathname}`;
  const filterParams = queryString.stringify(...queryStringParams);
  window.history.replaceState(filters, 'Eeedo', `${url}${filterParams ? `?${filterParams}` : ''}`);
};

export const clearFiltersFromURL = () => {
  window.history.replaceState({}, 'Eeedo', window.location.origin);
};

export const isUrl = (str: string | null | undefined): str is string => {
  if (!str) {
    return false;
  }

  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};
