import { TFunction } from "i18next";
import { ContentTypesFields } from "src/types/Ticket";

export type TicketStatuses = "todo" | "doing" | "done";

export const getContentStatusOptions = (contentType: ContentTypesFields, t: TFunction) => {
    switch (contentType) {
        case "tickets": {
            return [
              {
                text: t('CASE_STATUS_TODO'),
                icon: 'exclamation',
                value: 'todo'
              },
              {
                text: t('CASE_STATUS_DOING'),
                icon: 'clock',
                value: 'doing'
              },
              {
                text: t('CASE_STATUS_DONE'),
                icon: 'check',
                value: 'done'
              }
            ];
        }
        case "infopages": {
          return [
            { text: t('INFOPAGE_STATUS_DRAFT'), value: 'draft', icon: 'write' },
            { text: t('INFOPAGE_STATUS_INREVIEW'), value: 'inReview', icon: "magnify" },
            { text: t('INFOPAGE_STATUS_WAITING'), value: 'waitingToBePublished', icon: "hourglass" },
            { text: t('INFOPAGE_STATUS_PUBLISHED'), value: 'published', icon: "check circle" },
            { text: t('INFOPAGE_STATUS_WASTE'), value: 'waste', icon: 'trash' }
          ];
        }
    }
};