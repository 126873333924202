// low level client for version api
import axios from 'axios';

import ApiConfig from '../ApiConfig';
import { consoleLogUserTimeLogs } from './utils';
import type { UserTimeLogsRow } from './types';

class UserTimeLogsApi {
  sendUserTimeLogs = async (entries: UserTimeLogsRow[]) => {
    const url = `${ApiConfig.getConfig().API_URL}/recordActiveUserTime`;
    const data = entries.map((row) => ({
      id: parseInt(row.id),
      duration: row.duration
    }));
    const res = await axios.post(url, data);
    consoleLogUserTimeLogs('[userTimeLogs]: Report sent:', res.status, res.statusText);
  };
}

export default new UserTimeLogsApi();
