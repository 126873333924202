import * as React from 'react';

export const PropsChecker = <P extends Object>(WrappedComponent: React.ComponentType<P>) => {
  return class extends React.Component {
    propsToBeOmmited = ['staticContext'];

    isPropsValid = (props: any): boolean => {
      for (const [key, value] of Object.entries(props)) {
        if (value === undefined && !this.propsToBeOmmited.includes(key)) return false;
      }
      return true;
    };

    render() {
      const checkedProps = this.isPropsValid(this.props);
      if (!checkedProps) {
        return null;
      } else {
        return <WrappedComponent {...(this.props as P)} />;
      }
    }
  };
};
