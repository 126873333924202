import type { Tag } from 'src/types/Tag';

export const filterTagsShownToUser = (tags: Tag[], userTicketTypes: number[]) => {
  // filter out: deprecated
  return tags.filter((tag: Tag) => {
    if (tag.deprecated === 1) {
      return false;
    } else if (tag.ticketTypes.length > 0) {
      // filter out: user's ticketTypes do not match
      return tag.ticketTypes.some((ticketType: number) => {
        return userTicketTypes.indexOf(ticketType) !== -1;
      });
    } else {
      return true;
    }
  });
};

export const getTags = (ticketTags: string[], allTags: Tag[]) =>
  allTags.filter((tag: Tag) => ticketTags.includes(tag.id));
