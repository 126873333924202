import React from 'react';
import { isEmpty, isEqual, pick, uniq, uniqBy } from 'lodash';
import { Accordion, Button, Divider, Dropdown, Form, Icon, Label, Modal, Table } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { createFilter } from 'react-select';
import { t as tr } from 'i18next';

import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import FeatureFlags from 'src/api/FeatureFlags';
import FormDropzoneDropdown from './components/FormDropzoneDropdown';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyEditor from 'src/Components/Case/ReplyEditor';
import ReplyTemplates from './ReplyTemplates';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import mapEmailValueToOptions from 'src/Utilities/mapEmailValueToOptions';
import { Channels } from 'src/types/Channel';
import { KnowledgeBank } from './KnowledgeBank';
import { MultiDraggableDropdown, MultiDraggableDropdownProvider } from 'src/Components/MultiDraggableDropdown';
import { ReplyMethod } from './ReplyMethod';
import { defaultCreatableSelectStyles } from 'src/Components/Utilities';
import { getPrettyDate, DATE_TIME_FORMAT } from 'src/Utilities/dates';
import { serializeContent } from 'src/Utilities/parseUtils';
import { trimAndSplitRecipients } from 'src/Components/Utilities/recipients';

import type { Attachment, Comment, Entity, Suggestion, Ticket, UploadFileResult } from 'src/types/Ticket';
import type { IItem, IItemsCollection } from 'src/Components/MultiDraggableDropdown';
import type { PersonalData, User } from 'src/types/User';
import type { ReplyMethodProps } from './ReplyMethod';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { sendEntityEvent } from 'src/actions/ticketsActions';

export interface ReplyEmailProps extends ReplyMethodProps<ReplyEmailState> {
  userData: PersonalData;
  to: string;
  attachments: Attachment[];
  senderEmails: SenderEmail[];
  ticketType: TicketType;
  taskType: TicketType;
  templates: ResponseTemplate[];
  drafts: ReplyEmailState;
  task: Ticket;
  entities: Entity[];
  suggestions: Suggestion[];
  users: User[];
  subject?: string;
  ticketTypes: TicketType[];
  canCloseAsDone: boolean;

  // TODO: typing for props functions
  onSubmit: (body: any) => Promise<Comment>;
  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
  sendEntityEvent?: typeof sendEntityEvent;
  closeAsDone: (ticketId: string, uid: string) => Promise<void>;
}

interface ReplyEmailState {
  content: string;
  cc: string;
  subject: string;
  bcc: string;
  to: string;
  from: string;
  isEmailOptionsOpen: boolean;
  isCaseDoneAfterSubmit: boolean;
  isSendAndCloseEnabled: boolean;
  isSubmitModalOpen: boolean;
  isLoading: boolean;
  selectedAttachmentIds: string[];
  suggestionResults: Array<Suggestion>;
  searchLoading: boolean;
  selectedReplyTemplate: string | undefined;
  entitiesChanged?: boolean;
}

class ReplyEmail extends ReplyMethod<ReplyEmailProps, ReplyEmailState> {
  protected channel = ChannelType.Email;
  private emailOptions: Array<{ text: string; value: string }>;

  constructor(props: ReplyEmailProps) {
    super(props);
    this.state = this.getInitialState(this.props, false);
    this.setEmailOptions(props);
  }

  getOptions = () =>
    this.props.attachments
      .filter((attachment) => !attachment.deprecated && !attachment.isQuarantined)
      .map((attachment) => ({
        text: attachment.fileName,
        value: attachment.id,
        uploaded: attachment.uploaded,
        isQuarantined: attachment.isQuarantined,
        description: getPrettyDate(attachment.uploaded, { format: DATE_TIME_FORMAT }),
        label: attachment.isQuarantined ? { color: 'red', empty: true, circular: true } : ''
      }))
      .concat({
        text: tr('ATTACHMENT_ADD_DROPDOWN'),
        value: 'ADD_ATTACHMENT'
      } as any);

  getDraftChannel(): Channels {
    return Channels.email;
  }

  getDraftState(state: ReplyEmailState): Partial<ReplyEmailState> {
    return {
      content: state.content,
      isCaseDoneAfterSubmit: state.isCaseDoneAfterSubmit,
      subject: state.subject,
      to: state.to,
      cc: state.cc,
      from: state.from,
      bcc: state.bcc,
      selectedAttachmentIds: state.selectedAttachmentIds,
      isLoading: state.isLoading,
      isSendAndCloseEnabled: state.isSendAndCloseEnabled,
      isSubmitModalOpen: state.isSubmitModalOpen,
      isEmailOptionsOpen: state.isEmailOptionsOpen,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private setEmailOptions = (props: ReplyEmailProps) => {
    this.emailOptions = props.senderEmails.map((senderEmail: SenderEmail, index: number) => ({
      key: index,
      text: senderEmail.text,
      value: senderEmail.email
    }));
  };

  private getInitialState = (props: ReplyEmailProps, entitiesChanged: boolean, previousRecipients?: string) => {
    let to: string | string[] = props.drafts.to === undefined ? props.to : props.drafts.to;
    let initialmail = '';

    props.senderEmails.forEach((email: SenderEmail) => {
      if (email.default) {
        initialmail = email.email;
      }
    });

    if (entitiesChanged && previousRecipients !== undefined) {
      const currentPropsRecipientsArray = props.to.split(',').filter(Boolean);
      const previousPropsRecipientsArray = previousRecipients.split(',').filter(Boolean);

      let recipientsToBeAdded = currentPropsRecipientsArray.filter(
        (address) => !previousPropsRecipientsArray.includes(address)
      );

      const recipientsToBeRemoved = previousPropsRecipientsArray.filter(
        (address) => !currentPropsRecipientsArray.includes(address)
      );

      let currentRecipientsArray = typeof to === 'string' ? to.split(',') : to;
      const recipientOverlap = currentRecipientsArray.filter((address) => recipientsToBeAdded.includes(address));

      recipientsToBeAdded = recipientsToBeAdded.filter((address) => !recipientOverlap.includes(address));

      currentRecipientsArray = currentRecipientsArray.concat(recipientsToBeAdded);

      currentRecipientsArray = currentRecipientsArray.filter((address) => !recipientsToBeRemoved.includes(address));

      to = currentRecipientsArray.join(',');
    }

    // don't included sender in recipients
    const from = props.drafts.from || initialmail;
    if (!Array.isArray(to)) {
      const toArray: string[] = to.split(',');
      to = uniq(toArray).filter((email: string) => {
        return email.toLowerCase().trim() !== from.toLowerCase().trim();
      });
      to = to.toString();
    }

    const state: ReplyEmailState = {
      content: props.drafts.content || '',
      isCaseDoneAfterSubmit: props.drafts.isCaseDoneAfterSubmit || false,
      subject: props.drafts.subject || ' ' + props.subject || 'Re: ',
      to: to,
      cc: props.drafts.cc || '',
      from: props.drafts.from || initialmail,
      bcc: props.drafts.bcc || '',
      isEmailOptionsOpen: props.drafts.isEmailOptionsOpen || false,
      isLoading: props.drafts.isLoading || false,
      isSendAndCloseEnabled: props.drafts.isSendAndCloseEnabled || false,
      isSubmitModalOpen: props.drafts.isSubmitModalOpen || false,
      selectedAttachmentIds: props.drafts.selectedAttachmentIds || [],
      suggestionResults: [],
      searchLoading: false,
      selectedReplyTemplate: props.drafts.selectedReplyTemplate || undefined
    };

    return state;
  };

  componentDidUpdate(prevProps: ReplyEmailProps) {
    if (
      !isEqual(
        prevProps.entities?.map?.((entity) => pick(entity, ['_id', '_type', 'isLoading'])),
        this.props.entities?.map?.((entity) => pick(entity, ['_id', '_type', 'isLoading']))
      )
    ) {
      this.setState(this.getInitialState(this.props, true, prevProps.to), () => {
        this.saveDraft(this.state);
      });
    }
  }

  componentWillReceiveProps(nextProps: ReplyEmailProps) {
    if (!isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps, false));
    }

    this.setEmailOptions(nextProps);
  }

  protected clearFields = () => {
    const { to, subject } = this.props;

    this.updateState({
      content: '',
      subject,
      from: '',
      to,
      cc: '',
      bcc: '',
      selectedAttachmentIds: [],
      isLoading: false,
      isSendAndCloseEnabled: false,
      isSubmitModalOpen: false,
      selectedReplyTemplate: undefined
    });
  };

  protected getSelectedAttachments = (selectAttachmentIds: string[]): Attachment[] =>
    this.props.attachments.filter(
      (attachment: Attachment) => !attachment.isQuarantined && selectAttachmentIds.includes(attachment.id)
    );

  protected submitEmail = (closeAsDone: boolean) => async () => {
    //Froala blobs are replaced with cid references
    const contentWithCids = serializeContent(this.state.content, this.props.attachments);
    this.setState({ isLoading: true }, async () => {
      try {
        const to = trimAndSplitRecipients(this.state.to)?.filter((to) => to);
        const bodyOfRequest = {
          content:
            this.state.subject?.length > 900
              ? this.state.subject.slice(900, -1) + ' \n\n ' + contentWithCids
              : contentWithCids,
          sendAsMail: true,
          ViestiketjuId: this.props.task.case.ViestiketjuId || undefined,
          OsapuoliId: this.props.task.case.OsapuoliId || undefined,
          subject: this.state.subject?.length > 900 ? this.state.subject.slice(0, 900) : this.state.subject,
          channel: this.channel,
          direction: 'out',
          metaData: {
            cc: this.state.cc.length > 0 ? trimAndSplitRecipients(this.state.cc)?.filter((cc) => cc) : undefined,
            bcc: this.state.bcc.length > 0 ? trimAndSplitRecipients(this.state.bcc)?.filter((bcc) => bcc) : undefined,
            title: this.state.subject,
            from: this.state.from,
            to: to,
            attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds)
          },
          from: this.state.from,
          to: to,
          html:
            this.state.subject && this.state.subject?.length > 900
              ? this.state.subject.slice(900, -1) + ' \n\n ' + contentWithCids
              : contentWithCids,
          cc: this.state.cc.length > 0 ? trimAndSplitRecipients(this.state.cc)?.filter((cc) => cc) : undefined,
          attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds),
          bcc: this.state.bcc.length > 0 ? trimAndSplitRecipients(this.state.bcc)?.filter((bcc) => bcc) : undefined,
          title: this.state.subject.trim()
        } as any;
        if (this.props.sendEntityEvent) {
          const recipientEntities = [] as any;

          bodyOfRequest.to.forEach((recipient: string) => {
            const emailEntityMatch = this.props.entities.find((entity) => {
              return entity.data?.email === recipient;
            });
            if (emailEntityMatch) {
              recipientEntities.push({
                _id: emailEntityMatch._id,
                _type: emailEntityMatch._type
              });
            }
          });
          if (recipientEntities.length > 0) {
            await this.props.sendEntityEvent({
              type: 'contact',
              ticketType: this.props.ticketType.name,
              entities: recipientEntities,
              data: {
                direction: bodyOfRequest.direction,
                channel: bodyOfRequest.channel,
                to: bodyOfRequest.to,
                subject: bodyOfRequest.subject,
                content: bodyOfRequest.content,
                attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds)
              }
            });
          }
        }

        const response = await this.props.onSubmit(bodyOfRequest);
        if (response) {
          if (closeAsDone) {
            this.props.closeAsDone(this.props.taskId, this.props.userData.UID);
          }
          this.clearFields();
        }
      } catch (error) {
        console.error('Failed to save ticket', error);
      } finally {
        this.updateState({
          isLoading: false,
          isSendAndCloseEnabled: false,
          isSubmitModalOpen: false
        });
      }
    });
  };

  // TODO: move to separate component to unload current component codebase
  protected getSubmitModal = () => {
    return (
      <Modal
        open={this.state.isSubmitModalOpen}
        closeOnDocumentClick={true}
        onClick={() => this.updateState({ isSubmitModalOpen: false })}
      >
        <Modal.Header>{tr('COMMENT_SEND_AS_EMAIL_QUESTION')}</Modal.Header>
        <Modal.Description style={{ padding: '20px' }}>
          <Table celled padded>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{tr('ADD_COMMENT_TITLE')}</Table.Cell>
                <Table.Cell>{this.state.subject}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>{tr('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}</Table.Cell>
                <Table.Cell>{this.state.from}</Table.Cell>
              </Table.Row>

              {this.state.selectedAttachmentIds.length > 0 && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_ATTACHMENT_LABEL')}</Table.Cell>
                  <Table.Cell>
                    {this.getSelectedAttachments(this.state.selectedAttachmentIds).map((x) => (
                      <Label>{x.fileName}</Label>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Divider horizontal>
            <Icon name="arrow down" />
          </Divider>
          <Table celled padded>
            <Table.Body>
              {this.state.to && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_RECEPIENTS')}</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(this.state.to)?.map((recipient: string) => (
                      <>{!isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
              {this.state.cc && (
                <Table.Row>
                  <Table.Cell>CC</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(this.state.cc)?.map((recipient: string) => (
                      <>{!isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
              {this.state.bcc && (
                <Table.Row>
                  <Table.Cell>BCC</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(this.state.bcc)?.map((recipient: string) => (
                      <>{!isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Description>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            negative
            icon
            labelPosition="left"
            onClick={() => this.updateState({ isSubmitModalOpen: false })}
          >
            <Icon name="remove" />
            {tr('GENERAL_CANCEL')}
          </Button>
          {this.state.isSendAndCloseEnabled ? (
            <Button
              disabled={this.state.isLoading}
              icon
              labelPosition="left"
              loading={this.state.isLoading}
              onClick={this.submitEmail(true)}
              positive
            >
              <Icon name="check" />
              {tr('GENERAL_SEND_AND_CLOSE')}
            </Button>
          ) : (
            <Button
              disabled={this.state.isLoading}
              icon
              labelPosition="left"
              loading={this.state.isLoading}
              onClick={this.submitEmail(false)}
              primary
            >
              <Icon name="send" />
              {tr('GENERAL_SEND')}
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  };

  private mapSuggestions = (suggestions: Suggestion[]) => {
    return suggestions
      .filter((suggestion: Suggestion) => {
        if (!isEmpty(suggestion.ticketTypes)) {
          return suggestion.type === 'email' && suggestion.ticketTypes.includes(this.props.ticketType.id);
        } else {
          return suggestion.type === 'email';
        }
      })
      .map((suggestion: Suggestion) => ({
        label: suggestion.name,
        value: suggestion.value
      }));
  };

  private formatSuggestionOptionLabel = ({ label, value }: any) => (
    <span>
      {label ? <Label> {label}</Label> : ''} {value ? value : ''}
    </span>
  );

  private handleAddressesChange = ({ to, cc, bcc }: IItemsCollection) => {
    const itemsToString = (items: IItem[]) =>
      items
        .reduce<string[]>(
          (acc, curr) => uniq([...acc, ...mapEmailValueToOptions(curr.value).map(({ value }) => value)]),
          []
        )
        .join(',');

    const state = {
      ...this.state,
      to: itemsToString(to),
      cc: itemsToString(cc),
      bcc: itemsToString(bcc)
    };

    this.setState(state, () => {
      this.saveDraft(this.state);
    });
  };

  private getAddresses = () => ({
    to: mapEmailValueToOptions(this.state.to),
    bcc: mapEmailValueToOptions(this.state.bcc),
    cc: mapEmailValueToOptions(this.state.cc).filter(
      (o) => !this.state.from.toLowerCase().includes(o.value.toLowerCase())
    )
  });

  private formatCreateLabel = (text: string) => {
    return <span>{text}</span>;
  };

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    attachmentFiles.map((file: File) => {
      const data = new FormData();
      data.append('attachments', file);
      return this.props.uploadFile(this.props.taskId, data).then((files: { attachmentId: string }[]) => {
        const attachments = files.map((att: { attachmentId: string }) => att.attachmentId);

        this.updateWithPreviousState((previous) => ({
          selectedAttachmentIds: [...previous.selectedAttachmentIds, ...attachments]
        }));

        return files;
      });
    });
  };

  private onEmailOptionsClick = () => {
    this.setState(
      (previousState) => ({
        isEmailOptionsOpen: !previousState['isEmailOptionsOpen']
      }),
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  render() {
    const bccOrCCHasContent = !isEmpty(this.state.bcc) || !isEmpty(this.state.cc);
    const replyDisabled = this.state.content.length === 0 || this.state.to.length === 0;
    const replyOnSubmit = () => {
      if (replyDisabled) {
        return;
      }
      this.updateState({ isSubmitModalOpen: true, isSendAndCloseEnabled: false });
    };
    const replyAndCloseOnSubmit = () => {
      this.updateState({ isSubmitModalOpen: true, isSendAndCloseEnabled: true });
    };

    return (
      <Translation ns="translations">
        {(t) => (
          <React.Fragment>
            {this.getSubmitModal()}

            <Form reply={true} style={{ marginTop: '20px' }} loading={this.state.isLoading}>
              <Form.Input label={t('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}>
                <Dropdown
                  selection={true}
                  selectOnBlur={false}
                  fluid={true}
                  onChange={(_event, data) => {
                    const email = this.props.senderEmails.find((x) => x.email === data.value);
                    this.updateState({ from: email ? email.email : '' });
                  }}
                  value={this.state.from}
                  noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                  options={uniqBy(this.emailOptions, 'value')}
                />
              </Form.Input>
              {/* MultiDraggableDropdown component is used only inside this component, why do we have such a overcomplicated data flow? (mix
              of component state, redux state and react context)
              TODO: refactor MultiDraggableDropdown use, get rid of context */}
              <MultiDraggableDropdownProvider
                itemsCollection={this.getAddresses()}
                setItemsCollection={this.handleAddressesChange}
              >
                <Form.Field>
                  <label>{t('ADD_COMMENT_RECEPIENTS')}</label>
                  <MultiDraggableDropdown
                    name="to"
                    hideSelectedOptions
                    isClearable
                    formatOptionLabel={this.formatSuggestionOptionLabel}
                    options={this.mapSuggestions(this.props.suggestions)}
                    formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_RECIPIENT'))}
                    placeholder={t('ADD_COMMENT_RECEPIENTS_PLACEHOLDER')}
                    noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                    classNamePrefix="addressSelect"
                    styles={defaultCreatableSelectStyles}
                    filterOption={createFilter({ ignoreAccents: false })}
                  />
                </Form.Field>

                <Accordion className="ccBccAccordion">
                  <Accordion.Title onClick={this.onEmailOptionsClick} active={this.state.isEmailOptionsOpen}>
                    <Icon name="dropdown" />
                    {this.state.isEmailOptionsOpen
                      ? `${t('GENERAL_LESS')} ${t('ADD_COMMENT_EMAIL_OPTIONS')}`
                      : `${t('GENERAL_MORE')} ${t('ADD_COMMENT_EMAIL_OPTIONS')}`}
                  </Accordion.Title>

                  <Accordion.Content active={this.state.isEmailOptionsOpen || bccOrCCHasContent}>
                    <Form.Field>
                      <label>CC</label>
                      <MultiDraggableDropdown
                        name="cc"
                        hideSelectedOptions
                        isClearable
                        formatOptionLabel={this.formatSuggestionOptionLabel}
                        options={this.mapSuggestions(this.props.suggestions)}
                        formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_CC'))}
                        placeholder={t('ADD_COMMENT_CC_PLACEHOLDER')}
                        noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                        classNamePrefix="addressSelect"
                        filterOption={createFilter({ ignoreAccents: false })}
                        styles={defaultCreatableSelectStyles}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label>BCC</label>
                      <MultiDraggableDropdown
                        name="bcc"
                        hideSelectedOptions
                        isClearable
                        formatOptionLabel={this.formatSuggestionOptionLabel}
                        options={this.mapSuggestions(this.props.suggestions)}
                        formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_BCC'))}
                        placeholder={t('ADD_COMMENT_BCC_PLACEHOLDER')}
                        noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                        classNamePrefix="addressSelect"
                        styles={defaultCreatableSelectStyles}
                        filterOption={createFilter({ ignoreAccents: false })}
                      />
                    </Form.Field>
                  </Accordion.Content>
                </Accordion>
              </MultiDraggableDropdownProvider>
              <FormDropzoneDropdown
                attachments={this.props.attachments.filter((a) => !a.isQuarantined)}
                onChangeAttachments={(addedAttachments) =>
                  this.updateState({ selectedAttachmentIds: addedAttachments })
                }
                onDropAccepted={this.onUploadAttachment}
                options={this.getOptions()}
                value={this.state.selectedAttachmentIds}
              />
              <Form.Group>
                <Form.Field width={10}>
                  <label>{t('ADD_COMMENT_TITLE')}</label>
                  <Form.Input
                    onChange={(event, data) => this.updateState({ subject: data.value })}
                    type="text"
                    fluid={true}
                    // maxLength={900} // over limit 900 characters to the mail's content
                    value={this.state.subject}
                  >
                    <input />
                  </Form.Input>
                  {/** the title limit 998 but we add extra " [tsk1234567...]"  so limit to 900 to be sure */}
                  {this.state.subject.length >= 900 && (
                    <p style={{ color: 'red' }}>{t('general_reply.max_length_limit')} (900)</p>
                  )}
                </Form.Field>

                <Form.Field width={6}>
                  <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
                  <ReplyTemplates
                    userData={this.props.userData}
                    ticketType={this.props.ticketType}
                    templates={this.props.templates}
                    task={this.props.task}
                    channel={this.channel}
                    toAddresses={this.state.to}
                    ccAddresses={this.state.cc}
                    bccAddresses={this.state.bcc}
                    selectedOption={this.state.selectedReplyTemplate}
                    setSelectedOption={(value) => {
                      this.setState({ selectedReplyTemplate: value });
                    }}
                    setContent={(value) => {
                      this.updateState({ ...value });
                    }}
                    content={
                      this.state.subject && this.state.subject?.length > 900
                        ? this.state.subject.slice(900, -1) + ' \n\n ' + this.state.content
                        : this.state.content
                    }
                    entities={this.props.entities}
                    users={this.props.users}
                  />
                </Form.Field>
              </Form.Group>
              {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
                <Form.Field>
                  <KnowledgeBank
                    title={this.props.subject}
                    id={this.props.taskId}
                    extraArguments={this.props.taskType.knowledgeBank}
                    content={
                      this.state.subject && this.state.subject?.length > 900
                        ? this.state.subject.slice(900, -1) + ' \n\n ' + this.state.content
                        : this.state.content
                    }
                    updateState={(value: string) => {
                      this.updateState({ content: value });
                    }}
                  />
                </Form.Field>
              )}
              {FeatureFlags.isFlagOn('EMAIL_SEND_FROM_TOP') && (
                <ReplyControlButtons
                  disabled={replyDisabled}
                  onClear={this.clearFields}
                  onSaveDraft={() => this.saveVCDraft(false)}
                  onSubmit={replyOnSubmit}
                  onSubmitAndCloseAsDone={replyAndCloseOnSubmit}
                />
              )}
              <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
              <Form.Field>
                <label>{t('ADD_COMMENT_CONTENT')}</label>
                <ReplyEditor
                  style={{
                    borderRadius: '5px',
                    marginTop: '10px',
                    marginBottom: '10px',
                    wordBreak: 'break-word'
                  }}
                  value={this.state.content}
                  ticket={this.props.task}
                  onChange={(value) => this.updateState({ content: value })}
                  editorLanguage={this.props.userData.language}
                  onAttachmentUploaded={(attachment) =>
                    this.updateState({
                      selectedAttachmentIds: [...this.state.selectedAttachmentIds, attachment.attachmentId]
                    })
                  }
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                      replyOnSubmit();
                    }
                  }}
                />
              </Form.Field>
              <Divider />
              {FeatureFlags.isFlagOn('EMAIL_SEND_FROM_BOTTOM') && (
                <ReplyControlButtons
                  disabled={replyDisabled}
                  disabledCloseAsDone={!this.props.canCloseAsDone}
                  onClear={this.clearFields}
                  onSaveDraft={() => this.saveVCDraft(false)}
                  onSubmit={replyOnSubmit}
                  onSubmitAndCloseAsDone={replyAndCloseOnSubmit}
                />
              )}
            </Form>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default ReplyEmail;
