import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import type { ConnectedProps } from 'react-redux';

import AccordionHeader from '../AccordionHeader';
import type { State } from 'src/types/initialState';
import type { Channel } from 'src/types/Channel';
import type { Ticket } from 'src/types/Ticket';
import type { Widget } from 'src/types/TicketType';

interface CaseChannelProps extends CaseChannelReduxProps {
  task: Ticket;
  widgetOpen: boolean;
  widget: Widget;
  channelTypes: Partial<Channel>[];

  handleChannelChange(channel: number): void;

  ind: number;
}

const CaseChannel = ({
  task,
  widgetOpen,
  widget,
  channelTypes,
  handleChannelChange,
  userData,
  ind
}: CaseChannelProps) => {
  const { t } = useTranslation();

  return (
    <AccordionHeader
      id="widget-channel"
      as="h4"
      active={widgetOpen}
      title={t('CASE_CHANNEL')}
      icon="inbox"
      key={`case-channel-${ind}`}
    >
      <Dropdown
        disabled={!userData.permissions.includes('updateContent')}
        selectOnBlur={false}
        value={task.channel}
        selection
        search
        id={widget.type}
        onChange={(_, data: any) => {
          handleChannelChange(data.value);
        }}
        options={orderBy(channelTypes, ['text', 'value'])}
      />
    </AccordionHeader>
  );
};

const connector = connect((state: State) => ({
  userData: state.userData
}));

type CaseChannelReduxProps = ConnectedProps<typeof connector>;

export default connector(CaseChannel);
