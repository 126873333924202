import axios from 'axios';

import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { Priority } from 'src/types/Priority';

const API_URL_PRIORITY = '/priority/';

export default class PriorityApi {
  static addPriority = (data: any): Promise<any> => {
    return axios.put(ApiConfig.getConfig().API_URL + API_URL_PRIORITY, data).then((response) => {
      return response.data;
    });
  };

  static patchPriority = (data: any): Promise<any> => {
    return axios.patch(ApiConfig.getConfig().API_URL + API_URL_PRIORITY + data.id, data.data).then((response) => {
      return response.data;
    });
  };

  static getPriorities = (): Promise<Priority[]> => {
    return axiosWithRetry.get(ApiConfig.getConfig().API_URL + API_URL_PRIORITY).then((response) => {
      return response.data;
    });
  };
}
