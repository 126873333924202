import React from 'react';
import { Dropdown, Table } from 'semantic-ui-react';
import type { VFC, CSSProperties } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';

import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';

interface DropdownOption {
  text: string;
  value: string;
}

interface InfoFieldDropdownProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  selectionEnabled: boolean;
  renderHeader: ({ value, isNegative }: { value: any; isNegative: boolean }) => React.ReactNode;
  placeholder: string;
  noResultsMessage: string;
  fieldValue?: string | DropdownOption[];
  disabled?: boolean;
}

const InfoFieldDropdown: VFC<InfoFieldDropdownProps> = ({
  field,
  fieldValue,
  placeholder,
  noResultsMessage,
  selectionEnabled,
  renderHeader,
  disabled,
  setValue,
  onSaveData
}) => {
  let arrayOptions: DropdownOption[] = [];
  let style: CSSProperties | undefined = {};
  let icon: SemanticICONS | undefined = undefined;
  if (field.options === 'FROM_VALUE') {
    arrayOptions = fieldValue as DropdownOption[];
  } else if (Array.isArray(field.options)) {
    arrayOptions = field.options.map((v) => ({
      text: v.name,
      value: v.value
    }));

    const selectedOption = field.options.find((o) => o.value === fieldValue);
    style = selectedOption?.style;
    icon = selectedOption?.icon;
  }

  const arrayValues = arrayOptions.map((option: any) => option.value);
  const isValueMissingFromOptions =
    arrayValues.indexOf(fieldValue) === -1 && fieldValue !== '' && typeof fieldValue !== 'undefined';
  if (isValueMissingFromOptions) {
    arrayOptions.push({ text: fieldValue as string, value: fieldValue as string });
  }

  return (
    <>
      {renderHeader({
        value: fieldValue,
        isNegative: isValueMissingFromOptions
      })}
      <Table.Cell width={10} negative={isValueMissingFromOptions} style={style}>
        <Dropdown
          size="small"
          disabled={disabled}
          selectOnBlur={false}
          selection={selectionEnabled}
          search={true}
          fluid={true}
          scrolling={true}
          id={field.value}
          noResultsMessage={noResultsMessage}
          onChange={(_e, data) => {
            // Because of the default behavior in dropdowns, we transform them back into undefined
            const value = data.value !== null ? data.value : undefined;
            setValue?.(field, value);
            onSaveData?.(field, value);
          }}
          placeholder={placeholder}
          value={fieldValue as string}
          options={arrayOptions}
          icon={icon}
        />
      </Table.Cell>
    </>
  );
};

export default React.memo(InfoFieldDropdown);
