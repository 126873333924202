import { createAction } from '@reduxjs/toolkit';
import { Priority } from 'src/types/Priority';
import { TicketListTicket } from 'src/types/Ticket';
import {
  ADD_TICKET_TO_TICKETLIST,
  REMOVE_TICKET_FROM_TICKETLIST,
  FETCH_TICKETS_ERROR,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKET_PRIORITIES_SUCCESS,
  FETCH_TICKET_SUCCESS,
  UPDATE_TICKET_IN_TICKETLIST
} from '.';

export const fetchTicketsSuccess = createAction<{ id: string; tickets: TicketListTicket[] }>(FETCH_TICKETS_SUCCESS);

export const fetchTicketsError = createAction<string>(FETCH_TICKETS_ERROR);

export const addTicketToTicketlist = createAction<{ ticket: TicketListTicket; id: string }>(ADD_TICKET_TO_TICKETLIST);

export const removeTicketFromTicketlist = createAction<{ ticketId: string; id: string }>(REMOVE_TICKET_FROM_TICKETLIST);

export const updateTicketInTicketlist =
  createAction<{ ticket: TicketListTicket; id: string }>(UPDATE_TICKET_IN_TICKETLIST);

export const fetchTicketSuccess = createAction<TicketListTicket>(FETCH_TICKET_SUCCESS);

export const loadTicketPrioritiesSuccess = createAction<Priority[]>(FETCH_TICKET_PRIORITIES_SUCCESS);
