import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';

const API_URL_PATCH_AUTO_SUGGESTION = '/autosuggestion/';
const API_URL_DELETE_AUTO_SUGGESTION = '/autosuggestion/';
const API_URL_CREATE_AUTO_SUGGESTION = '/autosuggestions';

export default class SuggestionsApi {
  static fetchAutoSuggestions = async <T>() => {
    return axiosWithRetry.get<T>(`${ApiConfig.getConfig().API_URL}/autoSuggestions`).then((response) => response.data);
  };

  static patchAutoSuggestion = async <T>(data: any) => {
    return axiosWithRetry
      .patch<T>(ApiConfig.getConfig().API_URL + API_URL_PATCH_AUTO_SUGGESTION + data.id, data.data)
      .then((response) => response.data);
  };

  static createAutoSuggestion = async <T>(data: any) => {
    return axiosWithRetry
      .post<T>(ApiConfig.getConfig().API_URL + API_URL_CREATE_AUTO_SUGGESTION, data)
      .then((response) => response.data);
  };

  static deleteAutoSuggestion = async (autoSuggestionId: number): Promise<{ success: boolean }> => {
    return axiosWithRetry
      .delete(ApiConfig.getConfig().API_URL + API_URL_DELETE_AUTO_SUGGESTION + autoSuggestionId)
      .then((response) => response.data);
  };
}
