import { connect } from 'react-redux';

import ProfileDropdown from '../ProfileDropdown';
import { logout } from 'src/actions/authActions';
import { State } from 'src/types/initialState';

const mapStateToProps = (state: State) => {
  return {
    userData: state.userData,
    usersList: state.usersList.usersList
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLogout: () => {
      dispatch(logout());
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProfileDropdown);
