import _ from 'lodash';
import * as React from 'react';
import { t as tr } from 'i18next';
import { Translation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';
import { Button, Dropdown, Form, Icon, Label, Modal, Table } from 'semantic-ui-react';

import FeatureFlags from 'src/api/FeatureFlags';
import ConfigurationApi from 'src/api/ConfigurationApi';
import ReplyTemplates from './ReplyTemplates';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { KnowledgeBank } from './KnowledgeBank';
import ChannelType from '../CommentIconContent/ChannelType';
import ReplyTextArea from './ReplyTextArea';
import { ReplyMethod } from './ReplyMethod';
import { defaultCreatableSelectStyles } from '../Utilities';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';
import { trimAndSplitRecipients } from '../Utilities/recipients';
import { replaceNordicCharacters } from 'src/Utilities/parseUtils';
import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import { Channels } from 'src/types/Channel';
import type { ReplyMethodProps } from './ReplyMethod';
import type { Option } from '../MultiSelectInput/MultiSelectInput';
import type { PersonalData } from 'src/types/User';
import type { TicketType } from 'src/types/TicketType';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { Entity, Suggestion, Ticket } from 'src/types/Ticket';
import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';

interface ReplySmsProps extends ReplyMethodProps<ReplySmsState> {
  userData: PersonalData;
  ticketType: TicketType;
  templates: Array<ResponseTemplate>;
  drafts: ReplySmsState;
  task: Ticket;
  phoneNumbers: string;
  entities: Entity[];
  suggestions: Suggestion[];

  onSubmit(body: SMSPayload): Promise<unknown>;
}

interface SMSPayload {
  content: string;
  sendAsSmsMessage: true;
  channel: number;
  direction: 'in' | 'out';
  metaData: {
    to: string[];
  };
  recipients: string[];
  sendingNumber?: string;
}

interface ReplySmsState {
  defaultNumbers: { text: string; value: string }[];
  content: string;
  recipients: string;
  from: string | null;
  isLoading: boolean;
  isSubmitModalOpen: boolean;
  selectedReplyTemplate: string | undefined;
}

class ReplySms extends ReplyMethod<ReplySmsProps, ReplySmsState> {
  private channel = ChannelType.Sms;
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplySmsProps) {
    super(props);

    this.state = {
      ...this.getInitialState(this.props.drafts, this.props.phoneNumbers),
      defaultNumbers: []
    };
  }

  componentWillReceiveProps(nextProps: ReplySmsProps) {
    if (this.props.ticketType !== nextProps.ticketType) {
      this.getDefaultNumbers();
    }

    if (!_.isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts, ''));
    }

    if (!_.isEqual(this.props.phoneNumbers, nextProps.phoneNumbers)) {
      this.setState(this.getInitialState(nextProps.drafts, nextProps.phoneNumbers));
    }
  }

  getDraftChannel(): Channels {
    return Channels.sms;
  }

  getDraftState(state: ReplySmsState) {
    return {
      recipients: state.recipients,
      content: state.content,
      from: state.from,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  updateState = (update: Partial<ReplySmsState>) => {
    if (update.content && !_.isEmpty(update.content)) {
      // decodeURIComponent could be another solution
      update.content = replaceNordicCharacters(update.content);
    }

    super.updateState(update);
  };

  private async getDefaultNumbers() {
    const result = await ConfigurationApi.getSMSConfigurations();
    const defaultNumberOptions = result.map((defaultNumber) => ({
      text: defaultNumber.number,
      value: defaultNumber.number
    }));
    this.setState({ defaultNumbers: defaultNumberOptions });
  }

  componentDidMount() {
    this.getDefaultNumbers();
  }

  private getFromNumber = (drafts: ReplySmsState) => {
    if (drafts.from && drafts.from !== null) {
      return drafts.from;
    } else if (this.props.ticketType.senderSMSNumbers && this.props.ticketType.senderSMSNumbers.length === 1) {
      return this.props.ticketType.senderSMSNumbers[0].value;
    } else if (this.state && this.state.defaultNumbers && this.state.defaultNumbers.length === 1) {
      return this.state.defaultNumbers[0].value;
    } else {
      return null;
    }
  };

  private getInitialState = (drafts: ReplySmsState, phoneNumbers: string) => {
    const numbers = this.trimAndSplit(phoneNumbers).map((pn) => normalizePhoneNumber(pn));
    const state = {
      from: this.getFromNumber(drafts),
      content: drafts.content || '',
      recipients: drafts.recipients || numbers.join(','),
      isLoading: false,
      isSubmitModalOpen: false,
      selectedReplyTemplate: drafts.selectedReplyTemplate || undefined
    };
    return state;
  };

  private submitSMS = () => {
    this.setState({ isLoading: true }, async () => {
      const bodyOfRequest: SMSPayload = {
        content: this.state.content,
        sendAsSmsMessage: true,
        channel: this.channel,
        sendingNumber: this.state.from ?? undefined,
        direction: 'out',
        metaData: {
          to: this.trimAndSplit(this.state.recipients)
        },
        recipients: this.trimAndSplit(this.state.recipients)
      };
      const request = this.props.onSubmit(bodyOfRequest);
      request
        .then((response: any) => {
          if (!response.data) {
            this.clearFields();
          }
        })
        .catch((error: Error) => {
          console.error('Failed to add comment', error);
        });
    });
  };

  private trimAndSplit = (recipients: string) => {
    return recipients
      .split(',')
      .map((recipient) => recipient.trim())
      .filter((recipient) => recipient.length > 1);
  };

  private clearFields = () => {
    const { phoneNumbers } = this.props;

    this.setState({ content: '', recipients: phoneNumbers, selectedReplyTemplate: undefined }, () => {
      this.props.updateState(this.props.taskId, Channels.sms, {
        from: this.state.from,
        content: this.state.content,
        recipients: this.state.recipients
      });
    });
  };

  private getSubmitModal = () => {
    return (
      <Modal
        open={this.state.isSubmitModalOpen}
        closeOnDocumentClick={true}
        onClick={() => this.updateState({ isSubmitModalOpen: false })}
      >
        <Modal.Header>{tr('COMMENT_SEND_AS_SMS_QUESTION')}</Modal.Header>
        <Modal.Description style={{ padding: '20px' }}>
          <Table celled padded>
            <Table.Body>
              {this.state.recipients && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_RECEPIENTS')}</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(this.state.recipients)?.map((recipient: string) => (
                      <>{!_.isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
              {this.state.from && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}</Table.Cell>
                  <Table.Cell>
                    <>{!_.isEmpty(this.state.from) && <Label style={{ margin: '2px' }}>{this.state.from}</Label>}</>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Description>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            negative
            icon
            labelPosition="left"
            onClick={() => this.updateState({ isSubmitModalOpen: false, isLoading: false })}
          >
            <Icon name="remove" />
            {tr('GENERAL_CANCEL')}
          </Button>
          <Button
            primary
            disabled={this.state.isLoading || this.isSubmitPrevented()}
            loading={this.state.isLoading}
            icon
            onClick={() => this.submitSMS()}
            labelPosition="left"
          >
            <Icon name="send" />
            {tr('GENERAL_SEND')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  private getValue = (state: ReplySmsState) => {
    const recipients = state.recipients;

    const separatedValues = trimAndSplitRecipients(recipients);
    return separatedValues?.map((val) => {
      if (val !== '') {
        return {
          label: '',
          value: val
        };
      } else {
        return;
      }
    });
  };

  private formatSuggestionOptionLabel = ({ label, value }: { label: string; value: string }) => (
    <span>
      {label ? <Label> {label}</Label> : ''} {value ? value : ''}
    </span>
  );

  private handleNumberChange = (addressArray: any) => {
    const state = { ...this.state };
    if (addressArray !== null && addressArray !== undefined) {
      const newAddresses = addressArray.map((address: any) => {
        return normalizePhoneNumber(address.value);
      });
      state.recipients = newAddresses.join(',');
    } else {
      state.recipients = '';
    }

    this.setState(state, () => {
      this.saveDraft(this.state);
    });
  };

  private formatCreateLabel = (text: string) => {
    return <span>{text}</span>;
  };

  private onNewOption = (value: string) => {
    const separatedValues = trimAndSplitRecipients(value);

    const mappedValues = separatedValues?.map((val: string) => ({
      label: '',
      value: normalizePhoneNumber(val)
    }));

    const newValues = mappedValues?.map((recipient) => recipient.value).join(',');

    this.setState(
      {
        recipients: this.state.recipients + ',' + newValues
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  private mapSuggestions = (suggestions: Suggestion[]) => {
    return suggestions
      .filter((suggestion: Suggestion) => {
        if (!_.isEmpty(suggestion.ticketTypes)) {
          return suggestion.type === 'phone' && suggestion.ticketTypes.includes(this.props.ticketType.id);
        } else {
          return suggestion.type === 'phone';
        }
      })
      .map((suggestion: Suggestion) => {
        return {
          label: suggestion.name,
          value: suggestion.value
        };
      });
  };

  private getPlaceholderForSendingNumbers(
    configuredNumbers: { text: string; value: string }[],
    defaultNumbers: { text: string; value: string }[]
  ) {
    if (configuredNumbers.length === 0 && this.state.defaultNumbers.length === 0) {
      return tr('replyMethods.sms.sendingNumber.none_placeholder');
    } else if (configuredNumbers.length === 1 || (defaultNumbers.length === 1 && configuredNumbers.length === 0)) {
      // Display placeholder for current value (only when either 1 configured or 1 default number)
      return tr('replyMethods.sms.sendingNumber.cannot_select_placeholder', {
        phonenumber: configuredNumbers.length === 0 ? defaultNumbers[0]?.value : configuredNumbers[0].value
      });
    } else {
      // Display placeholder to select
      return tr('replyMethods.sms.sendingNumber.please_select_placeholder');
    }
  }

  private isSenderSelectionDisabled = (): boolean => {
    const isDisabled =
      (this.props.ticketType.senderSMSNumbers && this.props.ticketType.senderSMSNumbers?.length < 2) ||
      this.state.defaultNumbers.length < 2;

    return isDisabled;
  };

  private isSubmitPrevented = (): boolean => {
    return !this.state.content.length || !this.state.recipients.length;
  };

  render() {
    const configuredNumbers = this.props.ticketType.senderSMSNumbers;
    let numberOptions: { text: string; value: string }[] = [];
    if (configuredNumbers && configuredNumbers.length > 0) {
      // Use configured sending numbers
      numberOptions =
        configuredNumbers?.map((x) => {
          return {
            text: `${x.text} (${x.value})`,
            value: x.value
          };
        }) || [];
    } else {
      numberOptions = this.state.defaultNumbers;
    }

    const onSubmit = () => {
      if (this.isSubmitPrevented()) {
        return;
      }
      this.updateState({ isSubmitModalOpen: true });
    };

    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            {this.getSubmitModal()}
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t('PHONENUMBER')}</label>
                <CreatableSelect
                  isMulti
                  value={this.getValue(this.state) as Option[]}
                  hideSelectedOptions
                  isClearable
                  onChange={(value) => this.handleNumberChange(value)}
                  onCreateOption={(value) => this.onNewOption(value)}
                  formatOptionLabel={this.formatSuggestionOptionLabel}
                  options={this.mapSuggestions(this.props.suggestions)}
                  formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_RECIPIENT'))}
                  placeholder={t('ADD_COMMENT_SMS_RECEPIENTS_PLACEHOLDER')}
                  noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                  classNamePrefix="addressSelect"
                  styles={defaultCreatableSelectStyles}
                  filterOption={createFilter({ ignoreAccents: false })}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
                <ReplyTemplates
                  userData={this.props.userData}
                  ticketType={this.props.ticketType}
                  templates={this.props.templates}
                  task={this.props.task}
                  channel={this.channel}
                  selectedOption={this.state.selectedReplyTemplate}
                  setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                  setContent={(value) => this.updateState({ ...value })}
                  insertAtCursor={(value, content) => {
                    value.content = insertAtCursor(this.editor, content);
                    this.updateState({ ...value });
                  }}
                  content={this.state.content}
                  entities={this.props.entities}
                  discardHtml={true}
                />
              </Form.Field>
            </Form.Group>
            {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
              <Form.Field>
                <KnowledgeBank
                  title={this.state.content}
                  id={this.props.taskId}
                  extraArguments={this.props.ticketType.knowledgeBank}
                  content={this.state.content}
                  updateState={(value: string) => {
                    this.updateState({ content: value });
                  }}
                />
              </Form.Field>
            )}
            <Form.Input label={t('ADD_COMMENT_SENDER_EMAIL_ADDRESS')} required>
              <Dropdown
                selection={true}
                selectOnBlur={false}
                fluid={true}
                disabled={this.isSenderSelectionDisabled()}
                placeholder={this.getPlaceholderForSendingNumbers(configuredNumbers || [], this.state.defaultNumbers)}
                onChange={(_, data) => {
                  this.updateState({ from: data.value?.toString() });
                }}
                value={this.state.from ?? undefined}
                noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                options={numberOptions}
              />
            </Form.Input>
            <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
            <Form.Field>
              <label>
                {t('ADD_COMMENT_CONTENT')} ({this.state.content.length} {t('CHARACTERS')})
              </label>
              <ReplyTextArea
                ref={(ref) => (this.editor = ref)}
                content={this.state.content}
                onChange={(content) => {
                  this.updateState({ content });
                }}
                onKeyDown={(event: KeyDownEvent) => {
                  if (event.keyCode === 13 && (event.ctrlKey || event.metaKey)) {
                    onSubmit();
                  }
                }}
              />
            </Form.Field>
            <ReplyControlButtons
              disabled={this.isSubmitPrevented()}
              onClear={this.clearFields}
              onSaveDraft={() => this.saveVCDraft(false)}
              onSubmit={onSubmit}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default ReplySms;
