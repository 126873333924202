import React from 'react';
import ImageGallery from 'react-image-gallery';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import type { VFC } from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';

import { closeAttachmentsPreview } from 'src/actions/attachmentsActions';

import * as styles from './Lightbox.style';
import './Lightbox.css';

interface LightboxProps {
  open: boolean;
  items: (string | ReactImageGalleryItem)[];
  onClose?: React.MouseEventHandler;
}

const Lightbox: VFC<LightboxProps> = ({ open, items, onClose }) => {
  const dispatch = useDispatch();

  return (
    <Modal style={styles.modal} isOpen={open} shouldCloseOnOverlayClick onRequestClose={onClose}>
      <Icon
        name="close"
        size="large"
        color="grey"
        style={styles.close}
        onClick={() => {
          dispatch(closeAttachmentsPreview());
        }}
      />
      <ImageGallery
        lazyLoad
        showBullets={false}
        showPlayButton={false}
        showFullscreenButton={false}
        items={items.map((item) => (typeof item === 'string' ? { original: item, loading: 'lazy' } : item))}
      />
    </Modal>
  );
};

export default Lightbox;
