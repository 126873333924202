import axios from 'axios';
import ApiConfig from './ApiConfig';

const API_URL_SEND_ENTITY_EVENT = '/entityEvent';

export default class EntityEventsApi {
  static sendEntityEvent = (data: any): Promise<any> => {
    return axios.post(ApiConfig.getConfig().API_URL + API_URL_SEND_ENTITY_EVENT, data).then((response) => {
      return response;
    });
  };
}
