import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';

import EnvSettings from 'src/api/EnvSettings';
import FeatureFlags from 'src/api/FeatureFlags';
import { parseFieldValue } from 'src/Components/Case/Info/InfoField/InfoField';
import type { PersonalData } from 'src/types/User';
import type { Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

interface UseOpenExternalLinkProps {
  selectedTicketType?: TicketType;
  userData: PersonalData;
  values: any;
  task: Ticket;
}

const openedLinks: string[] = [];

const valueToArray = (value?: string | string[] | number | number[]) => {
  if (!value) return [];
  return Array.isArray(value) ? value : [value];
};

const useOpenExternalLink = ({ selectedTicketType, userData, values, task }: UseOpenExternalLinkProps) => {
  const customerInfoFields = selectedTicketType?.fieldSets.map((fieldSet) => fieldSet.customerInfo).find(Boolean);

  const { fieldName, channel, inBackground, direction } = EnvSettings.getSettings().OPEN_EXTERNAL_LINK.data;
  const fieldNames = valueToArray(fieldName);
  const channels = valueToArray(channel);

  const fields = (customerInfoFields || []).filter(({ name }) => name && fieldNames.includes(name));
  const isChannelsNotSetOrMatched = !channels?.length || channels.includes(task.channel);
  const isDirectionNotSetOrMatched = !direction?.length || direction.includes(task.originalDirection);

  const links = useMemo(() => {
    if (!fields.length || isEmpty(values) || !isChannelsNotSetOrMatched || !isDirectionNotSetOrMatched) {
      return [];
    }

    return fields.map((field) =>
      parseFieldValue({
        field,
        values,
        shouldEncodeUriComponent: false,
        personalData: userData
      })
    );
  }, [fields, values, userData, isChannelsNotSetOrMatched, isDirectionNotSetOrMatched]);

  useEffect(() => {
    if (FeatureFlags.isFlagOn('OPEN_EXTERNAL_LINK_ON_PHONE_CALL') && links.length) {
      links.forEach((link) => {
        const key = link && [task.id, link].join('_');
        if (!openedLinks.includes(key)) {
          inBackground ? fetch(link).catch(console.error) : window.open(link, '_blank');
          openedLinks.push(key);
        }
      });
    }
  }, [links, task.id]);
};

export default useOpenExternalLink;
