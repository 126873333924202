import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Translation } from 'react-i18next';
import type { VFC } from 'react';

import './DateTimePicker.css';
import 'react-datepicker/dist/react-datepicker.css';

interface DateTimePickerProps {
  value?: number | null;
  trigger?: React.ReactElement;
  isClearable?: boolean;
  inline?: boolean;
  minDate?: moment.Moment;

  onChange: (date: number | null) => void;
}

const DateTimePicker: VFC<DateTimePickerProps> = ({ isClearable, trigger, value, minDate, onChange, inline }) => {
  const [currentValue, setCurrentvalue] = useState<Date | null>(value ? moment.unix(value).toDate() : null);

  return (
    <Translation ns="translations">
      {(t) => (
        <DatePicker
          isClearable={isClearable}
          customInput={trigger}
          showTimeSelect={true}
          todayButton={t('TODAY')}
          showWeekNumbers={true}
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="LLLL"
          selected={currentValue}
          minDate={minDate?.toDate()}
          onChange={(v) => {
            setCurrentvalue(v);

            if (v === null) {
              onChange(null);
            } else {
              onChange(moment(v).unix());
            }
          }}
          inline={!!inline}
          calendarStartDay={1}
        />
      )}
    </Translation>
  );
};

export default DateTimePicker;
