import React, { SyntheticEvent } from 'react';
import { Icon, Menu, MenuItemProps } from 'semantic-ui-react';

import { MenuTab } from 'src/types/MenuTab';

interface TabProps {
  tab: MenuTab;
  cname: string;
  isMain?: boolean;
  refreshDisabled?: boolean;
  onClick: MenuItemProps['onClick'];
  onRefreshClick?: React.EventHandler<SyntheticEvent>;
  onCloseClick?: React.EventHandler<SyntheticEvent>;
}

const TabBarTab: React.FC<TabProps> = ({
  tab,
  cname,
  isMain,
  refreshDisabled,
  onClick,
  onRefreshClick,
  onCloseClick,
  children
}) => (
  <Menu.Item onClick={onClick} className={cname} icon={true} active={tab.activeTab} id={tab.title}>
    {children}
    {isMain ? (
      <span>
        <Icon
          className={'TabBar-Refresh-Icon'}
          name="sync alternate"
          onClick={onRefreshClick}
          style={refreshDisabled ? { color: 'grey' } : {}}
        />
      </span>
    ) : (
      <span>
        <Icon className="TabBar-Delete-Icon" name="delete" onClick={onCloseClick} />
      </span>
    )}
  </Menu.Item>
);

export default TabBarTab;
