import _ from 'lodash';
import * as React from 'react';
import { t as tr } from 'i18next';
import type { AnyAction } from 'redux';
import { connect } from 'react-redux';
import sanitizeHTML from 'sanitize-html';
import type { ThunkDispatch } from 'redux-thunk';
import type { IziToast } from 'izitoast';
import iziToast from 'izitoast';
import type { DropdownProps } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';

import ResponseTemplatesApi from 'src/api/ResponseTemplatesApi';
import { addTagToContent } from 'src/actions/ticketsActions';
import { sortTemplatesNumerical } from 'src/Components/Utilities';
import { newlinesToLineBreaks, removeHTMLTags } from 'src/Utilities/sanitize';
import { tagIdToString } from 'src/types/Tag';
import type { State } from 'src/types/initialState';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';

export interface ReplyTemplateProps {
  userData: PersonalData;
  task: Ticket;
  content: string;
  channel: number;
  ticketType: TicketType;
  entities: Entity[];
  templates: ResponseTemplate[];
  selectedOption: string | undefined;
  toAddresses?: string;
  ccAddresses?: string;
  bccAddresses?: string;
  users?: User[];
  discardHtml?: boolean;

  setContent: (value: Update) => void;
  setSelectedOption: (value: string) => void;
  addTag: (task: string, tagId: string) => void;
  insertAtCursor?: (value: Omit<Update, 'content'>, content: string) => void;
}

export interface ReplyTemplateState {
  loading: boolean;
}

interface Update {
  content: string;
  insertAtCursor?: boolean;
  [x: string]: unknown;
}

interface CheckForAndAssignParametersOptions {
  template: ResponseTemplate;
  task: Ticket;
  userData: PersonalData;
  entities?: object[];
  typeParams?: any;
}

interface DispatchProps {
  addTag: (taskId: string, tagId: string) => void;
}

type ToastButtons = [
  string,
  (
    instance: IziToast,
    toast: HTMLDivElement,
    button: HTMLButtonElement,
    event: MouseEvent,
    inputs: Array<HTMLInputElement>
  ) => void,
  boolean
][];

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchProps => ({
  addTag: (task, tagId) => {
    dispatch(addTagToContent(task, tagId, false));
  }
});

class ReplyTemplates extends React.Component<ReplyTemplateProps, ReplyTemplateState> {
  constructor(props: ReplyTemplateProps) {
    super(props);

    this.state = {
      loading: false
    };
  }

  private onPromptAction = (update: Update, originalContent: string): Promise<Update | null> => {
    return new Promise((resolve) => {
      const buttons: ToastButtons = [
        [
          `<button><b>${tr('GENERAL_REPLACE')}</b></button>`,
          (instance: IziToast, toast: HTMLDivElement) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
            resolve(update);
          },
          true
        ],
        [
          `<button>${tr('GENERAL_CONCAT_END')}</button>`,
          (instance: IziToast, toast: HTMLDivElement) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const content = newlinesToLineBreaks(`${originalContent}<br/><p>${update.content}</p>`);
            resolve({
              ...update,
              content: content
            });
          },
          false
        ],
        [
          `<button>${tr('GENERAL_CONCAT_START')}</button>`,
          (instance: IziToast, toast: HTMLDivElement) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const content = newlinesToLineBreaks(`<br/>${update.content}<br/>${originalContent}<br/>`);
            resolve({
              ...update,
              content: content
            });
          },
          false
        ]
      ];

      if (this.props.insertAtCursor) {
        buttons.push([
          `<button>${tr('GENERAL_CONCAT_CURSOR')}</button>`,
          (instance: IziToast, toast: HTMLDivElement) => {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
            const content = newlinesToLineBreaks(update.content);
            resolve({
              ...update,
              content: content,
              insertAtCursor: true
            });
          },
          false
        ]);
      }

      buttons.push([
        `<button>${tr('GENERAL_CANCEL')}</button>`,
        (instance: IziToast, toast: HTMLDivElement) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'cancel');
          // Perform no action.
          resolve(null);
        },
        false
      ]);

      iziToast.question({
        timeout: 0,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        message: tr('TEMPLATE_TEXT_AREA_ALREADY_HAS_TEXT'),
        position: 'center',
        buttons: buttons
      });
    });
  };

  private searchValueFromObject = (searchedObject: undefined | null | object[] | object, parameterArray: string[]) => {
    let responseString = '';
    parameterArray.forEach((parameterKey: string) => {
      if (searchedObject !== undefined && searchedObject !== null) {
        if (Array.isArray(searchedObject)) {
          searchedObject.forEach((searchedObjectSubArray: undefined | null | object[] | object) => {
            const resp = this.searchValueFromObject(
              searchedObjectSubArray,
              parameterArray.slice(parameterArray.indexOf(parameterKey))
            );
            if (resp !== undefined) {
              responseString += resp + ', ';
            }
            searchedObject = null;
          });
        } else {
          searchedObject = searchedObject[parameterKey];
        }
      }
    });
    if (searchedObject !== undefined) {
      if (searchedObject === null) {
        return responseString.substring(0, responseString.length - 2);
      } else {
        responseString += searchedObject;

        return responseString;
      }
    } else {
      return searchedObject;
    }
  };

  private replaceParams = (template: ResponseTemplate): { [x: string]: unknown } | undefined => {
    let recipients = this.props.toAddresses;
    let ccRecipients = this.props.ccAddresses;
    let bccRecipients = this.props.bccAddresses;

    const params = template.params;
    if (params?.replaceToAddress !== undefined) {
      recipients = this.props.toAddresses?.concat(' ', params?.replaceToAddress);
    }
    if (params?.replaceCCAddress !== undefined) {
      ccRecipients = this.props.ccAddresses?.concat(' ', params?.replaceCCAddress);
    }
    if (params?.replaceBccAddress !== undefined) {
      bccRecipients = this.props.bccAddresses?.concat(' ', params?.replaceBccAddress);
    }

    return {
      to: recipients,
      cc: ccRecipients,
      bcc: bccRecipients,
      ...(params?.changeTitle && { subject: params?.changeTitle })
    };
  };

  private checkForAndAssignParameters = (options: CheckForAndAssignParametersOptions): Update => {
    const { template, task, userData, entities, typeParams } = options;

    let templateContent = template.content;
    const paramMatchRegex = /\{{(.*?)\}}/g;
    const templateContentToBeReplaced = templateContent.match(paramMatchRegex) || [];
    const missingParameters: string[] = [];

    templateContentToBeReplaced.forEach((paramKey) => {
      let paramToBeReplaced;
      const paramKeyName = paramKey.substring(2, paramKey.length - 2);

      if (paramKey === 'agentAliasName') {
        const user = this.props.users?.find((usr: User) => {
          return usr.UID === 'USR' + task.handledByUser;
        });
        if (user) {
          paramToBeReplaced = user.aliasName;
        } else {
          missingParameters.push(tr('HANDLING_AGENT'));
          paramToBeReplaced = '';
        }
      } else {
        const address = template.params?.[paramKeyName]?.address ?? paramKeyName;
        paramToBeReplaced = this.searchValueFromObject(
          { ...task, userData: userData.profile, entities, typeParams },
          address.split('.')
        );
      }
      if (!paramToBeReplaced) {
        missingParameters.push(template.params?.[paramKeyName]?.UIName ?? paramKey);
        paramToBeReplaced = '';
      }
      templateContent = templateContent.replace(new RegExp(paramKey, 'gi'), paramToBeReplaced || '');
    });

    if (missingParameters.length > 0) {
      const errorMessages: string[] = [tr('TEMPLATE_PARAMETER_MISSING')];
      const missingParams = missingParameters.map((misParam: string) => {
        return `<br/> - ${misParam}`;
      });

      const errorMessage = errorMessages.concat(missingParams).join(' \n');
      iziToast.warning({
        timeout: 0,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        message: errorMessage,
        position: 'center',
        buttons: [
          [
            `<button><b>${tr('GENERAL_CLOSE')}</b></button>`,
            (instance: IziToast, toast: HTMLDivElement) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
            },
            true
          ]
        ]
      });
    }

    const templateParams = this.replaceParams(template);

    return {
      content: newlinesToLineBreaks(templateContent),
      ...templateParams
    };
  };

  private addTag = (ticket: Ticket, tagId: string | number): void => {
    const existingTags = this.props.task.tags;
    tagId = tagIdToString(tagId);
    if (existingTags.includes(tagId)) {
      return;
    }

    this.props.addTag(ticket.id, tagId);
  };

  private fireParameterEvents = (template: ResponseTemplate) => {
    const tags = template.params?.addTags;

    if (tags) {
      for (const tag of tags) {
        this.addTag(this.props.task, tag);
      }
    }
  };

  private getUsableTemplates = (templates: ResponseTemplate[]) => {
    return templates
      .filter((template: ResponseTemplate) =>
        template.ticketTypes.find((ticketType: number) => ticketType === this.props.ticketType.id)
      )
      .filter((template: ResponseTemplate) =>
        template.channels.find((channel: number) => channel === this.props.channel)
      );
  };

  private onChange = async (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
    const template = this.props.templates.find((template) => template._id === data.value);

    if (!template) {
      return;
    }
    this.props.setSelectedOption(data.value!.toString());

    let typeParams;

    if (this.props.entities && template.type) {
      const entity: Entity = this.props.entities[0];
      this.setState({ loading: true });
      if (_.isEmpty(this.props.entities)) {
        this.setState({ loading: false });
        iziToast.warning({
          timeout: 0,
          close: false,
          overlay: true,
          id: 'question',
          zindex: 999,
          message: tr('RESPONSE_TEMPLATE_MISSING_ENTITY'),
          position: 'center',
          buttons: [
            [
              `<button><b>${tr('GENERAL_CLOSE')}</b></button>`,
              (instance: IziToast, toast: HTMLDivElement) => {
                instance.hide({ transitionOut: 'fadeOut' }, toast, 'confirm');
              },
              true
            ]
          ]
        });
      } else {
        const paramsByTemplateType = await ResponseTemplatesApi.getTemplateTypeParams(
          template.type,
          entity._id,
          entity._type,
          this.props.task.id
        );
        this.setState({ loading: false });
        typeParams = paramsByTemplateType.parameters;
      }
    }

    this.fireParameterEvents(template);

    const originalContent = this.props.content;
    const parametrized = this.checkForAndAssignParameters({
      template,
      task: this.props.task,
      userData: this.props.userData,
      entities: this.props.entities,
      typeParams: typeParams
    });

    if (parametrized === null) {
      return;
    }

    const sanitizedOriginalContent = sanitizeHTML(originalContent, {
      allowedTags: [],
      allowedAttributes: {}
    }).replace(/ /g, '');
    let update: Update = parametrized;

    if (parametrized.content && originalContent && sanitizedOriginalContent.length > 0 && originalContent !== '') {
      const result = await this.onPromptAction(parametrized, originalContent);
      if (result === null) {
        return;
      }
      update = result;
    }

    if (this.props.discardHtml) {
      update.content = removeHTMLTags(update.content);
    }

    if (update.insertAtCursor) {
      const { content, ...value } = update;
      this.props.insertAtCursor?.(value, content);
    } else {
      this.props.setContent(update);
    }
  };

  render() {
    const usableTemplates = this.getUsableTemplates(this.props.templates);

    return (
      <Dropdown
        fluid={true}
        onChange={this.onChange}
        selection
        loading={this.state.loading}
        selectOnBlur={false}
        search={true}
        value={this.props.selectedOption || ''}
        placeholder={tr('PLACEHOLDER_SEARCH_CANNED_RESPONSE')}
        noResultsMessage={tr('PLACEHOLDER_SEARCH_NO_RESULTS')}
        options={sortTemplatesNumerical(usableTemplates).map((template) => ({
          key: template._id,
          text: template.name,
          value: template._id
        }))}
      />
    );
  }
}

export default connect(null, mapDispatchToProps)(ReplyTemplates);
