import classNames from 'classnames';
import type { ChangeEvent, FocusEvent, KeyboardEvent, VFC } from 'react';
import React from 'react';
import { Input, Table } from 'semantic-ui-react';
import type { InfoFieldProps } from './InfoFieldProps';

interface SubEntityFieldProps extends Pick<InfoFieldProps, 'field' | 'setValue' | 'onSaveData'> {
  inputType: 'number' | 'text';
  subEntityFieldValue: string;
  disabled: boolean;
  mongoSaveDisabled: boolean;
  integrationSaveDisabled: boolean;
}

const SubEntityField: VFC<SubEntityFieldProps> = ({
  field,
  inputType,
  subEntityFieldValue,
  disabled,
  integrationSaveDisabled,
  mongoSaveDisabled,
  onSaveData,
  setValue
}) => {
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  return (
    <Table.Cell width={10}>
      <Input
        key="5"
        type={inputType}
        disabled={disabled}
        className={className}
        size="mini"
        onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter' && !integrationSaveDisabled && !mongoSaveDisabled) {
            onSaveData?.(field, e.currentTarget.value);
          }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (!integrationSaveDisabled && !mongoSaveDisabled) {
            setValue?.(field, e.target.value);
          }
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          if (!integrationSaveDisabled && !mongoSaveDisabled) {
            onSaveData?.(field, e.target.value);
          }
        }}
        value={subEntityFieldValue || ''}
        fluid={true}
      />
    </Table.Cell>
  );
};

export default React.memo(SubEntityField);
