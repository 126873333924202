import { connect } from 'react-redux';

import { appendToDraft, switchDraftTabIndex } from 'src/actions/draftActions';
import CommentEditorWidget from '../Components/CommentEditorWidget/CommentEditorWidget';
import ChannelType from '../Components/CommentIconContent/ChannelType';
import { Channels } from 'src/types/Channel';
import type { State } from 'src/types/initialState';
import type { SenderEmail } from 'src/types/TicketType';

const initialState: any = {
  assets: []
};

interface OwnProps {
  comment: string;
  taskId: string;
  title: string;
  metaData: any;
  senderEmails: SenderEmail[];
  created: number;
  isHTML: boolean;
  attachmentIds: string[];
  buttonsType?: 'basic' | 'primary';
}

interface DispatchProps {
  replyToEmail: Function;
}

const mapStateToProps = (state: State = initialState, ownProps: OwnProps) => {
  return {
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    replyToEmail: (payload: any, taskID: string, param: string) => {
      dispatch(appendToDraft(payload, taskID, Channels.email));
      dispatch(switchDraftTabIndex(taskID, ChannelType.Email));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentEditorWidget);
