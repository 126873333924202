import React from 'react';
import { cloneDeep, get } from 'lodash';
import { withTranslation } from 'react-i18next';
import { Grid, Header, Icon, Loader } from 'semantic-ui-react';
import type { SemanticICONS } from 'semantic-ui-react';
import type { TFunction, WithTranslation } from 'react-i18next';

import { contentsVC } from 'src/api/VersionControl';
import CaseContentEditor from './CaseContentEditor';
import Comments from '../Comments/Comments';
import DraftsContainer from 'src/containers/DraftsContainer';
import CommentEditorWidgetContainer from 'src/containers/CommentEditorWidgetContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import FeatureFlags from 'src/api/FeatureFlags';
import LoaderComponent from '../Loader/Loader';
import Widgets from './Widget/Widgets';
import TopBarGeneralInfo from 'src/Components/Case/TopBar/TopBarGeneralInfo';
import TopBarStatusButtons from 'src/Components/Case/TopBar/TopBarStatusButtons';
import ActiveTimeTrackingHandler from 'src/Components/Utilities/ActiveTimeTrackingHandler';
import { getCommentAttachmentsIds } from 'src/Components/Utilities/comments';
import { taskIdToNumericalId } from 'src/Utilities/ticketList';
import ChannelType from '../CommentIconContent/ChannelType';
import type { CaseHOCProps } from 'src/containers/CaseContainer';
import type { SenderEmail } from 'src/types/TicketType';
import type { Ticket } from 'src/types/Ticket';

import './Case.css';
import CaseIFrameViewTabBar from './CaseIFrameViewTabBar';

interface CaseProps extends CaseHOCProps, WithTranslation {
  task: Ticket;
  loading: boolean;
  iFrame: { url: string; activeTab: 'case' | 'iframe'; tabTitle: string } | undefined;
}

interface CaseState {
  isReplyHeaderPinned: boolean;
}

export class Case extends React.Component<CaseProps, CaseState> {
  private senderEmails: SenderEmail[];
  private replyContainer: React.RefObject<HTMLDivElement>;
  private replyHeaderRef = React.createRef<HTMLDivElement>();
  private taskStatusOptions: (t: TFunction) => {
    text: string;
    icon: SemanticICONS;
    value: string;
  }[] = (t) => [
    {
      text: t('CASE_STATUS_TODO'),
      icon: 'exclamation',
      value: 'todo'
    },
    {
      text: t('CASE_STATUS_DOING'),
      icon: 'clock',
      value: 'doing'
    },
    {
      text: t('CASE_STATUS_DONE'),
      icon: 'check',
      value: 'done'
    }
  ];

  constructor(props: CaseProps) {
    super(props);

    this.state = { isReplyHeaderPinned: false };

    this.senderEmails = [];
    this.replyContainer = React.createRef<HTMLDivElement>();
  }

  componentWillReceiveProps(nextProps: CaseProps) {
    if (nextProps.task) {
      if (nextProps.ticketTypes?.length > 0 && !nextProps.task.entityFields) {
        this.senderEmails = [];
        nextProps.ticketTypes.forEach((ticketType: any) => {
          const senderEmail = cloneDeep(ticketType);
          senderEmail.senderemail.type = senderEmail.name;
          if (senderEmail.name === nextProps.task.taskType) {
            senderEmail.senderemail.default = true;
          }
          this.senderEmails.push(senderEmail.senderemail);
        });
      }
    }

    if (this.props.scrollInstantly) {
      this.props.falsifyInstantScroll();
      this.replyContainer?.current?.scrollIntoView({
        behavior: 'auto'
      });
    }
  }

  componentDidMount() {
    this.getTask(this.props.match.params.id);
  }

  componentDidUpdate(prevProps: CaseProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.getTask(this.props.match.params.id);
    }

    if (this.props.needToScroll !== prevProps.needToScroll && this.props.needToScroll) {
      if (!this.props.scrollInstantly) {
        this.replyContainer?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }

      this.props.falsifyScroll();
    }

    this.isReplyHeaderInViewport(-47);
  }

  private getTask = (id: string) => {
    if (id.match(/^\d+$/g) !== null) {
      // the ticked ID is number only, should be TSK prefixed
      // redirect to valid URL
      const url = `/case/TSK${id}`;
      this.props.history.push(url);
      return;
    }

    this.props.openTab(id);
    this.props.fetchTicket(id);
    contentsVC.loadDraft({
      taskId: taskIdToNumericalId(id),
      attachments: []
    });
  };

  private isReplyHeaderInViewport = (offset = 0) => {
    if (!this.replyHeaderRef.current) return false;

    const top = this.replyHeaderRef.current!.getBoundingClientRect().top;

    // Additional conditions to prevent unnecessary state changes
    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      if (this.state.isReplyHeaderPinned) {
        return this.setState({ isReplyHeaderPinned: false });
      }
    } else {
      if (!this.state.isReplyHeaderPinned) {
        return this.setState({ isReplyHeaderPinned: true });
      }
    }
  };

  caseView = () => {
    const lastExternalComment = this.props.task?.comments?.filter((comment) => comment.direction === 'in').at(-1);
    const { t } = this.props;

    return this.props.task ? (
      <ErrorBoundary>
        <div id={this.props.mobileMode ? 'mobileElement topelement' : 'topelement'}>
          <Loader size="huge" active={this.props.loading}>
            {t('CASE_LOADING_CONTENT')}
          </Loader>
          <ActiveTimeTrackingHandler />

          <Grid
            columns={2}
            divided={true}
            stackable={true}
            className={this.props.mobileMode ? 'mobileElement detailViewScrollContainer' : 'detailViewScrollContainer'}
          >
            <Grid.Column
              onScroll={() => this.isReplyHeaderInViewport(-43)}
              className={this.props.mobileMode ? 'case-left-side-mobile' : 'case-left-side'}
              style={{ paddingTop: 0 }}
              width={10}
            >
              <TopBarGeneralInfo contentType="task" />

              {this.props.mobileMode && (
                <TopBarStatusButtons contentType="task" statusOptions={this.taskStatusOptions(t)} />
              )}

              {!FeatureFlags.isFlagOn('DISABLE_TASK_CONTENTS') === true &&
                this.props.userData.permissions.includes('updateContent') && <CaseContentEditor />}

              <Comments senderEmails={this.senderEmails} mobileMode={this.props.mobileMode} />

              {this.props.userData.permissions.includes('updateContent') && (
                <>
                  <div ref={this.replyHeaderRef}></div>

                  {/* TODO: move to separate ReplyHeader component */}
                  <Header
                    as="h3"
                    style={{
                      display: 'flex',
                      padding: '7px 0',
                      margin: 0
                    }}
                    {...(this.state.isReplyHeaderPinned && {
                      style: {
                        display: 'flex',
                        position: 'sticky',
                        bottom: '-14px',
                        background: '#fff',
                        width: '100%',
                        padding: '7px 0',
                        borderTop: '1px solid rgba(0, 0, 0, .1)',
                        cursor: 'pointer',
                        margin: 0
                      },
                      onClick: () => {
                        if (this.state.isReplyHeaderPinned) {
                          this.replyHeaderRef.current?.scrollIntoView({ behavior: 'smooth' });
                        }
                      }
                    })}
                  >
                    <Icon name="talk" />
                    {t('CASE_ANSWER')}

                    {this.props.task.channel === ChannelType.Email && lastExternalComment && (
                      <div
                        className="Comment-Content images-max-w-95"
                        style={{ marginLeft: 'auto', marginRight: '5px' }}
                      >
                        <ErrorBoundary>
                          <CommentEditorWidgetContainer
                            senderEmails={this.senderEmails}
                            created={lastExternalComment.created!}
                            title={lastExternalComment.title || ''}
                            comment={lastExternalComment.content!}
                            taskId={this.props.task.id}
                            metaData={lastExternalComment.metaData}
                            isHTML={get(this.props, ['metaData', 'html'])}
                            attachmentIds={getCommentAttachmentsIds(lastExternalComment.metaData, this.props.task)}
                            buttonsType="primary"
                          />
                        </ErrorBoundary>
                      </div>
                    )}
                  </Header>

                  <ErrorBoundary>
                    <div ref={this.replyContainer}>
                      <DraftsContainer />
                    </div>
                  </ErrorBoundary>
                </>
              )}
            </Grid.Column>

            <Grid.Column
              width={6}
              style={{ padding: 0 }}
              className={this.props.mobileMode ? 'case-right-side-mobile' : 'case-right-side'}
            >
              <div>
                {!this.props.mobileMode && (
                  <TopBarStatusButtons contentType="task" statusOptions={this.taskStatusOptions(t)} />
                )}

                <ErrorBoundary>
                  <Widgets />
                </ErrorBoundary>
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </ErrorBoundary>
    ) : (
      <LoaderComponent />
    );
  };

  render() {
    if (this.props.iFrame) {
      const taskId = taskIdToNumericalId(this.props.task?.id);
      return (
        <ErrorBoundary>
          <CaseIFrameViewTabBar
            caseTitle={this.props.task.id}
            frameTitle={`TSK${this.props.iFrame.tabTitle}`}
            taskId={taskId!}
            activeTab={this.props.iFrame.activeTab}
          />
          {this.props.iFrame.activeTab === 'case' ? (
            <this.caseView />
          ) : (
            <iframe src={this.props.iFrame.url} style={{ height: '84.5vh', width: '100%' }} />
          )}
        </ErrorBoundary>
      );
    }
    return <this.caseView />;
  }
}

export default withTranslation('translations')(Case);
