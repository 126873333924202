import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import MitelIntegration from '../Components/PhoneServices/MitelIntegration';
import { handleIncomingPhoneCall } from '../handlers/handlePhoneCall';
import SocketInstance from '../realTimeNotifications';
import { activateTicket } from '../actions/ticketsActions';
import { activateTab, addTab } from '../actions/tabActionsRTK';
import { convertCaseNaming } from 'src/Utilities/helper';
import { addComment } from '../actions/commentsActions';
import type { IntegrationData } from '../handlers/handlePhoneCall';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    configurations: state.configurations,
    workStatus: state.workStatus,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>) => {
  return {
    activateFoundTicket: (ticketId: number) => {
      const stringTicketId = convertCaseNaming(ticketId, 'string', 'task') as string;
      dispatch(addTab({ id: stringTicketId, type: 'tickets' }));
      dispatch(activateTicket(stringTicketId));
      dispatch(activateTab(stringTicketId));

      SocketInstance.joinRoom(stringTicketId);
    },

    createNewComment: (
      ticketId: number,
      payload: {
        content: string;
        direction: string;
        foreignId: string;
        foreignIdType: string;
      }
    ) => {
      return dispatch(addComment(`TSK${ticketId}`, payload));
    },

    handleIncomingPhoneCall: (callObject: IntegrationData) => {
      return handleIncomingPhoneCall({ dispatch }, callObject);
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MitelIntegration));
