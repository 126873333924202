// low level client for version api
import axios from 'axios';
import { generatePath } from 'react-router-dom';
import ApiConfig from '../ApiConfig';
import type { CacheKey, DraftLogEntry, VersionLogEntry } from './types';

const API_ENDPOINT = '/versions/:tableName';
const API_GET_LOG = '/:id/log';
const API_GET_SPECIFIC_LOG = '/:id/log/:vid';
const API_ADD_DRAFT = '/draft';
const API_GET_DRAFTS = '/:id/:channelId?/drafts';
const API_GET_LATEST_DRAFT = '/:id/:channelId?/draft';
const API_GET_SPECIFIC_DRAFT = '/:id/draft/:did';
const API_GET_STATE = '/:id/:channelId?/info';

type Counters = { drafts: number; versions: number };
type DraftResult = {
  content: string | null;
  id: number;
  channelId?: number;
} & Counters;

type VersionLogResult = {
  id: number;
  current: string;
  log: VersionLogEntry[];
};

export class VersionApi {
  constructor(readonly tableName: string) {
  }

  private getUrl = (pattern: string, params?: { [paramName: string]: string | number | undefined }) => {
    const { API_URL } = ApiConfig.getConfig();
    const baseUrl = generatePath(API_ENDPOINT, { tableName: this.tableName });
    const path = generatePath(pattern, params);

    return API_URL + baseUrl + path;
  };

  loadDraft = async (id: number, channelId?: number): Promise<DraftResult> => {
    try {
      const url = this.getUrl(API_GET_LATEST_DRAFT, { id, channelId });
      const data = await axios.get(url);
      if (data.data) {
        return {
          drafts: data.data.drafts,
          versions: data.data.versions,
          id,
          content: data.data.draftContent?.content || null
        };
      }
    } catch (error) {
      console.error(error);
    }
    return {
      drafts: 0,
      versions: 0,
      id,
      content: null
    };
  };

  loadDraftInfo = async (key: CacheKey): Promise<Counters & { id: number, channelId?: number }> => {
    const [id, channelId] = key.split('_').map(Number);
    try {
      const url = this.getUrl(API_GET_STATE, { id, channelId });
      const res = await axios.get(url);
      if (res.data) {
        return {
          id,
          drafts: res.data.drafts,
          versions: res.data.versions
        };
      }
    } catch (error) {
      console.error(error);
    }
    return { drafts: 0, versions: 0, id };
  };

  saveDraft = async (id: number, content: string, channelId?: number): Promise<void> => {
    try {
      const url = this.getUrl(API_ADD_DRAFT);
      await axios.post(url, { id, content, channelId });
    } catch (error) {
      console.error(error);
    }
  };

  loadAllDrafts = async (id: number, channelId?: number): Promise<DraftLogEntry[]> => {
    try {
      const url = this.getUrl(API_GET_DRAFTS, { id, channelId });
      const data = await axios.get(url);
      if (data.data) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  loadAllVersions = async (id: number): Promise<VersionLogResult | null> => {
    try {
      const url = this.getUrl(API_GET_LOG, { id });
      const data = await axios.get(url);
      if (data.data) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  loadDraftById = async (id: number, draftId: number): Promise<DraftLogEntry | null> => {
    try {
      const url = this.getUrl(API_GET_SPECIFIC_DRAFT, { id, did: draftId });
      const data = await axios.get(url);
      if (data.data) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  loadVersionById = async (id: number, versionId: number): Promise<VersionLogEntry | null> => {
    try {
      const url = this.getUrl(API_GET_SPECIFIC_LOG, { id, vid: versionId });
      const data = await axios.get(url);
      if (data.data) {
        return data.data;
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  };
}
