import { connect } from 'react-redux';
import type { ReplyTabIndex } from '../types/Drafts';
import type { SenderEmail } from '../types/TicketType';
import { appendToDraft, switchDraftTabIndex } from '../actions/draftActions';
import SuggestionEditorWidget from '../Components/SuggestionEditorWidget/SuggestionEditorWidget';

const initialState: any = {
  assets: []
};

interface OwnProps {
  comment: string;
  channel: number;
  taskId: string;
  title: string;
  metaData: any;
  senderEmails: SenderEmail[];
  created: number;
  isHTML: boolean;
  attachmentIds: string[];
  replyTabIndexList: ReplyTabIndex[];
}

interface DispatchProps {
  changeDraftContents: (payload: any, taskID: string, replyChannel: string, replyChannelTabIndex: number) => void;
}

const mapStateToProps = (state: any = initialState, ownProps: OwnProps) => {
  return {
    ...ownProps,
    drafts: state.drafts,
    channels: state.channels
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    changeDraftContents: (payload: any, taskID: string, replyChannel: string, replyChannelTabIndex: number) => {
      dispatch(appendToDraft(payload, taskID, replyChannel));
      dispatch(switchDraftTabIndex(taskID, replyChannelTabIndex));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuggestionEditorWidget);
