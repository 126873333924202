import axios from 'axios';
import ApiConfig from './ApiConfig';
import { Template, TemplateFormValues } from '../types/Template';
import { generatePath } from 'react-router-dom';

const API_URL_TEMPLATES = '/templates/:id?';

export default class TemplatesApi {
  static getTemplates = (): Promise<Template[]> => {
    return axios
      .get(ApiConfig.getConfig().API_URL + generatePath(API_URL_TEMPLATES))
      .then((response) => response.data);
  };

  static createTemplate = (template: TemplateFormValues): Promise<Template> => {
    return axios
      .post(ApiConfig.getConfig().API_URL + generatePath(API_URL_TEMPLATES), {
        ...template,
        template: JSON.stringify(template.template),
      })
      .then((response) => response.data);
  };

  static updateTemplate = (template: TemplateFormValues): Promise<Template> => {
    return axios
      .put(ApiConfig.getConfig().API_URL + generatePath(API_URL_TEMPLATES, { id: template.id }), {
        ...template,
        template: JSON.stringify(template.template),
      })
      .then((response) => response.data);
  };

  static deleteTemplate = (id: string): Promise<Template> => {
    return axios
      .delete(ApiConfig.getConfig().API_URL + generatePath(API_URL_TEMPLATES, { id }))
      .then((response) => response.data);
  };
}
