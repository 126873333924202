import classNames from 'classnames';
import type { VFC } from 'react';
import React from 'react';
import { Input, Table } from 'semantic-ui-react';

import DateRangePickerField from '../../DateRangePickerField';
import type { InfoFieldProps } from './InfoFieldProps';

interface InfoDateRange extends Pick<InfoFieldProps, 'field' | 'onSaveData' | 'language'> {
  fieldValue?: string;
  mongoSaveDisabled: boolean;
  integrationSaveDisabled: boolean;
  inputDisabled: boolean;
}

const InfoDateRange: VFC<InfoDateRange> = ({
  field,
  fieldValue,
  language,
  inputDisabled,
  mongoSaveDisabled,
  integrationSaveDisabled,
  onSaveData
}) => {
  const fieldNameClass = field.name?.split(' ').join('_');
  const className = classNames({
    'Info-CompactInput': true,
    disabledInputField: mongoSaveDisabled,
    readOnlyInputField: integrationSaveDisabled
  });

  return (
    <Table.Cell width={10} id={'DatePickerTable_' + fieldNameClass}>
      {integrationSaveDisabled || mongoSaveDisabled ? (
        <Input
          id={'Date_' + fieldNameClass}
          disabled={inputDisabled}
          className={className}
          size="mini"
          value={fieldValue || ''}
          fluid={true}
        />
      ) : (
        <DateRangePickerField
          id={'DatePicker_' + fieldNameClass}
          onChange={(data) => {
            onSaveData?.(field, data);
          }}
          value={fieldValue}
          language={language}
          {...field.props}
        />
      )}
    </Table.Cell>
  );
};

export default React.memo(InfoDateRange);
