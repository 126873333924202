import * as React from 'react';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Dropdown, Icon } from 'semantic-ui-react';
import { History } from 'history';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import EnvSettings from './api/EnvSettings';
import FeatureFlags from './api/FeatureFlags';
import type { Tab } from 'src/types/Tab';
import type { State } from 'src/types/initialState';
import type { ContentTypesFields } from 'src/types/Ticket';

import './MenuDropdown.css';

interface OwnProps extends RouteComponentProps {
  history: History;

  onClickExportData: () => void;
}

interface StateProps {
  tabs: Tab[];
}

type IProps = StateProps & OwnProps;

const MenuDropdown = (props: IProps) => {
  const openActiveTabByType = (type: ContentTypesFields) => {
    const activeTab = props.tabs.find((tab: Tab) => tab.type === type && tab.activeTab === true);

    if (activeTab) {
      switch (type) {
        case 'tickets':
          props.history.push(`/case/${activeTab.id}`);
          break;
        case 'infopages':
          props.history.push(`/infopage/${activeTab.id}`);
          break;
      }
    }
  };

  return (
    <Translation ns="translations">
      {(t) => (
        <Dropdown
          selectOnBlur={false}
          trigger={<Icon size="big" name="bars" style={{ color: 'white' }} />}
          simple={true}
          icon={null}
          style={{ margin: 0, padding: 0, zIndex: 9999 }}
        >
          <Dropdown.Menu>
            <Dropdown.Header onClick={() => openActiveTabByType('tickets')}>
              <Link to="/" className="dropdown-link">
                <Icon name="tasks" /> {t('MENU_DROPDOWN_MAINVIEW')}
              </Link>
            </Dropdown.Header>

            {FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE') === true && (
              <Dropdown.Header onClick={() => openActiveTabByType('infopages')}>
                <Link to="/infopage" className="dropdown-link">
                  <Icon name="file alternate outline" /> {t('MENU_DROPDOWN_INFOPAGES')}
                </Link>
              </Dropdown.Header>
            )}

            <Dropdown.Header>
              <Link to="/settings" className="dropdown-link">
                <Icon name="settings" /> {t('MENU_DROPDOWN_SETTINGS')}
              </Link>
            </Dropdown.Header>

            {EnvSettings.getSettings().REPORTING_PAGE_URL !== '' && (
              <Dropdown.Header>
                <Link to="/reporting" className="dropdown-link">
                  <Icon name="chart bar" /> {t('MENU_DROPDOWN_REPORTING')}
                </Link>
              </Dropdown.Header>
            )}

            {FeatureFlags.isFlagOn('EXPORT_DATA') === true && (
              <Dropdown.Header onClick={() => props.onClickExportData()}>
                <a href="/export" onClick={(e) => e.preventDefault()} className="dropdown-link">
                  <Icon name="external share" /> {t('export.menu.title')}
                </a>
              </Dropdown.Header>
            )}

            {EnvSettings.getSettings().REPORTING_PAGE_URL === '' && (
              <Dropdown.Header>
                <Icon name="help" /> {t('TOPLEFT_LINK_DROPDOWN_NO_PAGES')}
              </Dropdown.Header>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Translation>
  );
};

const mapStateToProps = (state: State) => ({
  tabs: state.ticketTabs as unknown as Tab[]
});

export default withRouter(connect(mapStateToProps)(MenuDropdown));
