import React from 'react';
import { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { throttle } from 'lodash';
import type { History } from 'history';

import AutomaticClosingTickets from './Components/AutomaticClosingTickets';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import LoginContainer from './containers/LoginContainer';
import activationContainer from './containers/activationContainer';
import Main from './containers/MainContainer';
import SimpleCaseContainer from './containers/SimpleCaseContainer';
import CaseContainer from './containers/CaseContainer';
import ErrorBoundary from './ErrorBoundary';
import OutdatedVersionModal from './OutdatedVersionModal';
import DisconnectedNotification from './Components/Notifications/DisconnectedNotification';
import FeatureFlags from 'src/api/FeatureFlags';
import ShortcutsModal from 'src/Components/Shortcuts/ShortcutsModal';
import { PrivateRoute } from './PrivateRoute';
import { refreshToken } from 'src/actions/authActions';
import { store } from 'src/store';
import { toggleMobileMode } from './actions/mobileActions';
import type { Dispatch } from '@reduxjs/toolkit';
import type { State } from 'src/types/initialState';

interface AppProps {
  history: History;
  auth: boolean;
  uiType: string;

  toggleMobileMode: (mode: boolean) => void;
}

class App extends Component<AppProps> {
  componentDidMount() {
    this.resizeListener();
    window.addEventListener('resize', this.resizeListener);

    /**
     * Use case:
     * After device innactivity user returns to the page and 'visibilitychange' event fires
     * During that time no timeouts were fired nor renewed
     */
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible' && window.refreshTimeout === undefined) {
        store.dispatch(refreshToken(true) as any);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeListener);
  }

  private resizeListener = throttle(() => {
    if (window.innerWidth < 800) {
      this.props.toggleMobileMode(true);
    } else {
      this.props.toggleMobileMode(false);
    }
  }, 500);

  render() {
    return (
      <ErrorBoundary>
        <ConnectedRouter history={this.props.history}>
          <div>
            <Switch>
              <Route path="/login" component={LoginContainer} />
              <Route path="/activate" component={activationContainer} />
              <Route path="/forgotpassword" component={ForgotPasswordContainer} />
              {this.props.uiType === 'cs' && (
                <PrivateRoute
                  authenticated={this.props.auth}
                  path="/caseview/:id"
                  component={CaseContainer}
                  exact={true}
                />
              )}
              {this.props.uiType === 'am' && (
                <PrivateRoute
                  authenticated={this.props.auth}
                  path="/caseview/:id"
                  component={SimpleCaseContainer}
                  exact={true}
                />
              )}
              <PrivateRoute path={'/'} authenticated={this.props.auth} component={Main} />
            </Switch>
          </div>
        </ConnectedRouter>
        {FeatureFlags.isFlagOn('AUTOMATIC_CLOSING_TICKETS') && <AutomaticClosingTickets />}
        <ShortcutsModal />
        <OutdatedVersionModal />
        <DisconnectedNotification />
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state: State) => {
  return {
    auth: state.auth.loggedIn
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleMobileMode: (mode: boolean) => {
    dispatch(toggleMobileMode(mode));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
