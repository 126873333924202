import { Cookies } from 'react-cookie';
import iziToast from 'izitoast';
import { t } from 'i18next';
import { ThunkAction } from 'redux-thunk';
import { ActionCreator, Dispatch } from 'redux';

import SocketInstance from 'src/realTimeNotifications';
import AuthApi from 'src/api/AuthApi';
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE_CREDENTIALS,
  LOGIN_FAILURE_GENERAL,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  ACTIVATION_SUCCESS
} from './index';
import { fetchUsers, fetchPersonalData } from './userActions';
import { fetchTags } from './tagActions';
import {
  fetchTickets,
  fetchTicketTypes,
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTitleTemplates
} from './ticketsActions';
import { fetchLinkLists } from './linkListActions';
import { fetchAssets } from './assetActions';
import { fetchFeatureFlagsAndInfoPages } from './infoPagesActions';
import { removeAllNotifications } from './notificationsActions';
import { accessTokenRefresher, tokenRefreshTimeout } from '../Utilities/token';
import { StaticTabs } from 'src/types/TicketList';
import { State } from 'src/types/initialState';

export const loginSuccess = () => {
  return { type: LOGIN_SUCCESS };
};

export const loginFailureCredentials = () => {
  return { type: LOGIN_FAILURE_CREDENTIALS };
};

export const loginFailureGeneral = () => {
  return { type: LOGIN_FAILURE_GENERAL };
};

export const logoutSuccess = () => {
  return { type: LOGOUT_SUCCESS };
};

export const logoutFailure = () => {
  return { type: LOGOUT_FAILURE };
};

export const activationSuccess = () => {
  return { type: ACTIVATION_SUCCESS };
};

export const loginExternal: ActionCreator<ThunkAction<any, State, any, any>> = (token: string, username: string) => {
  return (dispatch: Dispatch) => {
    return AuthApi.loginExternal(token, username)
      .then((response) => {
        if (response.hasLoggedIn) {
          dispatch(loginSuccess());
          dispatch(fetchUsers('') as any);
          dispatch(fetchTags() as any);
        } else if (response.hasEnteredIncorrectLoginCredentials) {
          dispatch(loginFailureCredentials());
        } else {
          dispatch(loginFailureGeneral());
        }
      })
      .catch((error) => {
        console.error('Error while logging externally', error);
        dispatch(loginFailureGeneral());
      });
  };
};

export const loginExternalByBackend: ActionCreator<ThunkAction<any, State, any, any>> = () => {
  return (dispatch: Dispatch) => {
    return AuthApi.loginBackend()
      .then(() => {
        dispatch(loginSuccess());
        dispatch(fetchUsers() as any);
        dispatch(fetchTicketTypes() as any);
        dispatch(fetchChannelTypes() as any);
        dispatch(fetchResponseTemplates() as any);
        dispatch(fetchTitleTemplates() as any);
        dispatch(fetchLinkLists() as any);
        dispatch(fetchTags() as any);
        dispatch(fetchAssets() as any);
        dispatch(fetchPersonalData() as any);
        dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW) as any);
        dispatch(fetchFeatureFlagsAndInfoPages() as any);
      })
      .catch((error: any) => {
        console.error('Error while logging in by backend', error);
      });
  };
};

export const activate: ActionCreator<ThunkAction<any, State, any, any>> = (token: string, password: string) => {
  return (dispatch: Dispatch) => {
    return AuthApi.activate(token, password)
      .then(() => {
        dispatch(activationSuccess());
      })
      .catch((error) => {
        console.error('Error while activating user', error);
        return false;
      });
  };
};

export const forgotPassword: ActionCreator<ThunkAction<any, State, any, any>> = (email: string) => {
  return () => {
    return AuthApi.forgotPasswordRequest(email).catch((error) => {
      console.error('Error while sending request', error);
      return error;
    });
  };
};

export const refreshToken: ActionCreator<ThunkAction<any, State, any, any>> =
  (refresherActivation?: boolean) => (dispatch) =>
    AuthApi.refreshToken()
      .then((accessTokenExpiration) => {
        localStorage.setItem('accessTokenExpiration', accessTokenExpiration);

        if (refresherActivation) {
          accessTokenRefresher(() => dispatch(refreshToken(true))).catch(() => performUserLogoutWhenTokenHasExpired());
        }
      })
      .catch(() => {
        const accessTokenExpiration = localStorage.getItem('accessTokenExpiration');

        if (accessTokenExpiration) {
          setTimeout(() => {
            if (localStorage.getItem('loggedIn')) AuthApi.refreshToken();
          }, tokenRefreshTimeout(accessTokenExpiration));
        }
      });

export const login: ActionCreator<ThunkAction<any, State, any, any>> = (user: string, password: string) => {
  return (dispatch: Dispatch) => {
    return AuthApi.login(user, password)
      .then((response) => {
        if (response.hasLoggedIn) {
          dispatch(loginSuccess());
          dispatch(fetchTickets(null, StaticTabs.MAIN_VIEW) as any);
          dispatch(fetchFeatureFlagsAndInfoPages() as any);
          return true;
        } else if (response.hasEnteredIncorrectLoginCredentials) {
          dispatch(loginFailureCredentials());
          return false;
        } else {
          dispatch(loginFailureGeneral());
          return false;
        }
      })
      .catch((error) => {
        console.error('Error while logging in by default way', error);
        dispatch(loginFailureGeneral());
        return false;
      });
  };
};

export const logout: ActionCreator<ThunkAction<any, State, any, any>> = () => {
  return async (dispatch: Dispatch) => {
    try {
      removeAuthTraces();
    } catch (error) {
      console.error(error);
    }

    SocketInstance.disconnect();
    dispatch(removeAllNotifications());

    try {
      await AuthApi.logout();
      dispatch(logoutSuccess());
    } catch (error) {
      console.error('Error while logout in authAction', error);
      dispatch(logoutFailure());
    }
  };
};

export const performUserLogoutWhenTokenHasExpired: ActionCreator<ThunkAction<any, State, any, any>> = () => {
  return (dispatch) => {
    dispatch(logout());
    iziToast.info({
      message: t('auth.tokenExpired.message'),
      timeout: 10000,
      position: 'bottomRight',
      icon: 'icon privacy'
    });
  };
};

const removeAuthTraces = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('accessTokenExpiration');

  const cookies = new Cookies();
  cookies.remove('eeedo', { domain: 'oc-cloud2.elisa.fi' });
  cookies.remove('eeedo', { path: '/' });
  cookies.remove('eeedo_refresh', { path: '/' });
  // cookies.remove('__HOST-eeedo', { path: '/' });
  cookies.remove('loggedIn', { path: '/' });
  cookies.remove('eeedocore', { path: '/' });
};
