import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';

export default class AssetsApi {
  static getAssets = (): Promise<any> => {
    const baseUrl = ApiConfig.getConfig().ASSET_API_URL;
    if (baseUrl === '' || baseUrl === undefined || baseUrl === null) {
      return Promise.resolve([]);
    } else {
      return axiosWithRetry
        .get(ApiConfig.getConfig().ASSET_API_URL + '/assets', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          withCredentials: true
        })
        .then((response) => {
          return response.data;
        });
    }
  };

  static getAsset = (tagID: string): Promise<any> => {
    const baseUrl = ApiConfig.getConfig().ASSET_API_URL;
    if (baseUrl === '' || baseUrl === undefined || baseUrl === null) {
      return Promise.resolve([]);
    } else {
      return axiosWithRetry
        .get(ApiConfig.getConfig().ASSET_API_URL + '/assets/' + tagID, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          withCredentials: true
        })
        .then((response) => {
          return response.data;
        });
    }
  };
}
