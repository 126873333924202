import * as React from 'react';
import { t } from 'i18next';

import ChannelType from './ChannelType';

function iconTranslation(channel: ChannelType): string {
  const noMetadataChannels = {
    [ChannelType.Email]: t('COMMMENT_ICON_CONTENT_EMAIL_CHANNEL'),
    [ChannelType.Webform]: t('COMMMENT_ICON_CONTENT_WEBFORM_CHANNEL'),
    [ChannelType.Chat]: t('COMMMENT_ICON_CONTENT_CHAT_CHANNEL'),
    [ChannelType.Internal]: t('COMMMENT_ICON_CONTENT_INTERNAL_CHANNEL'),
    [ChannelType.Sms]: 'SMS',
    [ChannelType.SecureEmail]: t('COMMMENT_ICON_CONTENT_SECURE_EMAIL_CHANNEL'),
    [ChannelType.Facebook]: t('COMMMENT_ICON_CONTENT_FACEBOOK_CHANNEL'),
    [ChannelType.WhatsApp]: 'Whatsapp'
  };

  const defaultContent = t('COMMMENT_ICON_CONTENT_UNKNOWN_CHANNEL');
  return noMetadataChannels[channel] ?? defaultContent;
}

function withoutData(title: string) {
  return (
    <div>
      <b>{title}</b>
      <hr />
    </div>
  );
}

function EmptyContent(props: { channel: ChannelType }) {
  const message = iconTranslation(props.channel);
  return withoutData(message);
}

export default EmptyContent;
