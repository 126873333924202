import axios from 'axios';
import iziToast from 'izitoast';
import { t } from 'i18next';

import ApiConfig from './ApiConfig';
import axiosWithRetry from 'src/Utilities/axiosWithRetry';
import type { LoginState } from 'src/types/Auth';

const API_URL_LOGIN = '/auth/login';
const API_URL_REFRESH_TOKEN = '/auth/refresh';
const API_URL_LOGOUT = '/auth/logout';
const API_URL_LOGIN_EXTERNAL = '/loginExternal';
const API_URL_ACTIVATE = '/activateUser';
const API_URL_FORGOT_PASSWORD = '/forgotpassword';

export default class AuthApi {
  static loginExternal = (token: string, username: string): Promise<LoginState> => {
    return axios
      .post(ApiConfig.getConfig().API_URL + API_URL_LOGIN_EXTERNAL, {
        username: username,
        coretoken: token
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        axios.defaults.headers.common = { Authorization: 'Bearer ' + localStorage.getItem('token') };
        return {
          hasLoggedIn: true,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: false
        };
      })
      .catch(() => {
        return {
          hasLoggedIn: false,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: true
        };
      });
  };

  static loginBackend = (): Promise<void> => {
    return Promise.resolve().then(() => {
      axios.defaults.withCredentials = true;
    });
  };

  static activate = (token: string, password: string): Promise<any> => {
    return axios
      .post(ApiConfig.getConfig().API_URL + API_URL_ACTIVATE, {
        token: token,
        password: password
      })
      .then(() => {
        return { success: 'true' };
      })
      .catch((error: any) => {
        if (error.response.data.msg === 'Token is not available') {
          iziToast.error({
            title: t('ERROR_HAPPENED'),
            message: t('ACTIVATION_TOKEN_NOT_AVAILABLE'),
            position: 'bottomRight',
            timeout: 10000,
            icon: 'delete'
          });
          throw error;
        } else {
          iziToast.error({
            title: t('ERROR_HAPPENED'),
            message: '',
            position: 'bottomRight',
            timeout: 10000,
            icon: 'delete'
          });
          throw error;
        }
      });
  };

  static forgotPasswordRequest = (email: string): Promise<any> => {
    return axios
      .post(ApiConfig.getConfig().API_URL + API_URL_FORGOT_PASSWORD, {
        email: email
      })
      .then(() => {
        return { success: 'true' };
      })
      .catch((error: any) => {
        return error;
      });
  };

  static refreshToken = async (): Promise<string> => {
    return axiosWithRetry.post(ApiConfig.getConfig().API_URL + API_URL_REFRESH_TOKEN).then((response) => {
      return response.data.accessTokenExpiration;
    });
  };

  static login = async (user: string, password: string): Promise<LoginState> => {
    axios.defaults.withCredentials = true;
    return axios
      .post(ApiConfig.getConfig().API_URL + API_URL_LOGIN, {
        username: user,
        password: password
      })
      .then((response) => {
        localStorage.setItem('loggedIn', 'true');
        axios.defaults.withCredentials = true;

        if (response.data.accessTokenExpiration)
          localStorage.setItem('accessTokenExpiration', response.data.accessTokenExpiration);

        return {
          hasLoggedIn: true,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: false
        };
      })
      .catch((error) => {
        if (error.response) {
          // server responsed with non 2xx code
          console.error('Error while logging in', error.response);
          switch (error.response.status) {
            case 400:
              iziToast.error({
                title: t('LOGIN_FAILURE'),
                message: t('LOGIN_CHECK_YOUR_CREDENTIALS'),
                position: 'center',
                timeout: 5000,
                icon: 'delete'
              });
              return {
                hasLoggedIn: false,
                hasEnteredIncorrectLoginCredentials: true,
                hasEncounteredGeneralLoginError: false
              };
            default:
              iziToast.error({
                title: t('LOGIN_FAILURE'),
                message: t('LOGIN_TRY_AGAIN_SOON'),
                position: 'center',
                timeout: 5000,
                icon: 'delete'
              });
              return {
                hasLoggedIn: false,
                hasEnteredIncorrectLoginCredentials: false,
                hasEncounteredGeneralLoginError: true
              };
          }
        } else if (error.request) {
          iziToast.error({
            title: t('LOGIN_FAILURE'),
            message: t('LOGIN_CHECK_YOUR_INTERNET_CONNECTION'),
            position: 'center',
            timeout: 5000,
            icon: 'delete'
          });
          return {
            hasLoggedIn: false,
            hasEnteredIncorrectLoginCredentials: false,
            hasEncounteredGeneralLoginError: true
          };
        } else {
          iziToast.error({
            title: t('LOGIN_FAILURE'),
            message: t('LOGIN_TRY_AGAIN_SOON'),
            position: 'center',
            timeout: 5000,
            icon: 'delete'
          });
          return {
            hasLoggedIn: false,
            hasEnteredIncorrectLoginCredentials: false,
            hasEncounteredGeneralLoginError: true
          };
        }
      });
  };

  static logout = async (forcedLogout = false): Promise<void> => {
    // New instance for axios to prevent it being applied to axios-auth-refresh logic
    return axios
      .create()
      .get(ApiConfig.getConfig().API_URL + API_URL_LOGOUT)
      .then(() => {
        if (!forcedLogout) {
          iziToast.success({
            message: t('LOGOUT_SUCCESSFUL'),
            position: 'bottomRight',
            icon: 'icon check'
          });
        }
      })
      .catch((error) => {
        console.error('Error while logout in authApi', error);
      });
  };
}
