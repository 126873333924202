import { connect } from 'react-redux';

import SimpleCase from '../Components/Case/SimpleCase';
import { addTab, closeTab, activateTab } from '../actions/tabActionsRTK';
import {
  activateTicket,
  addEntityToCase,
  removeEntityFromCase,
  addDelegateToContent,
  addDelegateGroupToContent,
  addTagToContent,
  addTicket,
  createContent,
  fetchTicket,
  removeDelegateFromContent,
  removeTagFromContent,
  updateEntityDetails,
  updateTicket,
  updateSingleTicketCaseDetail,
  uploadFile,
  editAttachment,
  deprecateAttachment,
  unDeprecateAttachment
} from '../actions/ticketsActions';
import { Task } from '../api/Task';
import { withRouter } from 'react-router-dom';
import SocketInstance from '../realTimeNotifications';
import { fetchTicketsByEntity } from '../actions/ticketsActions';
import { activateContentListTab, addContentListTab } from 'src/api/CaseListApi';
import { convertCaseNaming } from 'src/Utilities/helper';
import { addComment } from 'src/actions/commentsActions';
import { stopWorkingOn } from 'src/Utilities/workStatusParser';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';

const mapStateToProps = (state: any, ownProps: any) => {
  const id = ownProps.match.params.id;
  let task: any = new Task();
  if (state.userData && state.userData.userPreferences && state.userData.userPreferences.defaultTicketType) {
    task.taskType = state.userData.userPreferences.defaultTicketType;
  }

  if (state.router.location.pathname === '/case/new_edit') {
    task = null;
  } else if (id !== 'new' && id !== 'new_oc') {
    task = state.detailedTickets.find((ticket: any) => {
      return ticket.id === state.activeTicketTab;
    });
  }

  return {
    showEssentials: state.auth.external.token && state.auth.external.username,
    task: task,
    usersList: state.usersList.usersList,
    ticketTypes: state.ticketTypes,
    responseTemplates: state.templates.responseTemplates,
    ticketChannels: state.channels,
    externalLinks: state.linkLists,
    userData: state.userData,
    mobileMode: state.mobileReducer.mobileMode,
    ajaxCalls: state.ajaxCallsInProgress
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addTicket: (ticket: any) => {
      dispatch(addTicket(ticket));
    },
    addEntityToCase: (ticketId: string, body: object) => {
      dispatch(addEntityToCase(ticketId, body));
    },
    removeEntityFromCase: (ticketId: string, body: object) => {
      dispatch(removeEntityFromCase(ticketId, body));
    },
    activateTicket: (id: number) => {
      dispatch(activateTicket(id));
    },
    fetchTicket: (id: string) => {
      dispatch(fetchTicket({ id }));
    },
    updateTicketCaseDetails: (ticketId: string, updateKey: string, updateValue: any, group: string) => {
      dispatch(updateSingleTicketCaseDetail(ticketId, updateKey, updateValue, group));
    },
    updateEntityDetails: (
      ticketId: string,
      type: any,
      entityId: string,
      updateKey: string,
      updateValue: any,
      object: any,
      partial: boolean
    ) => {
      dispatch(updateEntityDetails(ticketId, type, entityId, updateKey, updateValue, object, partial));
    },
    updateTicket: (ticket: any) => {
      dispatch(updateTicket(ticket.id, ticket));
    },
    createTicket: (ticket: any) => {
      dispatch(createContent(ticket));
    },
    addComment: (ticketId: string, value: any) => {
      return dispatch(addComment(ticketId, value));
    },
    addTagToContent: (ticket: any, tagId: string, addNew: boolean) => {
      if (tagId.slice(0, 3) === 'TAG' || addNew) {
        dispatch(addTagToContent(ticket.id, tagId, addNew));
      }
    },
    removeTagFromContent: (ticket: any, tagId: string) => {
      dispatch(removeTagFromContent(ticket.id, tagId));
    },
    addDelegateToContent: (ticket: any, usr: string) => {
      dispatch(addDelegateToContent(ticket.id, usr));
    },
    addDelegateGroupToContent: (ticket: any, usrGroup: number[]) => {
      dispatch(addDelegateGroupToContent(ticket.id, usrGroup));
    },
    removeDelegateFromContent: (ticket: any, usr: string) => {
      dispatch(removeDelegateFromContent(ticket.id, usr));
    },
    uploadFile: (ticketId: string, file: any) => {
      dispatch(uploadFile(ticketId, file));
    },
    editAttachment: (ticketId: string, file: any) => {
      dispatch(editAttachment(ticketId, file));
    },
    deprecateAttachment: (ticketId: string, attachmentId: string, file: any) => {
      dispatch(deprecateAttachment(ticketId, attachmentId, file));
    },
    unDeprecateAttachment: (ticketId: string, attachmentId: string, file: any) => {
      dispatch(unDeprecateAttachment(ticketId, attachmentId, file));
    },
    onSearchPreviousTickets: (entityId: string) => {
      dispatch(addContentListTab(entityId, 'TAB_NAME_CUSTOMER #' + entityId.substring(0, 3), 'tickets'));
      dispatch(activateContentListTab(entityId, 'tickets'));
      dispatch(fetchTicketsByEntity(entityId, entityId, 'eeedoCustomer'));
    },
    closeAsDone: (task: Task, UID: string | undefined) => {
      if (UID !== undefined) {
        dispatch(ticketStopWorkingOn(...stopWorkingOn(UID, task.id)));
      }
      // mark as done
      const newTask = Object.assign({}, task);
      newTask.status = 'done';
      dispatch(updateTicket(newTask.id, newTask));
      // close tab
      dispatch(closeTab(task.id.substring(3)));
    },
    openTab: (id: string) => {
      SocketInstance.joinRoom(convertCaseNaming(id, 'string', 'task') as string);
      dispatch(addTab({ id, type: 'tickets' }));
    },
    activateTab: (id: number) => {
      dispatch(activateTab(id as any));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SimpleCase));
