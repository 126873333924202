import type { Field } from '../types/Info';

export const getInfoInputType = (field: Field) => (typeof field.value === 'number' ? 'number' : 'text');

export const processSubEntities = (fields: Field[], values: any): Field[] =>
  fields.reduce((acc, field) => {
    if (field?.customType !== 'subEntity') {
      return [...acc, field];
    }

  let subFields = [] as Field[] 
  const targetSubEntity = values?.subEntities?.[values[field.params.getByValue] + field.params.entityType];
  if (targetSubEntity) {
    if (targetSubEntity.statusCode !== 200) {
      subFields = [{
        name: field.name,
        value: field.value,
        customType: 'errorMessage'
      }];
    } else {
      subFields = (field.value as unknown as any[]).map((subField) => ({
        name: subField.name,
        value: subField.value,
        customType: "subEntityField",
        params: Object.assign(subField.params || {}, field.params)
      }));
    }
  } else {
    subFields = [{
      name: field.name,
      value: field.name!,
      customType: 'loadingChevron',
      params: field.params
    }]
  }

    return [...acc, ...subFields];
  }, [] as Field[]);
