import axios from 'axios';

import ApiConfig from './ApiConfig';
import type { Language } from 'src/types/Language';

const LANG_LIST: Language[] = [
  { id: 'fi', flag: 'fi', name: 'Suomi', momentLocale: 'fi' },
  { id: 'en', flag: 'gb', name: 'English', momentLocale: 'en' },
  { id: 'se', flag: 'se', name: 'Svenska', momentLocale: 'se' },
  { id: 'be', flag: 'nl', name: 'Nederlands', momentLocale: 'nl-be' }
];

/**
 * TODO: Replace fixed list by database lookup with cache.
 */
export function getLanguages(): Language[] {
  return LANG_LIST;
}

export async function setLanguage(langId: string) {
  await axios.patch(ApiConfig.getConfig().API_URL + '/setLanguage', { langId });
}
