import _ from 'lodash';

import type { User } from 'src/types/User';
import type { Channel } from 'src/types/Channel';
import type { Attachment, CommentMetadata, Ticket, TicketDirection } from 'src/types/Ticket';

export const getCommentAttachmentsIds = (
  commentMetadata: CommentMetadata | null | undefined,
  task: Ticket
): string[] => {
  const source = !_.isEmpty(_.get(commentMetadata, ['originalTicketData', 'data', 'attachments']))
    ? _.get(commentMetadata, ['originalTicketData', 'data', 'attachments'])
    : _.get(commentMetadata, ['attachments']);
  const commentAttachments = _.map(source, (att) => att.filename || att.fileName);

  const attachmentIds = _.filter(task.attachments, (t: Attachment) => commentAttachments.includes(t.fileName)).map(
    (attObj) => attObj.id
  );

  return attachmentIds;
};

export const getCommentSender = ({
  entityData,
  channelData,
  user,
  metaData,
  direction
}: {
  entityData: any;
  channelData: Channel | undefined;
  user: User;
  metaData: CommentMetadata | null | undefined;
  direction: TicketDirection | undefined | null;
}): string => {
  switch (channelData?.channel) {
    case 'email':
      if (entityData && direction === 'in') {
        return entityData.email;
      } else if (!entityData && direction === 'in') {
        return _.get(metaData, ['from', 0]);
      } else {
        return `${((user || {}).profile || {}).firstName || ''} ${((user || {}).profile || {}).lastName || ''}`;
      }
    case 'webform':
      if (entityData && direction === 'in') {
        return entityData.email;
      } else {
        return `${((user || {}).profile || {}).firstName || ''} ${((user || {}).profile || {}).lastName || ''}`;
      }
    case 'whatsapp':
      if (metaData && metaData.name) {
        return `${metaData.name}`;
      }
  }
  return `${((user || {}).profile || {}).firstName || ''} ${((user || {}).profile || {}).lastName || ''}`;
};
