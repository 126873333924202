import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface CommentWidgetButtonsProps {
  buttonsType?: 'basic' | 'primary';

  onReplyClick: () => void;
  onReplyAllClick?: () => void;
  onReplyForwardClick?: () => void;
}

export const CommentWidgetButtons = (props: CommentWidgetButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ maxHeight: '30px', whiteSpace: 'nowrap', width: '100%' }}>
      <Button
        visible
        size="mini"
        {...(props.buttonsType && {
          [props.buttonsType]: true
        })}
        icon={true}
        labelPosition="left"
        onClick={props.onReplyClick}
      >
        <Icon name="reply" />
        {t('COMMENT_MAIL_REPLY')}
      </Button>

      {props.onReplyAllClick && (
        <Button
          visible
          style={{ marginLeft: '3px' }}
          size="mini"
          {...(props.buttonsType && {
            [props.buttonsType]: true
          })}
          icon={true}
          labelPosition="left"
          onClick={props.onReplyAllClick}
        >
          <Icon name="reply all" />
          {t('COMMENT_MAIL_REPLY_ALL')}
        </Button>
      )}

      {props.onReplyForwardClick && (
        <Button
          visible
          style={{ marginLeft: '3px' }}
          size="mini"
          {...(props.buttonsType && {
            [props.buttonsType]: true
          })}
          icon={true}
          labelPosition="left"
          onClick={props.onReplyForwardClick}
        >
          <Icon name="mail forward" />
          {t('COMMENT_MAIL_FORWARD')}
        </Button>
      )}
    </div>
  );
};
